/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/team-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/common";
import * as dependency_2 from "./../../protocol/concept";
import * as pb_1 from "google-protobuf";
export class TeamCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProto {
        const message = new TeamCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProto {
        return TeamCtlProto.deserialize(bytes);
    }
}
export class TeamCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: TeamCtlProtoGetAccessLevelsReq;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: TeamCtlProtoCheckTeamIDReq;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: TeamCtlProtoCreateTeamReq;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: TeamCtlProtoGetTeamReq;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: TeamCtlProtoUpdateTeamReq;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: TeamCtlProtoGetMemberReq;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: TeamCtlProtoListSpacesReq;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: TeamCtlProtoListSquadsReq;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: TeamCtlProtoListMembersReq;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: TeamCtlProtoListInvitationsReq;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: TeamCtlProtoListClustersReq;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: TeamCtlProtoListAPITokensReq;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: TeamCtlProtoInviteMemberReq;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: TeamCtlProtoResendInvitationReq;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: TeamCtlProtoRevokeInvitationReq;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: TeamCtlProtoRemoveMemberReq;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: TeamCtlProtoSetMemberAccessLevelReq;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: TeamCtlProtoRegisterAPITokenReq;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: TeamCtlProtoRevokeAPITokenReq;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: TeamCtlProtoGetFreeClusterCountReq;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        registerApiToken?: never;
        revokeApiToken?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: TeamCtlProtoGetPayableClusterOptionsReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkTeamId" in data && data.checkTeamId != undefined) {
                this.checkTeamId = data.checkTeamId;
            }
            if ("createTeam" in data && data.createTeam != undefined) {
                this.createTeam = data.createTeam;
            }
            if ("getTeam" in data && data.getTeam != undefined) {
                this.getTeam = data.getTeam;
            }
            if ("updateTeam" in data && data.updateTeam != undefined) {
                this.updateTeam = data.updateTeam;
            }
            if ("getMember" in data && data.getMember != undefined) {
                this.getMember = data.getMember;
            }
            if ("listSpaces" in data && data.listSpaces != undefined) {
                this.listSpaces = data.listSpaces;
            }
            if ("listSquads" in data && data.listSquads != undefined) {
                this.listSquads = data.listSquads;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
            if ("listClusters" in data && data.listClusters != undefined) {
                this.listClusters = data.listClusters;
            }
            if ("listApiTokens" in data && data.listApiTokens != undefined) {
                this.listApiTokens = data.listApiTokens;
            }
            if ("inviteMember" in data && data.inviteMember != undefined) {
                this.inviteMember = data.inviteMember;
            }
            if ("resendInvitation" in data && data.resendInvitation != undefined) {
                this.resendInvitation = data.resendInvitation;
            }
            if ("revokeInvitation" in data && data.revokeInvitation != undefined) {
                this.revokeInvitation = data.revokeInvitation;
            }
            if ("removeMember" in data && data.removeMember != undefined) {
                this.removeMember = data.removeMember;
            }
            if ("setMemberAccessLevel" in data && data.setMemberAccessLevel != undefined) {
                this.setMemberAccessLevel = data.setMemberAccessLevel;
            }
            if ("registerApiToken" in data && data.registerApiToken != undefined) {
                this.registerApiToken = data.registerApiToken;
            }
            if ("revokeApiToken" in data && data.revokeApiToken != undefined) {
                this.revokeApiToken = data.revokeApiToken;
            }
            if ("getFreeClusterCount" in data && data.getFreeClusterCount != undefined) {
                this.getFreeClusterCount = data.getFreeClusterCount;
            }
            if ("getPayableClusterOptions" in data && data.getPayableClusterOptions != undefined) {
                this.getPayableClusterOptions = data.getPayableClusterOptions;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetAccessLevelsReq, 1) as TeamCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: TeamCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkTeamId() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCheckTeamIDReq, 2) as TeamCtlProtoCheckTeamIDReq;
    }
    set checkTeamId(value: TeamCtlProtoCheckTeamIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckTeamId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCreateTeamReq, 3) as TeamCtlProtoCreateTeamReq;
    }
    set createTeam(value: TeamCtlProtoCreateTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateTeam() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetTeamReq, 4) as TeamCtlProtoGetTeamReq;
    }
    set getTeam(value: TeamCtlProtoGetTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetTeam() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoUpdateTeamReq, 5) as TeamCtlProtoUpdateTeamReq;
    }
    set updateTeam(value: TeamCtlProtoUpdateTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateTeam() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get getMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetMemberReq, 7) as TeamCtlProtoGetMemberReq;
    }
    set getMember(value: TeamCtlProtoGetMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetMember() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSpaces() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListSpacesReq, 8) as TeamCtlProtoListSpacesReq;
    }
    set listSpaces(value: TeamCtlProtoListSpacesReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSpaces() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listSquads() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListSquadsReq, 9) as TeamCtlProtoListSquadsReq;
    }
    set listSquads(value: TeamCtlProtoListSquadsReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListSquads() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListMembersReq, 10) as TeamCtlProtoListMembersReq;
    }
    set listMembers(value: TeamCtlProtoListMembersReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListInvitationsReq, 11) as TeamCtlProtoListInvitationsReq;
    }
    set listInvitations(value: TeamCtlProtoListInvitationsReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listClusters() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListClustersReq, 12) as TeamCtlProtoListClustersReq;
    }
    set listClusters(value: TeamCtlProtoListClustersReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListClusters() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listApiTokens() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListAPITokensReq, 13) as TeamCtlProtoListAPITokensReq;
    }
    set listApiTokens(value: TeamCtlProtoListAPITokensReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListApiTokens() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get inviteMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoInviteMemberReq, 14) as TeamCtlProtoInviteMemberReq;
    }
    set inviteMember(value: TeamCtlProtoInviteMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasInviteMember() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get resendInvitation() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoResendInvitationReq, 15) as TeamCtlProtoResendInvitationReq;
    }
    set resendInvitation(value: TeamCtlProtoResendInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasResendInvitation() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get revokeInvitation() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoRevokeInvitationReq, 16) as TeamCtlProtoRevokeInvitationReq;
    }
    set revokeInvitation(value: TeamCtlProtoRevokeInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
    }
    get hasRevokeInvitation() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get removeMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoRemoveMemberReq, 17) as TeamCtlProtoRemoveMemberReq;
    }
    set removeMember(value: TeamCtlProtoRemoveMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasRemoveMember() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get setMemberAccessLevel() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoSetMemberAccessLevelReq, 18) as TeamCtlProtoSetMemberAccessLevelReq;
    }
    set setMemberAccessLevel(value: TeamCtlProtoSetMemberAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasSetMemberAccessLevel() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get registerApiToken() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoRegisterAPITokenReq, 19) as TeamCtlProtoRegisterAPITokenReq;
    }
    set registerApiToken(value: TeamCtlProtoRegisterAPITokenReq) {
        pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0], value);
    }
    get hasRegisterApiToken() {
        return pb_1.Message.getField(this, 19) != null;
    }
    get revokeApiToken() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoRevokeAPITokenReq, 20) as TeamCtlProtoRevokeAPITokenReq;
    }
    set revokeApiToken(value: TeamCtlProtoRevokeAPITokenReq) {
        pb_1.Message.setOneofWrapperField(this, 20, this.#one_of_decls[0], value);
    }
    get hasRevokeApiToken() {
        return pb_1.Message.getField(this, 20) != null;
    }
    get getFreeClusterCount() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetFreeClusterCountReq, 21) as TeamCtlProtoGetFreeClusterCountReq;
    }
    set getFreeClusterCount(value: TeamCtlProtoGetFreeClusterCountReq) {
        pb_1.Message.setOneofWrapperField(this, 21, this.#one_of_decls[0], value);
    }
    get hasGetFreeClusterCount() {
        return pb_1.Message.getField(this, 21) != null;
    }
    get getPayableClusterOptions() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetPayableClusterOptionsReq, 22) as TeamCtlProtoGetPayableClusterOptionsReq;
    }
    set getPayableClusterOptions(value: TeamCtlProtoGetPayableClusterOptionsReq) {
        pb_1.Message.setOneofWrapperField(this, 22, this.#one_of_decls[0], value);
    }
    get hasGetPayableClusterOptions() {
        return pb_1.Message.getField(this, 22) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkTeamId" | "createTeam" | "getTeam" | "updateTeam" | "getMember" | "listSpaces" | "listSquads" | "listMembers" | "listInvitations" | "listClusters" | "listApiTokens" | "inviteMember" | "resendInvitation" | "revokeInvitation" | "removeMember" | "setMemberAccessLevel" | "registerApiToken" | "revokeApiToken" | "getFreeClusterCount" | "getPayableClusterOptions";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkTeamId",
            3: "createTeam",
            4: "getTeam",
            5: "updateTeam",
            7: "getMember",
            8: "listSpaces",
            9: "listSquads",
            10: "listMembers",
            11: "listInvitations",
            12: "listClusters",
            13: "listApiTokens",
            14: "inviteMember",
            15: "resendInvitation",
            16: "revokeInvitation",
            17: "removeMember",
            18: "setMemberAccessLevel",
            19: "registerApiToken",
            20: "revokeApiToken",
            21: "getFreeClusterCount",
            22: "getPayableClusterOptions"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDReq.prototype.toObject>;
        createTeam?: ReturnType<typeof TeamCtlProtoCreateTeamReq.prototype.toObject>;
        getTeam?: ReturnType<typeof TeamCtlProtoGetTeamReq.prototype.toObject>;
        updateTeam?: ReturnType<typeof TeamCtlProtoUpdateTeamReq.prototype.toObject>;
        getMember?: ReturnType<typeof TeamCtlProtoGetMemberReq.prototype.toObject>;
        listSpaces?: ReturnType<typeof TeamCtlProtoListSpacesReq.prototype.toObject>;
        listSquads?: ReturnType<typeof TeamCtlProtoListSquadsReq.prototype.toObject>;
        listMembers?: ReturnType<typeof TeamCtlProtoListMembersReq.prototype.toObject>;
        listInvitations?: ReturnType<typeof TeamCtlProtoListInvitationsReq.prototype.toObject>;
        listClusters?: ReturnType<typeof TeamCtlProtoListClustersReq.prototype.toObject>;
        listApiTokens?: ReturnType<typeof TeamCtlProtoListAPITokensReq.prototype.toObject>;
        inviteMember?: ReturnType<typeof TeamCtlProtoInviteMemberReq.prototype.toObject>;
        resendInvitation?: ReturnType<typeof TeamCtlProtoResendInvitationReq.prototype.toObject>;
        revokeInvitation?: ReturnType<typeof TeamCtlProtoRevokeInvitationReq.prototype.toObject>;
        removeMember?: ReturnType<typeof TeamCtlProtoRemoveMemberReq.prototype.toObject>;
        setMemberAccessLevel?: ReturnType<typeof TeamCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
        registerApiToken?: ReturnType<typeof TeamCtlProtoRegisterAPITokenReq.prototype.toObject>;
        revokeApiToken?: ReturnType<typeof TeamCtlProtoRevokeAPITokenReq.prototype.toObject>;
        getFreeClusterCount?: ReturnType<typeof TeamCtlProtoGetFreeClusterCountReq.prototype.toObject>;
        getPayableClusterOptions?: ReturnType<typeof TeamCtlProtoGetPayableClusterOptionsReq.prototype.toObject>;
    }): TeamCtlProtoReq {
        const message = new TeamCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = TeamCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkTeamId != null) {
            message.checkTeamId = TeamCtlProtoCheckTeamIDReq.fromObject(data.checkTeamId);
        }
        if (data.createTeam != null) {
            message.createTeam = TeamCtlProtoCreateTeamReq.fromObject(data.createTeam);
        }
        if (data.getTeam != null) {
            message.getTeam = TeamCtlProtoGetTeamReq.fromObject(data.getTeam);
        }
        if (data.updateTeam != null) {
            message.updateTeam = TeamCtlProtoUpdateTeamReq.fromObject(data.updateTeam);
        }
        if (data.getMember != null) {
            message.getMember = TeamCtlProtoGetMemberReq.fromObject(data.getMember);
        }
        if (data.listSpaces != null) {
            message.listSpaces = TeamCtlProtoListSpacesReq.fromObject(data.listSpaces);
        }
        if (data.listSquads != null) {
            message.listSquads = TeamCtlProtoListSquadsReq.fromObject(data.listSquads);
        }
        if (data.listMembers != null) {
            message.listMembers = TeamCtlProtoListMembersReq.fromObject(data.listMembers);
        }
        if (data.listInvitations != null) {
            message.listInvitations = TeamCtlProtoListInvitationsReq.fromObject(data.listInvitations);
        }
        if (data.listClusters != null) {
            message.listClusters = TeamCtlProtoListClustersReq.fromObject(data.listClusters);
        }
        if (data.listApiTokens != null) {
            message.listApiTokens = TeamCtlProtoListAPITokensReq.fromObject(data.listApiTokens);
        }
        if (data.inviteMember != null) {
            message.inviteMember = TeamCtlProtoInviteMemberReq.fromObject(data.inviteMember);
        }
        if (data.resendInvitation != null) {
            message.resendInvitation = TeamCtlProtoResendInvitationReq.fromObject(data.resendInvitation);
        }
        if (data.revokeInvitation != null) {
            message.revokeInvitation = TeamCtlProtoRevokeInvitationReq.fromObject(data.revokeInvitation);
        }
        if (data.removeMember != null) {
            message.removeMember = TeamCtlProtoRemoveMemberReq.fromObject(data.removeMember);
        }
        if (data.setMemberAccessLevel != null) {
            message.setMemberAccessLevel = TeamCtlProtoSetMemberAccessLevelReq.fromObject(data.setMemberAccessLevel);
        }
        if (data.registerApiToken != null) {
            message.registerApiToken = TeamCtlProtoRegisterAPITokenReq.fromObject(data.registerApiToken);
        }
        if (data.revokeApiToken != null) {
            message.revokeApiToken = TeamCtlProtoRevokeAPITokenReq.fromObject(data.revokeApiToken);
        }
        if (data.getFreeClusterCount != null) {
            message.getFreeClusterCount = TeamCtlProtoGetFreeClusterCountReq.fromObject(data.getFreeClusterCount);
        }
        if (data.getPayableClusterOptions != null) {
            message.getPayableClusterOptions = TeamCtlProtoGetPayableClusterOptionsReq.fromObject(data.getPayableClusterOptions);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDReq.prototype.toObject>;
            createTeam?: ReturnType<typeof TeamCtlProtoCreateTeamReq.prototype.toObject>;
            getTeam?: ReturnType<typeof TeamCtlProtoGetTeamReq.prototype.toObject>;
            updateTeam?: ReturnType<typeof TeamCtlProtoUpdateTeamReq.prototype.toObject>;
            getMember?: ReturnType<typeof TeamCtlProtoGetMemberReq.prototype.toObject>;
            listSpaces?: ReturnType<typeof TeamCtlProtoListSpacesReq.prototype.toObject>;
            listSquads?: ReturnType<typeof TeamCtlProtoListSquadsReq.prototype.toObject>;
            listMembers?: ReturnType<typeof TeamCtlProtoListMembersReq.prototype.toObject>;
            listInvitations?: ReturnType<typeof TeamCtlProtoListInvitationsReq.prototype.toObject>;
            listClusters?: ReturnType<typeof TeamCtlProtoListClustersReq.prototype.toObject>;
            listApiTokens?: ReturnType<typeof TeamCtlProtoListAPITokensReq.prototype.toObject>;
            inviteMember?: ReturnType<typeof TeamCtlProtoInviteMemberReq.prototype.toObject>;
            resendInvitation?: ReturnType<typeof TeamCtlProtoResendInvitationReq.prototype.toObject>;
            revokeInvitation?: ReturnType<typeof TeamCtlProtoRevokeInvitationReq.prototype.toObject>;
            removeMember?: ReturnType<typeof TeamCtlProtoRemoveMemberReq.prototype.toObject>;
            setMemberAccessLevel?: ReturnType<typeof TeamCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
            registerApiToken?: ReturnType<typeof TeamCtlProtoRegisterAPITokenReq.prototype.toObject>;
            revokeApiToken?: ReturnType<typeof TeamCtlProtoRevokeAPITokenReq.prototype.toObject>;
            getFreeClusterCount?: ReturnType<typeof TeamCtlProtoGetFreeClusterCountReq.prototype.toObject>;
            getPayableClusterOptions?: ReturnType<typeof TeamCtlProtoGetPayableClusterOptionsReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkTeamId != null) {
            data.checkTeamId = this.checkTeamId.toObject();
        }
        if (this.createTeam != null) {
            data.createTeam = this.createTeam.toObject();
        }
        if (this.getTeam != null) {
            data.getTeam = this.getTeam.toObject();
        }
        if (this.updateTeam != null) {
            data.updateTeam = this.updateTeam.toObject();
        }
        if (this.getMember != null) {
            data.getMember = this.getMember.toObject();
        }
        if (this.listSpaces != null) {
            data.listSpaces = this.listSpaces.toObject();
        }
        if (this.listSquads != null) {
            data.listSquads = this.listSquads.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        if (this.listClusters != null) {
            data.listClusters = this.listClusters.toObject();
        }
        if (this.listApiTokens != null) {
            data.listApiTokens = this.listApiTokens.toObject();
        }
        if (this.inviteMember != null) {
            data.inviteMember = this.inviteMember.toObject();
        }
        if (this.resendInvitation != null) {
            data.resendInvitation = this.resendInvitation.toObject();
        }
        if (this.revokeInvitation != null) {
            data.revokeInvitation = this.revokeInvitation.toObject();
        }
        if (this.removeMember != null) {
            data.removeMember = this.removeMember.toObject();
        }
        if (this.setMemberAccessLevel != null) {
            data.setMemberAccessLevel = this.setMemberAccessLevel.toObject();
        }
        if (this.registerApiToken != null) {
            data.registerApiToken = this.registerApiToken.toObject();
        }
        if (this.revokeApiToken != null) {
            data.revokeApiToken = this.revokeApiToken.toObject();
        }
        if (this.getFreeClusterCount != null) {
            data.getFreeClusterCount = this.getFreeClusterCount.toObject();
        }
        if (this.getPayableClusterOptions != null) {
            data.getPayableClusterOptions = this.getPayableClusterOptions.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckTeamId)
            writer.writeMessage(2, this.checkTeamId, () => this.checkTeamId.serialize(writer));
        if (this.hasCreateTeam)
            writer.writeMessage(3, this.createTeam, () => this.createTeam.serialize(writer));
        if (this.hasGetTeam)
            writer.writeMessage(4, this.getTeam, () => this.getTeam.serialize(writer));
        if (this.hasUpdateTeam)
            writer.writeMessage(5, this.updateTeam, () => this.updateTeam.serialize(writer));
        if (this.hasGetMember)
            writer.writeMessage(7, this.getMember, () => this.getMember.serialize(writer));
        if (this.hasListSpaces)
            writer.writeMessage(8, this.listSpaces, () => this.listSpaces.serialize(writer));
        if (this.hasListSquads)
            writer.writeMessage(9, this.listSquads, () => this.listSquads.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(10, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(11, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (this.hasListClusters)
            writer.writeMessage(12, this.listClusters, () => this.listClusters.serialize(writer));
        if (this.hasListApiTokens)
            writer.writeMessage(13, this.listApiTokens, () => this.listApiTokens.serialize(writer));
        if (this.hasInviteMember)
            writer.writeMessage(14, this.inviteMember, () => this.inviteMember.serialize(writer));
        if (this.hasResendInvitation)
            writer.writeMessage(15, this.resendInvitation, () => this.resendInvitation.serialize(writer));
        if (this.hasRevokeInvitation)
            writer.writeMessage(16, this.revokeInvitation, () => this.revokeInvitation.serialize(writer));
        if (this.hasRemoveMember)
            writer.writeMessage(17, this.removeMember, () => this.removeMember.serialize(writer));
        if (this.hasSetMemberAccessLevel)
            writer.writeMessage(18, this.setMemberAccessLevel, () => this.setMemberAccessLevel.serialize(writer));
        if (this.hasRegisterApiToken)
            writer.writeMessage(19, this.registerApiToken, () => this.registerApiToken.serialize(writer));
        if (this.hasRevokeApiToken)
            writer.writeMessage(20, this.revokeApiToken, () => this.revokeApiToken.serialize(writer));
        if (this.hasGetFreeClusterCount)
            writer.writeMessage(21, this.getFreeClusterCount, () => this.getFreeClusterCount.serialize(writer));
        if (this.hasGetPayableClusterOptions)
            writer.writeMessage(22, this.getPayableClusterOptions, () => this.getPayableClusterOptions.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = TeamCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkTeamId, () => message.checkTeamId = TeamCtlProtoCheckTeamIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createTeam, () => message.createTeam = TeamCtlProtoCreateTeamReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getTeam, () => message.getTeam = TeamCtlProtoGetTeamReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateTeam, () => message.updateTeam = TeamCtlProtoUpdateTeamReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getMember, () => message.getMember = TeamCtlProtoGetMemberReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSpaces, () => message.listSpaces = TeamCtlProtoListSpacesReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listSquads, () => message.listSquads = TeamCtlProtoListSquadsReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listMembers, () => message.listMembers = TeamCtlProtoListMembersReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = TeamCtlProtoListInvitationsReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listClusters, () => message.listClusters = TeamCtlProtoListClustersReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listApiTokens, () => message.listApiTokens = TeamCtlProtoListAPITokensReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.inviteMember, () => message.inviteMember = TeamCtlProtoInviteMemberReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.resendInvitation, () => message.resendInvitation = TeamCtlProtoResendInvitationReq.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.revokeInvitation, () => message.revokeInvitation = TeamCtlProtoRevokeInvitationReq.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.removeMember, () => message.removeMember = TeamCtlProtoRemoveMemberReq.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.setMemberAccessLevel, () => message.setMemberAccessLevel = TeamCtlProtoSetMemberAccessLevelReq.deserialize(reader));
                    break;
                case 19:
                    reader.readMessage(message.registerApiToken, () => message.registerApiToken = TeamCtlProtoRegisterAPITokenReq.deserialize(reader));
                    break;
                case 20:
                    reader.readMessage(message.revokeApiToken, () => message.revokeApiToken = TeamCtlProtoRevokeAPITokenReq.deserialize(reader));
                    break;
                case 21:
                    reader.readMessage(message.getFreeClusterCount, () => message.getFreeClusterCount = TeamCtlProtoGetFreeClusterCountReq.deserialize(reader));
                    break;
                case 22:
                    reader.readMessage(message.getPayableClusterOptions, () => message.getPayableClusterOptions = TeamCtlProtoGetPayableClusterOptionsReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoReq {
        return TeamCtlProtoReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8, 9, 10, 11, 12, 13, 17, 18]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: TeamCtlProtoGetAccessLevelsRes;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: TeamCtlProtoCheckTeamIDRes;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: TeamCtlProtoGetTeamRes;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: TeamCtlProtoGetMemberRes;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: TeamCtlProtoListSpacesRes;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: TeamCtlProtoListSquadsRes;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: TeamCtlProtoListMembersRes;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: TeamCtlProtoListInvitationsRes;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: TeamCtlProtoListClustersRes;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: TeamCtlProtoListAPITokensRes;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: TeamCtlProtoGetFreeClusterCountRes;
        getPayableClusterOptions?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        getMember?: never;
        listSpaces?: never;
        listSquads?: never;
        listMembers?: never;
        listInvitations?: never;
        listClusters?: never;
        listApiTokens?: never;
        getFreeClusterCount?: never;
        getPayableClusterOptions?: TeamCtlProtoGetPayableClusterOptionsRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkTeamId" in data && data.checkTeamId != undefined) {
                this.checkTeamId = data.checkTeamId;
            }
            if ("getTeam" in data && data.getTeam != undefined) {
                this.getTeam = data.getTeam;
            }
            if ("getMember" in data && data.getMember != undefined) {
                this.getMember = data.getMember;
            }
            if ("listSpaces" in data && data.listSpaces != undefined) {
                this.listSpaces = data.listSpaces;
            }
            if ("listSquads" in data && data.listSquads != undefined) {
                this.listSquads = data.listSquads;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
            if ("listClusters" in data && data.listClusters != undefined) {
                this.listClusters = data.listClusters;
            }
            if ("listApiTokens" in data && data.listApiTokens != undefined) {
                this.listApiTokens = data.listApiTokens;
            }
            if ("getFreeClusterCount" in data && data.getFreeClusterCount != undefined) {
                this.getFreeClusterCount = data.getFreeClusterCount;
            }
            if ("getPayableClusterOptions" in data && data.getPayableClusterOptions != undefined) {
                this.getPayableClusterOptions = data.getPayableClusterOptions;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetAccessLevelsRes, 1) as TeamCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: TeamCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkTeamId() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCheckTeamIDRes, 2) as TeamCtlProtoCheckTeamIDRes;
    }
    set checkTeamId(value: TeamCtlProtoCheckTeamIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckTeamId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetTeamRes, 4) as TeamCtlProtoGetTeamRes;
    }
    set getTeam(value: TeamCtlProtoGetTeamRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetTeam() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetMemberRes, 7) as TeamCtlProtoGetMemberRes;
    }
    set getMember(value: TeamCtlProtoGetMemberRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetMember() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSpaces() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListSpacesRes, 8) as TeamCtlProtoListSpacesRes;
    }
    set listSpaces(value: TeamCtlProtoListSpacesRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSpaces() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listSquads() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListSquadsRes, 9) as TeamCtlProtoListSquadsRes;
    }
    set listSquads(value: TeamCtlProtoListSquadsRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListSquads() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListMembersRes, 10) as TeamCtlProtoListMembersRes;
    }
    set listMembers(value: TeamCtlProtoListMembersRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListInvitationsRes, 11) as TeamCtlProtoListInvitationsRes;
    }
    set listInvitations(value: TeamCtlProtoListInvitationsRes) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listClusters() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListClustersRes, 12) as TeamCtlProtoListClustersRes;
    }
    set listClusters(value: TeamCtlProtoListClustersRes) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListClusters() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listApiTokens() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListAPITokensRes, 13) as TeamCtlProtoListAPITokensRes;
    }
    set listApiTokens(value: TeamCtlProtoListAPITokensRes) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListApiTokens() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get getFreeClusterCount() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetFreeClusterCountRes, 17) as TeamCtlProtoGetFreeClusterCountRes;
    }
    set getFreeClusterCount(value: TeamCtlProtoGetFreeClusterCountRes) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasGetFreeClusterCount() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get getPayableClusterOptions() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetPayableClusterOptionsRes, 18) as TeamCtlProtoGetPayableClusterOptionsRes;
    }
    set getPayableClusterOptions(value: TeamCtlProtoGetPayableClusterOptionsRes) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasGetPayableClusterOptions() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkTeamId" | "getTeam" | "getMember" | "listSpaces" | "listSquads" | "listMembers" | "listInvitations" | "listClusters" | "listApiTokens" | "getFreeClusterCount" | "getPayableClusterOptions";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkTeamId",
            4: "getTeam",
            7: "getMember",
            8: "listSpaces",
            9: "listSquads",
            10: "listMembers",
            11: "listInvitations",
            12: "listClusters",
            13: "listApiTokens",
            17: "getFreeClusterCount",
            18: "getPayableClusterOptions"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8, 9, 10, 11, 12, 13, 17, 18])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDRes.prototype.toObject>;
        getTeam?: ReturnType<typeof TeamCtlProtoGetTeamRes.prototype.toObject>;
        getMember?: ReturnType<typeof TeamCtlProtoGetMemberRes.prototype.toObject>;
        listSpaces?: ReturnType<typeof TeamCtlProtoListSpacesRes.prototype.toObject>;
        listSquads?: ReturnType<typeof TeamCtlProtoListSquadsRes.prototype.toObject>;
        listMembers?: ReturnType<typeof TeamCtlProtoListMembersRes.prototype.toObject>;
        listInvitations?: ReturnType<typeof TeamCtlProtoListInvitationsRes.prototype.toObject>;
        listClusters?: ReturnType<typeof TeamCtlProtoListClustersRes.prototype.toObject>;
        listApiTokens?: ReturnType<typeof TeamCtlProtoListAPITokensRes.prototype.toObject>;
        getFreeClusterCount?: ReturnType<typeof TeamCtlProtoGetFreeClusterCountRes.prototype.toObject>;
        getPayableClusterOptions?: ReturnType<typeof TeamCtlProtoGetPayableClusterOptionsRes.prototype.toObject>;
    }): TeamCtlProtoRes {
        const message = new TeamCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = TeamCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkTeamId != null) {
            message.checkTeamId = TeamCtlProtoCheckTeamIDRes.fromObject(data.checkTeamId);
        }
        if (data.getTeam != null) {
            message.getTeam = TeamCtlProtoGetTeamRes.fromObject(data.getTeam);
        }
        if (data.getMember != null) {
            message.getMember = TeamCtlProtoGetMemberRes.fromObject(data.getMember);
        }
        if (data.listSpaces != null) {
            message.listSpaces = TeamCtlProtoListSpacesRes.fromObject(data.listSpaces);
        }
        if (data.listSquads != null) {
            message.listSquads = TeamCtlProtoListSquadsRes.fromObject(data.listSquads);
        }
        if (data.listMembers != null) {
            message.listMembers = TeamCtlProtoListMembersRes.fromObject(data.listMembers);
        }
        if (data.listInvitations != null) {
            message.listInvitations = TeamCtlProtoListInvitationsRes.fromObject(data.listInvitations);
        }
        if (data.listClusters != null) {
            message.listClusters = TeamCtlProtoListClustersRes.fromObject(data.listClusters);
        }
        if (data.listApiTokens != null) {
            message.listApiTokens = TeamCtlProtoListAPITokensRes.fromObject(data.listApiTokens);
        }
        if (data.getFreeClusterCount != null) {
            message.getFreeClusterCount = TeamCtlProtoGetFreeClusterCountRes.fromObject(data.getFreeClusterCount);
        }
        if (data.getPayableClusterOptions != null) {
            message.getPayableClusterOptions = TeamCtlProtoGetPayableClusterOptionsRes.fromObject(data.getPayableClusterOptions);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDRes.prototype.toObject>;
            getTeam?: ReturnType<typeof TeamCtlProtoGetTeamRes.prototype.toObject>;
            getMember?: ReturnType<typeof TeamCtlProtoGetMemberRes.prototype.toObject>;
            listSpaces?: ReturnType<typeof TeamCtlProtoListSpacesRes.prototype.toObject>;
            listSquads?: ReturnType<typeof TeamCtlProtoListSquadsRes.prototype.toObject>;
            listMembers?: ReturnType<typeof TeamCtlProtoListMembersRes.prototype.toObject>;
            listInvitations?: ReturnType<typeof TeamCtlProtoListInvitationsRes.prototype.toObject>;
            listClusters?: ReturnType<typeof TeamCtlProtoListClustersRes.prototype.toObject>;
            listApiTokens?: ReturnType<typeof TeamCtlProtoListAPITokensRes.prototype.toObject>;
            getFreeClusterCount?: ReturnType<typeof TeamCtlProtoGetFreeClusterCountRes.prototype.toObject>;
            getPayableClusterOptions?: ReturnType<typeof TeamCtlProtoGetPayableClusterOptionsRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkTeamId != null) {
            data.checkTeamId = this.checkTeamId.toObject();
        }
        if (this.getTeam != null) {
            data.getTeam = this.getTeam.toObject();
        }
        if (this.getMember != null) {
            data.getMember = this.getMember.toObject();
        }
        if (this.listSpaces != null) {
            data.listSpaces = this.listSpaces.toObject();
        }
        if (this.listSquads != null) {
            data.listSquads = this.listSquads.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        if (this.listClusters != null) {
            data.listClusters = this.listClusters.toObject();
        }
        if (this.listApiTokens != null) {
            data.listApiTokens = this.listApiTokens.toObject();
        }
        if (this.getFreeClusterCount != null) {
            data.getFreeClusterCount = this.getFreeClusterCount.toObject();
        }
        if (this.getPayableClusterOptions != null) {
            data.getPayableClusterOptions = this.getPayableClusterOptions.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckTeamId)
            writer.writeMessage(2, this.checkTeamId, () => this.checkTeamId.serialize(writer));
        if (this.hasGetTeam)
            writer.writeMessage(4, this.getTeam, () => this.getTeam.serialize(writer));
        if (this.hasGetMember)
            writer.writeMessage(7, this.getMember, () => this.getMember.serialize(writer));
        if (this.hasListSpaces)
            writer.writeMessage(8, this.listSpaces, () => this.listSpaces.serialize(writer));
        if (this.hasListSquads)
            writer.writeMessage(9, this.listSquads, () => this.listSquads.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(10, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(11, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (this.hasListClusters)
            writer.writeMessage(12, this.listClusters, () => this.listClusters.serialize(writer));
        if (this.hasListApiTokens)
            writer.writeMessage(13, this.listApiTokens, () => this.listApiTokens.serialize(writer));
        if (this.hasGetFreeClusterCount)
            writer.writeMessage(17, this.getFreeClusterCount, () => this.getFreeClusterCount.serialize(writer));
        if (this.hasGetPayableClusterOptions)
            writer.writeMessage(18, this.getPayableClusterOptions, () => this.getPayableClusterOptions.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = TeamCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkTeamId, () => message.checkTeamId = TeamCtlProtoCheckTeamIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getTeam, () => message.getTeam = TeamCtlProtoGetTeamRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getMember, () => message.getMember = TeamCtlProtoGetMemberRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSpaces, () => message.listSpaces = TeamCtlProtoListSpacesRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listSquads, () => message.listSquads = TeamCtlProtoListSquadsRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listMembers, () => message.listMembers = TeamCtlProtoListMembersRes.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = TeamCtlProtoListInvitationsRes.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listClusters, () => message.listClusters = TeamCtlProtoListClustersRes.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listApiTokens, () => message.listApiTokens = TeamCtlProtoListAPITokensRes.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.getFreeClusterCount, () => message.getFreeClusterCount = TeamCtlProtoGetFreeClusterCountRes.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.getPayableClusterOptions, () => message.getPayableClusterOptions = TeamCtlProtoGetPayableClusterOptionsRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRes {
        return TeamCtlProtoRes.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetAccessLevels {
        const message = new TeamCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevels {
        return TeamCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuids" in data && data.teamUuids != undefined) {
                this.teamUuids = data.teamUuids;
            }
        }
    }
    get teamUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set teamUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuids?: Uint8Array[];
    }): TeamCtlProtoGetAccessLevelsReq {
        const message = new TeamCtlProtoGetAccessLevelsReq({});
        if (data.teamUuids != null) {
            message.teamUuids = data.teamUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuids?: Uint8Array[];
        } = {};
        if (this.teamUuids != null) {
            data.teamUuids = this.teamUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuids.length)
            writer.writeRepeatedBytes(1, this.teamUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevelsReq {
        return TeamCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_2.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_2.AccessLevelProto[];
    }
    set accessLevels(value: dependency_2.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_2.AccessLevelProto[];
    }): TeamCtlProtoGetAccessLevelsRes {
        const message = new TeamCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_2.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevelsRes {
        return TeamCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoCheckTeamID {
        const message = new TeamCtlProtoCheckTeamID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamID {
        return TeamCtlProtoCheckTeamID.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        id?: string;
    }): TeamCtlProtoCheckTeamIDReq {
        const message = new TeamCtlProtoCheckTeamIDReq({});
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamIDReq {
        return TeamCtlProtoCheckTeamIDReq.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): TeamCtlProtoCheckTeamIDRes {
        const message = new TeamCtlProtoCheckTeamIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamIDRes {
        return TeamCtlProtoCheckTeamIDRes.deserialize(bytes);
    }
}
export class TeamCtlProtoCreateTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoCreateTeam {
        const message = new TeamCtlProtoCreateTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCreateTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCreateTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCreateTeam {
        return TeamCtlProtoCreateTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoCreateTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        team?: dependency_2.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_2.TeamProto, 2) as dependency_2.TeamProto;
    }
    set team(value: dependency_2.TeamProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
    }): TeamCtlProtoCreateTeamReq {
        const message = new TeamCtlProtoCreateTeamReq({});
        if (data.team != null) {
            message.team = dependency_2.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(2, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCreateTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCreateTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.team, () => message.team = dependency_2.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCreateTeamReq {
        return TeamCtlProtoCreateTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetTeam {
        const message = new TeamCtlProtoGetTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeam {
        return TeamCtlProtoGetTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamId" in data && data.teamId != undefined) {
                this.teamId = data.teamId;
            }
        }
    }
    get teamId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set teamId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamId?: string;
    }): TeamCtlProtoGetTeamReq {
        const message = new TeamCtlProtoGetTeamReq({});
        if (data.teamId != null) {
            message.teamId = data.teamId;
        }
        return message;
    }
    toObject() {
        const data: {
            teamId?: string;
        } = {};
        if (this.teamId != null) {
            data.teamId = this.teamId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamId.length)
            writer.writeString(1, this.teamId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeamReq {
        return TeamCtlProtoGetTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeamRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        team?: dependency_2.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_2.TeamProto, 1) as dependency_2.TeamProto;
    }
    set team(value: dependency_2.TeamProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
    }): TeamCtlProtoGetTeamRes {
        const message = new TeamCtlProtoGetTeamRes({});
        if (data.team != null) {
            message.team = dependency_2.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(1, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeamRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeamRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.team, () => message.team = dependency_2.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeamRes {
        return TeamCtlProtoGetTeamRes.deserialize(bytes);
    }
}
export class TeamCtlProtoUpdateTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoUpdateTeam {
        const message = new TeamCtlProtoUpdateTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoUpdateTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoUpdateTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoUpdateTeam {
        return TeamCtlProtoUpdateTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoUpdateTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        team?: dependency_2.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_2.TeamProto, 1) as dependency_2.TeamProto;
    }
    set team(value: dependency_2.TeamProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
    }): TeamCtlProtoUpdateTeamReq {
        const message = new TeamCtlProtoUpdateTeamReq({});
        if (data.team != null) {
            message.team = dependency_2.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(1, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoUpdateTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoUpdateTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.team, () => message.team = dependency_2.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoUpdateTeamReq {
        return TeamCtlProtoUpdateTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoDeleteTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoDeleteTeam {
        const message = new TeamCtlProtoDeleteTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoDeleteTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoDeleteTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoDeleteTeam {
        return TeamCtlProtoDeleteTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoDeleteTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
    }): TeamCtlProtoDeleteTeamReq {
        const message = new TeamCtlProtoDeleteTeamReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoDeleteTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoDeleteTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoDeleteTeamReq {
        return TeamCtlProtoDeleteTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetMember {
        const message = new TeamCtlProtoGetMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetMember {
        return TeamCtlProtoGetMember.deserialize(bytes);
    }
}
export class TeamCtlProtoGetMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userId?: string;
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userId" in data && data.userId != undefined) {
                this.userId = data.userId;
            }
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get userId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set userId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        userId?: string;
        teamUuid?: Uint8Array;
    }): TeamCtlProtoGetMemberReq {
        const message = new TeamCtlProtoGetMemberReq({});
        if (data.userId != null) {
            message.userId = data.userId;
        }
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            userId?: string;
            teamUuid?: Uint8Array;
        } = {};
        if (this.userId != null) {
            data.userId = this.userId;
        }
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userId.length)
            writer.writeString(1, this.userId);
        if (this.teamUuid.length)
            writer.writeBytes(2, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userId = reader.readString();
                    break;
                case 2:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetMemberReq {
        return TeamCtlProtoGetMemberReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetMemberRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        member?: dependency_2.TeamMemberProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("member" in data && data.member != undefined) {
                this.member = data.member;
            }
        }
    }
    get member() {
        return pb_1.Message.getWrapperField(this, dependency_2.TeamMemberProto, 1) as dependency_2.TeamMemberProto;
    }
    set member(value: dependency_2.TeamMemberProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasMember() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        member?: ReturnType<typeof dependency_2.TeamMemberProto.prototype.toObject>;
    }): TeamCtlProtoGetMemberRes {
        const message = new TeamCtlProtoGetMemberRes({});
        if (data.member != null) {
            message.member = dependency_2.TeamMemberProto.fromObject(data.member);
        }
        return message;
    }
    toObject() {
        const data: {
            member?: ReturnType<typeof dependency_2.TeamMemberProto.prototype.toObject>;
        } = {};
        if (this.member != null) {
            data.member = this.member.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasMember)
            writer.writeMessage(1, this.member, () => this.member.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetMemberRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetMemberRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.member, () => message.member = dependency_2.TeamMemberProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetMemberRes {
        return TeamCtlProtoGetMemberRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListSpaces extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListSpaces {
        const message = new TeamCtlProtoListSpaces({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSpaces {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSpaces();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSpaces {
        return TeamCtlProtoListSpaces.deserialize(bytes);
    }
}
export class TeamCtlProtoListSpacesReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListSpacesReq {
        const message = new TeamCtlProtoListSpacesReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSpacesReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSpacesReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSpacesReq {
        return TeamCtlProtoListSpacesReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListSpacesRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaces?: dependency_2.SpaceProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaces" in data && data.spaces != undefined) {
                this.spaces = data.spaces;
            }
        }
    }
    get spaces() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.SpaceProto, 1) as dependency_2.SpaceProto[];
    }
    set spaces(value: dependency_2.SpaceProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        spaces?: ReturnType<typeof dependency_2.SpaceProto.prototype.toObject>[];
    }): TeamCtlProtoListSpacesRes {
        const message = new TeamCtlProtoListSpacesRes({});
        if (data.spaces != null) {
            message.spaces = data.spaces.map(item => dependency_2.SpaceProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            spaces?: ReturnType<typeof dependency_2.SpaceProto.prototype.toObject>[];
        } = {};
        if (this.spaces != null) {
            data.spaces = this.spaces.map((item: dependency_2.SpaceProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaces.length)
            writer.writeRepeatedMessage(1, this.spaces, (item: dependency_2.SpaceProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSpacesRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSpacesRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.spaces, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.SpaceProto.deserialize(reader), dependency_2.SpaceProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSpacesRes {
        return TeamCtlProtoListSpacesRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListSquads extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListSquads {
        const message = new TeamCtlProtoListSquads({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSquads {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSquads();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSquads {
        return TeamCtlProtoListSquads.deserialize(bytes);
    }
}
export class TeamCtlProtoListSquadsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListSquadsReq {
        const message = new TeamCtlProtoListSquadsReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSquadsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSquadsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSquadsReq {
        return TeamCtlProtoListSquadsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListSquadsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squads?: dependency_2.SquadProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squads" in data && data.squads != undefined) {
                this.squads = data.squads;
            }
        }
    }
    get squads() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.SquadProto, 1) as dependency_2.SquadProto[];
    }
    set squads(value: dependency_2.SquadProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        squads?: ReturnType<typeof dependency_2.SquadProto.prototype.toObject>[];
    }): TeamCtlProtoListSquadsRes {
        const message = new TeamCtlProtoListSquadsRes({});
        if (data.squads != null) {
            message.squads = data.squads.map(item => dependency_2.SquadProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            squads?: ReturnType<typeof dependency_2.SquadProto.prototype.toObject>[];
        } = {};
        if (this.squads != null) {
            data.squads = this.squads.map((item: dependency_2.SquadProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squads.length)
            writer.writeRepeatedMessage(1, this.squads, (item: dependency_2.SquadProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListSquadsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListSquadsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squads, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.SquadProto.deserialize(reader), dependency_2.SquadProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListSquadsRes {
        return TeamCtlProtoListSquadsRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListMembers {
        const message = new TeamCtlProtoListMembers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembers {
        return TeamCtlProtoListMembers.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListMembersReq {
        const message = new TeamCtlProtoListMembersReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembersReq {
        return TeamCtlProtoListMembersReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        members?: dependency_2.TeamMemberProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("members" in data && data.members != undefined) {
                this.members = data.members;
            }
        }
    }
    get members() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.TeamMemberProto, 1) as dependency_2.TeamMemberProto[];
    }
    set members(value: dependency_2.TeamMemberProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        members?: ReturnType<typeof dependency_2.TeamMemberProto.prototype.toObject>[];
    }): TeamCtlProtoListMembersRes {
        const message = new TeamCtlProtoListMembersRes({});
        if (data.members != null) {
            message.members = data.members.map(item => dependency_2.TeamMemberProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            members?: ReturnType<typeof dependency_2.TeamMemberProto.prototype.toObject>[];
        } = {};
        if (this.members != null) {
            data.members = this.members.map((item: dependency_2.TeamMemberProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.members.length)
            writer.writeRepeatedMessage(1, this.members, (item: dependency_2.TeamMemberProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.members, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.TeamMemberProto.deserialize(reader), dependency_2.TeamMemberProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembersRes {
        return TeamCtlProtoListMembersRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListInvitations extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListInvitations {
        const message = new TeamCtlProtoListInvitations({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListInvitations {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListInvitations();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListInvitations {
        return TeamCtlProtoListInvitations.deserialize(bytes);
    }
}
export class TeamCtlProtoListInvitationsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListInvitationsReq {
        const message = new TeamCtlProtoListInvitationsReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListInvitationsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListInvitationsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListInvitationsReq {
        return TeamCtlProtoListInvitationsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListInvitationsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitations?: dependency_2.InvitationProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitations" in data && data.invitations != undefined) {
                this.invitations = data.invitations;
            }
        }
    }
    get invitations() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.InvitationProto, 1) as dependency_2.InvitationProto[];
    }
    set invitations(value: dependency_2.InvitationProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
    }): TeamCtlProtoListInvitationsRes {
        const message = new TeamCtlProtoListInvitationsRes({});
        if (data.invitations != null) {
            message.invitations = data.invitations.map(item => dependency_2.InvitationProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
        } = {};
        if (this.invitations != null) {
            data.invitations = this.invitations.map((item: dependency_2.InvitationProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitations.length)
            writer.writeRepeatedMessage(1, this.invitations, (item: dependency_2.InvitationProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListInvitationsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListInvitationsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invitations, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.InvitationProto.deserialize(reader), dependency_2.InvitationProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListInvitationsRes {
        return TeamCtlProtoListInvitationsRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListClusters extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListClusters {
        const message = new TeamCtlProtoListClusters({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListClusters {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListClusters();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListClusters {
        return TeamCtlProtoListClusters.deserialize(bytes);
    }
}
export class TeamCtlProtoListClustersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListClustersReq {
        const message = new TeamCtlProtoListClustersReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListClustersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListClustersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListClustersReq {
        return TeamCtlProtoListClustersReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListClustersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusters?: dependency_2.ClusterProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusters" in data && data.clusters != undefined) {
                this.clusters = data.clusters;
            }
        }
    }
    get clusters() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ClusterProto, 1) as dependency_2.ClusterProto[];
    }
    set clusters(value: dependency_2.ClusterProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        clusters?: ReturnType<typeof dependency_2.ClusterProto.prototype.toObject>[];
    }): TeamCtlProtoListClustersRes {
        const message = new TeamCtlProtoListClustersRes({});
        if (data.clusters != null) {
            message.clusters = data.clusters.map(item => dependency_2.ClusterProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            clusters?: ReturnType<typeof dependency_2.ClusterProto.prototype.toObject>[];
        } = {};
        if (this.clusters != null) {
            data.clusters = this.clusters.map((item: dependency_2.ClusterProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusters.length)
            writer.writeRepeatedMessage(1, this.clusters, (item: dependency_2.ClusterProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListClustersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListClustersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.clusters, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.ClusterProto.deserialize(reader), dependency_2.ClusterProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListClustersRes {
        return TeamCtlProtoListClustersRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListAPITokens extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListAPITokens {
        const message = new TeamCtlProtoListAPITokens({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListAPITokens {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListAPITokens();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListAPITokens {
        return TeamCtlProtoListAPITokens.deserialize(bytes);
    }
}
export class TeamCtlProtoListAPITokensReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListAPITokensReq {
        const message = new TeamCtlProtoListAPITokensReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListAPITokensReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListAPITokensReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListAPITokensReq {
        return TeamCtlProtoListAPITokensReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListAPITokensRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        apiTokens?: dependency_2.TeamAPITokenProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("apiTokens" in data && data.apiTokens != undefined) {
                this.apiTokens = data.apiTokens;
            }
        }
    }
    get apiTokens() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.TeamAPITokenProto, 1) as dependency_2.TeamAPITokenProto[];
    }
    set apiTokens(value: dependency_2.TeamAPITokenProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        apiTokens?: ReturnType<typeof dependency_2.TeamAPITokenProto.prototype.toObject>[];
    }): TeamCtlProtoListAPITokensRes {
        const message = new TeamCtlProtoListAPITokensRes({});
        if (data.apiTokens != null) {
            message.apiTokens = data.apiTokens.map(item => dependency_2.TeamAPITokenProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            apiTokens?: ReturnType<typeof dependency_2.TeamAPITokenProto.prototype.toObject>[];
        } = {};
        if (this.apiTokens != null) {
            data.apiTokens = this.apiTokens.map((item: dependency_2.TeamAPITokenProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.apiTokens.length)
            writer.writeRepeatedMessage(1, this.apiTokens, (item: dependency_2.TeamAPITokenProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListAPITokensRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListAPITokensRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.apiTokens, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.TeamAPITokenProto.deserialize(reader), dependency_2.TeamAPITokenProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListAPITokensRes {
        return TeamCtlProtoListAPITokensRes.deserialize(bytes);
    }
}
export class TeamCtlProtoInviteMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoInviteMember {
        const message = new TeamCtlProtoInviteMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoInviteMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoInviteMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoInviteMember {
        return TeamCtlProtoInviteMember.deserialize(bytes);
    }
}
export class TeamCtlProtoInviteMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userEmail?: string;
        teamUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
        squadAccess?: TeamCtlProtoInviteMemberSquadAccess[];
        spaceAccess?: TeamCtlProtoInviteMemberSpaceAccess[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4, 5], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userEmail" in data && data.userEmail != undefined) {
                this.userEmail = data.userEmail;
            }
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
            if ("squadAccess" in data && data.squadAccess != undefined) {
                this.squadAccess = data.squadAccess;
            }
            if ("spaceAccess" in data && data.spaceAccess != undefined) {
                this.spaceAccess = data.spaceAccess;
            }
        }
    }
    get userEmail() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set userEmail(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    get squadAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, TeamCtlProtoInviteMemberSquadAccess, 4) as TeamCtlProtoInviteMemberSquadAccess[];
    }
    set squadAccess(value: TeamCtlProtoInviteMemberSquadAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get spaceAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, TeamCtlProtoInviteMemberSpaceAccess, 5) as TeamCtlProtoInviteMemberSpaceAccess[];
    }
    set spaceAccess(value: TeamCtlProtoInviteMemberSpaceAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    static fromObject(data: {
        userEmail?: string;
        teamUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
        squadAccess?: ReturnType<typeof TeamCtlProtoInviteMemberSquadAccess.prototype.toObject>[];
        spaceAccess?: ReturnType<typeof TeamCtlProtoInviteMemberSpaceAccess.prototype.toObject>[];
    }): TeamCtlProtoInviteMemberReq {
        const message = new TeamCtlProtoInviteMemberReq({});
        if (data.userEmail != null) {
            message.userEmail = data.userEmail;
        }
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        if (data.squadAccess != null) {
            message.squadAccess = data.squadAccess.map(item => TeamCtlProtoInviteMemberSquadAccess.fromObject(item));
        }
        if (data.spaceAccess != null) {
            message.spaceAccess = data.spaceAccess.map(item => TeamCtlProtoInviteMemberSpaceAccess.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            userEmail?: string;
            teamUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
            squadAccess?: ReturnType<typeof TeamCtlProtoInviteMemberSquadAccess.prototype.toObject>[];
            spaceAccess?: ReturnType<typeof TeamCtlProtoInviteMemberSpaceAccess.prototype.toObject>[];
        } = {};
        if (this.userEmail != null) {
            data.userEmail = this.userEmail;
        }
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        if (this.squadAccess != null) {
            data.squadAccess = this.squadAccess.map((item: TeamCtlProtoInviteMemberSquadAccess) => item.toObject());
        }
        if (this.spaceAccess != null) {
            data.spaceAccess = this.spaceAccess.map((item: TeamCtlProtoInviteMemberSpaceAccess) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userEmail.length)
            writer.writeString(1, this.userEmail);
        if (this.teamUuid.length)
            writer.writeBytes(2, this.teamUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (this.squadAccess.length)
            writer.writeRepeatedMessage(4, this.squadAccess, (item: TeamCtlProtoInviteMemberSquadAccess) => item.serialize(writer));
        if (this.spaceAccess.length)
            writer.writeRepeatedMessage(5, this.spaceAccess, (item: TeamCtlProtoInviteMemberSpaceAccess) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoInviteMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoInviteMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userEmail = reader.readString();
                    break;
                case 2:
                    message.teamUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                case 4:
                    reader.readMessage(message.squadAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 4, TeamCtlProtoInviteMemberSquadAccess.deserialize(reader), TeamCtlProtoInviteMemberSquadAccess));
                    break;
                case 5:
                    reader.readMessage(message.spaceAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 5, TeamCtlProtoInviteMemberSpaceAccess.deserialize(reader), TeamCtlProtoInviteMemberSpaceAccess));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoInviteMemberReq {
        return TeamCtlProtoInviteMemberReq.deserialize(bytes);
    }
}
export class TeamCtlProtoInviteMemberSquadAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): TeamCtlProtoInviteMemberSquadAccess {
        const message = new TeamCtlProtoInviteMemberSquadAccess({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoInviteMemberSquadAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoInviteMemberSquadAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoInviteMemberSquadAccess {
        return TeamCtlProtoInviteMemberSquadAccess.deserialize(bytes);
    }
}
export class TeamCtlProtoInviteMemberSpaceAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): TeamCtlProtoInviteMemberSpaceAccess {
        const message = new TeamCtlProtoInviteMemberSpaceAccess({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoInviteMemberSpaceAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoInviteMemberSpaceAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoInviteMemberSpaceAccess {
        return TeamCtlProtoInviteMemberSpaceAccess.deserialize(bytes);
    }
}
export class TeamCtlProtoResendInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoResendInvitation {
        const message = new TeamCtlProtoResendInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoResendInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoResendInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoResendInvitation {
        return TeamCtlProtoResendInvitation.deserialize(bytes);
    }
}
export class TeamCtlProtoResendInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): TeamCtlProtoResendInvitationReq {
        const message = new TeamCtlProtoResendInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoResendInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoResendInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoResendInvitationReq {
        return TeamCtlProtoResendInvitationReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRevokeInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoRevokeInvitation {
        const message = new TeamCtlProtoRevokeInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRevokeInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRevokeInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRevokeInvitation {
        return TeamCtlProtoRevokeInvitation.deserialize(bytes);
    }
}
export class TeamCtlProtoRevokeInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): TeamCtlProtoRevokeInvitationReq {
        const message = new TeamCtlProtoRevokeInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRevokeInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRevokeInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRevokeInvitationReq {
        return TeamCtlProtoRevokeInvitationReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRemoveMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoRemoveMember {
        const message = new TeamCtlProtoRemoveMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRemoveMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRemoveMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRemoveMember {
        return TeamCtlProtoRemoveMember.deserialize(bytes);
    }
}
export class TeamCtlProtoRemoveMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): TeamCtlProtoRemoveMemberReq {
        const message = new TeamCtlProtoRemoveMemberReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRemoveMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRemoveMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRemoveMemberReq {
        return TeamCtlProtoRemoveMemberReq.deserialize(bytes);
    }
}
export class TeamCtlProtoSetMemberAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoSetMemberAccessLevel {
        const message = new TeamCtlProtoSetMemberAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoSetMemberAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoSetMemberAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoSetMemberAccessLevel {
        return TeamCtlProtoSetMemberAccessLevel.deserialize(bytes);
    }
}
export class TeamCtlProtoSetMemberAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): TeamCtlProtoSetMemberAccessLevelReq {
        const message = new TeamCtlProtoSetMemberAccessLevelReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoSetMemberAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoSetMemberAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoSetMemberAccessLevelReq {
        return TeamCtlProtoSetMemberAccessLevelReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRegisterAPIToken extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoRegisterAPIToken {
        const message = new TeamCtlProtoRegisterAPIToken({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRegisterAPIToken {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRegisterAPIToken();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRegisterAPIToken {
        return TeamCtlProtoRegisterAPIToken.deserialize(bytes);
    }
}
export class TeamCtlProtoRegisterAPITokenReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        id?: string;
        teamUuid?: Uint8Array;
        spaceUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 5, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        name?: string;
        id?: string;
        teamUuid?: Uint8Array;
        spaceUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): TeamCtlProtoRegisterAPITokenReq {
        const message = new TeamCtlProtoRegisterAPITokenReq({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            name?: string;
            id?: string;
            teamUuid?: Uint8Array;
            spaceUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (this.teamUuid.length)
            writer.writeBytes(3, this.teamUuid);
        if (this.spaceUuid.length)
            writer.writeBytes(4, this.spaceUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(5, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRegisterAPITokenReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRegisterAPITokenReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.teamUuid = reader.readBytes();
                    break;
                case 4:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 5:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRegisterAPITokenReq {
        return TeamCtlProtoRegisterAPITokenReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRevokeAPIToken extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoRevokeAPIToken {
        const message = new TeamCtlProtoRevokeAPIToken({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRevokeAPIToken {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRevokeAPIToken();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRevokeAPIToken {
        return TeamCtlProtoRevokeAPIToken.deserialize(bytes);
    }
}
export class TeamCtlProtoRevokeAPITokenReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        apiTokenUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("apiTokenUuid" in data && data.apiTokenUuid != undefined) {
                this.apiTokenUuid = data.apiTokenUuid;
            }
        }
    }
    get apiTokenUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set apiTokenUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        apiTokenUuid?: Uint8Array;
    }): TeamCtlProtoRevokeAPITokenReq {
        const message = new TeamCtlProtoRevokeAPITokenReq({});
        if (data.apiTokenUuid != null) {
            message.apiTokenUuid = data.apiTokenUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            apiTokenUuid?: Uint8Array;
        } = {};
        if (this.apiTokenUuid != null) {
            data.apiTokenUuid = this.apiTokenUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.apiTokenUuid.length)
            writer.writeBytes(1, this.apiTokenUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRevokeAPITokenReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRevokeAPITokenReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.apiTokenUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRevokeAPITokenReq {
        return TeamCtlProtoRevokeAPITokenReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetFreeClusterCount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetFreeClusterCount {
        const message = new TeamCtlProtoGetFreeClusterCount({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetFreeClusterCount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetFreeClusterCount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetFreeClusterCount {
        return TeamCtlProtoGetFreeClusterCount.deserialize(bytes);
    }
}
export class TeamCtlProtoGetFreeClusterCountReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
    }): TeamCtlProtoGetFreeClusterCountReq {
        const message = new TeamCtlProtoGetFreeClusterCountReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetFreeClusterCountReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetFreeClusterCountReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetFreeClusterCountReq {
        return TeamCtlProtoGetFreeClusterCountReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetFreeClusterCountRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        count?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("count" in data && data.count != undefined) {
                this.count = data.count;
            }
        }
    }
    get count() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set count(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        count?: number;
    }): TeamCtlProtoGetFreeClusterCountRes {
        const message = new TeamCtlProtoGetFreeClusterCountRes({});
        if (data.count != null) {
            message.count = data.count;
        }
        return message;
    }
    toObject() {
        const data: {
            count?: number;
        } = {};
        if (this.count != null) {
            data.count = this.count;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.count != 0)
            writer.writeInt32(1, this.count);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetFreeClusterCountRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetFreeClusterCountRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.count = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetFreeClusterCountRes {
        return TeamCtlProtoGetFreeClusterCountRes.deserialize(bytes);
    }
}
export class TeamCtlProtoGetPayableClusterOptions extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetPayableClusterOptions {
        const message = new TeamCtlProtoGetPayableClusterOptions({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetPayableClusterOptions {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetPayableClusterOptions();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetPayableClusterOptions {
        return TeamCtlProtoGetPayableClusterOptions.deserialize(bytes);
    }
}
export class TeamCtlProtoGetPayableClusterOptionsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
    }): TeamCtlProtoGetPayableClusterOptionsReq {
        const message = new TeamCtlProtoGetPayableClusterOptionsReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetPayableClusterOptionsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetPayableClusterOptionsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetPayableClusterOptionsReq {
        return TeamCtlProtoGetPayableClusterOptionsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetPayableClusterOptionsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        providers?: dependency_2.ProviderProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("providers" in data && data.providers != undefined) {
                this.providers = data.providers;
            }
        }
    }
    get providers() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ProviderProto, 1) as dependency_2.ProviderProto[];
    }
    set providers(value: dependency_2.ProviderProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        providers?: ReturnType<typeof dependency_2.ProviderProto.prototype.toObject>[];
    }): TeamCtlProtoGetPayableClusterOptionsRes {
        const message = new TeamCtlProtoGetPayableClusterOptionsRes({});
        if (data.providers != null) {
            message.providers = data.providers.map(item => dependency_2.ProviderProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            providers?: ReturnType<typeof dependency_2.ProviderProto.prototype.toObject>[];
        } = {};
        if (this.providers != null) {
            data.providers = this.providers.map((item: dependency_2.ProviderProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.providers.length)
            writer.writeRepeatedMessage(1, this.providers, (item: dependency_2.ProviderProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetPayableClusterOptionsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetPayableClusterOptionsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.providers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.ProviderProto.deserialize(reader), dependency_2.ProviderProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetPayableClusterOptionsRes {
        return TeamCtlProtoGetPayableClusterOptionsRes.deserialize(bytes);
    }
}
