/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class FormService {
    readonly signupFormID = `cloud_signup`;
    readonly cloudRegionRequestFormID = "cloud_region_request";
    authToken = "fake";

    constructor(private http: HttpClient) {
    }

    submit(formId: string, data: { email: string } & Record<string, unknown>): Observable<unknown> {
        const props = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v.toString().trim().length));
        return this.http.post(`https://typedb.com/forms/${formId}/submit`, { data: props }, {
            headers: {
                "Authorization": `Basic ${this.authToken}`,
                "Content-Type": "application/json",
            },
        });
    }
}
