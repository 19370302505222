<tp-modal dialogTitle="Edit Profile" [isBusy]="isSubmitting$ | async">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-input type="text" [form]="form" field="id" label="Username" autocomplete="username" />
    <tp-form-input type="text" [form]="form" field="email" label="Email" autocomplete="email" />
    <tp-form-input class="capitalized-field" type="text" [form]="form" field="firstName" label="First Name" autocomplete="given-name" />
    <tp-form-input class="capitalized-field" type="text" [form]="form" field="lastName" label="Last Name" autocomplete="family-name" />
    @if (showEmailChangeInfo) {
      <div class="admonitionblock note">You will be prompted to sign in again with the new email address</div>
    }
    <tp-form-actions
      [cancellable]="true" submitText="Save" [submitDisabled]="!form.dirty" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="editProfileModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
