/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { ClusterApi } from "./cluster/cluster-api.service";
import { SquadApi } from "./squad/squad-api.service";
import { UserApi } from "./user/user-api.service";
import { TeamApi } from "./team/team-api.service";
import { SpaceApi } from "./space/space-api.service";

@Injectable({
    providedIn: "root",
})
export class AuthorizationService {
    constructor(
        private teamApi: TeamApi, private clusterApi: ClusterApi, private spaceApi: SpaceApi,
        private squadApi: SquadApi, private userApi: UserApi,
    ) {
    }

    teamAccessLevels(teamUuids: string[]) {
        return this.teamApi.getAccessLevels(teamUuids);
    }

    clusterAccessLevels(clusterUuids: string[]) {
        return this.clusterApi.getAccessLevels(clusterUuids);
    }

    spaceAccessLevels(spaceUuids: string[]) {
        return this.spaceApi.getAccessLevels(spaceUuids);
    }

    squadAccessLevels(squadUuids: string[]) {
        return this.squadApi.getAccessLevels(squadUuids);
    }

    userAccessLevels(userUuids: string[]) {
        return this.userApi.getAccessLevels(userUuids);
    }
}
