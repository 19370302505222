/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { Subject, map, startWith } from "rxjs";
import { ButtonComponent } from "typedb-platform-framework";
import { LOADING } from "../../../framework/strings";
import { TeamController } from "../../../service/team/team-controller.service";
import { AsyncPipe } from "@angular/common";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { ApplicationState } from "../../../service/application-state.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { clustersDeployPath } from "../../../routing/resource-paths";
import { SidebarLinkComponent } from "../../scaffold/sidebar/link/sidebar-link.component";

@Component({
    selector: "tp-get-started-page",
    templateUrl: "./get-started-page.component.html",
    styleUrls: ["./get-started-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, AsyncPipe, RouterLink, ButtonComponent, MatTooltipModule, SidebarLinkComponent
    ],
})
export class GetStartedPageComponent implements OnDestroy {
    readonly team = this.app.requireCurrentTeam();
    readonly userFirstName$ = this.app.currentUser$.pipe(map((user) => user?.firstName));

    private readonly unsub$ = new Subject<void>();
    readonly clusters = this.teamCtl.clustersTable(this.unsub$);
    readonly availability$ = resourceAvailabilityStreamOf(this.clusters);

    readonly clusterExists$ = this.clusters.items$.pipe(
        map((items) => items.length != 0)
    );

    readonly writableSpaces$ = this.teamCtl.listWritableSpacesSnapshot();
    readonly cannotDeployReason$ = this.writableSpaces$.pipe(map(writableSpaces => {
        if (!writableSpaces.length) return "You need write access to at least one space to deploy a new cluster";
        return null;
    }), startWith(LOADING));
    readonly deployEnabled$ = this.cannotDeployReason$.pipe(map(x => !x));

    constructor(public route: ActivatedRoute, private app: ApplicationState, private teamCtl: TeamController) {  }

    ngOnDestroy() {
        this.unsub$.next();
    }

    protected readonly clustersDeployPath = clustersDeployPath;
}
