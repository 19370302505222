/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    ClusterPriceProto, ClusterPriceProtoPrice,
    ClusterProto,
    ClusterProtoServer,
    ClusterProtoServerLogBatch,
    ClusterProtoServerStatus,
    ClusterProtoStatus
} from "../../protocol/concept";
import { bytesToString, stringToBytesOrUndefined, stripUndefinedValues, toSentenceCase } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import {
    MachineType, machineTypeOf, machineTypeProtoOf,
    Provider, providerOf, providerProtoOf,
    Region, regionOf, regionProtoOf,
    StorageType, storageTypeOf, storageTypeProtoOf,
    TypeDBVersion, typeDBVersionOf, typeDBVersionProtoOf
} from "./cluster-options";
import { partialSpaceOf, Space, spaceProtoOf } from "./space";

export interface Cluster extends BaseResource {
    serverCount: number;
    provider: Provider;
    region: Region;
    machineType: MachineType;
    storageType: StorageType;
    storageGB: number;
    typeDBVersion: TypeDBVersion;
    status: ClusterStatus;
    createdAt: Date;
    space: Space;
    sampleDataset: string;
    isAdminUserInitialized: boolean;
}

export function partialClusterOf(data: ClusterProto): PartialWithUuid<Cluster> {
    const cluster: PartialWithUuid<Cluster> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        serverCount: data.hasServerCount ? data.serverCount : undefined,
        provider: data.hasProvider ? providerOf(data.provider) : undefined,
        region: data.hasRegion ? regionOf(data.region) : undefined,
        machineType: data.hasMachineType ? machineTypeOf(data.machineType) : undefined,
        storageType: data.hasStorageType ? storageTypeOf(data.storageType) : undefined,
        storageGB: data.hasStorageGb ? data.storageGb : undefined,
        typeDBVersion: data.hasTypedbCloudVersion ? typeDBVersionOf(data.typedbCloudVersion) : undefined,
        status: data.hasStatus ? clusterStatusOf(data.status) : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        space: data.hasSpace ? partialSpaceOf(data.space) as Space : undefined,
        sampleDataset: data.hasSampleDataset ? data.sampleDataset : undefined,
        isAdminUserInitialized: data.hasIsAdminUserInitialized ? data.isAdminUserInitialized : undefined,
    };
    return stripUndefinedValues(cluster) as PartialWithUuid<Cluster>;
}

export function clusterProtoOf(data: Partial<Cluster>): ClusterProto {
    return new ClusterProto({
        uuid: stringToBytesOrUndefined(data.uuid),
        id: data.id,
        serverCount: data.serverCount,
        provider: data.provider ? providerProtoOf(data.provider) : undefined,
        region: data.region ? regionProtoOf(data.region) : undefined,
        machineType: data.machineType ? machineTypeProtoOf(data.machineType) : undefined,
        storageType: data.storageType ? storageTypeProtoOf(data.storageType) : undefined,
        storageGb: data.storageGB,
        typedbCloudVersion: data.typeDBVersion ? typeDBVersionProtoOf(data.typeDBVersion) : undefined,
        status: data.status ? clusterStatusProtoOf(data.status) : undefined,
        createdAt: data.createdAt ? data.createdAt.getTime() : undefined,
        space: data.space ? spaceProtoOf(data.space) : undefined,
        sampleDataset: data.sampleDataset,
        isAdminUserInitialized: data.isAdminUserInitialized,
    });
}

export type ClusterStatus =
    "starting" | "running" | "suspending" | "suspended" | "resuming" | "destroying" | "destroyed" | "error";

export const clusterStatusFromProtoMap: Record<ClusterProtoStatus, ClusterStatus> = {
    [ClusterProtoStatus.STARTING]: "starting",
    [ClusterProtoStatus.RUNNING]: "running",
    [ClusterProtoStatus.SUSPENDING]: "suspending",
    [ClusterProtoStatus.SUSPENDED]: "suspended",
    [ClusterProtoStatus.RESUMING]: "resuming",
    [ClusterProtoStatus.DESTROYING]: "destroying",
    [ClusterProtoStatus.DESTROYED]: "destroyed",
    [ClusterProtoStatus.ERROR]: "error"
};

const clusterStatusToProtoMap = Object.fromEntries(
    Object.entries(clusterStatusFromProtoMap).map(([proto, status]) => [status, proto])
) as unknown as Record<ClusterStatus, ClusterProtoStatus>;

function clusterStatusOf(status: ClusterProtoStatus): ClusterStatus {
    return clusterStatusFromProtoMap[status];
}

function clusterStatusProtoOf(status: ClusterStatus): ClusterProtoStatus {
    return clusterStatusToProtoMap[status];
}

export function clusterStatusToString(status: ClusterStatus) {
    return toSentenceCase(status);
}

const canBeResumedStatuses: ClusterStatus[] = ["suspending", "suspended"];

export function isInResumableState(cluster: Cluster) {
    return canBeResumedStatuses.includes(cluster.status);
}

const canBeSuspendedStatuses: ClusterStatus[] = ["starting", "running", "resuming", "error"];

export function isInSuspendableState(cluster: Cluster) {
    return canBeSuspendedStatuses.includes(cluster.status);
}

const canBeDestroyedStatuses: ClusterStatus[] = ["starting", "running", "resuming", "suspending", "suspended", "error"];

export function isInDestroyableState(cluster: Cluster) {
    return canBeDestroyedStatuses.includes(cluster.status);
}

const canConnectStatuses: ClusterStatus[] = ["running", "resuming", "starting"];

export function isInConnectableState(cluster: Cluster) {
    return canConnectStatuses.includes(cluster.status);
}

export function storageSizeToString(storageGB: number): string {
    if (storageGB < 1000) return `${storageGB} GB`;
    else return `${storageGB / 1000} TB`;
}

export type ClusterColumn =
    | "id" | "space" | "provider" | "region" | "machineType" | "clusterSize" | "storageType" | "storageSize"
    | "clusterStatus" | "typeDBVersion" | "createdAt";

export const clusterProperties: Record<ClusterColumn, Property> = {
    id: { id: "id", name: "Cluster Name", attributeType: "id" },
    space: { id: "space", name: "Space", ownerType: "space", attributeType: "id" },
    provider: { id: "provider", name: "Provider", ownerType: "provider", attributeType: "name" },
    region: { id: "region", name: "Region", ownerType: "region", attributeType: "vendor-id" },
    machineType: { id: "machineType", name: "Machine", ownerType: "machine-type", attributeType: "id" },
    clusterSize: { id: "clusterSize", name: "Servers", attributeType: "server-count" },
    storageType: { id: "storageType", name: "Storage Type", ownerType: "storage-type", attributeType: "id" },
    storageSize: { id: "storageSize", name: "Storage (GB)", attributeType: "storage-size-gb" },
    clusterStatus: { id: "clusterStatus", name: "Status", attributeType: "cluster-status" },
    typeDBVersion: { id: "typeDBVersion", name: "TypeDB Version", ownerType: "typedb-cloud", attributeType: "version" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const clusterPropertiesList = [
    clusterProperties.id, clusterProperties.space, clusterProperties.provider, clusterProperties.region,
    clusterProperties.machineType, clusterProperties.clusterSize, clusterProperties.storageType,
    clusterProperties.storageSize, clusterProperties.clusterStatus, clusterProperties.typeDBVersion,
    clusterProperties.createdAt
];

export interface Server {
    uuid: string;
    id: number;
    address?: string;
    status: ServerStatus;
}

export function serverOf(server: ClusterProtoServer): Server {
    return {
        uuid: bytesToString(server.uuid),
        id: server.id,
        address: server.hasAddress ? server.address : undefined,
        status: server.hasStatus ? serverStatusOf(server.status) : "error",
    };
}

function serverProtoOf(server: Server): ClusterProtoServer {
    return new ClusterProtoServer({ address: server.address, status: serverStatusProtoOf(server.status) });
}

export type ServerStatus = "unknown" | "pending" | "running" | "error";

const serverStatusFromProtoMap: Record<ClusterProtoServerStatus, ServerStatus> = {
    [ClusterProtoServerStatus.UNKNOWN]: "unknown",
    [ClusterProtoServerStatus.PENDING]: "pending",
    [ClusterProtoServerStatus.RUNNING]: "running",
    [ClusterProtoServerStatus.ERROR]: "error",
};

const serverStatusToProtoMap = Object.fromEntries(
    Object.entries(serverStatusFromProtoMap).map(([proto, status]) => [status, proto])
) as unknown as Record<ServerStatus, ClusterProtoServerStatus>;

function serverStatusOf(status: ClusterProtoServerStatus): ServerStatus {
    return serverStatusFromProtoMap[status];
}

function serverStatusProtoOf(status: ServerStatus): ClusterProtoServerStatus {
    return serverStatusToProtoMap[status];
}

export function serverStatusToString(status: ServerStatus) {
    return toSentenceCase(status);
}

export interface ServerLogBatch {
    lines: string[];
    lineNumberStart: number;
    isEndOfFile: boolean;
}

export function serverLogBatchOf(proto: ClusterProtoServerLogBatch): ServerLogBatch {
    return {
        lines: proto.lines,
        lineNumberStart: proto.lineNumberStart,
        isEndOfFile: proto.isEndOfFile
    }
}

type Price = { centsPerHour: number, baseCentsPerHour?: number };

function priceOf(proto: ClusterPriceProtoPrice) {
    return {
        centsPerHour: proto.centsPerHour,
        baseCentsPerHour: proto.hasBaseCentsPerHour ? proto.baseCentsPerHour : undefined,
    };
}

export interface ClusterPrice {
    running: Price;
    suspended: Price;
}

export function clusterPriceOf(proto: ClusterPriceProto) {
    return { running: priceOf(proto.running), suspended: priceOf(proto.suspended) };
}
