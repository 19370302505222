/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { BehaviorSubject, first, Subject, switchMap } from "rxjs";
import {
    BreadcrumbComponent, ButtonComponent, ConfirmationModalComponent, ConfirmationModalData, SpinnerComponent,
    ContactSupportDialogData, ContactSupportModalComponent, DeleteResourceSectionComponent, FormActionsComponent,
    FormComponent, FormInputComponent, MenuItem, ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent,
} from "typedb-platform-framework";
import { Invitation } from "../../../concept/team";
import { User } from "../../../concept/user";
import { DialogResult } from "../../../service/dialog.service";
import { ResourceService } from "../../../service/resource.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { UserController } from "../../../service/user/user-controller.service";
import { TeamCreationDialogComponent } from "../../team/create/team-creation-dialog.component";
import { InvitationsTableComponent } from "../../team/invitations-table/invitations-table.component";
import { TeamsTableComponent } from "../../team/table/teams-table.component";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { UserUpdateDialogComponent, UserUpdateDialogData } from "../update/user-update-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { AccessModalComponent } from "../../team/access-modal/access-modal.component";

@Component({
    selector: "tp-user-settings-page",
    templateUrl: "user-settings-page.component.html",
    styleUrls: ["user-settings-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        AsyncPipe, SpinnerComponent, FormActionsComponent, FormInputComponent, FormComponent,
        TableToolbarComponent, TeamsTableComponent, TablePaginatorComponent, InvitationsTableComponent,
        DeleteResourceSectionComponent, PropertiesTableRowComponent,
    ],
})
export class UserSettingsPageComponent implements OnInit, OnDestroy {
    readonly user$ = new BehaviorSubject<User | null>(null);
    teamCreationButton: TableToolbarAction = {
        text: "Create team",
        enabled: true,
        onClick: () => { this.dialog.open(TeamCreationDialogComponent); }
    };

    private unsubUser$ = new Subject<void>();

    private readonly unsubTeams$ = new Subject<void>();
    readonly teams = this.userCtl.teamsTable(this.unsubTeams$);
    private readonly unsubInvitations$ = new Subject<void>();
    readonly invitations = this.userCtl.invitationsTable(this.unsubInvitations$);
    // TODO - availability stream of user, teams and invitations
    readonly availability$ = resourceAvailabilityStreamOf(this.teams, this.invitations);

    readonly invitationsTableRowPopupMenuItems: (invitation: Invitation) => MenuItem[] = (invitation) => {
        return [
            {
                label: "Access Details",
                action: () => {
                    this.dialog.open(AccessModalComponent, {data: invitation});
                }
            },
            {
                label: "Accept invitation",
                action: () => {
                    this.userApi.acceptInvitation(invitation.uuid).subscribe(() => {
                        this.snackbar.success(`You are now a member of ${invitation.team.name}`);
                        this.invitations.refresh();
                        this.app.initCurrentUserTeamsSub();
                    });
                }
            },
            {
                label: "Reject invitation",
                action: () => {
                    this.openInvitationRejectionModal(invitation);
                },
                dangerous: true
            },
        ];
    }

    constructor(
        private userApi: UserApi, private app: ApplicationState, public auth: IdentityAuthService,
        private dialog: MatDialog, private snackbar: SnackbarService, private userCtl: UserController,
        private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initUserLoader();
    }

    ngOnDestroy() {
        this.unsubUser$.next();
        this.unsubTeams$.next();
        this.unsubInvitations$.next();
    }

    private initUserLoader() {
        this.app.currentUser$.pipe(
            first((user) => !!user),
            switchMap((user) => this.userApi.getUser(user!.id, this.unsubUser$))
        ).subscribe((res) => {
            this.resourceService.processResponse({ resource$: this.user$, res: res, unsub$: this.unsubUser$, onResync: () => this.initUserLoader() });
        });
    }

    openEditDialog() {
        this.auth.getIdToken().pipe(
            switchMap((token) => this.dialog.open<UserUpdateDialogComponent, UserUpdateDialogData, DialogResult>(
                UserUpdateDialogComponent, { data: { user: this.user$.value!, token } }
            ).beforeClosed())
        ).subscribe((result) => {
            if (result === "ok") this.initUserLoader();
        });
    }

    openInvitationRejectionModal(invitation: Invitation) {
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, { data: {
            title: "Reject invitation",
            body: `Are you sure you would like to reject the invitation to join ${invitation.team?.name}?`,
            confirmText: "Reject invitation",
            confirmButtonStyle: "primary-outline red stroke",
        } }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.userApi.rejectInvitation(invitation.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Invitation rejected successfully.`);
                this.invitations.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    showContactSupportDialog() {
        this.dialog.open<ContactSupportModalComponent, ContactSupportDialogData>(ContactSupportModalComponent, { data: { title: "Delete account" } });
    }
}
