/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { inject } from "@angular/core";
import { CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { first, map, Observable, of, switchMap, tap } from "rxjs";
import { AccountStatus, UserAuth } from "../concept/user";
import { LOGOUT_REASON, TEAM_ACTION, RETURN_TO } from "../framework/url-params";
import { ApplicationState } from "../service/application-state.service";
import { UserController } from "../service/user/user-controller.service";

type AuthGuardContext = {
    app: ApplicationState,
    router: Router,
    routerState: RouterStateSnapshot,
    userCtl: UserController,
};

function createAuthGuardContext(routerState: RouterStateSnapshot): AuthGuardContext {
    return {
        app: inject(ApplicationState),
        router: inject(Router),
        routerState: routerState,
        userCtl: inject(UserController),
    };
}

export const genericGuard: CanActivateFn = (_route, state) => {
    const ctx = createAuthGuardContext(state);
    return ctx.app.userAuth$.pipe(first(), switchMap((user) => resolveGeneric(user, ctx)));
};

const resolveExpectedAuthState: (validStates: (AccountStatus | "logged_out")[], ctx: AuthGuardContext) => Observable<boolean | UrlTree> = (validStates, ctx) => {
    return ctx.app.userAuth$.pipe(
        first(),
        switchMap((userAuth) => {
            if (validStates.includes(userAuth.status)) return of(true);
            else return resolveGeneric(userAuth, ctx);
        }),
    );
}

export const loggedOutGuard: CanActivateFn = (_route, state) => resolveExpectedAuthState(["logged_out"], createAuthGuardContext(state));

export const setupCompletedGuard: CanActivateFn = (_route, state) => resolveExpectedAuthState(["setup_completed"], createAuthGuardContext(state));

export const setupGuard: CanActivateFn = (_route, state) => resolveExpectedAuthState(["verified", "profile_created", "survey_completed", "team_joined_not_surveyed"], createAuthGuardContext(state))

export const teamJoinedNotSurveyedGuard: CanActivateFn = (_route, state) => resolveExpectedAuthState(["team_joined_not_surveyed"], createAuthGuardContext(state));

function resolveGeneric(userAuth: UserAuth, ctx: AuthGuardContext): Observable<UrlTree | boolean> {
    switch (userAuth.status) {
        case "logged_out": {
            if (hasUrlComponents(ctx.routerState)) return of(ctx.router.parseUrl(`sign-in?${RETURN_TO}=${ctx.routerState.url}`));
            else return of(ctx.router.parseUrl("sign-in"));
        }
        case "unverified": {
            return of(ctx.router.parseUrl("verify-email"));
        }
        case "verified":
        case "profile_created":
        case "survey_completed": {
            return of(ctx.router.parseUrl("setup"));
        }
        case "setup_completed": {
            const teamAction = ctx.routerState.root.queryParamMap.get(TEAM_ACTION) ?? ctx.routerState.root.queryParamMap.get("org_action") ?? "";
            return ctx.userCtl.getDefaultTeam().pipe(
                map(team => ctx.router.parseUrl(`team/${team.id}${teamAction}`)),
            );
        }
        case "team_joined_not_surveyed": {
            if (hasUrlComponents(ctx.routerState)) return of(ctx.router.parseUrl(`setup/project?${RETURN_TO}=${ctx.routerState.url}`));
            else return of(ctx.router.parseUrl(`setup/project`));
        }
        // TODO: invalid status handling
        default: {
            return ctx.app.signOut({ deauthUser: true, redirect: false, announce: false }).pipe(
                map(() => {
                    if (hasUrlComponents(ctx.routerState)) return ctx.router.parseUrl(`sign-in?${RETURN_TO}=${ctx.routerState.url}&${LOGOUT_REASON}=invalid_token`);
                    else return ctx.router.parseUrl(`sign-in?${LOGOUT_REASON}=invalid_token`);
                }),
                tap(() => ctx.app.unsetCurrentTeam()),
            );
        }
    }
}

function hasUrlComponents(routerState: RouterStateSnapshot): boolean {
    return routerState.url.length > 1;
}
