/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, PropertiesTableRowComponent, SpinnerComponent } from "typedb-platform-framework";
import { Cluster } from "../../../concept/cluster";
import { clusterDetailsPath } from "../../../routing/resource-paths";
import { ApplicationState } from "../../../service/application-state.service";
import { PlatformApiBackend } from "../../../service/backend/platform-api-backend.service";
import { ClusterApi } from "../../../service/cluster/cluster-api.service";
import { SpaceApi } from "../../../service/space/space-api.service";
import { ResourceService } from "../../../service/resource.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { PageScaffoldComponent, ResourceAvailability } from "../../scaffold/page/page-scaffold.component";
import {
    BehaviorSubject, Observable, Subject, catchError, combineLatest, combineLatestWith, distinctUntilChanged, filter,
    first, interval, map, of, scan, shareReplay, skip, startWith, switchMap, takeUntil, tap
} from "rxjs";
import { AsyncPipe } from "@angular/common";
import { ServerStatusPipe } from "../server-status.pipe";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialog } from "@angular/material/dialog";
import { DownloadClusterServerLogsData, DownloadClusterServerLogsDialogComponent } from "./download-logs-dialog/download-cluster-server-logs-dialog.component";

@Component({
    selector: "tp-server-details-page",
    templateUrl: "./server-details-page.component.html",
    styleUrls: ["./server-details-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, PropertiesTableComponent, PropertiesTableRowComponent, AsyncPipe,
        ButtonComponent, SpinnerComponent, ServerStatusPipe, MatTooltipModule,
    ],
})
export class ServerDetailsPageComponent implements OnInit, OnDestroy {
    @ViewChild("logViewer") logViewerEl?: ElementRef<HTMLPreElement>;
    readonly cluster$ = new BehaviorSubject<Cluster | null>(null);
    readonly serverId$ = this.route.params.pipe(map(params => Number(params["server-id"])));
    readonly server$ = this.cluster$.pipe(
        takeUntilDestroyed(),
        filter(cluster => !!cluster),
        map(cluster => cluster!),
        switchMap((cluster) => interval(5000).pipe(
            takeUntilDestroyed(this.destroyRef),
            startWith(-1),
            switchMap(() => this.clusterApi.listServers(cluster.uuid)),
            combineLatestWith(this.serverId$),
            map(([servers, id]) => servers?.find(x => x.id === id)),
        )),
        shareReplay(1),
    );
    readonly logs$ = new BehaviorSubject<string[] | null>(null);
    readonly breadcrumb$ = combineLatest([this.cluster$, this.route.params]).pipe(
        map(([cluster, params]) => {
            if (!cluster) return null;
            else return { items: [cluster.space.id, cluster.id, params["server-id"]], url: clusterDetailsPath(cluster, this.app.requireCurrentTeam()) };
        }),
    );
    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = combineLatest([this.cluster$, this.server$]).pipe(
        map(([x, y]) => x && y ? "ready" : "loading"), catchError(() => of("failed" as const))
    );
    private readonly isStreamingEnabled$ = new BehaviorSubject<boolean>(true);
    readonly toggleStreamingButtonText$: Observable<string> = this.isStreamingEnabled$.pipe(map(x => x ? "Pause" : "Resume"));
    readonly toggleStreamingButtonIconClass$: Observable<string> = this.isStreamingEnabled$.pipe(map(x => x ? "fa-light fa-pause" : "fa-light fa-play"));
    readonly streamingStatusText$: Observable<string> = this.isStreamingEnabled$.pipe(map(x => x ? "Live" : "Paused"));
    readonly streamingStatusIconClass$: Observable<string> = this.isStreamingEnabled$.pipe(map(x => x ? "tp-live" : "tp-paused"));

    constructor(
        private route: ActivatedRoute, private app: ApplicationState, private destroyRef: DestroyRef,
        private clusterApi: ClusterApi, private spaceApi: SpaceApi, private resourceService: ResourceService,
        private snackbar: SnackbarService, private dialog: MatDialog, private apiBackend: PlatformApiBackend,
    ) {
    }

    ngOnInit() {
        this.initDataLoader();
        this.initLogsLoader();
        this.autoscrollLogViewer();

        this.apiBackend.onReconnect$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.initLogsLoader();
        });
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    private initDataLoader() {
        this.route.params.pipe(
            filter((params) => params["space-id"] && params["cluster-id"]),
            map((params) => ({ spaceId: params["space-id"] as string, clusterId: params["cluster-id"] as string })),
            distinctUntilChanged((a, b) => a.clusterId === b.clusterId && a.spaceId === b.spaceId),
            tap(() => { this.unsub$.next(); }),
            switchMap(({ spaceId, clusterId }) => {
                return this.spaceApi.getSpace({ spaceId, teamUuid: this.app.requireCurrentTeam().uuid }, of(undefined)).pipe(
                    first(),
                    map((space) => ({ space: space.initial!, clusterId })),
                );
            }),
            switchMap(({ space, clusterId }) => {
                return this.clusterApi.getCluster({ clusterId: clusterId, spaceUuid: space.uuid }, this.unsub$);
            }),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.cluster$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.cluster$.error(err);
            }
        });
    }

    private initLogsLoader() {
        this.logs$.next(null);
        combineLatest([this.cluster$, this.server$]).pipe(
            filter(([cluster, server]) => !!cluster && !!server),
            map(([cluster, server]) => [cluster!!, server!!] as const),
            distinctUntilChanged(([_, s1], [_0, s2]) => s1.uuid === s2.uuid),
            tap(() => this.unsub$.next()),
            switchMap(([cluster, server]) =>
                this.clusterApi.listServerLogs(
                    { clusterUuid: cluster.uuid, serverUuid: server.uuid, serverId: server.id, initialLineCount: 100 }, this.unsub$
                ).pipe(
                    scan(((current, next) => {
                        const newArray = current.slice();
                        next.lines.forEach((line, idx) => newArray[next.lineNumberStart + idx] = line);
                        return newArray;
                    }), [] as string[]),
                    combineLatestWith(this.isStreamingEnabled$)
                )
            ),
            takeUntil(this.unsub$.pipe(skip(1))),
        ).subscribe(([logs, isStreamingEnabled]) => {
            if (isStreamingEnabled) this.logs$.next(logs);
        });
    }

    autoscrollLogViewer() {
        this.logs$.subscribe(_ => {
            if (this.logViewerEl) {
                this.logViewerEl.nativeElement.scrollTop = this.logViewerEl.nativeElement.scrollHeight;
            }
        });
    }

    toggleLogStream() {
        this.isStreamingEnabled$.next(!this.isStreamingEnabled$.value);
    }

    openDownloadLogsDialog() {
        this.server$.pipe(first()).subscribe((server) => {
            this.dialog.open<DownloadClusterServerLogsDialogComponent, DownloadClusterServerLogsData>(
                DownloadClusterServerLogsDialogComponent,
                { data: { cluster: this.cluster$.value!, server: server! } }
            );
        });
    }

    copyServerAddress() {
        this.server$.pipe(
            filter(x => !!x?.address),
            map(x => x!.address!),
            first()
        ).subscribe((address) => {
            navigator.clipboard.writeText(address);
            this.snackbar.success("Copied", { duration: 1250 });
        })
    }
}
