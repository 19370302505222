/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent } from 'typedb-platform-framework';
import { Invitation } from "../../../concept/team";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";

@Component({
    selector: 'tp-access-modal',
    standalone: true,
    imports: [ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent, AccessLevelPipe],
    templateUrl: './access-modal.component.html',
})
export class AccessModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: Invitation) {}
}
