@if (currentStep != null) {
  @if (onboardingTitle$ | async; as title) {
    <tp-heading-with-highlights level="h1" [value]="title" />
  }

  <mat-stepper [selectedIndex]="currentStep">
    <mat-step [completed]="currentStep > 0" [editable]="false">
      <ng-template matStepLabel>Profile</ng-template>
      <tp-setup-profile (done)="advance()"/>
    </mat-step>
    <mat-step [completed]="currentStep > 1" [editable]="false">
      <ng-template matStepLabel>Project</ng-template>
      <tp-setup-project (done)="advance()"/>
    </mat-step>
    <mat-step [completed]="false" [editable]="false">
      <ng-template matStepLabel>Environment</ng-template>
      @if (userCreated$ | async) {
        <tp-setup-team/>
      }
    </mat-step>
  </mat-stepper>
} @else {
  <tp-spinner/>
}
