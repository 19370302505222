<tp-page-scaffold [pageAvailability]="availability$ | async">
  @if (teamMember$ | async; as teamMember) {
    <article>
      <section>
        <h2 class="section-title-row">{{ teamMember.firstName }} {{ teamMember.lastName }} ({{teamMember.email}})</h2>
        <tp-properties-table title="User Details">
          <tp-properties-table-row key="Username">{{ teamMember.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Email">{{ teamMember.email }}</tp-properties-table-row>
          <tp-properties-table-row key="First Name">{{ teamMember.firstName }}</tp-properties-table-row>
          <tp-properties-table-row key="Last Name">{{ teamMember.lastName }}</tp-properties-table-row>
          <tp-properties-table-row key="Team Access Level">
            {{ teamMember.accessLevel | accessLevel }}
            @if (app.hasTeamAdminAccess$ | async) {
              <div class="flex-spacer"></div>
              <tp-button buttonStyle="primary-outline white stroke" [height]="32" (click)="openSetAccessLevelModal(teamMember)" buttonId="editTeamAccessLevel">
                <i class="fa-light fa-pen-to-square"></i> Edit
              </tp-button>
            }
          </tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (spaces) {
        <section>
          <tp-table-toolbar [table]="spaces" filterGroupId="spaces" tableTitle="Spaces"/>
          <tp-spaces-table [table]="spaces"/>
          <tp-table-paginator [table]="spaces"/>
        </section>
      }

      <tp-delete-resource-section
        title="Remove this user" [display]="{ text: 'Remove the user from this team.' }"
        buttonText="Remove" (buttonPressed)="beginRemoveUser()"
      />
    </article>
  }
</tp-page-scaffold>
