/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevelProto, UserSquadProto, SpaceSquadAssignmentProto, SquadProto, } from "../../protocol/concept";
import { AccessLevel, accessLevelOf } from "./iam";

export interface Squad extends BaseResource {
    name: string;
    createdAt: Date;
}

export interface SpaceSquadAssignment extends Squad {
    accessLevel: AccessLevel;
}

export interface UserSquad extends Squad {
    accessLevel: AccessLevel;
}

export function partialSquadOf(data: SquadProto): PartialWithUuid<Squad> {
    const squad: PartialWithUuid<Squad> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(squad) as PartialWithUuid<Squad>;
}

function partialSquadWithAccessLevelOf(data: { squad: SquadProto, accessLevel: AccessLevelProto, hasAccessLevel: boolean }) {
    const userWithAccessLevel = {
        ...partialSquadOf(data.squad),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(userWithAccessLevel);
}

export function partialSpaceSquadAssignmentOf(data: SpaceSquadAssignmentProto): PartialWithUuid<SpaceSquadAssignment> {
    return partialSquadWithAccessLevelOf(data) as PartialWithUuid<SpaceSquadAssignment>;
}

export function partialUserSquadOf(data: UserSquadProto): PartialWithUuid<UserSquad> {
    return partialSquadWithAccessLevelOf(data) as PartialWithUuid<UserSquad>;
}

export function squadProtoOf(squad: Partial<Squad>): SquadProto {
    return new SquadProto({
        uuid: squad.uuid ? stringToBytes(squad.uuid) : undefined,
        id: squad.id,
        name: squad.name,
        createdAt: squad.createdAt?.getTime(),
    });
}

type BaseSquadColumn = "id" | "name" | "createdAt";
export type SquadColumn = BaseSquadColumn | "accessLevel";

export const squadProperties: Record<BaseSquadColumn, Property> = {
    id: { id: "id", name: "Squad ID", attributeType: "id" },
    name: { id: "name", name: "Squad Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const spaceSquadAssignmentProperties: Record<SquadColumn, Property> = {
    ...squadProperties,
    accessLevel: { id: "accessLevel", name: "Space Access Level", ownerType: "assignment", attributeType: "access-level" },
}

export const memberSquadProperties: Record<SquadColumn, Property> = {
    ...squadProperties,
    accessLevel: { id: "accessLevel", name: "Access Level", ownerType: "membership", attributeType: "access-level" },
}

export const squadPropertiesList = [squadProperties.id, squadProperties.name, squadProperties.createdAt];
export const memberSquadPropertiesList = [...squadPropertiesList, memberSquadProperties.accessLevel];
export const spaceSquadAssignmentPropertiesList = [...squadPropertiesList, spaceSquadAssignmentProperties.accessLevel];
