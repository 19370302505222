<mat-sidenav-container>
  <mat-sidenav class="left" disableClose mode="side" opened>
    <div class="tp-sidenav-container left" [ngClass]="{ 'collapsed': leftSidebarCollapsed }" >
      <tp-sidebar (collapsedChange)="leftSidebarCollapsed = $event"/>
    </div>
  </mat-sidenav>

  <mat-sidenav-content [@sidebarLeftMargin]="leftSidebarCollapsed ? 'collapsed' : 'open'">
    @switch (pageAvailability) {
      @case ("ready") {
        <div class="action-bar">
          <ng-content select="[actionBar]"/>
          <div class="flex-spacer"></div>
          <a href="https://typedb.com/blog/" target="_blank">
            <tp-button buttonId="actionBarNews" buttonStyle="secondary">
              <i class="fa-light fa-bullhorn"></i>
              News
            </tp-button>
          </a>
          <tp-button buttonId="actionBarSupport" buttonStyle="secondary" (click)="rightSidebar.toggle(undefined, 'mouse')">
            Help
            <i class="fa-light fa-chevron-right"></i>
          </tp-button>
        </div>
        <ng-content/>
      }
      @case ("loading") {
        <tp-spinner/>
      }
      @case (null) {
        <tp-spinner/>
      }
      @default {
      }
    }
  </mat-sidenav-content>

  <mat-sidenav class="right" disableClose #rightSidebar mode="side" position="end">
    <div class="tp-sidenav-container right"><tp-right-sidebar/></div>
  </mat-sidenav>
</mat-sidenav-container>
