<div class="form-container">
  <p class="title">Connect {{ providerName }} Billing Account</p>
  @if (teamOptions$ | async) {
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitConnectTeamForm()">
      <mat-form-field>
        <mat-label>Team</mat-label>
        <mat-select [formControl]="form.controls.team">
          @for (team of teamOptions$ | async; track team) {
            <mat-option [disabled]="!team.enabled" [value]="team.value">{{ team.viewValue }}</mat-option>
          }
          <hr/>
          <button type="button" class="selector-button mat-mdc-option mdc-list-item" (click)="onCreateTeamClick()">
            <i class="fa-light fa-plus"></i><span>Create team</span>
          </button>
        </mat-select>
      </mat-form-field>
      <tp-form-actions [isSubmitting]="isSubmitting$ | async" submitText="Connect" [buttonIdPrefix]="buttonIdPrefix"/>
    </tp-form>
  } @else {
    <tp-spinner/>
  }
</div>
