/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    UserCtlProtoAcceptInvitationReq,
    UserCtlProtoCheckUserIDReq,
    UserCtlProtoCompleteOnboardingSurveyReq,
    UserCtlProtoCreateUserReq,
    UserCtlProtoGetAccessLevelsReq,
    UserCtlProtoGetUserReq,
    UserCtlProtoLeaveTeamReq,
    UserCtlProtoListInvitationsReq,
    UserCtlProtoListTeamsReq,
    UserCtlProtoListSpacesReq,
    UserCtlProtoListSquadsReq,
    UserCtlProtoRejectInvitationReq,
    UserCtlProtoReq,
    UserCtlProtoSendVerifyEmailReq,
    UserCtlProtoSetPropertyReq,
    UserCtlProtoSignUpReq,
    UserCtlProtoUnsetPropertyReq,
    UserCtlProtoUpdateUserReq,
    UserCtlProtoUpdateVerificationStatusReq,
} from "../../../application/protocol/user-controller";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { AccessLevel, accessLevelOf } from "../../concept/iam";
import { Invitation, UserTeam, partialInvitationOf, partialUserTeamOf } from "../../concept/team";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { UserSquad, partialUserSquadOf } from "../../concept/squad";
import { partialUserOf, User, userProtoOf } from "../../concept/user";
import { stringToBytes } from "../../util";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { ApiService } from "../backend/api.service";
import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { partialUserSpaceOf, UserSpace } from "../../concept/space";

@Injectable({
    providedIn: "root",
})
export class UserApi {
    constructor(private api: ApiService) {}

    getAccessLevels(userUuids: string[]): Observable<AccessLevel[]> {
        return this.api.userReqRes(new UserCtlProtoReq({ getAccessLevels: new UserCtlProtoGetAccessLevelsReq({ userUuids: userUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    signUp(email: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            signUp: new UserCtlProtoSignUpReq({ email }),
        }));
    }

    createUser(user: Partial<User>): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            createUser: new UserCtlProtoCreateUserReq({ user: userProtoOf(user) })
        }));
    }

    checkUserId(userId: string): Observable<{ exists: boolean }> {
        return this.api.userReqRes(new UserCtlProtoReq({
            checkUserId: new UserCtlProtoCheckUserIDReq({ id: userId })
        })).pipe(map((res) => ({ exists: res.checkUserId.exists })));
    }

    getUser(userId: string, unsub$: UnsubListener): Observable<ApiResponse<User>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { getUser: new UserCtlProtoGetUserReq({ userId: userId }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialUserOf(res.data.getUser.user)))
        );
    }

    updateUser(user: PartialWithUuid<User>): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ updateUser: new UserCtlProtoUpdateUserReq({ user: userProtoOf(user) }) }));
    }

    updateUserWithToken(user: PartialWithUuid<User>, token: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ updateUser: new UserCtlProtoUpdateUserReq({ user: userProtoOf(user), token }) }));
    }

    listTeams(params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserTeam>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { listTeams: new UserCtlProtoListTeamsReq({ params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listTeams.teams.map(partialUserTeamOf))));
    }

    listSpaces(props: { userUuid: string, teamUuid: string }, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserSpace>> {
        return this.api.userReqSub(new UserCtlProtoReq({ listSpaces: new UserCtlProtoListSpacesReq({
                userUuid: stringToBytes(props.userUuid), teamUuid: stringToBytes(props.teamUuid), params: parametersProtoOf(params)
        }) }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSpaces.spaces.map(partialUserSpaceOf))));
    }

    listSquads(props: { userUuid: string, teamUuid: string }, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserSquad>> {
        return this.api.userReqSub(new UserCtlProtoReq({ listSquads: new UserCtlProtoListSquadsReq({
                userUuid: stringToBytes(props.userUuid), teamUuid: stringToBytes(props.teamUuid), params: parametersProtoOf(params) }
        ) }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSquads.squads.map(partialUserSquadOf))));
    }

    listInvitations(params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Invitation>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { listInvitations: new UserCtlProtoListInvitationsReq({ params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listInvitations.invitations.map(partialInvitationOf))));
    }

    acceptInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            acceptInvitation: new UserCtlProtoAcceptInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    rejectInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            rejectInvitation: new UserCtlProtoRejectInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    sendVerifyEmail(isAsync: boolean): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            sendVerifyEmail: new UserCtlProtoSendVerifyEmailReq({ isAsync })
        }));
    }

    updateVerificationStatus(token: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            updateVerificationStatus: new UserCtlProtoUpdateVerificationStatusReq({ token })
        }));
    }

    leaveTeam(teamUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            leaveTeam: new UserCtlProtoLeaveTeamReq({ teamUuid: stringToBytes(teamUuid) })
        }));
    }

    setProperty(key: string, value: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ setProperty: new UserCtlProtoSetPropertyReq({ key, value }) }));
    }

    unsetProperty(key: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ unsetProperty: new UserCtlProtoUnsetPropertyReq({ key }) }));
    }

    completeOnboardingSurvey(): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ completeOnboardingSurvey: new UserCtlProtoCompleteOnboardingSurveyReq() }));
    }
}
