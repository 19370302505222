/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";
import { BannerComponent } from "../banner/banner.component";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { SpinnerComponent } from "typedb-platform-framework";
import { first, map, of } from "rxjs";
import { ApplicationState } from "../../../service/application-state.service";
import { UserController } from "../../../service/user/user-controller.service";
import { TeamApi } from "../../../service/team/team-api.service";

@Component({
    selector: "tp-team-subsite-scaffold", // eslint-disable-line @angular-eslint/component-selector
    templateUrl: "./team-subsite-scaffold.component.html",
    standalone: true,
    imports: [
        RouterOutlet, SpinnerComponent, AsyncPipe, BannerComponent,
    ],
})
export class TeamSubsiteScaffoldComponent {
    constructor(private route: ActivatedRoute, protected app: ApplicationState, private userCtl: UserController, private teamApi: TeamApi) {
        const teamId = this.route.snapshot.paramMap.get("team-id");
        if (teamId) {
            this.teamApi.getTeam(teamId, of(undefined)).pipe(
                first((res) => !!res.initial),
                map((res) => res.initial!)
            ).subscribe((team) => this.app.setCurrentTeam(team));
        } else {
            this.userCtl.getDefaultTeam().subscribe((team) => this.app.setCurrentTeam(team));
        }
    }
}
