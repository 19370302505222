/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { combineLatest, first, map, startWith, Subject, switchMap } from "rxjs";
import {
    ButtonComponent,
    ConfirmationModalComponent,
    ConfirmationModalData,
    MenuItem,
    ModalComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { Invitation, InvitationColumn } from "../../../concept/team";
import { LOADING } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { InvitationsTableComponent } from "../../team/invitations-table/invitations-table.component";
import { PageScaffoldComponent, resourceAvailabilityStreamOf } from "../../scaffold/page/page-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { InvitationDialogComponent } from "../invite/invitation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessModalComponent } from "../../team/access-modal/access-modal.component";
import { UsersTableComponent } from "../table/users-table.component";
import { TeamMember, User } from "../../../concept/user";
import {
    TeamSetUserAccessLevelDialogComponent
} from "../../team/set-user-access-level/team-set-user-access-level-dialog.component";

@Component({
    selector: "tp-members-page",
    templateUrl: "./members-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, ModalComponent, ConfirmationModalComponent, TableToolbarComponent, AsyncPipe,
        TablePaginatorComponent, InvitationsTableComponent, ButtonComponent,
        MatTooltipModule, UsersTableComponent,
    ],
})
export class MembersPageComponent implements OnDestroy {
    readonly team = this.app.requireCurrentTeam();

    private readonly unsubMembers$ = new Subject<void>();
    readonly members = this.teamCtl.membersTable(this.unsubMembers$);
    private readonly unsubInvitations$ = new Subject<void>();
    invitations?: ResourceTable<Invitation, InvitationColumn>;
    readonly availability$ = resourceAvailabilityStreamOf(this.members);

    readonly cannotInviteReason$ = this.app.hasTeamAdminAccess$.pipe(map(isAdmin => {
        if (!isAdmin) return "Only team administrators can invite members";
        return null;
    }), startWith(LOADING));
    readonly inviteEnabled$ = this.cannotInviteReason$.pipe(map(x => !x));

    usersTableRowPopupMenuItems: (user: TeamMember) => MenuItem[] = (user) => [];

    readonly invitationsTableRowPopupMenuItems: (invitation: Invitation) => MenuItem[] = (invitation) => [
        {
            label: "Access Details",
            action: () => {
                this.dialog.open(AccessModalComponent, { data: invitation });
            }
        },
        {
            label: "Resend invitation email",
            action: () => {
                this.teamApi.resendInvitation(invitation.uuid).subscribe(() => {
                    this.snackbar.success(`A new invitation email has been sent to '${invitation.userEmail}'`);
                });
            },
        },
        {
            label: "Revoke invitation",
            action: () => {
                this.openRevokeInvitationModal(invitation);
            },
            dangerous: true
        },
    ];

    constructor(
        public app: ApplicationState, private teamApi: TeamApi, private snackbar: SnackbarService,
        private teamCtl: TeamController, private dialog: MatDialog
    ) {
        combineLatest([
            this.app.hasTeamAdminAccess$.pipe(first()),
            this.app.currentUser$.pipe(first((user) => !!user))
        ]).subscribe(([isAdmin, currentUser]) => {
            if (isAdmin) this.invitations = this.teamCtl.invitationsTable(this.unsubInvitations$);
            this.usersTableRowPopupMenuItems = (user: TeamMember) => [
                {
                    label: "Change access level",
                    action: () => this.openChangeAccessLevelModal(user),
                    disabled: !isAdmin,
                    disabledReason: !isAdmin ? "Only team administrators can change user access levels" : undefined
                },
                {
                    label: "Remove",
                    action: () => this.openRemoveMemberModal(user),
                    disabled: !isAdmin || user.id == currentUser!.id,
                    disabledReason: !isAdmin ? "Only team administrators can remove members" :
                        user.id == currentUser!.id ? "You can't remove yourself from a team" : undefined
                },
            ];
        });
    }

    ngOnDestroy() {
        this.unsubMembers$.next();
        this.unsubInvitations$.next();
    }

    onInviteClick() {
        this.dialog.open(InvitationDialogComponent, { width: "600px" }).beforeClosed().subscribe(() => { this.invitations!.refresh(); });
    }

    openRevokeInvitationModal(invitation: Invitation) {
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: "Revoke invitation",
                body: `Are you sure you would like to revoke ${invitation.userEmail}'s invitation to ${invitation.team?.name}?`,
                confirmText: "Revoke invitation",
                confirmButtonStyle: "primary-outline red stroke",
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.teamApi.revokeInvitation(invitation.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Invitation revoked successfully.`);
                this.invitations!.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openChangeAccessLevelModal(user: TeamMember) {
        this.dialog.open(TeamSetUserAccessLevelDialogComponent, { data: { team: this.team, user } });
    }

    openRemoveMemberModal(user: User) {
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Remove member",
                body: confirmationMessage("remove", [user], "member") + ` They will no longer be able to view this team. This does not prevent them connecting to running TypeDB Cloud clusters, but they will no longer be accessible via the web UI.`,
                confirmText: "Remove member",
                strongConfirmationString: strongConfirmationStringOf([user]),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest([user].map(x => this.teamApi.removeMember({
            teamUuid: this.team.uuid,
            userUuid: x.uuid
        }))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Member(s) removed successfully.`);
                this.members.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
