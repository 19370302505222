/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { mergeChanges, mergeListChanges } from "../concept/api-response";
import { Ok, ok } from "../concept/base";
import { hasAdminAccess, hasWriteAccess } from "../concept/iam";
import { Team } from "../concept/team";
import { Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, filter,
    first, map, Observable, of, ReplaySubject, shareReplay, skip, Subject, switchMap, tap } from "rxjs";
import { User, UserAuth } from "../concept/user";
import { AnalyticsService } from "./analytics.service";
import { PlatformApiBackend } from "./backend/platform-api-backend.service";
import { PlatformAuthService } from "./authentication/platform-auth.service";
import { IdentityAuthService } from "./authentication/identity-auth.service";
import { TeamApi } from "./team/team-api.service";
import { SnackbarService } from "./snackbar.service";
import { UserApi } from "./user/user-api.service";
import { Router } from "@angular/router";

type SignOutOptions = {
    deauthUser: boolean;
    redirect: boolean;
    announce: boolean;
};

const TEAM_ID = "team_id";
const SIDEBAR_COLLAPSED = "sidebar_collapsed";

@Injectable({
    providedIn: "root",
})
export class ApplicationState {
    readonly userAuth$ = new ReplaySubject<UserAuth>(1);
    readonly currentUser$ = new BehaviorSubject<User | null>(null);
    readonly currentUserTeams$ = new BehaviorSubject<Team[] | null>(null);
    readonly currentTeam$ = new BehaviorSubject<Team | null>(null);
    private readonly teamAccess$ = this.currentTeam$.pipe(
        filter(team => !!team), map(team => team!),
        switchMap(team => this.teamApi.getAccessLevels([team.uuid]).pipe(map(x => x[0]))),
        shareReplay(1),
    );
    readonly hasTeamWriteAccess$ = this.teamAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly hasTeamAdminAccess$ = this.teamAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly unsubTeams$ = new Subject<void>();
    suppressRedirectOnSignout = false;
    apiFirebaseInfo?:{ tenantId: string, apiKey: string };

    constructor(
        private userApi: UserApi, private snackbarService: SnackbarService, private router: Router,
        private identityAuth: IdentityAuthService, private platformAuth: PlatformAuthService, private teamApi: TeamApi,
        private apiBackend: PlatformApiBackend, private analytics: AnalyticsService,
    ) {
        this.startAPIAuth();
        this.updateUserAuthOnIdentityAuthStateChanges();
        this.maybeRedirectOnSignout();
        this.reauthOnConnectionRecovered();
        this.informAnalyticsOnIdentityAuthStateChanges();
        this.initCurrentUserSubOnUserAuthChanges();
        this.initCurrentUserTeamsSub();
        this.initLastUsedTeamIdSub();
        (window as any)["app"] = this;
    }

    startAPIAuth() {
        this.platformAuth.startAPIAuth().pipe(first()).subscribe((res) => {
            this.apiFirebaseInfo = { tenantId: res.tenantId, apiKey: res.apiKey };
        });
    }

    private updateUserAuthOnIdentityAuthStateChanges() {
        this.identityAuth.currentUserStateChanges.pipe(
            switchMap((firebaseUser) => {
                if (!firebaseUser) return of(null);
                else return this.identityAuth.getIdToken();
            }),
            switchMap((token) => {
                if (!token) return of<UserAuth>({ status: "logged_out" });
                else return this.platformAuth.authUserToken(token);
            }),
            distinctUntilChanged(),
        ).subscribe((userAuth) => {
            this.userAuth$.next(userAuth);
        });
    }

    private maybeRedirectOnSignout(): void {
        this.identityAuth.currentUserStateChanges.pipe(
            map((firebaseUser) => !!firebaseUser),
            distinctUntilChanged(),
            skip(1),
        ).subscribe((isLoggedIn) => {
            if (isLoggedIn) return;
            this.apiBackend.isAuthenticated$.next(false);
            if (this.suppressRedirectOnSignout) {
                this.suppressRedirectOnSignout = false;
            } else this.router.navigate(["/"]);
        });
    }

    private reauthOnConnectionRecovered() {
        this.apiBackend.onReconnect$.pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap(() => console.info("Reauthenticated after connection recovery")),
        ).subscribe();
    }

    initCurrentUserSubOnUserAuthChanges() {
        const unsub$ = new Subject<void>();
        this.userAuth$.pipe(
            map((userAuth) => userAuth.user?.id ?? null),
            tap(() => { unsub$.next(); }),
            switchMap((userId) => userId ? this.userApi.getUser(userId, unsub$) : of(null)),
        ).subscribe((res) => {
            if (!res) {
                this.currentUser$.next(null);
                return;
            }
            const mergeResult = mergeChanges(this.currentUser$.value, res);
            if (mergeResult.result === "shouldDelete") {
                unsub$.next();
                // TODO: ???
            } else if (mergeResult.result === "shouldResync") {
                unsub$.next();
                this.initCurrentUserSubOnUserAuthChanges();
            } else {
                this.currentUser$.next(mergeResult.data);
            }
        });
    }

    initCurrentUserTeamsSub() {
        this.currentUser$.pipe(
            tap(() => this.unsubTeams$.next()),
            switchMap((user) => {
                if (!user) return of(null);
                return this.userApi.listTeams({ pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }, this.unsubTeams$);
            }),
        ).subscribe((res) => {
            if (res == null) {
                this.currentUserTeams$.next(null);
                this.currentTeam$.next(null);
                return;
            }
            const mergeResult = mergeListChanges(this.currentUserTeams$.value, res);
            if (mergeResult.result === "shouldResync") {
                this.initCurrentUserTeamsSub();
                return;
            }
            this.currentUserTeams$.next(mergeResult.data);
        });
    }

    private initLastUsedTeamIdSub() {
        this.currentTeam$.pipe(filter(x => !!x), map(x => x!)).subscribe((team) => {
            this.setLastUsedTeamId(team.id);
        });
    }

    private informAnalyticsOnIdentityAuthStateChanges() {
        this.identityAuth.currentUserStateChanges.pipe(
            map(x => x?.email),
            distinctUntilChanged(),
            filter(email => !!email), map(email => email!),
        ).subscribe((email) => {
            this.analytics.posthog.identify(email, { email });
            this.analytics.cio.identify(email, { email });
        });
    }

    requireCurrentTeam(): Team {
        const team = this.currentTeam$.value;
        if (team) return team;
        else {
            throw "No team selected";
        }
    }

    setLastUsedTeamId(teamId: string) {
        try {
            localStorage.setItem(TEAM_ID, teamId);
        } catch (e) {
            // do nothing - maybe local storage is unwritable by browser security policy
        }
    }

    lastUsedTeamId() {
        return localStorage.getItem(TEAM_ID);
    }

    setSidebarCollapsed(collapsed: boolean) {
        try {
            localStorage.setItem(SIDEBAR_COLLAPSED, `${collapsed}`);
        } catch (e) {
            // do nothing - maybe local storage is unwritable by browser security policy
        }
    }

    getSidebarCollapsed(): boolean {
        return localStorage.getItem(SIDEBAR_COLLAPSED) === "true"
    }

    setCurrentTeam(team: Team) {
        this.currentTeam$.next(team);
    }

    unsetCurrentTeam() {
        this.currentTeam$.next(null);
    }

    signOut(opts: SignOutOptions) {
        const maybeDeauthUser: Observable<Ok> = opts.deauthUser && this.currentUser$.value ? this.platformAuth.deauthUser() : of(ok);
        if (!opts.redirect) this.suppressRedirectOnSignout = true;
        return maybeDeauthUser.pipe(
            tap(() => {
                this.userAuth$.next({ status: "logged_out" });
                this.analytics.posthog.reset();
                this.analytics.cio.reset();
                if (opts.announce) this.snackbarService.success("You have been signed out.");
            }),
            switchMap(() => this.identityAuth.signOut()),
        );
    }
}
