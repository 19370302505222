/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Output, QueryList, ViewChildren } from "@angular/core";
import { MatExpansionModule, MatExpansionPanel } from "@angular/material/expansion";
import { SpinnerComponent, ButtonComponent, FormActionsComponent } from "typedb-platform-framework";
import { isMultipleChoiceQuestion, ParagraphWithHighlights } from "typedb-web-schema/lib";
import { ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { RETURN_TO } from "../../../framework/url-params";
import { AnalyticsService } from "../../../service/analytics.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { SanityService } from "../../../service/sanity.service";
import { UserApi } from "../../../service/user/user-api.service";
import { MultipleChoiceQuestionComponent } from "./question/multiple-choice/multiple-choice-question.component";
import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { combineLatest, first, map, switchMap, tap } from "rxjs";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
    QuestionInstance, SurveyInstance, SurveySectionInstance
} from "./survey-instance";
import { ActivatedRoute, Router } from "@angular/router";
import { trigger, transition, animate, style, sequence } from '@angular/animations';

@Component({
    selector: "tp-setup-project",
    templateUrl: "./setup-project.component.html",
    styleUrls: ["./setup-project.component.scss"],
    standalone: true,
    imports: [
        MultipleChoiceQuestionComponent, AsyncPipe, SpinnerComponent, MatExpansionModule, NgTemplateOutlet,
        MatIconModule, ButtonComponent, MatTooltipModule, FormActionsComponent, ParagraphWithHighlightsComponent
    ],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, height: 0}),
                sequence([
                    animate('50ms linear', style({height: 'auto'})),
                    animate('500ms linear', style({opacity: 1})),
                ])
            ]),
            transition(':leave', [
                sequence([
                    animate('250ms linear', style({opacity: 0})),
                    animate('50ms linear', style({height: 0})),
                ])
            ]),
        ]),
    ],
})
export class SetupProjectComponent {
    @Output() done = new EventEmitter();

    @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>;
    survey?: SurveyInstance;
    isSubmitting = false;
    tagline$ = this.sanity.onboarding.pipe(map(x => x.projectInfoText ? ParagraphWithHighlights.fromSanity(x.projectInfoText) : null));
    continueLink?: string;

    constructor(
        private sanity: SanityService, private userApi: UserApi, private identityAuth: IdentityAuthService,
        private platformAuth: PlatformAuthService, private app: ApplicationState, private analytics: AnalyticsService,
        private route: ActivatedRoute, private router: Router
    ) {
        sanity.projectInfoSurvey.pipe(first()).subscribe((survey) => {
            this.survey = new SurveyInstance(survey);
        });

        combineLatest([this.route.data, this.route.queryParamMap]).subscribe(([data, params]) => {
            if (data["variant"] === "standalone") {
                const returnToUrl = params.get(RETURN_TO);
                this.continueLink = returnToUrl ?? "/";
            }
        });
    }

    expandSection(sectionIndex: number) {
        this.expansionPanels.get(sectionIndex)!.open();
    }

    onQuestionAnswerChanged(section: SurveySectionInstance, question: QuestionInstance) {
        if (section.isCompleted && !this.survey!.firstCompletions.has(section.spec.name)) {
            this.survey!.firstCompletions.add(section.spec.name);
            if (this.shouldAutoAdvanceOnAnswerChanged(question)) { // NOTE: Auto-advance will not trigger if the last question answered in a section is multi-choice
                this.doAutoAdvance(section);
            }
        }
    }

    private shouldAutoAdvanceOnAnswerChanged(question: QuestionInstance) {
        return !(isMultipleChoiceQuestion(question.spec) && question.spec.isMultiSelect);
    }

    private doAutoAdvance(completedSection: SurveySectionInstance) {
        const completedSectionIndex = this.survey!.sections.findIndex(x => x.spec.name === completedSection.spec.name);
        const nextIncompleteSectionIndexRelative = this.survey!.sections.slice(completedSectionIndex).findIndex(x => !this.survey!.firstCompletions.has(x.spec.name));
        if (nextIncompleteSectionIndexRelative === -1) {
            // TODO: enable Continue button if all sections are completed; highlight all incomplete sections
        } else {
            this.expansionPanels.get(completedSectionIndex + nextIncompleteSectionIndexRelative)!.open();
        }
    }

    get finishButtonTooltip(): string {
        return this.survey?.isCompleted ? `` : `All questions must be answered in order to proceed`;
    }

    completeSurvey() {
        this.isSubmitting = true;
        this.unsetHiddenQuestions();
        this.userApi.completeOnboardingSurvey().pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
        ).subscribe({
            next: () => {
                this.isSubmitting = false;
                this.analytics.google.reportAdConversion("submitProjectInfo");
                this.done.emit();
                if (this.continueLink != null) this.router.navigate([this.continueLink]);
            },
            error: () => {
                this.isSubmitting = false;
            },
        });
    }

    private unsetHiddenQuestions() {
        this.survey?.sections.forEach((section) => {
            section.hiddenQuestions.forEach((hiddenQuestion) => {
                this.userApi.unsetProperty(hiddenQuestion.spec.posthogProperty).subscribe()
            })
        })
    }
}
