/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    SquadCtlProtoAddMemberReq, SquadCtlProtoCreateSquadReq, SquadCtlProtoDeleteSquadReq, SquadCtlProtoGetAccessLevelsReq,
    SquadCtlProtoGetSquadReq, SquadCtlProtoListMembersReq, SquadCtlProtoListSpacesReq,
    SquadCtlProtoRemoveMemberReq, SquadCtlProtoReq, SquadCtlProtoSetMemberAccessLevelReq, SquadCtlProtoUpdateSquadReq
} from "../../../application/protocol/squad-controller";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import { partialSquadOf, Squad, squadProtoOf } from "../../concept/squad";
import { partialSquadMemberOf, SquadMember } from "../../concept/user";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { partialSquadSpaceOf, SquadSpace } from "../../concept/space";

@Injectable({
    providedIn: "root",
})
export class SquadApi {
    constructor(private api: ApiService) {
    }

    getAccessLevels(squadUuids: string[]): Observable<AccessLevel[]> {
        return this.api.squadReqRes(new SquadCtlProtoReq({ getAccessLevels: new SquadCtlProtoGetAccessLevelsReq({ squadUuids: squadUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createSquad(props: { squad: Partial<Squad>, teamUuid: string }): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({
            createSquad: new SquadCtlProtoCreateSquadReq({
                squad: squadProtoOf(props.squad),
                teamUuid: stringToBytes(props.teamUuid)
            })
        }));
    }

    getSquad(props: { squadId: string, teamUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Squad>> {
        return this.api.squadReqSub(new SquadCtlProtoReq(
            { getSquad: new SquadCtlProtoGetSquadReq({ squadId: props.squadId, teamUuid: stringToBytes(props.teamUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialSquadOf(res.data.getSquad.squad)))
        );
    }

    updateSquad(squad: PartialWithUuid<Squad>): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({ updateSquad: new SquadCtlProtoUpdateSquadReq({ squad: squadProtoOf(squad) }) }));
    }

    deleteSquad(uuid: string): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({ deleteSquad: new SquadCtlProtoDeleteSquadReq({ squadUuid: stringToBytes(uuid) }) }));
    }

    listSpaces(squadUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<SquadSpace>> {
        return this.api.squadReqSub(new SquadCtlProtoReq({
            listSpaces: new SquadCtlProtoListSpacesReq({ squadUuid: stringToBytes(squadUuid), params: parametersProtoOf(params) })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSpaces.spaces.map(partialSquadSpaceOf))));
    }

    listMembers(squadUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<SquadMember>> {
        return this.api.squadReqSub(new SquadCtlProtoReq(
            { listMembers: new SquadCtlProtoListMembersReq({ squadUuid: stringToBytes(squadUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listMembers.members.map(partialSquadMemberOf))));
    }

    addMember(props: { squadUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({
            addMember: new SquadCtlProtoAddMemberReq({
                squadUuid: stringToBytes(props.squadUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    removeMember(props: { squadUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({
            removeMember: new SquadCtlProtoRemoveMemberReq(
                { squadUuid: stringToBytes(props.squadUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setMemberAccessLevel(props: { squadUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.squadReq(new SquadCtlProtoReq({
            setMemberAccessLevel: new SquadCtlProtoSetMemberAccessLevelReq({
                squadUuid: stringToBytes(props.squadUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }
}
