<tp-modal [dialogTitle]="!generatedToken ? 'Create new API token' : 'API Token ready'" [isBusy]="(isSubmitting$ | async) || !(isReady$ | async)">
  @if (generatedToken; as token) {
    <p class="tp-ready-message">You can now use API token <strong>{{ generatedToken!.name }}</strong> for authentication:</p>
    <tp-properties-table class="dark" title="Token Details">
      <tp-properties-table-row key="Client ID">{{ token.clientID }}</tp-properties-table-row>
      <tp-properties-table-row key="Client Secret" class="tp-copyable">
        {{ token.clientSecret }}
        <button
          class="btn-copy" mat-icon-button aria-label="copy"
          (click)="copyClientSecret(); $event.stopPropagation();" matTooltip="Copy to clipboard"
        >
          @if (!isClientSecretCopied) {
            <i class="fa-light fa-copy"></i>
          } @else {
            <i class="fa-solid fa-check"></i>
          }
        </button>
      </tp-properties-table-row>
    </tp-properties-table>
    <div class="admonitionblock attention">
      <div class="icon">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <p>Make sure to copy the client secret to somewhere safe - for security reasons you won't see it again.</p>
    </div>
    <br/>
    <p>
      For more information on how to set up and use the Cloud Administrative API, check out our
      <a href="https://typedb.com/docs/manual/cloud/api">documentation</a>.
    </p>
    <div class="tp-btn-section">
      <div class="flex-spacer"></div>
      <tp-button
        buttonId="ok" [buttonStyle]="isClientSecretCopied ? 'primary-outline green stroke' : 'primary-outline white stroke'"
        [enabled]="isClientSecretCopied" (click)="close()" [matTooltip]="isClientSecretCopied ? '' : 'Please copy your client secret first'"
      >
        Confirm and close
      </tp-button>
    </div>
  } @else if (isReady$ | async) {
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
      <section class="form">
          <tp-form-input type="text" [form]="form" field="name" label="API Token Name"/>
          <tp-form-select label="Space" [form]="form" field="space" [options]="(spaceOptions$ | async) || []"/>
          <tp-form-select label="Access Level" [form]="form" field="accessLevel" [options]="accessLevelOptions"/>
      </section>

      @if (form.getError("errorText")) {
        <mat-error>{{ form.getError("errorText") }}</mat-error>
      }

      <tp-form-actions
        [cancellable]="true" submitText="Generate token" [isSubmitting]="isSubmitting$ | async"
        buttonIdPrefix="createTeamModal" (cancel)="close()"
      />
    </tp-form>
  }
</tp-modal>
