/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { filter, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import {
    FormActionsComponent,
    FormComponent,
    FormInputComponent,
    ModalComponent,
    patternValidator,
    requiredValidator
} from "typedb-platform-framework";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { TeamApi } from "../../../service/team/team-api.service";
import { RETURN_TO } from '../../../framework/url-params';
import { cleanInputForID, ensureIdUnique } from "../../../util";

@Component({
    selector: "tp-team-creation-dialog",
    templateUrl: "./team-creation-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent],
})
export class TeamCreationDialogComponent implements OnInit {
    @Input() redirectAfterClose: boolean = true;

    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<TeamCreationDialogComponent>,
        private formBuilder: FormBuilder, private teamApi: TeamApi,
    ) { }

    ngOnInit() {
        this.form.controls.name.valueChanges.pipe(
            filter(() => !this.form.controls.id.dirty),
            map((teamName) => cleanInputForID(teamName)),
            tap((id) => this.form.patchValue({ id })),
            switchMap((id) => ensureIdUnique(id, (id) => this.checkId(id)))
        ).subscribe((id) => {
            if (!this.form.controls.id.dirty) this.form.patchValue({ id });
        });
    }

    checkId(id: string) {
        return this.teamApi.checkTeamId(id).pipe(map(({ exists }) => exists))
    }

    submit() {
        const id = this.form.value.id!;
        const name = this.form.value.name!;
        this.teamApi.createTeam({ id, name, isPersonal: false }).subscribe({
            next: () => {
                this.close();
                if (this.redirectAfterClose) {
                    window.location.href = `${window.location.origin}/team/${id}/clusters/deploy`;
                }
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
