@if (tagline$ | async; as tagline) {
  <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
} @else {
  <div style="height: 22px;"></div>
}

<div class="setup-container">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (submit)="onSubmit()">
    <tp-form-input class="capitalized-field" [form]="form" type="text" label="First Name" field="firstName" autocomplete="given-name"/>
    <tp-form-input class="capitalized-field" [form]="form" type="text" label="Last Name" field="lastName" autocomplete="family-name"/>
    <tp-form-input [form]="form" type="text" label="Username" field="id" autocomplete="username"/>
    <div class="tp-finish">
      <tp-form-actions
        [isSubmitting]="isSubmitting$ | async" submitText="Continue" [submitDisabled]="!form.dirty"
        buttonIdPrefix="createProfile" submitButtonStyle="primary-outline green stroke"
      />
    </div>
  </tp-form>
</div>
