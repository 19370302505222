/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { first, map, Observable, Subject } from "rxjs";
import {
    ButtonComponent,
    FormActionsComponent,
    FormComponent,
    FormInputComponent,
    ModalComponent,
    PropertiesTableComponent,
    PropertiesTableRowComponent,
    patternValidator,
    requiredValidator, FormSelectComponent, FormOption
} from "typedb-platform-framework";
import { namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { cryptoRandomAlphanumeric, randomId } from "../../../../util";
import { SnackbarService } from "../../../../service/snackbar.service";
import { ApplicationState } from "../../../../service/application-state.service";
import { TeamApi } from "../../../../service/team/team-api.service";
import { AccessLevel, accessLevelToString, accessLevelValues } from "../../../../concept/iam";
import { Space } from "../../../../concept/space";
import { TeamController } from "../../../../service/team/team-controller.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-api-token-creation-dialog",
    templateUrl: "./api-token-creation-dialog.component.html",
    styleUrls: ["./api-token-creation-dialog.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent,
        PropertiesTableComponent, PropertiesTableRowComponent, ButtonComponent, FormSelectComponent, MatFormFieldModule, MatButtonModule,
        MatTooltipModule
    ],
})
export class APITokenCreationDialogComponent {

    private team = this.app.requireCurrentTeam()
    readonly accessLevelOptions: FormOption<AccessLevel>[] = accessLevelValues.map(x => ({ value: x, viewValue: accessLevelToString(x) }));
    readonly spaces$ = this.teamCtl.listWritableSpacesSnapshot();
    readonly spaceOptions$: Observable<FormOption<Space>[]> = this.spaces$.pipe(map(spaces => spaces.map(space => ({
        value: space, viewValue: space.id,
    }))));
    readonly isReady$ = this.spaces$.pipe(map(() => true));
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.group({
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
        space: [null as Space | null, [requiredValidator]],
        accessLevel: ["read" as AccessLevel, [requiredValidator]],
    });
    generatedToken: { clientID: string, clientSecret: string, name: string } | undefined;
    isClientSecretCopied = false;

    constructor(
        private dialogRef: MatDialogRef<APITokenCreationDialogComponent>,
        private formBuilder: FormBuilder, private teamApi: TeamApi,
        private snackbar: SnackbarService, private app: ApplicationState,
        private teamCtl: TeamController,
    ) { }

    submit() {
        const name = this.form.value.name!;
        const accessLevel = this.form.value.accessLevel!;
        const spaceUuid = this.form.value.space!.uuid;
        const clientID = randomId(8);
        const clientSecret = cryptoRandomAlphanumeric(20);

        fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${this.app.apiFirebaseInfo!.apiKey}`,{
            method: "POST",
            body: JSON.stringify({
                email: `${clientID}@serviceaccounts.cloud.typedb.com`,
                password: clientSecret,
                tenantId: this.app.apiFirebaseInfo!.tenantId,
                returnSecureToken: true,
            }),
            headers: { "Content-Type": "application/json" },
        }).then(() => {
            this.teamApi.registerAPIToken({
                name, id: clientID,
                teamUuid: this.team.uuid,
                spaceUuid,
                accessLevel,
            }).subscribe(() => {
                this.isSubmitting$.next(false);
                this.generatedToken = { clientID, clientSecret, name };
            });
        })
    }

    copyClientSecret() {
        this.isClientSecretCopied = true;
        navigator.clipboard.writeText(this.generatedToken!.clientSecret);
        this.snackbar.success("Copied", { duration: 1250 });
    }

    close() {
        this.dialogRef.close();
    }
}
