/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { SANITY_CDN_QUERY_URL, SANITY_TOKEN, platformUiBannerQuery, SiteBanner, SANITY_QUERY_URL } from "typedb-web-common/lib";
import { CloudLoginPortal, cloudLoginPortalSchemaName, CloudOnboarding, cloudOnboardingSchemaName, ParagraphWithHighlights, PortableText, ProviderRegionData, providerRegionsQuery, SanitySurvey, Survey } from "typedb-web-schema/lib";
import { environment } from "../environments/environment";

type SanityLoginPortalData = {
    title: PortableText,
    subtitle: PortableText,
    keyPoints: string[],
};

type LoginPortalData = {
    title: ParagraphWithHighlights,
    subtitle: ParagraphWithHighlights,
    keyPoints: string[],
};

const loginPortalQuery = `*[(_id match '${cloudLoginPortalSchemaName}')][0]`;
const onboardingQuery = `*[(_id match '${cloudOnboardingSchemaName}')][0]`;
const projectInfoSurveyQuery = `*[_id == '3f83b3fb-c14c-4ae3-80d0-880eb0ffc14c'][0]`;

@Injectable({
    providedIn: "root",
})
export class SanityService {
    readonly loginPortal: Observable<LoginPortalData>;
    readonly onboarding: Observable<CloudOnboarding>;
    readonly projectInfoSurvey: Observable<Survey>;
    readonly providerRegionData: Observable<ProviderRegionData>;
    readonly appBanner: Observable<SiteBanner>;

    constructor(private http: HttpClient) {
        this.loginPortal = this.getSanityResult<SanityLoginPortalData>(loginPortalQuery).pipe(
            map(data => ({
                title: ParagraphWithHighlights.fromSanity(data.title),
                subtitle: ParagraphWithHighlights.fromSanity(data.subtitle),
                keyPoints: data.keyPoints,
            })),
            shareReplay(1),
        )
        this.appBanner = this.getSanityResult<{ platformUiBanner: SiteBanner }>(platformUiBannerQuery).pipe(
            map(x => x.platformUiBanner),
            shareReplay(1),
        );
        this.projectInfoSurvey = this.getSanityResult<SanitySurvey>(projectInfoSurveyQuery).pipe(
            map(data => Survey.fromSanity(data)),
            shareReplay(1),
        );
        this.providerRegionData = this.getSanityResult<ProviderRegionData>(providerRegionsQuery).pipe(shareReplay(1));
        this.onboarding = this.getSanityResult<CloudOnboarding>(onboardingQuery).pipe(shareReplay(1));

        [
            this.loginPortal,
            this.appBanner,
            this.projectInfoSurvey,
            this.providerRegionData,
            this.onboarding
        ].forEach((x: Observable<any>) => x.subscribe());
    }

    private getSanityResult<T>(query: string) {
        return this.http.get<{ result: T }>(
            environment.env === "production" ? SANITY_CDN_QUERY_URL : SANITY_QUERY_URL,
            environment.env === "production"
                ? { params: { query, perspective: "published" } }
                : { params: { query, perspective: "previewDrafts" }, headers: { Authorization: `Bearer ${SANITY_TOKEN}` } },
        ).pipe(map(({ result }) => result));
    }
}
