/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { first, map } from "rxjs";
import { Team } from "../../../concept/team";
import { ApplicationState } from "../../../service/application-state.service";
import { AsyncPipe, NgClass } from "@angular/common";
import { TeamCreationDialogComponent } from "../../team/create/team-creation-dialog.component";
import { SidebarLinkComponent } from "./link/sidebar-link.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect, MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { ModalComponent } from "typedb-platform-framework";
import {
    clustersPath,
    getStartedPath,
    membersPath,
    teamSettingsPath,
    spacesPath,
    squadsPath
} from "../../../routing/resource-paths";
import { UserAvatarComponent } from "../../user/avatar/user-avatar.component";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { MatMenuModule } from "@angular/material/menu";
import { userFullName } from "../../../concept/user";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    standalone: true,
    imports: [
        SidebarLinkComponent, ModalComponent, AsyncPipe, MatSelectModule, MatDividerModule, UserAvatarComponent,
        MatMenuModule, RouterLink, NgClass, MatTooltipModule
    ],
})
export class SidebarComponent {
    @ViewChild(MatSelect) teamSelector!: MatSelect;
    private _team = this.app.requireCurrentTeam();
    readonly userId$ = this.app.currentUser$.pipe(map((user) => user?.id));
    readonly userEmail$ = this.identityAuth.currentUserEmail$;
    readonly userFullName$ = this.app.currentUser$.pipe(map((user) => user ? userFullName(user) : ""));
    collapsed: boolean = this.app.getSidebarCollapsed();
    @Output() collapsedChange = new EventEmitter<boolean>();

    constructor(
        public app: ApplicationState, private route: ActivatedRoute,
        private dialog: MatDialog, private identityAuth: IdentityAuthService
    ) {}

    get team() {
        return this.teams?.find(x => x.uuid === this._team.uuid) || this._team;
    }

    get teams(): Team[] | null {
        return this.app.currentUserTeams$.value;
    }

    onTeamChange(change: MatSelectChange) {
        const team = change.value as Team;
        if (team.uuid !== this.team.uuid) {
            this.navigateToNewTeam(team.id);
        }
    }

    navigateToNewTeam(teamId: string) {
        this.route.data.pipe(first()).subscribe((data) => {
            const domain = data["domain"] as string | undefined;
            if (!domain || domain === "settings") window.location.href = `${window.location.origin}/team/${teamId}`;
            else window.location.href = `${window.location.origin}/team/${teamId}/${data["domain"]}`;
        });
    }

    onCreateTeamClick() {
        this.teamSelector.close();
        this.dialog.open(TeamCreationDialogComponent);
    }

    signOut() {
        this.app.signOut({ deauthUser: true, redirect: true, announce: true }).subscribe();
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.app.setSidebarCollapsed(this.collapsed);
        this.collapsedChange.emit(this.collapsed);
    }

    protected readonly clustersPath = clustersPath;
    protected readonly spacesPath = spacesPath;
    protected readonly squadsPath = squadsPath;
    protected readonly membersPath = membersPath;
    protected readonly teamSettingsPath = teamSettingsPath;
    protected readonly getStartedPath = getStartedPath;
}
