/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatSortModule } from "@angular/material/sort";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { Invitation, InvitationColumn } from "../../../concept/team";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { MatButtonModule } from "@angular/material/button";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";

@Component({
    selector: "tp-invitations-table",
    templateUrl: "./invitations-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, SpinnerComponent, MatMenuModule,
        MatButtonModule, AccessLevelPipe,
    ],
})
export class InvitationsTableComponent {
    @Input({ required: true}) table!: ResourceTable<Invitation, InvitationColumn>;
    @Input() rowPopupMenuItems?: ((row: Invitation) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService) {}
}
