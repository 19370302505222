/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
    FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { DialogResult } from "../../../service/dialog.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ApplicationState } from "../../../service/application-state.service";
import { Router } from "@angular/router";
import { SnackbarService } from "../../../service/snackbar.service";

@Component({
    selector: "tp-team-update-dialog",
    templateUrl: "./team-update-dialog.component.html",
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule,
    ],
})
export class TeamUpdateDialogComponent {
    readonly team = this.app.requireCurrentTeam();
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: [this.team.id, [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: [this.team.name, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<TeamUpdateDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        private teamApi: TeamApi, private app: ApplicationState, private router: Router,
        private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const form = this.form.value;
        const [id, name] = [form.id, form.name];
        this.teamApi.updateTeam({
            uuid: this.team.uuid,
            id: id !== this.team.id ? id : undefined,
            name: name !== this.team.name ? name : undefined
        }).subscribe({
            next: () => {
                this.close("ok");
                // Team is loaded in the auth guard, so only a page refresh can reload it
                if (id !== this.team.id) {
                    window.location.replace(`${window.location.origin}/team/${id}/settings`);
                } else {
                    window.location.reload();
                }
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
