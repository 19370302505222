/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    ClusterCtlProtoDeployClusterReq,
    ClusterCtlProtoDestroyClusterReq,
    ClusterCtlProtoGetAccessLevelsReq,
    ClusterCtlProtoGetClusterOptionsReq,
    ClusterCtlProtoGetClusterPresetPriceReq,
    ClusterCtlProtoGetClusterPriceReq,
    ClusterCtlProtoGetClusterReq,
    ClusterCtlProtoGetLatestCompatibleTypeDBReq,
    ClusterCtlProtoListServerLogsReq,
    ClusterCtlProtoListServersReq,
    ClusterCtlProtoReq,
    ClusterCtlProtoResumeClusterReq,
    ClusterCtlProtoSetupClusterReq,
    ClusterCtlProtoSuspendClusterReq,
    ClusterCtlProtoUpdateClusterReq,
    ClusterCtlProtoUpdateToLatestCompatibleTypeDBReq,
} from "../../../application/protocol/cluster-controller";
import { ApiResponse, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import {
    Cluster,
    ClusterPrice, clusterPriceOf,
    clusterProtoOf,
    partialClusterOf,
    Server,
    ServerLogBatch,
    serverLogBatchOf,
    serverOf
} from "../../concept/cluster";
import { ClusterOptions, clusterOptionsOf } from "../../concept/cluster-options";
import { AccessLevel, accessLevelOf } from "../../concept/iam";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { map, Observable, Subject, takeWhile } from "rxjs";
import { Injectable } from "@angular/core";
import { UnsubListener } from "../backend/platform-api-backend.service";

@Injectable({
    providedIn: "root",
})
export class ClusterApi {
    constructor(private api: ApiService) {}

    getAccessLevels(clusterUuids: string[]): Observable<AccessLevel[]> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq({ getAccessLevels: new ClusterCtlProtoGetAccessLevelsReq({ clusterUuids: clusterUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    getClusterOptions(): Observable<ClusterOptions> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq({ getClusterOptions: new ClusterCtlProtoGetClusterOptionsReq() }))
            .pipe(map((res) => clusterOptionsOf(res.getClusterOptions)));
    }

    getLatestCompatibleTypeDBVersion(compatibleWith: string): Observable<string> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq({
            getLatestCompatibleTypedb: new ClusterCtlProtoGetLatestCompatibleTypeDBReq({
                typedbVersion: compatibleWith
            })
        })).pipe(map((res) => res.getLatestCompatibleTypedb.typedbVersion));
    }

    deployCluster(cluster: Partial<Cluster>): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq({
            deployCluster: new ClusterCtlProtoDeployClusterReq({ cluster: clusterProtoOf(cluster) })
        }));
    }

    getCluster(props: { clusterId: string, spaceUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Cluster>> {
        return this.api.clusterReqSub(new ClusterCtlProtoReq(
            { getCluster: new ClusterCtlProtoGetClusterReq({ clusterId: props.clusterId, spaceUuid: stringToBytes(props.spaceUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialClusterOf(res.data.getCluster.cluster)))
        );
    }

    updateCluster(cluster: PartialWithUuid<Cluster>): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq(
            { updateCluster: new ClusterCtlProtoUpdateClusterReq({ cluster: clusterProtoOf(cluster) }) }
        ));
    }

    updateToLatestCompatibleTypeDB(clusterUuid: string): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq(
            { updateToLatestCompatibleTypedb: new ClusterCtlProtoUpdateToLatestCompatibleTypeDBReq({ clusterUuid: stringToBytes(clusterUuid) }) }
        ));
    }

    suspendCluster(clusterUuid: string): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq(
            { suspendCluster: new ClusterCtlProtoSuspendClusterReq({ clusterUuid: stringToBytes(clusterUuid) }) }
        ));
    }

    resumeCluster(clusterUuid: string): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq(
            { resumeCluster: new ClusterCtlProtoResumeClusterReq({ clusterUuid: stringToBytes(clusterUuid) }) }
        ));
    }

    destroyCluster(clusterUuid: string): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq(
            { destroyCluster: new ClusterCtlProtoDestroyClusterReq({ clusterUuid: stringToBytes(clusterUuid) }) }
        ));
    }

    getClusterPriceCentsPerHour(props: { cluster: Partial<Cluster>; teamUuid: string }): Observable<ClusterPrice> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq(
            { getClusterPrice: new ClusterCtlProtoGetClusterPriceReq({ teamUuid: stringToBytes(props.teamUuid), cluster: clusterProtoOf(props.cluster) }) }
        )).pipe(map(res => clusterPriceOf(res.getClusterPrice.price)));
    }

    getClusterPresetPriceCentsPerHour(props: { presetId: string; regionId: string; teamUuid: string; singleNode: boolean }): Observable<ClusterPrice> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq({
            getClusterPresetPrice: new ClusterCtlProtoGetClusterPresetPriceReq({
                teamUuid: stringToBytes(props.teamUuid), regionId: props.regionId, presetId: props.presetId, getForSingleNode: props.singleNode
            })
        })).pipe(map(res => clusterPriceOf(res.getClusterPresetPrice.price)));
    }

    listServers(clusterUuid: string): Observable<Server[]> {
        return this.api.clusterReqRes(new ClusterCtlProtoReq(
            { listServers: new ClusterCtlProtoListServersReq({ clusterUuid: stringToBytes(clusterUuid) }) }
        )).pipe(map(res => res.listServers.servers.map(serverOf)));
    }

    listServerLogs(
        props: { clusterUuid: string, serverUuid: string, serverId: number, initialLineCount: number },
        unsub$: UnsubListener
    ): Observable<ServerLogBatch> {
        return this.api.clusterReqSub(new ClusterCtlProtoReq({
            listServerLogs: new ClusterCtlProtoListServerLogsReq({
                clusterUuid: stringToBytes(props.clusterUuid),
                serverUuid: stringToBytes(props.serverUuid),
                serverIndex: props.serverId,
                initialLineCount: props.initialLineCount
            })
        }), unsub$).pipe(map(res => serverLogBatchOf(res.data.listServerLogs.logBatch)));
    }

    listServerLogsSnapshot(
        props: { clusterUuid: string, serverUuid: string, serverId: number, maxLines: number }
    ): Observable<ServerLogBatch> {
        return this.api.clusterReqSub(new ClusterCtlProtoReq({
            listServerLogs: new ClusterCtlProtoListServerLogsReq({
                clusterUuid: stringToBytes(props.clusterUuid),
                serverUuid: stringToBytes(props.serverUuid),
                serverIndex: props.serverId,
                initialLineCount: props.maxLines,
                snapshot: true,
            })
        }), new Subject<void>()).pipe(
            map(res => serverLogBatchOf(res.data.listServerLogs.logBatch)),
            takeWhile(res => !res.isEndOfFile, true),
        );
    }

    setupCluster(props: { clusterUuid: string, password: string }): Observable<Ok> {
        return this.api.clusterReq(new ClusterCtlProtoReq({
            setupCluster: new ClusterCtlProtoSetupClusterReq({
                clusterUuid: stringToBytes(props.clusterUuid),
                password: props.password
            })
        }));
    }
}
