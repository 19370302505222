/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/space-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as dependency_2 from "./../../protocol/common";
import * as pb_1 from "google-protobuf";
export class SpaceCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProto {
        const message = new SpaceCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProto {
        return SpaceCtlProto.deserialize(bytes);
    }
}
export class SpaceCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 10, 11, 12, 13, 14, 15]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SpaceCtlProtoGetAccessLevelsReq;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: SpaceCtlProtoCheckSpaceIDReq;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: SpaceCtlProtoCreateSpaceReq;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: SpaceCtlProtoGetSpaceReq;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: SpaceCtlProtoUpdateSpaceReq;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: SpaceCtlProtoDeleteSpaceReq;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: SpaceCtlProtoListClustersReq;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: SpaceCtlProtoListSquadsReq;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: SpaceCtlProtoListUsersReq;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: SpaceCtlProtoListAPITokensReq;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: SpaceCtlProtoAssignUserReq;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: SpaceCtlProtoUnassignUserReq;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: SpaceCtlProtoSetUserAccessLevelReq;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: SpaceCtlProtoAssignSquadReq;
        unassignSquad?: never;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: SpaceCtlProtoUnassignSquadReq;
        setSquadAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        createSpace?: never;
        getSpace?: never;
        updateSpace?: never;
        deleteSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
        assignUser?: never;
        unassignUser?: never;
        setUserAccessLevel?: never;
        assignSquad?: never;
        unassignSquad?: never;
        setSquadAccessLevel?: SpaceCtlProtoSetSquadAccessLevelReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkSpaceId" in data && data.checkSpaceId != undefined) {
                this.checkSpaceId = data.checkSpaceId;
            }
            if ("createSpace" in data && data.createSpace != undefined) {
                this.createSpace = data.createSpace;
            }
            if ("getSpace" in data && data.getSpace != undefined) {
                this.getSpace = data.getSpace;
            }
            if ("updateSpace" in data && data.updateSpace != undefined) {
                this.updateSpace = data.updateSpace;
            }
            if ("deleteSpace" in data && data.deleteSpace != undefined) {
                this.deleteSpace = data.deleteSpace;
            }
            if ("listClusters" in data && data.listClusters != undefined) {
                this.listClusters = data.listClusters;
            }
            if ("listSquads" in data && data.listSquads != undefined) {
                this.listSquads = data.listSquads;
            }
            if ("listUsers" in data && data.listUsers != undefined) {
                this.listUsers = data.listUsers;
            }
            if ("listApiTokens" in data && data.listApiTokens != undefined) {
                this.listApiTokens = data.listApiTokens;
            }
            if ("assignUser" in data && data.assignUser != undefined) {
                this.assignUser = data.assignUser;
            }
            if ("unassignUser" in data && data.unassignUser != undefined) {
                this.unassignUser = data.unassignUser;
            }
            if ("setUserAccessLevel" in data && data.setUserAccessLevel != undefined) {
                this.setUserAccessLevel = data.setUserAccessLevel;
            }
            if ("assignSquad" in data && data.assignSquad != undefined) {
                this.assignSquad = data.assignSquad;
            }
            if ("unassignSquad" in data && data.unassignSquad != undefined) {
                this.unassignSquad = data.unassignSquad;
            }
            if ("setSquadAccessLevel" in data && data.setSquadAccessLevel != undefined) {
                this.setSquadAccessLevel = data.setSquadAccessLevel;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoGetAccessLevelsReq, 1) as SpaceCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: SpaceCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkSpaceId() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoCheckSpaceIDReq, 2) as SpaceCtlProtoCheckSpaceIDReq;
    }
    set checkSpaceId(value: SpaceCtlProtoCheckSpaceIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckSpaceId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createSpace() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoCreateSpaceReq, 3) as SpaceCtlProtoCreateSpaceReq;
    }
    set createSpace(value: SpaceCtlProtoCreateSpaceReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateSpace() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getSpace() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoGetSpaceReq, 4) as SpaceCtlProtoGetSpaceReq;
    }
    set getSpace(value: SpaceCtlProtoGetSpaceReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetSpace() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateSpace() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoUpdateSpaceReq, 5) as SpaceCtlProtoUpdateSpaceReq;
    }
    set updateSpace(value: SpaceCtlProtoUpdateSpaceReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateSpace() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get deleteSpace() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoDeleteSpaceReq, 6) as SpaceCtlProtoDeleteSpaceReq;
    }
    set deleteSpace(value: SpaceCtlProtoDeleteSpaceReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasDeleteSpace() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listClusters() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListClustersReq, 7) as SpaceCtlProtoListClustersReq;
    }
    set listClusters(value: SpaceCtlProtoListClustersReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListClusters() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSquads() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListSquadsReq, 8) as SpaceCtlProtoListSquadsReq;
    }
    set listSquads(value: SpaceCtlProtoListSquadsReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSquads() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listUsers() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListUsersReq, 9) as SpaceCtlProtoListUsersReq;
    }
    set listUsers(value: SpaceCtlProtoListUsersReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListUsers() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listApiTokens() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListAPITokensReq, 16) as SpaceCtlProtoListAPITokensReq;
    }
    set listApiTokens(value: SpaceCtlProtoListAPITokensReq) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
    }
    get hasListApiTokens() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get assignUser() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoAssignUserReq, 10) as SpaceCtlProtoAssignUserReq;
    }
    set assignUser(value: SpaceCtlProtoAssignUserReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasAssignUser() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get unassignUser() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoUnassignUserReq, 11) as SpaceCtlProtoUnassignUserReq;
    }
    set unassignUser(value: SpaceCtlProtoUnassignUserReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasUnassignUser() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get setUserAccessLevel() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoSetUserAccessLevelReq, 12) as SpaceCtlProtoSetUserAccessLevelReq;
    }
    set setUserAccessLevel(value: SpaceCtlProtoSetUserAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasSetUserAccessLevel() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get assignSquad() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoAssignSquadReq, 13) as SpaceCtlProtoAssignSquadReq;
    }
    set assignSquad(value: SpaceCtlProtoAssignSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasAssignSquad() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get unassignSquad() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoUnassignSquadReq, 14) as SpaceCtlProtoUnassignSquadReq;
    }
    set unassignSquad(value: SpaceCtlProtoUnassignSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasUnassignSquad() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get setSquadAccessLevel() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoSetSquadAccessLevelReq, 15) as SpaceCtlProtoSetSquadAccessLevelReq;
    }
    set setSquadAccessLevel(value: SpaceCtlProtoSetSquadAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasSetSquadAccessLevel() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkSpaceId" | "createSpace" | "getSpace" | "updateSpace" | "deleteSpace" | "listClusters" | "listSquads" | "listUsers" | "listApiTokens" | "assignUser" | "unassignUser" | "setUserAccessLevel" | "assignSquad" | "unassignSquad" | "setSquadAccessLevel";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkSpaceId",
            3: "createSpace",
            4: "getSpace",
            5: "updateSpace",
            6: "deleteSpace",
            7: "listClusters",
            8: "listSquads",
            9: "listUsers",
            16: "listApiTokens",
            10: "assignUser",
            11: "unassignUser",
            12: "setUserAccessLevel",
            13: "assignSquad",
            14: "unassignSquad",
            15: "setSquadAccessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 10, 11, 12, 13, 14, 15])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SpaceCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkSpaceId?: ReturnType<typeof SpaceCtlProtoCheckSpaceIDReq.prototype.toObject>;
        createSpace?: ReturnType<typeof SpaceCtlProtoCreateSpaceReq.prototype.toObject>;
        getSpace?: ReturnType<typeof SpaceCtlProtoGetSpaceReq.prototype.toObject>;
        updateSpace?: ReturnType<typeof SpaceCtlProtoUpdateSpaceReq.prototype.toObject>;
        deleteSpace?: ReturnType<typeof SpaceCtlProtoDeleteSpaceReq.prototype.toObject>;
        listClusters?: ReturnType<typeof SpaceCtlProtoListClustersReq.prototype.toObject>;
        listSquads?: ReturnType<typeof SpaceCtlProtoListSquadsReq.prototype.toObject>;
        listUsers?: ReturnType<typeof SpaceCtlProtoListUsersReq.prototype.toObject>;
        listApiTokens?: ReturnType<typeof SpaceCtlProtoListAPITokensReq.prototype.toObject>;
        assignUser?: ReturnType<typeof SpaceCtlProtoAssignUserReq.prototype.toObject>;
        unassignUser?: ReturnType<typeof SpaceCtlProtoUnassignUserReq.prototype.toObject>;
        setUserAccessLevel?: ReturnType<typeof SpaceCtlProtoSetUserAccessLevelReq.prototype.toObject>;
        assignSquad?: ReturnType<typeof SpaceCtlProtoAssignSquadReq.prototype.toObject>;
        unassignSquad?: ReturnType<typeof SpaceCtlProtoUnassignSquadReq.prototype.toObject>;
        setSquadAccessLevel?: ReturnType<typeof SpaceCtlProtoSetSquadAccessLevelReq.prototype.toObject>;
    }): SpaceCtlProtoReq {
        const message = new SpaceCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SpaceCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkSpaceId != null) {
            message.checkSpaceId = SpaceCtlProtoCheckSpaceIDReq.fromObject(data.checkSpaceId);
        }
        if (data.createSpace != null) {
            message.createSpace = SpaceCtlProtoCreateSpaceReq.fromObject(data.createSpace);
        }
        if (data.getSpace != null) {
            message.getSpace = SpaceCtlProtoGetSpaceReq.fromObject(data.getSpace);
        }
        if (data.updateSpace != null) {
            message.updateSpace = SpaceCtlProtoUpdateSpaceReq.fromObject(data.updateSpace);
        }
        if (data.deleteSpace != null) {
            message.deleteSpace = SpaceCtlProtoDeleteSpaceReq.fromObject(data.deleteSpace);
        }
        if (data.listClusters != null) {
            message.listClusters = SpaceCtlProtoListClustersReq.fromObject(data.listClusters);
        }
        if (data.listSquads != null) {
            message.listSquads = SpaceCtlProtoListSquadsReq.fromObject(data.listSquads);
        }
        if (data.listUsers != null) {
            message.listUsers = SpaceCtlProtoListUsersReq.fromObject(data.listUsers);
        }
        if (data.listApiTokens != null) {
            message.listApiTokens = SpaceCtlProtoListAPITokensReq.fromObject(data.listApiTokens);
        }
        if (data.assignUser != null) {
            message.assignUser = SpaceCtlProtoAssignUserReq.fromObject(data.assignUser);
        }
        if (data.unassignUser != null) {
            message.unassignUser = SpaceCtlProtoUnassignUserReq.fromObject(data.unassignUser);
        }
        if (data.setUserAccessLevel != null) {
            message.setUserAccessLevel = SpaceCtlProtoSetUserAccessLevelReq.fromObject(data.setUserAccessLevel);
        }
        if (data.assignSquad != null) {
            message.assignSquad = SpaceCtlProtoAssignSquadReq.fromObject(data.assignSquad);
        }
        if (data.unassignSquad != null) {
            message.unassignSquad = SpaceCtlProtoUnassignSquadReq.fromObject(data.unassignSquad);
        }
        if (data.setSquadAccessLevel != null) {
            message.setSquadAccessLevel = SpaceCtlProtoSetSquadAccessLevelReq.fromObject(data.setSquadAccessLevel);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SpaceCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkSpaceId?: ReturnType<typeof SpaceCtlProtoCheckSpaceIDReq.prototype.toObject>;
            createSpace?: ReturnType<typeof SpaceCtlProtoCreateSpaceReq.prototype.toObject>;
            getSpace?: ReturnType<typeof SpaceCtlProtoGetSpaceReq.prototype.toObject>;
            updateSpace?: ReturnType<typeof SpaceCtlProtoUpdateSpaceReq.prototype.toObject>;
            deleteSpace?: ReturnType<typeof SpaceCtlProtoDeleteSpaceReq.prototype.toObject>;
            listClusters?: ReturnType<typeof SpaceCtlProtoListClustersReq.prototype.toObject>;
            listSquads?: ReturnType<typeof SpaceCtlProtoListSquadsReq.prototype.toObject>;
            listUsers?: ReturnType<typeof SpaceCtlProtoListUsersReq.prototype.toObject>;
            listApiTokens?: ReturnType<typeof SpaceCtlProtoListAPITokensReq.prototype.toObject>;
            assignUser?: ReturnType<typeof SpaceCtlProtoAssignUserReq.prototype.toObject>;
            unassignUser?: ReturnType<typeof SpaceCtlProtoUnassignUserReq.prototype.toObject>;
            setUserAccessLevel?: ReturnType<typeof SpaceCtlProtoSetUserAccessLevelReq.prototype.toObject>;
            assignSquad?: ReturnType<typeof SpaceCtlProtoAssignSquadReq.prototype.toObject>;
            unassignSquad?: ReturnType<typeof SpaceCtlProtoUnassignSquadReq.prototype.toObject>;
            setSquadAccessLevel?: ReturnType<typeof SpaceCtlProtoSetSquadAccessLevelReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkSpaceId != null) {
            data.checkSpaceId = this.checkSpaceId.toObject();
        }
        if (this.createSpace != null) {
            data.createSpace = this.createSpace.toObject();
        }
        if (this.getSpace != null) {
            data.getSpace = this.getSpace.toObject();
        }
        if (this.updateSpace != null) {
            data.updateSpace = this.updateSpace.toObject();
        }
        if (this.deleteSpace != null) {
            data.deleteSpace = this.deleteSpace.toObject();
        }
        if (this.listClusters != null) {
            data.listClusters = this.listClusters.toObject();
        }
        if (this.listSquads != null) {
            data.listSquads = this.listSquads.toObject();
        }
        if (this.listUsers != null) {
            data.listUsers = this.listUsers.toObject();
        }
        if (this.listApiTokens != null) {
            data.listApiTokens = this.listApiTokens.toObject();
        }
        if (this.assignUser != null) {
            data.assignUser = this.assignUser.toObject();
        }
        if (this.unassignUser != null) {
            data.unassignUser = this.unassignUser.toObject();
        }
        if (this.setUserAccessLevel != null) {
            data.setUserAccessLevel = this.setUserAccessLevel.toObject();
        }
        if (this.assignSquad != null) {
            data.assignSquad = this.assignSquad.toObject();
        }
        if (this.unassignSquad != null) {
            data.unassignSquad = this.unassignSquad.toObject();
        }
        if (this.setSquadAccessLevel != null) {
            data.setSquadAccessLevel = this.setSquadAccessLevel.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckSpaceId)
            writer.writeMessage(2, this.checkSpaceId, () => this.checkSpaceId.serialize(writer));
        if (this.hasCreateSpace)
            writer.writeMessage(3, this.createSpace, () => this.createSpace.serialize(writer));
        if (this.hasGetSpace)
            writer.writeMessage(4, this.getSpace, () => this.getSpace.serialize(writer));
        if (this.hasUpdateSpace)
            writer.writeMessage(5, this.updateSpace, () => this.updateSpace.serialize(writer));
        if (this.hasDeleteSpace)
            writer.writeMessage(6, this.deleteSpace, () => this.deleteSpace.serialize(writer));
        if (this.hasListClusters)
            writer.writeMessage(7, this.listClusters, () => this.listClusters.serialize(writer));
        if (this.hasListSquads)
            writer.writeMessage(8, this.listSquads, () => this.listSquads.serialize(writer));
        if (this.hasListUsers)
            writer.writeMessage(9, this.listUsers, () => this.listUsers.serialize(writer));
        if (this.hasListApiTokens)
            writer.writeMessage(16, this.listApiTokens, () => this.listApiTokens.serialize(writer));
        if (this.hasAssignUser)
            writer.writeMessage(10, this.assignUser, () => this.assignUser.serialize(writer));
        if (this.hasUnassignUser)
            writer.writeMessage(11, this.unassignUser, () => this.unassignUser.serialize(writer));
        if (this.hasSetUserAccessLevel)
            writer.writeMessage(12, this.setUserAccessLevel, () => this.setUserAccessLevel.serialize(writer));
        if (this.hasAssignSquad)
            writer.writeMessage(13, this.assignSquad, () => this.assignSquad.serialize(writer));
        if (this.hasUnassignSquad)
            writer.writeMessage(14, this.unassignSquad, () => this.unassignSquad.serialize(writer));
        if (this.hasSetSquadAccessLevel)
            writer.writeMessage(15, this.setSquadAccessLevel, () => this.setSquadAccessLevel.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SpaceCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkSpaceId, () => message.checkSpaceId = SpaceCtlProtoCheckSpaceIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createSpace, () => message.createSpace = SpaceCtlProtoCreateSpaceReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getSpace, () => message.getSpace = SpaceCtlProtoGetSpaceReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateSpace, () => message.updateSpace = SpaceCtlProtoUpdateSpaceReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.deleteSpace, () => message.deleteSpace = SpaceCtlProtoDeleteSpaceReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listClusters, () => message.listClusters = SpaceCtlProtoListClustersReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSquads, () => message.listSquads = SpaceCtlProtoListSquadsReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listUsers, () => message.listUsers = SpaceCtlProtoListUsersReq.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.listApiTokens, () => message.listApiTokens = SpaceCtlProtoListAPITokensReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.assignUser, () => message.assignUser = SpaceCtlProtoAssignUserReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.unassignUser, () => message.unassignUser = SpaceCtlProtoUnassignUserReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.setUserAccessLevel, () => message.setUserAccessLevel = SpaceCtlProtoSetUserAccessLevelReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.assignSquad, () => message.assignSquad = SpaceCtlProtoAssignSquadReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.unassignSquad, () => message.unassignSquad = SpaceCtlProtoUnassignSquadReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.setSquadAccessLevel, () => message.setSquadAccessLevel = SpaceCtlProtoSetSquadAccessLevelReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoReq {
        return SpaceCtlProtoReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8, 9, 10]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SpaceCtlProtoGetAccessLevelsRes;
        checkSpaceId?: never;
        getSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: SpaceCtlProtoCheckSpaceIDRes;
        getSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        getSpace?: SpaceCtlProtoGetSpaceRes;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        getSpace?: never;
        listClusters?: SpaceCtlProtoListClustersRes;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        getSpace?: never;
        listClusters?: never;
        listSquads?: SpaceCtlProtoListSquadsRes;
        listUsers?: never;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        getSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: SpaceCtlProtoListUsersRes;
        listApiTokens?: never;
    } | {
        getAccessLevels?: never;
        checkSpaceId?: never;
        getSpace?: never;
        listClusters?: never;
        listSquads?: never;
        listUsers?: never;
        listApiTokens?: SpaceCtlProtoListAPITokensRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkSpaceId" in data && data.checkSpaceId != undefined) {
                this.checkSpaceId = data.checkSpaceId;
            }
            if ("getSpace" in data && data.getSpace != undefined) {
                this.getSpace = data.getSpace;
            }
            if ("listClusters" in data && data.listClusters != undefined) {
                this.listClusters = data.listClusters;
            }
            if ("listSquads" in data && data.listSquads != undefined) {
                this.listSquads = data.listSquads;
            }
            if ("listUsers" in data && data.listUsers != undefined) {
                this.listUsers = data.listUsers;
            }
            if ("listApiTokens" in data && data.listApiTokens != undefined) {
                this.listApiTokens = data.listApiTokens;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoGetAccessLevelsRes, 1) as SpaceCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: SpaceCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkSpaceId() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoCheckSpaceIDRes, 2) as SpaceCtlProtoCheckSpaceIDRes;
    }
    set checkSpaceId(value: SpaceCtlProtoCheckSpaceIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckSpaceId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getSpace() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoGetSpaceRes, 4) as SpaceCtlProtoGetSpaceRes;
    }
    set getSpace(value: SpaceCtlProtoGetSpaceRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetSpace() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get listClusters() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListClustersRes, 7) as SpaceCtlProtoListClustersRes;
    }
    set listClusters(value: SpaceCtlProtoListClustersRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListClusters() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSquads() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListSquadsRes, 8) as SpaceCtlProtoListSquadsRes;
    }
    set listSquads(value: SpaceCtlProtoListSquadsRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSquads() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listUsers() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListUsersRes, 9) as SpaceCtlProtoListUsersRes;
    }
    set listUsers(value: SpaceCtlProtoListUsersRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListUsers() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listApiTokens() {
        return pb_1.Message.getWrapperField(this, SpaceCtlProtoListAPITokensRes, 10) as SpaceCtlProtoListAPITokensRes;
    }
    set listApiTokens(value: SpaceCtlProtoListAPITokensRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListApiTokens() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkSpaceId" | "getSpace" | "listClusters" | "listSquads" | "listUsers" | "listApiTokens";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkSpaceId",
            4: "getSpace",
            7: "listClusters",
            8: "listSquads",
            9: "listUsers",
            10: "listApiTokens"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8, 9, 10])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SpaceCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkSpaceId?: ReturnType<typeof SpaceCtlProtoCheckSpaceIDRes.prototype.toObject>;
        getSpace?: ReturnType<typeof SpaceCtlProtoGetSpaceRes.prototype.toObject>;
        listClusters?: ReturnType<typeof SpaceCtlProtoListClustersRes.prototype.toObject>;
        listSquads?: ReturnType<typeof SpaceCtlProtoListSquadsRes.prototype.toObject>;
        listUsers?: ReturnType<typeof SpaceCtlProtoListUsersRes.prototype.toObject>;
        listApiTokens?: ReturnType<typeof SpaceCtlProtoListAPITokensRes.prototype.toObject>;
    }): SpaceCtlProtoRes {
        const message = new SpaceCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SpaceCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkSpaceId != null) {
            message.checkSpaceId = SpaceCtlProtoCheckSpaceIDRes.fromObject(data.checkSpaceId);
        }
        if (data.getSpace != null) {
            message.getSpace = SpaceCtlProtoGetSpaceRes.fromObject(data.getSpace);
        }
        if (data.listClusters != null) {
            message.listClusters = SpaceCtlProtoListClustersRes.fromObject(data.listClusters);
        }
        if (data.listSquads != null) {
            message.listSquads = SpaceCtlProtoListSquadsRes.fromObject(data.listSquads);
        }
        if (data.listUsers != null) {
            message.listUsers = SpaceCtlProtoListUsersRes.fromObject(data.listUsers);
        }
        if (data.listApiTokens != null) {
            message.listApiTokens = SpaceCtlProtoListAPITokensRes.fromObject(data.listApiTokens);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SpaceCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkSpaceId?: ReturnType<typeof SpaceCtlProtoCheckSpaceIDRes.prototype.toObject>;
            getSpace?: ReturnType<typeof SpaceCtlProtoGetSpaceRes.prototype.toObject>;
            listClusters?: ReturnType<typeof SpaceCtlProtoListClustersRes.prototype.toObject>;
            listSquads?: ReturnType<typeof SpaceCtlProtoListSquadsRes.prototype.toObject>;
            listUsers?: ReturnType<typeof SpaceCtlProtoListUsersRes.prototype.toObject>;
            listApiTokens?: ReturnType<typeof SpaceCtlProtoListAPITokensRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkSpaceId != null) {
            data.checkSpaceId = this.checkSpaceId.toObject();
        }
        if (this.getSpace != null) {
            data.getSpace = this.getSpace.toObject();
        }
        if (this.listClusters != null) {
            data.listClusters = this.listClusters.toObject();
        }
        if (this.listSquads != null) {
            data.listSquads = this.listSquads.toObject();
        }
        if (this.listUsers != null) {
            data.listUsers = this.listUsers.toObject();
        }
        if (this.listApiTokens != null) {
            data.listApiTokens = this.listApiTokens.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckSpaceId)
            writer.writeMessage(2, this.checkSpaceId, () => this.checkSpaceId.serialize(writer));
        if (this.hasGetSpace)
            writer.writeMessage(4, this.getSpace, () => this.getSpace.serialize(writer));
        if (this.hasListClusters)
            writer.writeMessage(7, this.listClusters, () => this.listClusters.serialize(writer));
        if (this.hasListSquads)
            writer.writeMessage(8, this.listSquads, () => this.listSquads.serialize(writer));
        if (this.hasListUsers)
            writer.writeMessage(9, this.listUsers, () => this.listUsers.serialize(writer));
        if (this.hasListApiTokens)
            writer.writeMessage(10, this.listApiTokens, () => this.listApiTokens.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SpaceCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkSpaceId, () => message.checkSpaceId = SpaceCtlProtoCheckSpaceIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getSpace, () => message.getSpace = SpaceCtlProtoGetSpaceRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listClusters, () => message.listClusters = SpaceCtlProtoListClustersRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSquads, () => message.listSquads = SpaceCtlProtoListSquadsRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listUsers, () => message.listUsers = SpaceCtlProtoListUsersRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listApiTokens, () => message.listApiTokens = SpaceCtlProtoListAPITokensRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoRes {
        return SpaceCtlProtoRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoGetAccessLevels {
        const message = new SpaceCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetAccessLevels {
        return SpaceCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuids" in data && data.spaceUuids != undefined) {
                this.spaceUuids = data.spaceUuids;
            }
        }
    }
    get spaceUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set spaceUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        spaceUuids?: Uint8Array[];
    }): SpaceCtlProtoGetAccessLevelsReq {
        const message = new SpaceCtlProtoGetAccessLevelsReq({});
        if (data.spaceUuids != null) {
            message.spaceUuids = data.spaceUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuids?: Uint8Array[];
        } = {};
        if (this.spaceUuids != null) {
            data.spaceUuids = this.spaceUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuids.length)
            writer.writeRepeatedBytes(1, this.spaceUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetAccessLevelsReq {
        return SpaceCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): SpaceCtlProtoGetAccessLevelsRes {
        const message = new SpaceCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetAccessLevelsRes {
        return SpaceCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoCheckSpaceID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoCheckSpaceID {
        const message = new SpaceCtlProtoCheckSpaceID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoCheckSpaceID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoCheckSpaceID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoCheckSpaceID {
        return SpaceCtlProtoCheckSpaceID.deserialize(bytes);
    }
}
export class SpaceCtlProtoCheckSpaceIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        id?: string;
    }): SpaceCtlProtoCheckSpaceIDReq {
        const message = new SpaceCtlProtoCheckSpaceIDReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            id?: string;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoCheckSpaceIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoCheckSpaceIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoCheckSpaceIDReq {
        return SpaceCtlProtoCheckSpaceIDReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoCheckSpaceIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): SpaceCtlProtoCheckSpaceIDRes {
        const message = new SpaceCtlProtoCheckSpaceIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoCheckSpaceIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoCheckSpaceIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoCheckSpaceIDRes {
        return SpaceCtlProtoCheckSpaceIDRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoCreateSpace extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoCreateSpace {
        const message = new SpaceCtlProtoCreateSpace({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoCreateSpace {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoCreateSpace();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoCreateSpace {
        return SpaceCtlProtoCreateSpace.deserialize(bytes);
    }
}
export class SpaceCtlProtoCreateSpaceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        space?: dependency_1.SpaceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get space() {
        return pb_1.Message.getWrapperField(this, dependency_1.SpaceProto, 2) as dependency_1.SpaceProto;
    }
    set space(value: dependency_1.SpaceProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
    }): SpaceCtlProtoCreateSpaceReq {
        const message = new SpaceCtlProtoCreateSpaceReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.space != null) {
            message.space = dependency_1.SpaceProto.fromObject(data.space);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasSpace)
            writer.writeMessage(2, this.space, () => this.space.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoCreateSpaceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoCreateSpaceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.space, () => message.space = dependency_1.SpaceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoCreateSpaceReq {
        return SpaceCtlProtoCreateSpaceReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetSpace extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoGetSpace {
        const message = new SpaceCtlProtoGetSpace({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetSpace {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetSpace();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetSpace {
        return SpaceCtlProtoGetSpace.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetSpaceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        spaceId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("spaceId" in data && data.spaceId != undefined) {
                this.spaceId = data.spaceId;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get spaceId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set spaceId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        spaceId?: string;
    }): SpaceCtlProtoGetSpaceReq {
        const message = new SpaceCtlProtoGetSpaceReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.spaceId != null) {
            message.spaceId = data.spaceId;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            spaceId?: string;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.spaceId != null) {
            data.spaceId = this.spaceId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.spaceId.length)
            writer.writeString(2, this.spaceId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetSpaceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetSpaceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.spaceId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetSpaceReq {
        return SpaceCtlProtoGetSpaceReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoGetSpaceRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        space?: dependency_1.SpaceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
        }
    }
    get space() {
        return pb_1.Message.getWrapperField(this, dependency_1.SpaceProto, 2) as dependency_1.SpaceProto;
    }
    set space(value: dependency_1.SpaceProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
    }): SpaceCtlProtoGetSpaceRes {
        const message = new SpaceCtlProtoGetSpaceRes({});
        if (data.space != null) {
            message.space = dependency_1.SpaceProto.fromObject(data.space);
        }
        return message;
    }
    toObject() {
        const data: {
            space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
        } = {};
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSpace)
            writer.writeMessage(2, this.space, () => this.space.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoGetSpaceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoGetSpaceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.space, () => message.space = dependency_1.SpaceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoGetSpaceRes {
        return SpaceCtlProtoGetSpaceRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoUpdateSpace extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoUpdateSpace {
        const message = new SpaceCtlProtoUpdateSpace({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUpdateSpace {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUpdateSpace();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUpdateSpace {
        return SpaceCtlProtoUpdateSpace.deserialize(bytes);
    }
}
export class SpaceCtlProtoUpdateSpaceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        space?: dependency_1.SpaceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
        }
    }
    get space() {
        return pb_1.Message.getWrapperField(this, dependency_1.SpaceProto, 1) as dependency_1.SpaceProto;
    }
    set space(value: dependency_1.SpaceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
    }): SpaceCtlProtoUpdateSpaceReq {
        const message = new SpaceCtlProtoUpdateSpaceReq({});
        if (data.space != null) {
            message.space = dependency_1.SpaceProto.fromObject(data.space);
        }
        return message;
    }
    toObject() {
        const data: {
            space?: ReturnType<typeof dependency_1.SpaceProto.prototype.toObject>;
        } = {};
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSpace)
            writer.writeMessage(1, this.space, () => this.space.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUpdateSpaceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUpdateSpaceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.space, () => message.space = dependency_1.SpaceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUpdateSpaceReq {
        return SpaceCtlProtoUpdateSpaceReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoDeleteSpace extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoDeleteSpace {
        const message = new SpaceCtlProtoDeleteSpace({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoDeleteSpace {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoDeleteSpace();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoDeleteSpace {
        return SpaceCtlProtoDeleteSpace.deserialize(bytes);
    }
}
export class SpaceCtlProtoDeleteSpaceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
    }): SpaceCtlProtoDeleteSpaceReq {
        const message = new SpaceCtlProtoDeleteSpaceReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoDeleteSpaceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoDeleteSpaceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoDeleteSpaceReq {
        return SpaceCtlProtoDeleteSpaceReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoListClusters extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoListClusters {
        const message = new SpaceCtlProtoListClusters({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListClusters {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListClusters();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListClusters {
        return SpaceCtlProtoListClusters.deserialize(bytes);
    }
}
export class SpaceCtlProtoListClustersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SpaceCtlProtoListClustersReq {
        const message = new SpaceCtlProtoListClustersReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListClustersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListClustersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListClustersReq {
        return SpaceCtlProtoListClustersReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoListClustersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusters?: dependency_1.ClusterProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusters" in data && data.clusters != undefined) {
                this.clusters = data.clusters;
            }
        }
    }
    get clusters() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.ClusterProto, 1) as dependency_1.ClusterProto[];
    }
    set clusters(value: dependency_1.ClusterProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        clusters?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>[];
    }): SpaceCtlProtoListClustersRes {
        const message = new SpaceCtlProtoListClustersRes({});
        if (data.clusters != null) {
            message.clusters = data.clusters.map(item => dependency_1.ClusterProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            clusters?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>[];
        } = {};
        if (this.clusters != null) {
            data.clusters = this.clusters.map((item: dependency_1.ClusterProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusters.length)
            writer.writeRepeatedMessage(1, this.clusters, (item: dependency_1.ClusterProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListClustersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListClustersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.clusters, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.ClusterProto.deserialize(reader), dependency_1.ClusterProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListClustersRes {
        return SpaceCtlProtoListClustersRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoListSquads extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoListSquads {
        const message = new SpaceCtlProtoListSquads({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListSquads {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListSquads();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListSquads {
        return SpaceCtlProtoListSquads.deserialize(bytes);
    }
}
export class SpaceCtlProtoListSquadsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SpaceCtlProtoListSquadsReq {
        const message = new SpaceCtlProtoListSquadsReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListSquadsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListSquadsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListSquadsReq {
        return SpaceCtlProtoListSquadsReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoListSquadsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squads?: dependency_1.SpaceSquadAssignmentProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squads" in data && data.squads != undefined) {
                this.squads = data.squads;
            }
        }
    }
    get squads() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.SpaceSquadAssignmentProto, 1) as dependency_1.SpaceSquadAssignmentProto[];
    }
    set squads(value: dependency_1.SpaceSquadAssignmentProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        squads?: ReturnType<typeof dependency_1.SpaceSquadAssignmentProto.prototype.toObject>[];
    }): SpaceCtlProtoListSquadsRes {
        const message = new SpaceCtlProtoListSquadsRes({});
        if (data.squads != null) {
            message.squads = data.squads.map(item => dependency_1.SpaceSquadAssignmentProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            squads?: ReturnType<typeof dependency_1.SpaceSquadAssignmentProto.prototype.toObject>[];
        } = {};
        if (this.squads != null) {
            data.squads = this.squads.map((item: dependency_1.SpaceSquadAssignmentProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squads.length)
            writer.writeRepeatedMessage(1, this.squads, (item: dependency_1.SpaceSquadAssignmentProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListSquadsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListSquadsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squads, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.SpaceSquadAssignmentProto.deserialize(reader), dependency_1.SpaceSquadAssignmentProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListSquadsRes {
        return SpaceCtlProtoListSquadsRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoListUsers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoListUsers {
        const message = new SpaceCtlProtoListUsers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListUsers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListUsers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListUsers {
        return SpaceCtlProtoListUsers.deserialize(bytes);
    }
}
export class SpaceCtlProtoListUsersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SpaceCtlProtoListUsersReq {
        const message = new SpaceCtlProtoListUsersReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListUsersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListUsersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListUsersReq {
        return SpaceCtlProtoListUsersReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoListUsersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        users?: dependency_1.SpaceUserAssignmentProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("users" in data && data.users != undefined) {
                this.users = data.users;
            }
        }
    }
    get users() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.SpaceUserAssignmentProto, 1) as dependency_1.SpaceUserAssignmentProto[];
    }
    set users(value: dependency_1.SpaceUserAssignmentProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        users?: ReturnType<typeof dependency_1.SpaceUserAssignmentProto.prototype.toObject>[];
    }): SpaceCtlProtoListUsersRes {
        const message = new SpaceCtlProtoListUsersRes({});
        if (data.users != null) {
            message.users = data.users.map(item => dependency_1.SpaceUserAssignmentProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            users?: ReturnType<typeof dependency_1.SpaceUserAssignmentProto.prototype.toObject>[];
        } = {};
        if (this.users != null) {
            data.users = this.users.map((item: dependency_1.SpaceUserAssignmentProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.users.length)
            writer.writeRepeatedMessage(1, this.users, (item: dependency_1.SpaceUserAssignmentProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListUsersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListUsersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.users, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.SpaceUserAssignmentProto.deserialize(reader), dependency_1.SpaceUserAssignmentProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListUsersRes {
        return SpaceCtlProtoListUsersRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoListAPITokens extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoListAPITokens {
        const message = new SpaceCtlProtoListAPITokens({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListAPITokens {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListAPITokens();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListAPITokens {
        return SpaceCtlProtoListAPITokens.deserialize(bytes);
    }
}
export class SpaceCtlProtoListAPITokensReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SpaceCtlProtoListAPITokensReq {
        const message = new SpaceCtlProtoListAPITokensReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListAPITokensReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListAPITokensReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListAPITokensReq {
        return SpaceCtlProtoListAPITokensReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoListAPITokensRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        apiTokens?: dependency_1.SpaceAPITokenProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("apiTokens" in data && data.apiTokens != undefined) {
                this.apiTokens = data.apiTokens;
            }
        }
    }
    get apiTokens() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.SpaceAPITokenProto, 1) as dependency_1.SpaceAPITokenProto[];
    }
    set apiTokens(value: dependency_1.SpaceAPITokenProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        apiTokens?: ReturnType<typeof dependency_1.SpaceAPITokenProto.prototype.toObject>[];
    }): SpaceCtlProtoListAPITokensRes {
        const message = new SpaceCtlProtoListAPITokensRes({});
        if (data.apiTokens != null) {
            message.apiTokens = data.apiTokens.map(item => dependency_1.SpaceAPITokenProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            apiTokens?: ReturnType<typeof dependency_1.SpaceAPITokenProto.prototype.toObject>[];
        } = {};
        if (this.apiTokens != null) {
            data.apiTokens = this.apiTokens.map((item: dependency_1.SpaceAPITokenProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.apiTokens.length)
            writer.writeRepeatedMessage(1, this.apiTokens, (item: dependency_1.SpaceAPITokenProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoListAPITokensRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoListAPITokensRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.apiTokens, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.SpaceAPITokenProto.deserialize(reader), dependency_1.SpaceAPITokenProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoListAPITokensRes {
        return SpaceCtlProtoListAPITokensRes.deserialize(bytes);
    }
}
export class SpaceCtlProtoAssignUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoAssignUser {
        const message = new SpaceCtlProtoAssignUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoAssignUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoAssignUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoAssignUser {
        return SpaceCtlProtoAssignUser.deserialize(bytes);
    }
}
export class SpaceCtlProtoAssignUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 4, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SpaceCtlProtoAssignUserReq {
        const message = new SpaceCtlProtoAssignUserReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.userUuid.length)
            writer.writeBytes(3, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(4, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoAssignUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoAssignUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 3:
                    message.userUuid = reader.readBytes();
                    break;
                case 4:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoAssignUserReq {
        return SpaceCtlProtoAssignUserReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoUnassignUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoUnassignUser {
        const message = new SpaceCtlProtoUnassignUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUnassignUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUnassignUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUnassignUser {
        return SpaceCtlProtoUnassignUser.deserialize(bytes);
    }
}
export class SpaceCtlProtoUnassignUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): SpaceCtlProtoUnassignUserReq {
        const message = new SpaceCtlProtoUnassignUserReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.userUuid.length)
            writer.writeBytes(3, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUnassignUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUnassignUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 3:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUnassignUserReq {
        return SpaceCtlProtoUnassignUserReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoAssignSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoAssignSquad {
        const message = new SpaceCtlProtoAssignSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoAssignSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoAssignSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoAssignSquad {
        return SpaceCtlProtoAssignSquad.deserialize(bytes);
    }
}
export class SpaceCtlProtoAssignSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SpaceCtlProtoAssignSquadReq {
        const message = new SpaceCtlProtoAssignSquadReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            squadUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.squadUuid.length)
            writer.writeBytes(2, this.squadUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoAssignSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoAssignSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    message.squadUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoAssignSquadReq {
        return SpaceCtlProtoAssignSquadReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoSetUserAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoSetUserAccessLevel {
        const message = new SpaceCtlProtoSetUserAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoSetUserAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoSetUserAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoSetUserAccessLevel {
        return SpaceCtlProtoSetUserAccessLevel.deserialize(bytes);
    }
}
export class SpaceCtlProtoSetUserAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SpaceCtlProtoSetUserAccessLevelReq {
        const message = new SpaceCtlProtoSetUserAccessLevelReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoSetUserAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoSetUserAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoSetUserAccessLevelReq {
        return SpaceCtlProtoSetUserAccessLevelReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoSetSquadAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoSetSquadAccessLevel {
        const message = new SpaceCtlProtoSetSquadAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoSetSquadAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoSetSquadAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoSetSquadAccessLevel {
        return SpaceCtlProtoSetSquadAccessLevel.deserialize(bytes);
    }
}
export class SpaceCtlProtoSetSquadAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SpaceCtlProtoSetSquadAccessLevelReq {
        const message = new SpaceCtlProtoSetSquadAccessLevelReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            squadUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.squadUuid.length)
            writer.writeBytes(2, this.squadUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoSetSquadAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoSetSquadAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    message.squadUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoSetSquadAccessLevelReq {
        return SpaceCtlProtoSetSquadAccessLevelReq.deserialize(bytes);
    }
}
export class SpaceCtlProtoUnassignSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SpaceCtlProtoUnassignSquad {
        const message = new SpaceCtlProtoUnassignSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUnassignSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUnassignSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUnassignSquad {
        return SpaceCtlProtoUnassignSquad.deserialize(bytes);
    }
}
export class SpaceCtlProtoUnassignSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceUuid" in data && data.spaceUuid != undefined) {
                this.spaceUuid = data.spaceUuid;
            }
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
        }
    }
    get spaceUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set spaceUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        spaceUuid?: Uint8Array;
        squadUuid?: Uint8Array;
    }): SpaceCtlProtoUnassignSquadReq {
        const message = new SpaceCtlProtoUnassignSquadReq({});
        if (data.spaceUuid != null) {
            message.spaceUuid = data.spaceUuid;
        }
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceUuid?: Uint8Array;
            squadUuid?: Uint8Array;
        } = {};
        if (this.spaceUuid != null) {
            data.spaceUuid = this.spaceUuid;
        }
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceUuid.length)
            writer.writeBytes(1, this.spaceUuid);
        if (this.squadUuid.length)
            writer.writeBytes(2, this.squadUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceCtlProtoUnassignSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceCtlProtoUnassignSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceUuid = reader.readBytes();
                    break;
                case 2:
                    message.squadUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceCtlProtoUnassignSquadReq {
        return SpaceCtlProtoUnassignSquadReq.deserialize(bytes);
    }
}
