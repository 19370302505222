/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { AsyncValidatorFn, FormBuilder } from "@angular/forms";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { Subject, delay, filter, first, map, of, shareReplay, switchMap, tap } from "rxjs";
import { ButtonComponent, FormActionsComponent, FormComponent, FormInputComponent, FormTextareaComponent, SpinnerComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { ParagraphWithHighlights } from "typedb-web-schema/lib";
import { Invitation } from "../../../concept/team";
import { ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { AnalyticsService } from "../../../service/analytics.service";
import { SanityService } from "../../../service/sanity.service";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { TeamApi } from "../../../service/team/team-api.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { UserApi } from "../../../service/user/user-api.service";
import { MultipleChoiceQuestionComponent } from "../project/question/multiple-choice/multiple-choice-question.component";
import { cleanInputForID, ensureIdUnique } from "../../../util";
import { TEAM_ACTION } from "../../../framework/url-params";
import { trigger, transition, animate, style, sequence } from '@angular/animations';

type TeamMode = "personal" | "team";

@Component({
    selector: "tp-setup-team",
    templateUrl: "./setup-team.component.html",
    styleUrls: ["./setup-team.component.scss"],
    standalone: true,
    imports: [
        FormComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormTextareaComponent,
        SpinnerComponent, ButtonComponent, SpinnerComponent, MatProgressBarModule, ParagraphWithHighlightsComponent, MultipleChoiceQuestionComponent
    ],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, height: 0}),
                sequence([
                    animate('50ms linear', style({height: 'auto'})),
                    animate('250ms linear', style({opacity: 1})),
                ])
            ]),
            transition(':leave', [
                sequence([
                    animate('125ms linear', style({opacity: 0})),
                    animate('50ms linear', style({height: 0})),
                ])
            ]),
        ]),
    ],
})
export class SetupTeamComponent implements OnDestroy {
    private teamIdValidator: AsyncValidatorFn = (control) => {
        return of(control.value as string).pipe(
            delay(500),
            switchMap((id) => this.teamApi.checkTeamId(id)),
            map((res) => res.exists ? { errorText: "This team ID is already in use" } : null),
            tap((validationError) => { if (validationError) control.markAsTouched(); })
        );
    };
    unsub$ = new Subject<void>();
    // TODO: don't unsubscribe immediately - subscribe and live-update invite list
    invitations$ = this.userApi.listInvitations(
        { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "created-at", direction: "desc" } },
        this.unsub$
    ).pipe(
        first(x => !!x.initial),
        map(x => x.initial!),
        shareReplay(1),
    );
    readonly createTeamForm = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator], [this.teamIdValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });
    readonly createTeamFormIsSubmitting$ = new Subject<boolean>();
    readonly invitationReviewForm = this.formBuilder.nonNullable.group({})
    readonly invitationReviewFormIsSubmitting$ = new Subject<boolean>();
    tagline$ = this.sanity.onboarding.pipe(map(x => x.joinTeamText ? ParagraphWithHighlights.fromSanity(x.joinTeamText) : null));
    teamMode: TeamMode = "team";

    constructor(
        private identityAuth: IdentityAuthService, private router: Router, private teamApi: TeamApi,
        private app: ApplicationState, private platformAuth: PlatformAuthService, private userApi: UserApi,
        private formBuilder: FormBuilder, private analytics: AnalyticsService, private sanity: SanityService,
    ) {
        this.createTeamForm.controls.name.valueChanges.pipe(
            filter(() => !this.createTeamForm.controls.id.dirty),
            map((teamName) => cleanInputForID(teamName)),
            tap((id) => this.createTeamForm.patchValue({ id })),
            switchMap((id) => ensureIdUnique(id, (id) => this.checkId(id)))
        ).subscribe((id) => {
            if (!this.createTeamForm.controls.id.dirty) this.createTeamForm.patchValue({ id });
        });
    }

    checkId(id: string) {
        return this.teamApi.checkTeamId(id).pipe(map(({ exists }) => exists))
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    acceptInvitation(invitation: Invitation) {
        this.invitationReviewFormIsSubmitting$.next(true);
        this.userApi.setProperty("setup_team_action", "joined").subscribe();
        this.userApi.acceptInvitation(invitation.uuid).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion("joinTeam");
                window.location.href = window.location.origin + `?${TEAM_ACTION}=/get-started`
            },
            error: () => {
                this.invitationReviewFormIsSubmitting$.next(false);
            }
        });
    }

    declineInvitation(invitation: Invitation) {
        this.invitationReviewFormIsSubmitting$.next(true);
        this.userApi.rejectInvitation(invitation.uuid).subscribe({
            next: () => {
                window.location.href = window.location.origin;
            },
            error: () => {
                this.invitationReviewFormIsSubmitting$.next(false);
            }
        });
    }

    submitCreateTeamForm() {
        const id = this.createTeamForm.value.id!;
        const name = this.createTeamForm.value.name!;
        this.createTeam(id, name, false);
    }

    createDefaultTeam() {
        this.createTeamFormIsSubmitting$.next(true);
        this.app.currentUser$.pipe(
            first(user => !!user), map(user => user!),
            switchMap((user) =>
                ensureIdUnique(user.id, (id) => this.checkId(id))
            )
        ).subscribe((id) => this.createTeam(id, "Personal", true));
    }

    private createTeam(id: string, name: string, isPersonal: boolean) {
        this.teamApi.createTeam({ id, name, isPersonal }).pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
        ).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion("joinTeam");
                this.navigateToGetStarted(id);
            },
            error: () => {
                this.createTeamFormIsSubmitting$.next(false);
            }
        });
    }

    navigateToGetStarted(teamID: string) {
        this.router.navigate(["team", teamID, "get-started"]);
    }

    selectTeamMode(teamMode: TeamMode) {
        this.teamMode = teamMode;
        this.createTeamForm.markAsPristine();
        this.createTeamForm.markAsUntouched();
        this.userApi.setProperty("setup_team_action", teamMode).subscribe();
    }
}
