<div mat-dialog-title>
  <h1>{{ modalTitle(stage) }}</h1>
  <tp-modal-close-button />
</div>
<div class="tp-modal-content">
  <tp-scroll-container class="tp-modal-body" mat-dialog-content>
    @switch (stage) {
      @case (undefined) { <tp-spinner/> }

      @case (IntroStage.SETUP_CLUSTER) {
        <div class="tp-cluster-password-section">
          <p>
            Before you can connect, you'll need to set the admin password for your cluster.
            You can use the pre-generated secure password below, or choose your own.
          </p>
          <div class="tp-cluster-password-input-row">
            <tp-form-password-input [revealed]="true" [form]="passwordForm" field="password" label="Password">
              <button inputSuffix
                class="tp-cluster-password-regenerate" type="button" buttonId="regeneratePassword"
                [disabled]="isPasswordFormSubmitting$ | async" (click)="generatePassword(); $event.stopPropagation()"
              >
                <i class="fa-light fa-refresh"></i>
              </button>
            </tp-form-password-input>
            <tp-button
              buttonId="copyPassword" [buttonStyle]="isPasswordCopied ? 'primary-outline white stroke' : 'primary-outline green stroke'"
              [enabled]="!(isPasswordFormSubmitting$ | async) && !!passwordForm.value.password"
              (click)="copyPassword()" [height]="48"
            >
              <div class="tp-copy-password-content">
                @if (!isPasswordCopied) {
                  <i class="fa-light fa-copy"></i>
                } @else {
                  <i class="fa-solid fa-check"></i>
                }
                Copy
              </div>
            </tp-button>
          </div>
          <div class="admonitionblock attention">
            <div class="icon">
              <i class="fa-solid fa-triangle-exclamation"></i>
            </div>
            <p>Make sure to keep your password somewhere safe - you won't see it again.</p>
          </div>
        </div>
      }

      @case (IntroStage.SELECT_CONNECTION_METHOD) {
        <h2>Connect through our tools</h2>
        <button class="card hoverable tp-horizontal-icon-card" (click)="selectConnectionMethod(ConnectionMethod.STUDIO)">
          <i class="fa-thin fa-browser"></i>
          <div class="tp-horizontal-icon-card-content">
            <h3 class="tp-horizontal-icon-card-title">TypeDB Studio</h3>
            <p>Query your data through TypeDB's GUI</p>
          </div>
          <div class="flex-spacer"></div>
          <i class="fa-light fa-chevron-right"></i>
        </button>
        <button class="card hoverable tp-horizontal-icon-card" (click)="selectConnectionMethod(ConnectionMethod.CONSOLE)">
          <i class="fa-thin fa-rectangle-terminal"></i>
          <div class="tp-horizontal-icon-card-content">
            <h3 class="tp-horizontal-icon-card-title">TypeDB Console</h3>
            <p>Access your database from the command line</p>
          </div>
          <div class="flex-spacer"></div>
          <i class="fa-light fa-chevron-right"></i>
        </button>
        <h2>Connect from your application</h2>
        <button class="card hoverable tp-horizontal-icon-card" (click)="selectConnectionMethod(ConnectionMethod.DRIVERS)">
          <i class="fa-thin fa-gear-complex-code"></i>
          <div class="tp-horizontal-icon-card-content">
            <h3 class="tp-horizontal-icon-card-title">TypeDB Drivers</h3>
            <p>Connect to your database from one of our language drivers</p>
          </div>
          <div class="flex-spacer"></div>
          <i class="fa-light fa-chevron-right"></i>
        </button>
      }

      @case (IntroStage.CONNECTION_INSTRUCTIONS) {
        @switch (connectionMethod) {
          @case (ConnectionMethod.CONSOLE) {
            <h2>Install Console</h2>
            <p>Follow the instructions in our docs to
              <a class="tp-external-link" [href]="(consoleInstallDocsLink$ | async)" target="_blank">install TypeDB Console<i class="fa-light fa-arrow-up-right"></i></a>
            </p>
            <hr/>
            <h2>Connect to TypeDB</h2>
            @if (ready$ | async) {
              <p>Run the following command wherever you've installed TypeDB Console</p>
              @if (connectionCommand$ | async; as command) {
                <div class="tp-code">
                  <tp-code-snippet [snippet]="{ language: 'shell', code: command.replaceAll(' --', ' \\\n  --') }"/>
                  <button
                    class="btn-copy" aria-label="copy" matTooltip="Copy to clipboard"
                    (click)="copy(command); $event.stopPropagation();"
                  >
                    <i class="fa-light fa-copy"></i>
                  </button>
                </div>
              }
            }
          }
          @case (ConnectionMethod.STUDIO) {
            <h2>Install TypeDB Studio</h2>
            <div class="tp-download-studio">
              <mat-form-field class="tp-connection-selector">
                <mat-label>Operating System</mat-label>
                <mat-select [(value)]="operatingSystem">
                  <mat-option [value]="OperatingSystem.MAC_X86_64">macOS Intel x86</mat-option>
                  <mat-option [value]="OperatingSystem.MAC_ARM64">macOS arm64</mat-option>
                  <mat-option [value]="OperatingSystem.LINUX_X86_64">Linux Intel x86</mat-option>
                  <mat-option [value]="OperatingSystem.LINUX_ARM64">Linux arm64</mat-option>
                  <mat-option [value]="OperatingSystem.WINDOWS_X86_64">Windows 64-bit</mat-option>
                </mat-select>
              </mat-form-field>
              <a class="tp-download-studio-button" [href]="studioDownloadLink$ | async" target="_blank">
                <tp-button [height]="48" buttonStyle="primary-outline green stroke" buttonId="downloadStudio">
                  <i class="tp-download-icon fa-light fa-download"></i>
                  Download
                </tp-button>
              </a>
            </div>
            <a class="tp-installation-options tp-external-link" [href]="(studioInstallDocsLink$ | async)" target="_blank">
              Other installation options<i class="fa-light fa-arrow-up-right"></i>
            </a>
            <hr/>
            <h2>Connect to TypeDB</h2>
            @if (ready$ | async) {
              <p>Copy the following connection string into TypeDB Studio</p>
              @if (connectionStringSnippet$ | async; as connectionString) {
                <div class="tp-code">
                  <tp-code-snippet [snippet]="connectionString"/>
                  <button
                    class="btn-copy" aria-label="copy" matTooltip="Copy to clipboard"
                    (click)="copy(connectionString.code); $event.stopPropagation();"
                  >
                    <i class="fa-light fa-copy"></i>
                  </button>
                </div>
              }
              <div class="tp-studio-info">
                <i class="fa-solid fa-circle-info"></i>
                Connection strings are only available on TypeDB Studio version 2.28.5 and later.
                Make sure you're using an up-to-date version.
              </div>
            }
          }
          @case (ConnectionMethod.DRIVERS) {
            <h2>Setup Driver</h2>
            <p>View the <a class="tp-external-link" [href]="driverDocsLink$ | async" target="_blank">driver documentation<i class="fa-light fa-arrow-up-right"></i></a> for instructions on how to install and set up your driver</p>
            <hr/>
            <h2>Connect to TypeDB</h2>
            @if (ready$ | async) {
              <p>Use the following values to configure your driver</p>
              <tp-properties-table class="dark">
                <tp-properties-table-row key="Username">admin</tp-properties-table-row>
                <tp-properties-table-row key="Password" class="tp-copyable">
                  @if (password) {
                    {{ password }}
                    <button class="btn-copy" mat-icon-button aria-label="copy" (click)="copy(password); $event.stopPropagation();" matTooltip="Copy to clipboard">
                      <i class="fa-light fa-copy"></i>
                    </button>
                  } @else {
                    <{{ PASSWORD_FILLER }}>
                  }
                </tp-properties-table-row>
                @for (address of serverAddresses$ | async; track address) {
                  <tp-properties-table-row key="Address" class="tp-copyable tp-address">
                    {{ address }}
                    <button class="btn-copy" mat-icon-button aria-label="copy" (click)="copy(address!); $event.stopPropagation();" matTooltip="Copy to clipboard">
                      <i class="fa-light fa-copy"></i>
                    </button>
                  </tp-properties-table-row>
                }
                <tp-properties-table-row key="TLS Enabled">True</tp-properties-table-row>
                <tp-properties-table-row key="Root CA Path">-</tp-properties-table-row>
              </tp-properties-table>
            }
          }
        }

        @if (errored$ | async) {
          <div class="admonitionblock warning">
            <p>An error occurred while setting up your cluster. Please contact support.</p>
          </div>
        } @else if (loading$ | async) {
          <div class="tp-loading">
            @if (loadingInfo$ | async; as info) {
              <div class="shimmer tp-horizontal-icon-card card">
                <i [class]="'fa-thin ' + info.icon"></i>
                <div class="tp-horizontal-icon-card-content">
                  <span class="tp-horizontal-icon-card-title">{{ info.loadingText }}...</span>
                  <span>Your connection information will be available soon</span>
                </div>
              </div>
              <div class="card tp-loading-tip-section">
                <div #loadingTip class="tp-loading-tip">
                  @if (loadingTips$ | async; as loadingTips) {
                    <h3>{{ loadingTips[loadingTipIndex].title }}</h3>
                    {{ loadingTips[loadingTipIndex].body }}
                    @if (loadingTips[loadingTipIndex].code) {
                      <div class="tp-code"><tp-code-snippet [snippet]="{ language: 'typeql', code: loadingTips[loadingTipIndex].code! }"/></div>
                    }
                  }
                </div>
                <div class="tp-loading-tip-buttons">
                  <button (click)="prevTip(loadingTip)"><i class="fa-light fa-chevron-left"></i></button>
                  <button (click)="nextTip(loadingTip)"><i class="fa-light fa-chevron-right"></i></button>
                </div>
              </div>
            }
          </div>
        } @else if (!(ready$ | async)) {
          <tp-spinner/>
        }
      }

      @case (IntroStage.NEXT_STEPS) {
        <h2>Useful resources</h2>
        <a class="card hoverable tp-horizontal-icon-card" target="_blank" [href]="(quickstartDocsLink$ | async)">
          <i class="fa-thin fa-forward"></i>
          <div class="tp-horizontal-icon-card-content">
            <span class="tp-horizontal-icon-card-title">Quickstart</span>
            <span>Follow the TypeDB Quickstart tutorial to get started with TypeDB instantly</span>
          </div>
        </a>
        <a class="card hoverable tp-horizontal-icon-card" target="_blank" [href]="(typeqlDocsLink$ | async)">
          <i class="fa-thin fa-user-graduate"></i>
          <div class="tp-horizontal-icon-card-content">
            <span class="tp-horizontal-icon-card-title">Learn TypeQL</span>
            <span>Discover TypeDB's query language to learn how to query your database</span>
          </div>
        </a>
        <h2>Join our community</h2>
        <a class="card hoverable tp-horizontal-icon-card" target="_blank" href="https://typedb.com/discord">
          <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="discord">
            <path d="M12.4357 14.6C11.1897 14.8255 9.91039 14.8209 8.66614 14.5864C8.44823 14.5456 8.13975 14.4735 7.74213 14.3715L6.4389 16C3.22257 15.9007 2 13.8286 2 13.8286C2 9.22871 4.09564 5.49953 4.09564 5.49953C6.19128 3.95806 8.18503 4.00024 8.18503 4.00024L8.75529 4.71451C8.80784 4.70742 8.86074 4.70288 8.91377 4.7009C10.0008 4.56236 11.1024 4.56694 12.1881 4.71451L12.8136 4.00024C12.8136 4.00024 14.8087 3.95806 16.9029 5.50089C16.9029 5.50089 19 9.22871 19 13.8286C19 13.8286 17.7619 15.8993 14.5455 16L13.1206 14.4572C12.8937 14.5117 12.6653 14.5589 12.4357 14.6Z" stroke="currentColor"/>
            <path d="M8.00039 9C7.44162 9 7 9.45 7 10C7 10.55 7.45092 11 8.00039 11C8.55823 11 8.99986 10.55 8.99986 10C9.00916 9.45 8.55916 9 8.00039 9Z" fill="currentColor"/>
            <path d="M12.9995 9C12.4417 9 12 9.45 12 10C12 10.55 12.451 11 12.9995 11C13.5583 11 14 10.55 14 10C14 9.45 13.5583 9 12.9995 9Z" fill="currentColor"/>
          </svg>
          <div class="tp-horizontal-icon-card-content">
            <span class="tp-horizontal-icon-card-title">Connect on Discord</span>
            <span>Talk to the creators and users of TypeDB</span>
          </div>
        </a>
      }
    }
  </tp-scroll-container>

  <div class="tp-btn-section">
    @if (stage == IntroStage.CONNECTION_INSTRUCTIONS || stage == IntroStage.NEXT_STEPS) {
      <tp-button buttonId="back" buttonStyle="primary-outline white grey-stroke" (click)="back()">Back</tp-button>
      <div class="flex-spacer"></div>
      @if (stage == IntroStage.CONNECTION_INSTRUCTIONS) {
        <tp-button [enabled]="!!(ready$ | async)" buttonId="next" buttonStyle="primary-outline white grey-stroke" (click)="next()">Next</tp-button>
      } @else if (stage == IntroStage.NEXT_STEPS) {
        <tp-button buttonId="done" buttonStyle="primary-outline white grey-stroke" (click)="close()">Done</tp-button>
      }
    } @else if (stage == IntroStage.SETUP_CLUSTER) {
      <div class="flex-spacer"></div>
      <tp-button
        [enabled]="!(isPasswordFormSubmitting$ | async) && isPasswordCopied"
        buttonId="confirmPassword" [buttonStyle]="isPasswordCopied ? 'primary-outline green stroke' : 'primary-outline white stroke'"
        (click)="submitPasswordForm()" [matTooltip]="isPasswordCopied ? '' : 'Please copy your password first'"
      >
        Confirm and continue
      </tp-button>
    }
  </div>
</div>
