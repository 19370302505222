/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export const CONNECT = "connect";
export const DIALOG = "dialog";
export const EMAIL = "email";
export const LOGOUT_REASON = "logout_reason";
export const TEAM_ACTION = "team_action";
export const SPACE_ID = "space_id";
export const RETURN_TO = "return_to";
export const SETUP = "setup";
export const TRUE = "true";
