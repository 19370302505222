<tp-page-scaffold [pageAvailability]="availability$ | async">
  @if (space$ | async; as space) {
    <article>
      <section>
        <div class="section-title-row">
          <h2>{{ space.name }} ({{ space.id }})</h2>
          <tp-button
            (click)="openEditModal()" buttonStyle="secondary stroke" [height]="40"
            [enabled]="(hasWriteAccess$ | async) || false" buttonId="editSpace"
          >
            <i class="fa-light fa-pen-to-square"></i> Edit
          </tp-button>
        </div>

        <tp-properties-table title="Space Details">
          <tp-properties-table-row key="Space ID">{{ space.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Space Name">{{ space.name }}</tp-properties-table-row>
          <tp-properties-table-row key="Creation Date">{{ space.createdAt | datetime }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (clusters) {
        <section>
          <tp-table-toolbar [table]="clusters" filterGroupId="clusters" tableTitle="Clusters" [actions]="[deployClusterButton]"/>
          <tp-clusters-table [table]="clusters"/>
          <tp-table-paginator [table]="clusters"/>
        </section>
      }

      @if (members) {
        <section>
          <tp-table-toolbar [table]="members" filterGroupId="users" tableTitle="Users" [actions]="[assignUserButton]"/>
          <tp-users-table [table]="members" [rowPopupMenuItems]="membersTableRowPopupMenuItems"/>
          <tp-table-paginator [table]="members"/>
        </section>
      }

      @if (apiTokens) {
        <section>
          <tp-table-toolbar [table]="apiTokens" filterGroupId="apiTokens" tableTitle="API Tokens"/>
          <tp-api-tokens-table [table]="apiTokens"/>
          <tp-table-paginator [table]="apiTokens"/>
        </section>
      }

      <tp-delete-resource-section
        title="Delete this space"
        [display]="{
            enabled: spaceCanBeDeleted,
            enabledText: 'Delete the space from this team',
            disabledText: deleteSpaceDisabledText,
        }"
        buttonText="Delete"
        buttonIconClass="fa-light fa-trash-can-xmark"
        (buttonPressed)="openDeleteModal()"
      />
    </article>
  }
</tp-page-scaffold>
