<tp-page-scaffold [pageAvailability]="availability$ | async">
  <ng-container actionBar>
    @if (teamHasCluster$ | async) {
      <a routerLink="deploy" [relativeTo]="route">
        <tp-button
          [matTooltip]="(cannotDeployReason$ | async) || ''" buttonStyle="secondary"
          [enabled]="(deployEnabled$ | async) || false" buttonId="deployCluster"
        >
          <i class="fa-light fa-square-plus"></i> Create
        </tp-button>
      </a>
      <tp-button
        [matTooltip]="(cannotSuspendReason$ | async) || ''" buttonStyle="secondary" buttonId="suspendCluster"
        [enabled]="(suspendEnabled$ | async) || false" (click)="openSuspendResumeModalForSelection('suspend')"
      >
        <i class="fa-light fa-circle-pause"></i> Suspend
      </tp-button>
      <tp-button
        [matTooltip]="(cannotResumeReason$ | async) || ''" buttonStyle="secondary" buttonId="resumeCluster"
        [enabled]="(resumeEnabled$ | async) || false" (click)="openSuspendResumeModalForSelection('resume')"
      >
        <i class="fa-light fa-circle-play"></i> Resume
      </tp-button>
    }
  </ng-container>
  @if (teamHasCluster$ | async) {
    <article>
      <section>
        <tp-table-toolbar [table]="clusters" filterGroupId="clusters" tableTitle="Clusters"/>
        <tp-clusters-table [table]="clusters" [rowPopupMenuItems]="clusterMenuItems"/>
        <tp-table-paginator [table]="clusters"/>
      </section>
    </article>
  } @else {
    <a routerLink="deploy" [relativeTo]="route" class="no-clusters-deploy-cluster tp-bento-box hoverable">
      <button
        [matTooltip]="(cannotDeployReason$ | async) || ''"
        [disabled]="!(deployEnabled$ | async)" buttonId="deployCluster"
      >
        <img src="/assets/logo/cluster-deploy-128x132.svg" alt=""/>
        <h2 class="no-clusters-title">Deploy a cluster</h2>
        <p>Deploy your first cluster and start developing on TypeDB instantly</p>
      </button>
    </a>
  }
</tp-page-scaffold>
