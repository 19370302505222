/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from "rxjs";
import {
    BreadcrumbComponent,
    ButtonComponent,
    ConfirmationModalComponent,
    ContactSupportDialogData,
    ContactSupportModalComponent,
    DeleteResourceSectionComponent,
    ModalComponent,
    PropertiesTableComponent,
    PropertiesTableRowComponent
} from "typedb-platform-framework";
import { SpaceColumn, UserSpace } from "../../../concept/space";
import { TeamMember } from "../../../concept/user";
import { ActivatedRoute } from "@angular/router";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { ApplicationState } from "../../../service/application-state.service";
import { UserController } from "../../../service/user/user-controller.service";
import { PageScaffoldComponent, ResourceAvailability } from "../../scaffold/page/page-scaffold.component";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";
import { TeamApi } from "../../../service/team/team-api.service";
import { SpacesTableComponent } from "../../space/table/spaces-table.component";
import {
    TeamSetUserAccessLevelDialogComponent
} from "../../team/set-user-access-level/team-set-user-access-level-dialog.component";

@Component({
    selector: "tp-member-details-page",
    templateUrl: "./member-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, ConfirmationModalComponent, AsyncPipe, TableToolbarComponent,
        TablePaginatorComponent, PropertiesTableRowComponent, AccessLevelPipe, SpacesTableComponent, ButtonComponent
    ],
})
export class MemberDetailsPageComponent implements OnInit, OnDestroy{
    readonly teamMember$ = new BehaviorSubject<TeamMember | null>(null);
    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.teamMember$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubSpaces$ = new Subject<void>();
    spaces?: ResourceTable<UserSpace, SpaceColumn>;

    constructor(
        protected app: ApplicationState, private route: ActivatedRoute, private userCtl: UserController,
        private teamApi: TeamApi, private dialog: MatDialog, private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubSpaces$.next();
    }

    initDataLoader() {
        this.teamMember$.pipe(first(x => !!x), map(x => x!)).subscribe(user => {
            this.spaces = this.userCtl.spacesTable(user, this.unsubSpaces$);
        });

        this.route.params.pipe(
            map((params) => params["user-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((userId) => this.teamApi.getMember(userId, this.app.requireCurrentTeam().uuid, this.unsub$)),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.teamMember$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.teamMember$.error(err);
            }
        });
    }

    beginRemoveUser() {
        this.dialog.open<ContactSupportModalComponent, ContactSupportDialogData>(
            ContactSupportModalComponent, { data: { title: "Remove user" } }
        );
    }

    openSetAccessLevelModal(user: TeamMember) {
        this.dialog.open(TeamSetUserAccessLevelDialogComponent, { data: { team: this.app.requireCurrentTeam(), user } });
    }
}
