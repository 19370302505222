@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$" matSort (matSortChange)="tableService.handleMatSortChange(table, $event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef><tp-table-selection-header-cell [table]="table"/></th>
      <td mat-cell *matCellDef="let user"><tp-table-selection-cell [table]="table" [row]="user"/></td>
    </ng-container>
    <ng-container matColumnDef="avatar">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <tp-user-avatar [userEmail]="user.email"/>
      </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.id.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.id }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.email.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.email }}</td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.firstName.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.lastName.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.createdAt.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.createdAt | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="accessLevel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.accessLevel.name }}</th>
      <td mat-cell *matCellDef="let user">{{ user.accessLevel | accessLevel }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button
          mat-fab aria-label="more" [disabled]="!rowPopupMenuItems!(user).length"
          [matMenuTriggerFor]="rowPopupMenu" [matMenuTriggerData]="{ items: rowPopupMenuItems!(user) }"
          (click)="$event.stopPropagation()"
        >
          <i class="fa-light fa-ellipsis"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr
      mat-row *matRowDef="let user; columns: table.displayedColumns"
      [class]="tableService.rowClass$(table, user) | async"
      (click)="tableService.handleRowClick(table, user)"
    ></tr>
  </table>
} @else {
  <tp-spinner/>
}

<mat-menu #rowPopupMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    @for (item of items; track item) {
      <button mat-menu-item [matTooltip]="item.disabledReason" [disabled]="item.disabled" (click)="item.action()" [class]="item.dangerous ? 'dangerous' : ''">{{ item.label }}</button>
    }
  </ng-template>
</mat-menu>
