/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { Space } from "../../concept/space";
import { spaceDetailsPath } from "../../routing/resource-paths";
import { ApplicationState } from "../../service/application-state.service";

@Pipe({
    name: "spaceDetailsPath",
    standalone: true,
})
export class SpaceDetailsPathPipe implements PipeTransform {
    constructor(private app: ApplicationState) {
    }

    transform(value: Space): string {
        return spaceDetailsPath(value, this.app.requireCurrentTeam());
    }
}
