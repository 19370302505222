/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Subject, combineLatest, delay, filter, first, map, of, startWith, switchMap, tap } from "rxjs";
import { ParagraphWithHighlights } from "typedb-web-schema/lib";
import { ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { FormService } from "../../../service/form.service";
import { SanityService } from "../../../service/sanity.service";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { AnalyticsService } from "../../../service/analytics.service";
import { AsyncValidatorFn, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { FormActionsComponent, FormComponent, FormInputComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { AsyncPipe } from "@angular/common";
import { cleanInputForID, ensureIdUnique } from "../../../util";

@Component({
    selector: "tp-setup-profile",
    templateUrl: "./setup-profile.component.html",
    standalone: true,
    imports: [
        FormActionsComponent, FormComponent, FormInputComponent, AsyncPipe, ReactiveFormsModule,
        ParagraphWithHighlightsComponent
    ],
})
export class SetupProfileComponent implements OnInit {
    @Output() done = new EventEmitter();

    private userIdValidator: AsyncValidatorFn = (control) => {
        return of(control.value as string).pipe(
            delay(500),
            switchMap((id) => this.userApi.checkUserId(id)),
            map((res) => res.exists ? { errorText: "This username is already in use" } : null),
            tap((validationError) => { if (validationError) control.markAsTouched(); })
        );
    };
    form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator], [this.userIdValidator]],
        firstName: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
        lastName: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });
    isSubmitting$ = new Subject<boolean>();
    tagline$ = this.sanity.onboarding.pipe(map(x => x.createProfileText ? ParagraphWithHighlights.fromSanity(x.createProfileText) : null));

    constructor(
        private identityAuth: IdentityAuthService, private analytics: AnalyticsService, private userApi: UserApi,
        private app: ApplicationState, private platformAuth: PlatformAuthService, private formBuilder: FormBuilder,
        private sanity: SanityService, private formService: FormService,
    ) {
    }

    ngOnInit() {
        this.app.currentUser$.pipe(first(x => !!x), map(x => x!)).subscribe((user) => {
            this.form.controls.id.clearAsyncValidators();
            this.form.patchValue({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
            });
            Object.values(this.form.controls).forEach(x => x.disable());
        });

        combineLatest([this.form.controls.firstName.valueChanges, this.form.controls.lastName.valueChanges.pipe(startWith(""))]).pipe(
            filter(() => !this.form.controls.id.dirty),
            map(([firstName, lastName]) => {
                if (lastName) return `${cleanInputForID(firstName)}${cleanInputForID(lastName)}`;
                else return `${cleanInputForID(firstName)}`;
            }),
            tap((id) => this.form.patchValue({ id })),
            switchMap((id) => ensureIdUnique(id, (id) => this.checkId(id)))
        ).subscribe((id) => {
            if (!this.form.controls.id.dirty) this.form.patchValue({ id });
        });

        this.identityAuth.providerFirstName$.subscribe((firstName) => {
            if (firstName && namePattern.test(firstName)) {
                this.form.controls.firstName.patchValue(firstName);
                this.form.controls.firstName.markAsDirty();
                this.form.controls.firstName.updateValueAndValidity();
                this.form.controls.firstName.disable();
            }
        });
        this.identityAuth.providerLastName$.subscribe((lastName) => {
            if (lastName && namePattern.test(lastName)) {
                this.form.controls.lastName.patchValue(lastName);
                this.form.controls.firstName.markAsDirty();
                this.form.controls.lastName.updateValueAndValidity();
                this.form.controls.lastName.disable();
            }
        });
        this.identityAuth.providerUserID$.subscribe((userID) => {
            this.form.controls.id.patchValue(userID);
            this.form.controls.id.updateValueAndValidity();
        });
    }

    checkId(id: string) {
        return this.userApi.checkUserId(id).pipe(map(({ exists }) => exists))
    }

    onSubmit() {
        const [id, firstName, lastName] = [this.form.value.id!, this.form.getRawValue().firstName, this.form.getRawValue().lastName];
        this.userApi.createUser({ id, firstName, lastName }).pipe(
            switchMap(() => {
                return this.formService.submit(this.formService.signupFormID, {
                    email: this.identityAuth.currentUser!.email!,
                    cloud_user_id: id,
                    first_name: firstName,
                    last_name: lastName,
                });
            }),
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
        ).subscribe({
            next: () => {
                this.isSubmitting$.next(false);
                this.analytics.google.reportAdConversion("createProfile");
                this.done.emit();
            },
            error: () => {
                this.isSubmitting$.next(false);
            }
        });
    }
}
