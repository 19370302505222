/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    SpaceCtlProtoAssignSquadReq,
    SpaceCtlProtoAssignUserReq,
    SpaceCtlProtoCreateSpaceReq,
    SpaceCtlProtoDeleteSpaceReq,
    SpaceCtlProtoGetAccessLevelsReq,
    SpaceCtlProtoGetSpaceReq, SpaceCtlProtoListAPITokensReq,
    SpaceCtlProtoListClustersReq,
    SpaceCtlProtoListSquadsReq,
    SpaceCtlProtoListUsersReq,
    SpaceCtlProtoReq, SpaceCtlProtoSetSquadAccessLevelReq,
    SpaceCtlProtoSetUserAccessLevelReq,
    SpaceCtlProtoUnassignSquadReq,
    SpaceCtlProtoUnassignUserReq,
    SpaceCtlProtoUpdateSpaceReq
} from "../../../application/protocol/space-controller";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { Cluster, partialClusterOf } from "../../concept/cluster";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import { partialSpaceOf, Space, spaceProtoOf } from "../../concept/space";
import { partialSpaceSquadAssignmentOf, SpaceSquadAssignment } from "../../concept/squad";
import { partialSpaceUserAssignmentOf, SpaceUserAssignment } from "../../concept/user";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { partialSpaceAPITokenOf, SpaceAPIToken } from "../../concept/api-token";

@Injectable({
    providedIn: "root",
})
export class SpaceApi {
    constructor(private api: ApiService) {
    }

    getAccessLevels(spaceUuids: string[]): Observable<AccessLevel[]> {
        return this.api.spaceReqRes(new SpaceCtlProtoReq({ getAccessLevels: new SpaceCtlProtoGetAccessLevelsReq({ spaceUuids: spaceUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createSpace(props: { space: Partial<Space>, teamUuid: string }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            createSpace: new SpaceCtlProtoCreateSpaceReq({ space: spaceProtoOf(props.space), teamUuid: stringToBytes(props.teamUuid) })
        }));
    }

    getSpace(props: { spaceId: string, teamUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Space>> {
        return this.api.spaceReqSub(new SpaceCtlProtoReq(
            { getSpace: new SpaceCtlProtoGetSpaceReq({ spaceId: props.spaceId, teamUuid: stringToBytes(props.teamUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialSpaceOf(res.data.getSpace.space)))
        );
    }

    updateSpace(space: PartialWithUuid<Space>): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({ updateSpace: new SpaceCtlProtoUpdateSpaceReq({ space: spaceProtoOf(space) }) }));
    }

    deleteSpace(spaceUuid: string): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({ deleteSpace: new SpaceCtlProtoDeleteSpaceReq({ spaceUuid: stringToBytes(spaceUuid) }) }));
    }

    listClusters(spaceUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Cluster>> {
        return this.api.spaceReqSub(new SpaceCtlProtoReq(
            { listClusters: new SpaceCtlProtoListClustersReq({ spaceUuid: stringToBytes(spaceUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listClusters.clusters.map(partialClusterOf))));
    }

    listUsers(spaceUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<SpaceUserAssignment>> {
        return this.api.spaceReqSub(new SpaceCtlProtoReq(
            { listUsers: new SpaceCtlProtoListUsersReq({ spaceUuid: stringToBytes(spaceUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listUsers.users.map(partialSpaceUserAssignmentOf))));
    }

    listAPITokens(spaceUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<SpaceAPIToken>> {
        return this.api.spaceReqSub(new SpaceCtlProtoReq({
            listApiTokens: new SpaceCtlProtoListAPITokensReq({ spaceUuid: stringToBytes(spaceUuid), params: parametersProtoOf(params) })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listApiTokens.apiTokens.map(partialSpaceAPITokenOf))));
    }

    assignUser(props: { spaceUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            assignUser: new SpaceCtlProtoAssignUserReq({
                spaceUuid: stringToBytes(props.spaceUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    unassignUser(props: { spaceUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            unassignUser: new SpaceCtlProtoUnassignUserReq(
                { spaceUuid: stringToBytes(props.spaceUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setUserAccessLevel(props: { spaceUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            setUserAccessLevel: new SpaceCtlProtoSetUserAccessLevelReq({
                spaceUuid: stringToBytes(props.spaceUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    listSquads(spaceUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<SpaceSquadAssignment>> {
        return this.api.spaceReqSub(new SpaceCtlProtoReq(
            { listSquads: new SpaceCtlProtoListSquadsReq({ spaceUuid: stringToBytes(spaceUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSquads.squads.map(partialSpaceSquadAssignmentOf))));
    }

    assignSquad(props: { spaceUuid: string, squadUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            assignSquad: new SpaceCtlProtoAssignSquadReq({
                spaceUuid: stringToBytes(props.spaceUuid),
                squadUuid: stringToBytes(props.squadUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    unassignSquad(props: { spaceUuid: string, squadUuid: string }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            unassignSquad: new SpaceCtlProtoUnassignSquadReq(
                { spaceUuid: stringToBytes(props.spaceUuid), squadUuid: stringToBytes(props.squadUuid) }
            )
        }));
    }

    setSquadAccessLevel(props: { spaceUuid: string, squadUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.spaceReq(new SpaceCtlProtoReq({
            setSquadAccessLevel: new SpaceCtlProtoSetSquadAccessLevelReq({
                spaceUuid: stringToBytes(props.spaceUuid),
                squadUuid: stringToBytes(props.squadUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }
}
