/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { BaseConcept, PartialWithUuid, Property } from "./base";
import {
    APITokenProto,
    TeamAPITokenProto,
    SpaceAPITokenProto,
} from "../../protocol/concept";
import { bytesToString, stripUndefinedValues } from "../util";
import { AccessLevel, accessLevelOf } from "./iam";


export interface APIToken extends BaseConcept {
    name: string;
    id: string;
    createdAt: Date;
}

export interface TeamAPIToken extends APIToken {
    spaceId: string;
    accessLevel: AccessLevel;
}

export interface SpaceAPIToken extends APIToken {
    accessLevel: AccessLevel;
}

export function partialApiTokenOf(apiTokenProto: APITokenProto): PartialWithUuid<APIToken> {
    return {
        uuid: bytesToString(apiTokenProto.uuid),
        name: apiTokenProto.hasName ? apiTokenProto.name : undefined,
        id: apiTokenProto.hasId ? apiTokenProto.id : undefined,
        createdAt: apiTokenProto.hasCreatedAt ? new Date(apiTokenProto.createdAt) : undefined
    };
}

export function partialTeamAPITokenOf(data: TeamAPITokenProto) {
    const teamAPIToken = {
        ...partialApiTokenOf(data.apiToken),
        spaceId: data.hasSpaceAccess ? data.spaceAccess.spaceId : undefined,
        accessLevel: data.hasSpaceAccess ? accessLevelOf(data.spaceAccess.accessLevel) : undefined,
    }
    return teamAPIToken as PartialWithUuid<TeamAPIToken>
}

export function partialSpaceAPITokenOf(data: SpaceAPITokenProto): PartialWithUuid<SpaceAPIToken> {
    return stripUndefinedValues({
        ...partialApiTokenOf(data.apiToken),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    }) as PartialWithUuid<SpaceAPIToken>;
}

type BaseAPITokenColumn = "name" | "id" | "createdAt";
export type APITokenColumn = BaseAPITokenColumn | "accessLevel"| "space";

export const apiTokenProperties: Record<BaseAPITokenColumn, Property> = {
    name: { id: "name", name: "Name", attributeType: "name" },
    id: { id: "id", name: "Client ID", attributeType: "id" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const teamAPITokenProperties: Record<APITokenColumn, Property> = {
    ...apiTokenProperties,
    space: { id: "space", name: "Space", ownerType: "space", attributeType: "id" },
    accessLevel: { id: "accessLevel", name: "Space Access Level", ownerType: "assignment", attributeType: "access-level" },
};

export const spaceAPITokenProperties = {
    ...apiTokenProperties,
    accessLevel: { id: "accessLevel", name: "Space Access Level", ownerType: "assignment", attributeType: "access-level" },
};

const apiTokenPropertiesList = [apiTokenProperties.name, apiTokenProperties.id];
export const teamAPITokenPropertiesList = [...apiTokenPropertiesList, teamAPITokenProperties.space, teamAPITokenProperties.accessLevel, teamAPITokenProperties.createdAt];
export const spaceAPITokenPropertiesList = [...apiTokenPropertiesList, spaceAPITokenProperties.accessLevel!!, spaceAPITokenProperties.createdAt];
