/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import {
    Cluster,
    ClusterColumn,
    clusterProperties,
    clusterPropertiesList,
    clusterStatusFromProtoMap
} from "../../concept/cluster";
import { AccessLevel, accessLevelValues, hasWriteAccess } from "../../concept/iam";
import {
    Invitation,
    InvitationColumn,
    invitationProperties,
    invitationPropertiesList,
    Team
} from "../../concept/team";
import {
    Space,
    SpaceColumn,
    spaceProperties,
    spacePropertiesList
} from "../../concept/space";
import { Squad, SquadColumn, squadProperties, squadPropertiesList } from "../../concept/squad";
import {
    TeamMember,
    teamMemberProperties,
    teamMemberPropertiesList,
    User,
    UserColumn,
    userProperties,
} from "../../concept/user";
import { dateFilterSpec, longFilterSpec, stringFilterSpec } from "../../framework/table";
import {
    clusterDetailsPath,
    memberDetailsPath,
    spaceDetailsPath,
    squadDetailsPath
} from "../../routing/resource-paths";
import { AuthorizationService } from "../authorization.service";
import { DEFAULT_INITIAL_PARAMS, ResourceTable, SpecialColumn } from "../resource-table.service";
import { TeamApi } from "./team-api.service";
import { first, map, Observable, of, Subject, switchMap } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { ApplicationState } from "../application-state.service";
import { Property } from "../../concept/base";
import {
    APIToken,
    APITokenColumn,
    apiTokenProperties,
    TeamAPIToken,
    teamAPITokenProperties,
    teamAPITokenPropertiesList
} from "../../concept/api-token";

@Injectable({
    providedIn: "root",
})
export class TeamController {

    constructor(private api: TeamApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    clustersTable(unsub$: Subject<void>): ResourceTable<Cluster, ClusterColumn> {
        return new TeamClustersTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$);
    }

    spacesTable(unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS): ResourceTable<Space, SpaceColumn> {
        return new TeamSpacesTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$, initialParams);
    }

    membersTable(unsub$: Subject<void>): BaseMembersTable<TeamMember, UserColumn> {
        return new TeamMembersTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$);
    }

    invitationsTable(unsub$: Subject<void>): ResourceTable<Invitation, InvitationColumn> {
        return new TeamInvitationsTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$);
    }

    squadsTable(unsub$: Subject<void>): ResourceTable<Squad, SquadColumn> {
        return new TeamSquadsTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$);
    }

    apiTokensTable(unsub$: Subject<void>): ResourceTable<TeamAPIToken, APITokenColumn> {
        return new TeamAPITokensTable(this.app.requireCurrentTeam(), this.api, this.authorization, unsub$);
    }

    listWritableSquadsSnapshot(): Observable<Squad[]> {
        return this.api.listSquads(
            this.app.requireCurrentTeam().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined),
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((squads) => {
                return this.authorization.squadAccessLevels(squads.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => squads[x])),
                )
            })
        )
    }

    listWritableSpacesSnapshot(): Observable<Space[]> {
        return this.api.listSpaces(
            this.app.requireCurrentTeam().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined)
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((spaces) => {
                return this.authorization.spaceAccessLevels(spaces.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => spaces[x])),
                );
            }),
        );
    }
}

export class TeamClustersTable extends ResourceTable<Cluster, ClusterColumn> {
    override readonly properties = clusterPropertiesList;
    override readonly primaryProperty = clusterProperties.id;
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "typeDBVersion", "createdAt"].includes(x.id))];
    override readonly filterSpecs = [
        stringFilterSpec(clusterProperties.id),
        stringFilterSpec(clusterProperties.space),
        stringFilterSpec(clusterProperties.provider),
        stringFilterSpec(clusterProperties.region),
        stringFilterSpec(clusterProperties.machineType),
        longFilterSpec(clusterProperties.clusterSize),
        stringFilterSpec(clusterProperties.storageType),
        longFilterSpec(clusterProperties.storageSize),
        stringFilterSpec(clusterProperties.clusterStatus, Object.values(clusterStatusFromProtoMap)),
        stringFilterSpec(clusterProperties.typeDBVersion),
    ];

    constructor(private team: Team, private teamApi: TeamApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Cluster>> {
        return this.teamApi.listClusters(this.team.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Cluster[]): Observable<AccessLevel[]> {
        return this.authorization.clusterAccessLevels(resources.map(x => x.uuid));
    }

    override get displayedColumns(): ("select" | "actions" | "status" | ClusterColumn)[] {
        return ["select", ...this.displayedProperties.map(x => x.id as ClusterColumn), "status", "actions"];
    }

    override itemRoute(cluster: Cluster): string {
        return clusterDetailsPath(cluster, this.team);
    }

    override noAccessMessage(cluster: Cluster): string {
        return `You don't have access to the cluster '${cluster.id}'`;
    }
}

export abstract class BaseSpacesTable<ENTITY extends Space> extends ResourceTable<ENTITY, SpaceColumn> {
    override readonly primaryProperty = spaceProperties.id;

    protected constructor(
        protected team: Team, private authorization: AuthorizationService, unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS
    ) {
        super(unsub$, initialParams);
    }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.spaceAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(space: ENTITY): string {
        return spaceDetailsPath(space, this.team);
    }

    override noAccessMessage(space: ENTITY): string {
        return `You don't have access to the space '${space.id}'`;
    }
}

export class TeamSpacesTable extends BaseSpacesTable<Space> {
    override readonly properties = spacePropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(spaceProperties.id), stringFilterSpec(spaceProperties.name)];

    constructor(protected override team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS) {
        super(team, authorization, unsub$, initialParams);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Space>> {
        return this.teamApi.listSpaces(this.team.uuid, params, this.unsub$);
    }
}

export abstract class BaseMembersTable<ENTITY extends User, COLUMN extends string> extends ResourceTable<ENTITY, COLUMN> {
    override readonly primaryProperty = userProperties.id;

    protected constructor(protected team: Team, private authorization: AuthorizationService, unsub$: Subject<void>) { super(unsub$); }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.userAccessLevels(resources.map(x => x.uuid));
    }

    override noAccessMessage(user: ENTITY): string {
        return `You don't have permissions to manage user '${user.id}'`;
    }

    override itemRoute(user: ENTITY): string {
        return memberDetailsPath(user, this.team);
    }
}

export class TeamMembersTable extends BaseMembersTable<TeamMember, UserColumn> {
    override readonly properties = teamMemberPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(teamMemberProperties.id),
        stringFilterSpec(teamMemberProperties.email),
        stringFilterSpec(teamMemberProperties.firstName),
        stringFilterSpec(teamMemberProperties.lastName),
        stringFilterSpec(teamMemberProperties.accessLevel, accessLevelValues)
    ];

    constructor(protected override team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
        // TODO
        // this.items$.pipe(
        //     switchMap((members) => {
        //         const teamAccessLevels = this.authorization.
        //     }),
        // ).subscribe();
    }

    override getData(params: Parameters): Observable<ApiListResponse<TeamMember>> {
        return this.teamApi.listMembers(this.team.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (SpecialColumn | UserColumn)[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id), "actions"] as (SpecialColumn | UserColumn)[];
    }
}

export abstract class BaseSquadsTable<ENTITY extends Squad, COLUMN extends string> extends ResourceTable<ENTITY, COLUMN> {
    override readonly primaryProperty = squadProperties.id;

    protected constructor(protected team: Team, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.squadAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(squad: ENTITY): string {
        return squadDetailsPath(squad, this.team);
    }

    override noAccessMessage(squad: ENTITY): string {
        return `You don't have access to the squad '${squad.id}'`;
    }
}

export class TeamSquadsTable extends BaseSquadsTable<Squad, SquadColumn> {
    override readonly properties = squadPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(squadProperties.id), stringFilterSpec(squadProperties.name)];

    constructor(protected override team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Squad>> {
        return this.teamApi.listSquads(this.team.uuid, params, this.unsub$);
    }
}

export class TeamInvitationsTable extends ResourceTable<Invitation, InvitationColumn> {
    override readonly properties;
    override readonly primaryProperty = invitationProperties.userEmail;
    override displayedProperties: Property[];
    override readonly filterSpecs = [
        stringFilterSpec(invitationProperties.userEmail),
        stringFilterSpec(invitationProperties.teamAccessLevel, accessLevelValues)
    ];

    constructor(protected team: Team, private teamApi: TeamApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "created-at", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
        const properties = [...invitationPropertiesList];
        properties.remove(invitationProperties.teamId);
        properties.remove(invitationProperties.teamName);
        this.displayedProperties = [...properties];
        this.properties = properties;
    }

    override getData(params: Parameters): Observable<ApiListResponse<Invitation>> {
        return this.teamApi.listInvitations(this.team.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Invitation[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels(resources.map(x => x.team.uuid));
    }

    override get displayedColumns(): ("actions" | InvitationColumn)[] {
        return [...this.displayedProperties.map(x => x.id as InvitationColumn), "actions"];
    }

    override itemRoute(): string {
        return ``;
    }

    override noAccessMessage(): string {
        return ``;
    }
}


export abstract class BaseAPITokensTable<ENTITY extends APIToken, COLUMN extends string> extends ResourceTable<ENTITY, COLUMN> {
    override readonly primaryProperty = apiTokenProperties.id;

    protected constructor(protected team: Team, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "name", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
    }

    override getAccessLevels(resources: APIToken[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels([this.team.uuid]).pipe(
            map((accessLevels) => resources.map(_ => accessLevels[0]))
        );
    }

    override noAccessMessage(apiToken: ENTITY): string {
        return `You don't have permissions to manage API token '${apiToken.name}'`;
    }

    override itemRoute(): string {
        return ``;
    }
}

class TeamAPITokensTable extends BaseAPITokensTable<TeamAPIToken, APITokenColumn> {
    override readonly properties = teamAPITokenPropertiesList.filter(x => x.id !== invitationProperties.userEmail.id);
    override displayedProperties: Property[] = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(teamAPITokenProperties.name),
        stringFilterSpec(teamAPITokenProperties.id),
        stringFilterSpec(teamAPITokenProperties.accessLevel, accessLevelValues)
    ];

    constructor(protected override team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<TeamAPIToken>> {
        return this.teamApi.listAPITokens(this.team.uuid, params, this.unsub$);
    }

    override get displayedColumns(): ("actions" | APITokenColumn)[] {
        return [...this.displayedProperties.map(x => x.id as APITokenColumn), "actions"];
    }
}
