<h3>Guides</h3>
<div class="sidebar-section">
  <a class="sidebar-link-content" href="https://typedb.com/docs/manual/connect/cloud" target="_blank" id="learnManualConnecting">
    <div class="sidebar-link-title">
      <p>Connecting to TypeDB</p>
      <i class="fa-light fa-arrow-up-right"></i>
  </div>
    <p class="sidebar-link-description">Follow our simple guide to get connected.</p>
  </a>
  <a class="sidebar-link-content" href="https://typedb.com/docs/home/crash-course" target="_blank" id="learnCrashCourse">
    <div class="sidebar-link-title">
      <p>Crash Course</p>
      <i class="fa-light fa-arrow-up-right"></i>
  </div>
    <p class="sidebar-link-description">Learn more about TypeDB through our crash course.</p>
  </a>
</div>
<hr/>
<h3>Reference</h3>
<div class="sidebar-section">
  <a class="sidebar-link-content" href="https://typedb.com/docs/manual/" target="_blank" id="learnManual">
    <div class="sidebar-link-title">
      <p>Database Manual</p>
      <i class="fa-light fa-arrow-up-right"></i>
    </div>
    <p class="sidebar-link-description">Learn how to operate TypeDB.</p>
  </a>
  <a class="sidebar-link-content" href="https://typedb.com/docs/drivers/" target="_blank" id="learnDrivers">
    <div class="sidebar-link-title">
      <p>Drivers</p>
      <i class="fa-light fa-arrow-up-right"></i>
  </div>
    <p class="sidebar-link-description">Language drivers to connect to TypeDB programmatically.</p>
  </a>
  <a class="sidebar-link-content" href="https://typedb.com/docs/typeql/" target="_blank" id="learnTypeQL">
    <div class="sidebar-link-title">
      <p>TypeQL</p>
      <i class="fa-light fa-arrow-up-right"></i>
    </div>
    <p class="sidebar-link-description">Reference guide for our query language, TypeQL.</p>
  </a>
</div>
<div class="flex-spacer"></div>
<a target="_blank" href="https://typedb.com/discord">
  <tp-button buttonStyle="primary-outline white stroke" buttonId="sidebar_discord">
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="discord">
      <path d="M12.4357 14.6C11.1897 14.8255 9.91039 14.8209 8.66614 14.5864C8.44823 14.5456 8.13975 14.4735 7.74213 14.3715L6.4389 16C3.22257 15.9007 2 13.8286 2 13.8286C2 9.22871 4.09564 5.49953 4.09564 5.49953C6.19128 3.95806 8.18503 4.00024 8.18503 4.00024L8.75529 4.71451C8.80784 4.70742 8.86074 4.70288 8.91377 4.7009C10.0008 4.56236 11.1024 4.56694 12.1881 4.71451L12.8136 4.00024C12.8136 4.00024 14.8087 3.95806 16.9029 5.50089C16.9029 5.50089 19 9.22871 19 13.8286C19 13.8286 17.7619 15.8993 14.5455 16L13.1206 14.4572C12.8937 14.5117 12.6653 14.5589 12.4357 14.6Z" stroke="currentColor"/>
      <path d="M8.00039 9C7.44162 9 7 9.45 7 10C7 10.55 7.45092 11 8.00039 11C8.55823 11 8.99986 10.55 8.99986 10C9.00916 9.45 8.55916 9 8.00039 9Z" fill="currentColor"/>
      <path d="M12.9995 9C12.4417 9 12 9.45 12 10C12 10.55 12.451 11 12.9995 11C13.5583 11 14 10.55 14 10C14 9.45 13.5583 9 12.9995 9Z" fill="currentColor"/>
    </svg>
    <span>Join our Discord server</span>
  </tp-button>
</a>
<tp-button buttonStyle="primary-outline white stroke" buttonId="sidebar_support">
  <i class="fa-light fa-wand-magic-sparkles"></i>
  <span>Ask TypeDB AI</span>
</tp-button>
