/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { ButtonComponent,
    CodeSnippetComponent, ScrollContainerComponent,
    LinkPanelComponent, ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent, SpinnerComponent, FormPasswordInputComponent, requiredValidator, FormComponent, FormActionsComponent, ModalCloseButtonComponent
} from "typedb-platform-framework";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { SnackbarService } from "../../../../service/snackbar.service";
import { Cluster, ClusterStatus, Server } from "../../../../concept/cluster";
import { Observable, Subject, combineLatest, filter, first, map, shareReplay, startWith } from "rxjs";
import { MatSelectModule } from "@angular/material/select";
import { SidebarLinkComponent } from "../../../scaffold/sidebar/link/sidebar-link.component";
import { MatStepperModule } from "@angular/material/stepper";
import { ClusterStatusPipe } from "../../cluster-status.pipe";
import { LoadingTip, loadingTips2, loadingTips3 } from "./loading-tips";
import { FormBuilder } from "@angular/forms";
import { ClusterApi } from "../../../../service/cluster/cluster-api.service";
import { cryptoRandomAlphanumeric } from "../../../../util";

export type ConnectDialogData = {
    cluster$: Observable<Cluster>,
    servers$: Observable<Server[]>,
    password: string | undefined,
    updatePassword: (newPassword: string | undefined) => void
}

enum ConnectionMethod {
    STUDIO,
    DRIVERS,
    CONSOLE,
}

enum IntroStage {
    SETUP_CLUSTER = 0,
    SELECT_CONNECTION_METHOD = 1,
    CONNECTION_INSTRUCTIONS = 2,
    NEXT_STEPS = 3,
}

enum OperatingSystem {
    MAC_X86_64 = "mac-x86_64",
    MAC_ARM64 = "mac-arm64",
    LINUX_X86_64 = "linux-x86_64",
    LINUX_ARM64 = "linux-arm64",
    WINDOWS_X86_64 = "windows-x86_64",
}

function detectOS(): OperatingSystem {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('win')) {
        return OperatingSystem.WINDOWS_X86_64;
    } else if (userAgent.includes('mac')) {
        return OperatingSystem.MAC_X86_64;
    } else if (userAgent.includes('linux')) {
        return OperatingSystem.LINUX_X86_64;
    }

    return OperatingSystem.MAC_X86_64;
}

const modalTitles: Record<IntroStage, string> = {
    [IntroStage.SETUP_CLUSTER]: "Setup TypeDB cluster",
    [IntroStage.SELECT_CONNECTION_METHOD]: "Select connection method",
    [IntroStage.CONNECTION_INSTRUCTIONS]: "Connect to your cluster",
    [IntroStage.NEXT_STEPS]: "Next steps",
}

const PASSWORD_FILLER = "db_password"

@Component({
    selector: "tp-cluster-connect-dialog",
    templateUrl: "./cluster-connect-dialog.component.html",
    styleUrls: ["./cluster-connect-dialog.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, MatTooltipModule, MatButtonModule, SpinnerComponent,
        PropertiesTableComponent, PropertiesTableRowComponent, MatSelectModule, ButtonComponent, NgTemplateOutlet,
        LinkPanelComponent, CodeSnippetComponent, SidebarLinkComponent, ScrollContainerComponent, MatStepperModule, ClusterStatusPipe,
        MatDialogContent, MatDialogTitle, FormPasswordInputComponent, FormComponent, FormActionsComponent, ModalCloseButtonComponent,
    ],
})
export class ClusterConnectDialogComponent {
    serverAddresses$ = combineLatest([this.data.servers$, this.data.cluster$]).pipe(
        filter(([servers, cluster]) => servers.every((server) => !!server.address) && servers.length == cluster.serverCount),
        map(([servers, _]) => servers.map((server) => server.address)),
        shareReplay(1),
    );
    clusterStatus$ = this.data.cluster$.pipe(map((cluster) => cluster.status));
    private loadingStatuses: ClusterStatus[] = ["starting", "resuming"];
    loading$ = this.clusterStatus$.pipe(map((status) => this.loadingStatuses.includes(status)));
    loadingInfo$: Observable<{ icon: string, loadingText: string } | undefined> = this.clusterStatus$.pipe(
        filter((status) => this.loadingStatuses.includes(status)),
        map((status) => {
            if (status == "starting" || status == "resuming") {
                return { icon: "fa-circle-nodes", loadingText: "Starting servers" };
            } else {
                return undefined;
            }
        })
    );
    ready$ = this.clusterStatus$.pipe(map((status) => status == "running"));
    errored$ = this.clusterStatus$.pipe(map((status) => status == "error"));
    isTypeDBVersion2$ = this.data.cluster$.pipe(map(cluster => cluster?.typeDBVersion.version.startsWith("2")))

    docsVersionSpecifier$ = this.isTypeDBVersion2$.pipe(map((isVersion2) => isVersion2 ? "2.x/" : ""));
    driverDocsLink$ = this.docsVersionSpecifier$.pipe(map((specifier) => `https://typedb.com/docs/drivers/${specifier}`));
    consoleInstallDocsLink$ = this.docsVersionSpecifier$.pipe(map((specifier) => `https://typedb.com/docs/home/${specifier}install-tools#_console`));
    studioInstallDocsLink$ = this.docsVersionSpecifier$.pipe(map((specifier) => `https://typedb.com/docs/home/${specifier}install-tools#_studio`));
    quickstartDocsLink$ = this.docsVersionSpecifier$.pipe(map((specifier) => `https://typedb.com/docs/home/${specifier}quickstart`));
    typeqlDocsLink$ = this.docsVersionSpecifier$.pipe(map((specifier) => `https://typedb.com/docs/typeql/${specifier}`));
    operatingSystem: OperatingSystem = detectOS();
    private studioLinkStart = `https://repo.typedb.com/public/public-release/raw/names/typedb-studio-${this.operatingSystem}/versions`;
    studioDownloadLink$ = this.isTypeDBVersion2$.pipe(map((isVersion2) =>
        isVersion2 ?
            `${this.studioLinkStart}/2.28.6/typedb-studio-${this.operatingSystem}-2.28.6.dmg` :
            `${this.studioLinkStart}/latest/download`
    ))

    connectionCommand$ = this.serverAddresses$.pipe(
        map((addresses) =>
            `./typedb console ${addresses.map((x) => `--cloud=${x}`).join(" ")} --username=admin --password=${this.password ?? `&lt;${PASSWORD_FILLER}&gt;`} --tls-enabled`
        ),
        shareReplay(1),
    );

    connectionStringSnippet$ = this.serverAddresses$.pipe(
        map((addresses) =>
            ({ code: `typedb-cloud://admin:${this.password ? encodeURIComponent(this.password) : `&lt;${PASSWORD_FILLER}&gt;`}@${addresses.join(",")}/?tlsEnabled=true`})
        ),
        shareReplay(1),
    );

    passwordForm = this.formBuilder.nonNullable.group({
        password: ["", [requiredValidator]]
    });
    isPasswordFormSubmitting$ = new Subject<boolean>();
    isPasswordCopied = false;
    password: string | undefined = undefined;

    connectionMethod: ConnectionMethod = ConnectionMethod.STUDIO;
    stage: IntroStage | undefined;
    loadingTips$: Observable<LoadingTip[]> = this.isTypeDBVersion2$.pipe(
        map((isVersion2) => {
            if (isVersion2) {
                return loadingTips2.sort(() => Math.random() - Math.random());
            } else {
                return loadingTips3.sort(() => Math.random() - Math.random());
            }
        }),
        startWith([]),
        shareReplay(1)
    )
    loadingTipIndex = 0;
    nextTip(el: HTMLElement) {
        this.loadingTips$.subscribe((tips) => {
            this.loadingTipIndex = (this.loadingTipIndex + 1) % tips.length;
            setTimeout(() => el.scrollIntoView());
        });
    }
    prevTip(el: HTMLElement) {
        this.loadingTips$.subscribe((tips) => {
            this.loadingTipIndex = (this.loadingTipIndex - 1 + tips.length) % tips.length;
            setTimeout(() => el.scrollIntoView());
        });
    }

    constructor(
        private dialogRef: MatDialogRef<ClusterConnectDialogComponent>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ConnectDialogData, private snackbar: SnackbarService,
        private clusterApi: ClusterApi,
    ) {
        this.data.cluster$.pipe(first()).subscribe((x) => {
            if (!x.isAdminUserInitialized) this.stage = IntroStage.SETUP_CLUSTER
            else {
                this.stage = IntroStage.SELECT_CONNECTION_METHOD;
                this.password = this.data.password;
            }
        });

        if (!this.data.password) {
            this.generatePassword();
        } else {
            this.passwordForm.patchValue({ password: this.data.password });
        }

        this.passwordForm.controls.password.valueChanges.subscribe(() => this.isPasswordCopied = false);

        this.dialogRef.beforeClosed().subscribe(() => this.data.updatePassword(this.password ?? this.passwordForm.value.password))
    }

    selectConnectionMethod(connectionMethod: ConnectionMethod) {
        this.connectionMethod = connectionMethod;
        this.stage = IntroStage.CONNECTION_INSTRUCTIONS;
    }

    copyPassword() {
        if (!this.passwordForm.value.password) return;
        this.copy(this.passwordForm.value.password);
        this.isPasswordCopied = true;
    }

    copy(toCopy: string) {
        navigator.clipboard.writeText(toCopy);
        this.snackbar.success("Copied", { duration: 1250 });
    }

    close() {
        this.dialogRef.close();
    }

    back() {
        if (this.stage) this.stage--;
    }

    next() {
        if (this.stage) this.stage++;
    }

    generatePassword() {
        this.passwordForm.patchValue({ password: cryptoRandomAlphanumeric(16) });
    }

    submitPasswordForm() {
        this.passwordForm.markAllAsTouched();
        if (this.passwordForm.invalid) return;
        this.passwordForm.disable();
        this.isPasswordFormSubmitting$.next(true);
        this.data.cluster$.pipe(first()).subscribe((cluster) => {
            this.password = this.passwordForm.value.password!;
            this.clusterApi.setupCluster({ clusterUuid: cluster.uuid, password: this.password }).subscribe({
                next: () => {
                    this.stage = IntroStage.SELECT_CONNECTION_METHOD;
                    this.isPasswordFormSubmitting$.next(false);
                },
                error: () => {
                    this.password = undefined;
                    this.isPasswordFormSubmitting$.next(false);
                    this.passwordForm.enable();
                }
            });
        });
    }

    modalTitle(stage: IntroStage | undefined) {
        if (stage == undefined) return "Loading";
        else return modalTitles[stage];
    }

    protected readonly ConnectionMethod = ConnectionMethod;
    protected readonly IntroStage = IntroStage;
    protected readonly OperatingSystem = OperatingSystem;
    protected readonly PASSWORD_FILLER = PASSWORD_FILLER;
}
