<tp-page-scaffold [pageAvailability]="availability$ | async">
  <article>
    <section>
      <div class="section-title-row">
        <h2>Account Settings</h2>
        <tp-button (click)="openEditDialog()" buttonStyle="secondary stroke" style="float: right" buttonId="editProfile">
          <i class="fa-light fa-pen-to-square"></i> Edit
        </tp-button>
      </div>

      @if (user$ | async; as user) {
        <tp-properties-table>
          <tp-properties-table-row key="Username">{{ user.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Email">{{ user.email }}</tp-properties-table-row>
          <tp-properties-table-row key="First Name">{{ user.firstName }}</tp-properties-table-row>
          <tp-properties-table-row key="Last Name">{{ user.lastName }}</tp-properties-table-row>
        </tp-properties-table>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <div class="section-title-row">
        <h3>Profile Picture</h3>
      </div>

      <aside>Profile pictures are managed through <a href="https://gravatar.com" target="_blank">Gravatar</a>.</aside>
    </section>

    <section>
      <tp-table-toolbar [table]="teams" filterGroupId="teams" tableTitle="Teams" [actions]="[teamCreationButton]"/>
      <tp-teams-table [table]="teams"/>
      <tp-table-paginator [table]="teams"/>
    </section>

    <section>
      <tp-table-toolbar [table]="invitations" filterGroupId="invitations" tableTitle="Invitations"/>
      <tp-invitations-table [table]="invitations" [rowPopupMenuItems]="invitationsTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="invitations"/>
    </section>

    <tp-delete-resource-section
      title="Delete account"
      [display]="{ text: 'Once you delete your account, there is no going back. Please be certain.' }"
      buttonText="Delete account"
      (buttonPressed)="showContactSupportDialog()"
      buttonIconClass="fa-light fa-trash-can-xmark"
    />
  </article>
</tp-page-scaffold>
