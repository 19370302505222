<tp-page-scaffold [pageAvailability]="availability$ | async">
  <ng-container actionBar>
    <tp-button
      [matTooltip]="(cannotCreateReason$ | async) || ''" buttonStyle="secondary" buttonId="createSpace"
      [enabled]="(createEnabled$ | async) || false" (click)="openCreateModal()"
    >
      <i class="fa-light fa-square-plus"></i> Create
    </tp-button>
  </ng-container>
  <article>
    <section>
      <tp-table-toolbar [table]="spaces" filterGroupId="spaces" tableTitle="Spaces"/>
      <tp-spaces-table [table]="spaces" [rowPopupMenuItems]="spacesTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="spaces"/>
    </section>
  </article>
</tp-page-scaffold>
