<tp-page-scaffold [pageAvailability]="availability$ | async">
  <article>
    <section>
      @if (server$ | async; as server) {
        <div class="section-title-row">
          @if (cluster$ | async; as cluster) {
            <h2>Server {{ server.id }} ({{ cluster.id }})</h2>
          }
        </div>
        <tp-properties-table title="Server Details">
          <tp-properties-table-row key="Server ID">{{ server.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Address" class="tp-address">
            {{ server.address }}
            <button class="btn-copy" mat-icon-button aria-label="copy" (click)="copyServerAddress(); $event.stopPropagation();" matTooltip="Copy to clipboard">
              <i class="fa-light fa-copy"></i>
            </button>
          </tp-properties-table-row>
          <tp-properties-table-row key="Status">{{ server.status | serverStatus }}</tp-properties-table-row>
        </tp-properties-table>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <div style="display: flex; flex-direction: column">
        <div class="section-title-row">
          <h3>Logs</h3>
          <div class="flex-spacer"></div>
          <tp-button buttonStyle="secondary stroke" [enabled]="true" buttonId="toggleLogStream" (click)="toggleLogStream()">
            @if (toggleStreamingButtonIconClass$ | async; as clazz) {<i [class]="clazz"></i>}{{ toggleStreamingButtonText$ | async }}
          </tp-button>
          <tp-button buttonStyle="secondary stroke" [enabled]="true" buttonId="downloadLogs" (click)="openDownloadLogsDialog()">
            <i class="fa-light fa-download"></i> Download
          </tp-button>
        </div>

        @if (logs$ | async; as logs) {
          <div class="log-viewer-container">
<pre #logViewer>
<code>@for (log of logs; track log) {<span>{{ log }}</span>
}
</code>
</pre>
            <aside class="logs-status"><i [class]="(streamingStatusIconClass$ | async) + ' fa-solid fa-circle'"></i> {{ streamingStatusText$ | async }}</aside>
          </div>
        } @else {
          <tp-spinner/>
        }
      </div>
    </section>
  </article>
</tp-page-scaffold>
