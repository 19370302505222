/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { FormControl } from "@angular/forms";
import {
    isMultipleChoiceQuestion, MatchingAnswers,
    MultipleChoiceQuestion,
    QuestionCondition,
    Survey,
    SurveyQuestion,
    SurveySection
} from "typedb-web-schema/lib";

export class SurveyInstance {
    firstCompletions = new Set<string>();
    sections: SurveySectionInstance[];

    constructor(public data: Survey) {
        this.sections = data.sections.map(x => new SurveySectionInstance(x));
    }

    get isCompleted(): boolean {
        return this.sections.every(x => x.isCompleted);
    }
}

export class SurveySectionInstance {
    readonly questions: MultipleChoiceQuestionInstance[];

    constructor(public spec: SurveySection) {
        this.questions = spec.questions.map(x => questionInstanceOf(x));
    }

    get isCompleted(): boolean {
        return this.questions.every(x => x.isAnswered || this.hiddenQuestions.includes(x));
    }

    get hiddenQuestions(): MultipleChoiceQuestionInstance[] {
        let hiddenQuestions: MultipleChoiceQuestionInstance[] = []
        this.questions.forEach((question) => {
            const condition: QuestionCondition = question.spec.showHideCondition;
            if (!condition?.enabled) return;
            let conditionMatches: boolean;
            if (condition.match === "allMatch") conditionMatches = condition.matchingAnswers.every((answer) =>
                this.checkMatchingAnswers(answer) && !hiddenQuestions.some((x) => x.spec.posthogProperty === answer.question)
            );
            else conditionMatches = condition.matchingAnswers.some((answer) =>
                this.checkMatchingAnswers(answer) && !hiddenQuestions.some((x) => x.spec.posthogProperty === answer.question)
            );

            if (
                (condition.showOrHide === "hide" && conditionMatches)
                || (condition.showOrHide === "show" && !conditionMatches)
            ) hiddenQuestions.push(question);
        });

        return hiddenQuestions;
    }

    private checkMatchingAnswers(matchingAnswer: MatchingAnswers) {
        let question = this.questions.find(question => question.spec.posthogProperty === matchingAnswer.question)!;
        if (question instanceof MultipleChoiceQuestionInstance) {
            const answerOptions = question.spec.options;
            const currentAnswers = question.answers.map((answer) => answerOptions.find(opt => opt.text === answer)!.posthogProperty);
            return matchingAnswer.validAnswers.some((answer: string) => currentAnswers.includes(answer));
        }
        return false;
    }
}

export interface QuestionInstance {
    spec: SurveyQuestion;
    isAnswered: boolean;
}

function questionInstanceOf(data: SurveyQuestion): MultipleChoiceQuestionInstance {
    if (isMultipleChoiceQuestion(data)) return new MultipleChoiceQuestionInstance(data);
    else throw `Unexpected question data: ${JSON.stringify(data)}`;
}

export class MultipleChoiceQuestionInstance implements QuestionInstance {
    answers: string[] = [];
    formControl = new FormControl('');

    constructor(public spec: MultipleChoiceQuestion) {}

    get isAnswered() {
        return !!this.answers.length;
    }
}
