<tp-form class="dc-form" [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
  <div class="dc-form-container">
    <mat-stepper [class.disabled]="regionGroups.length == 0" [(selectedIndex)]="currentStep" (selectionChange)="onStepChange($event)">
      <mat-step label="Cloud" [completed]="false">
        <div class="form">
          @if (providerSectionReady) {
            <label>Provider</label>
            <div class="cards-row provider-cards-row">
              @for (provider of providers$ | async; track provider) {
                <button type="button" class="provider-card" [ngClass]="providerCardClasses(provider)" (click)="selectProvider(provider)" [disabled]="!form.enabled">
                  <div class="card-content">
                    <img [src]="providerImageUrl(provider)" [alt]="provider.name" />
                  </div>
                  <div class="card-footer">{{ provider.name }}</div>
                </button>
              }
            </div>
            @if (regionGroups.length != 0) {
              <div class="form-input-row region-input-row">
                <label>Region</label>
                <mat-form-field>
                  <mat-select class="region-mat-select" [formControl]="form.controls.region">
                    <mat-select-trigger class="region-mat-select-trigger">
                      @if (form.value.region; as region) {
                        <img [ngSrc]="regionFlagUrl(region.countryCode)" width="20" height="15" alt="" />&nbsp;<span>{{ region | region }}</span>
                      }
                    </mat-select-trigger>
                    @for (regionGroup of regionGroups; track regionGroup) {
                      <mat-optgroup [label]="regionGroup.name">
                        @for (region of regionGroup.options; track region) {
                          <mat-option [value]="region.value">
                            <img [ngSrc]="regionFlagUrl(region.value.countryCode)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
                          </mat-option>
                        }
                      </mat-optgroup>
                    }
                  </mat-select>
                  @if (form.controls.region.getError("errorText")) {
                    <mat-error>{{ form.controls.region.getError("errorText") }}</mat-error>
                  }
                </mat-form-field>
              </div>
            } @else {
              <div class="admonitionblock note">
                Want to deploy TypeDB on {{ form.controls.provider.value?.name }}? Please get in touch with us using the form below.
              </div>

              <div class="form-input-row region-input-row">
                <label>Desired region</label>
                <mat-form-field>
                  <mat-select class="region-mat-select" placeholder="Select an option" [formControl]="requestForm.controls.region" (selectionChange)="changeSelectedCloudRegion($event)">
                    <mat-select-trigger class="region-mat-select-trigger">
                      @if (requestedRegionOption; as region) {
                        <img [ngSrc]="regionFlagUrl(region.value.country.code)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
                      }
                    </mat-select-trigger>
                    @for (regionGroup of cloudRegionOptionGroups; track regionGroup) {
                      <mat-optgroup [label]="regionGroup.name">
                        @for (region of regionGroup.options; track region) {
                          <mat-option [value]="region.value">
                            <img [ngSrc]="regionFlagUrl(region.value.country.code)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
                          </mat-option>
                        }
                      </mat-optgroup>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <label>Additional information (optional)</label>
              <mat-form-field>
                <textarea rows="3" matInput [formControl]="requestForm.controls.message"></textarea>
              </mat-form-field>
              <tp-button
                [enabled]="!(requestFormSubmitting$ | async) && !!requestForm.valid"
                class="tp-availability-request-button" buttonStyle="primary-outline green stroke"
                (click)="submitRequestForm()" buttonId="requestAvailability"
              >Request availability</tp-button>
            }
          } @else {
            <tp-spinner/>
          }
        </div>
      </mat-step>
      <mat-step label="Capacity" [completed]="false">
        <div class="form">
          <div class="cards-row preset-cards-row">
            @for (preset of clusterPresets; track preset) {
              <button [disabled]="advancedConfig" type="button" class="preset-card" [ngClass]="presetCardClasses(preset.value)" (click)="selectPreset(preset.value)">
                @if (selectedPreset?.id == preset.value.id && !advancedConfig) {
                  <img class="tp-logo" src="assets/logo/typedb-bot.svg" alt="TypeDB"/>
                }
                <div class="card-content">
                  <div class="preset-card-wrapper">
                    <div class="preset-card-image">
                      <div class="preset-card-bg"></div>
                      <img src="https://cdn.sanity.io/images/xndl14mc/production/ab910f27e737370f3ed4df4007f93fc6cf57e259-122x135.svg" [alt]="preset.value.name"/>
                    </div>
                  </div>
                  <table>
                    <tr>
                      <th>Compute</th>
                      <td>{{ preset.value.machineType | machineType:"compute" }}</td>
                    </tr>
                    <tr>
                      <th>Cluster Size</th>
                      <td>{{ preset.value.serverCount }}</td>
                    </tr>
                    <tr>
                      <th>Storage</th>
                      <td>{{ storageSizeToString(preset.value.storageGB) }}</td>
                    </tr>
                    <tr>
                      <th>Price</th>
                        @if (presetCanBeFree(preset.value) && !(teamAlreadyHasFreeCluster$ | async)) {
                          <td class="free highlight tooltip" matTooltip="Free forever. One free cluster per team">
                            Free
                          </td>
                        } @else {
                          <td>{{ pricePerHourToPerHourString(preset.value.running.centsPerHour) }} per hour</td>
                        }
                    </tr>
                  </table>
                </div>
                <div class="card-footer">{{ preset.value.name }}</div>
              </button>
            }
          </div>
          <mat-slide-toggle color="primary" [checked]="advancedConfig" (change)="toggleAdvanced()">
            Enable advanced configuration
          </mat-slide-toggle>
        </div>
        @if (advancedConfig) {
          <div class="form advanced-config">
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The specs of your cluster's machines</p></div>
              <mat-form-field [title]="machineTypeFieldTitle">
                <mat-select class="machine-type-mat-select" [formControl]="form.controls.machineType">
                  <mat-select-trigger class="machine-type-mat-select-trigger" [class.machine-type-free]="form.value.machineType?.isFree">
                    @if (form.value.machineType; as machineType) {
                      <span>{{ machineType | machineType }}</span>
                    }
                  </mat-select-trigger>
                  @for (machineType of machineTypes; track machineType) {
                    <mat-option [value]="machineType.value" [class.machine-type-free]="machineType.value.isFree">
                      <span>{{ machineType.viewValue }}</span>
                    </mat-option>
                  }
                </mat-select>
                @if (form.controls.machineType.getError("errorText")) {
                  <mat-error>{{ form.controls.machineType.getError("errorText") }}</mat-error>
                }
              </mat-form-field>
            </div>
            <div class="form-input-row form-toggle-group-row">
              <div class="form-input-row-description"><p>The size of your cluster (multi-machine clusters provide high availability)</p></div>
              <tp-form-toggle-group
                [form]="form" field="serverCount" [options]="(serverCounts$ | async)!" [matTooltip]="(serverCountTooltip$ | async)!"
                [disabled]="!!(serverCountLocked$ | async)"
              />
            </div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The specs of your cluster's storage device</p></div>
              <tp-form-select [form]="form" field="storageType" [options]="storageTypes"/>
            </div>
            <div class="form-input-row form-slider-row">
              <div class="form-input-row-description"><p>Storage in gigabytes</p></div>
              <div class="form-slider-field" [matTooltip]="(storageSliderTooltip$ | async)!">
                <mat-form-field class="form-slider-field-text-box">
                  <input
                    matInput type="text" readonly
                    [value]="storageSliderIndicatorText"
                  />
                </mat-form-field>
                <mat-slider min="0" max="9" [showTickMarks]="true" [displayWith]="storageSliderValueToString" [disabled]="storageSliderLocked">
                  <input [formControl]="form.controls.storageSlider" matSliderThumb>
                </mat-slider>
              </div>
            </div>
          </div>
        }
      </mat-step>
      <mat-step label="Details" [completed]="false">
        <div class="form tp-details-form">
          <div class="form-input-row">
            <div class="form-input-row-description"><p>Your cluster's name. Must be unique within a space</p></div>
            <div class="tp-id-input">
              @if (idGenerateCount > 10) {
                @for (animalOption of animalIconOptions; track animalOption) {
                  @if (animalIcon == animalOption) {
                    <i [class]="'tp-animal fa-light ' + animalOption"></i>
                  }
                }
              }
              <tp-form-input [form]="form" field="id">
                <button inputSuffix class="regenerate-id-button" type="button" buttonId="regenerateId" (click)="generateAndFillID(); $event.stopPropagation()" [disabled]="isSubmitting$ | async">
                  <i class="fa-light fa-refresh"></i>
                </button>
              </tp-form-input>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-input-row-description"><p>The space for this cluster</p></div>
            @if (spaceOptions$ | async; as spaceOptions) {
              <tp-form-select [form]="form" field="space" [options]="spaceOptions"/>
            } @else {
              <tp-spinner/>
            }
          </div>
          <div class="form-input-row">
            <div class="form-input-row-description"><p>The TypeDB version your cluster will use</p></div>
            @if (typeDBVersions$ | async; as typeDBVersions) {
              <tp-form-select [form]="form" field="typeDBVersion" [options]="typeDBVersions"/>
            } @else {
              <tp-spinner/>
            }
          </div>
          <div class="form-input-row">
            <div class="form-input-row-description"><p>A sample dataset to pre-load into your cluster</p></div>
            @if (sampleDatasets$ | async; as sampleDatasets) {
              <mat-form-field [matTooltip]="sampleDatasets.length == 0 ? 'Sample datasets will be published soon' : ''">
                <mat-select
                  [formControl]="form.controls.sampleDataset"
                  [class.sample-dataset-none]="form.controls.sampleDataset.value === NO_SAMPLE_DATASET_VALUE"
                >
                  <mat-option class="sample-dataset-none" [value]="NO_SAMPLE_DATASET_VALUE">{{ NO_SAMPLE_DATASET_VALUE }}</mat-option>
                  @for (sampleDataset of sampleDatasets; track sampleDataset) {
                    <mat-option [value]="sampleDataset">
                      {{ sampleDataset }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            } @else {
              <tp-spinner/>
            }
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="dc-basket-bar">
    <div class="subtitle subtitle-first-of-type"><span>Cloud</span></div>
    <table>
      <tr>
        <th>Provider</th>
        <td>{{ form.value.provider ? (form.value.provider | providerId) : "--" }}</td>
      </tr>
      <tr>
        <th>Location</th>
        <td>{{ form.value.region?.locationName || "--" }}</td>
      </tr>
      <tr>
        <th>Region</th>
        <td>{{ form.value.region?.vendorId || "--" }}</td>
      </tr>
    </table>

    <div class="subtitle"><span>Capacity</span></div>
    <table>
      <tr>
        <th>Compute</th>
        <td>{{ form.value.machineType ? (form.value.machineType | machineType:"compute") : "--" }}</td>
      </tr>
      <tr>
        <th>Cluster Size</th>
        <td>{{ form.value.serverCount || "--" }}</td>
      </tr>
      <tr>
        <th>Storage</th>
        <td>{{ storageSliderCurrentValueToString }}</td>
      </tr>
    </table>
    <button type="button" class="show-more" id="showMore" (click)="checkoutShowDetailedSpecs = !checkoutShowDetailedSpecs">
      @if (checkoutShowDetailedSpecs) {
        Details <i class="fa-regular fa-chevron-up"></i>
      } @else {
        Details <i class="fa-regular fa-chevron-down"></i>
      }
    </button>
    <table>
      @if (checkoutShowDetailedSpecs) {
        <tr>
          <th>Machine Type</th>
          <td>{{ form.value.machineType?.vendorId || "--" }}</td>
        </tr>
        <tr>
          <th>Storage Speed</th>
          <td>{{ form.value.storageType?.iops || "--" }}</td>
        </tr>
        <tr>
          <th>Storage Type</th>
          <td>{{ form.value.storageType?.vendorId || "--" }}</td>
        </tr>
      }
    </table>

    <div class="subtitle"><span>Cost</span></div>
    <table>
      <tr>
        <th class="tooltip" matTooltip="Hourly rate while the cluster is running">Hourly rate</th>
        <td>
          @if (pricePerHourString$ | async; as pricePerHourString) {
            @if (basePricePerHourString$ | async; as basePricePerHourString) {
              <span class="tp-base-price">{{ basePricePerHourString }}</span>
            }
            <span [ngClass]="{ highlight: (isFree$ | async) || !!(basePricePerHourString$ | async) }">{{ pricePerHourString }}</span>
          } @else {
            <tp-spinner [size]="16"/>
          }
        </td>
      </tr>
    </table>
    <button type="button" class="show-more" id="showMonthlyEstimate" (click)="checkoutShowMonthlyEstimate = !checkoutShowMonthlyEstimate">
      @if (checkoutShowMonthlyEstimate) {
        Estimate <i class="fa-regular fa-chevron-up"></i>
      } @else {
        Estimate <i class="fa-regular fa-chevron-down"></i>
      }
    </button>
    <table>
      @if (checkoutShowMonthlyEstimate) {
        <tr>
          <th class="tooltip" matTooltip="Projected monthly bill, based on 100% cluster uptime over a 30-day period">Monthly projection</th>
          <td>
            @if (pricePerMonthString$ | async; as pricePerMonthString) {
              <span [ngClass]="{ highlight: (isFree$ | async) || !!(basePricePerHourString$ | async) }">{{ pricePerMonthString }}</span>
            } @else {
              <tp-spinner [size]="16"/>
            }
          </td>
        </tr>
      }
    </table>

    @if (currentStep == ClusterCreationSteps.CONFIGURATION) {
      @if (mustAddPaymentMethod$ | async) {
        <tp-button
          class="continue-button" buttonStyle="primary-outline green stroke"
          [enabled]="(canSubmit$ | async) || false"
          buttonId="addPaymentMethod" (click)="openPaymentDetailsDialog()">
          @if (!(canSubmit$ | async)) {<tp-spinner [size]="20"/>} @else {Add payment method}
        </tp-button>
      } @else {
        <tp-button
          class="continue-button"
          type="submit" [enabled]="(canSubmit$ | async) || false" buttonId="deploy"
          buttonStyle="primary-outline green stroke"
        >
          @if (!(canSubmit$ | async)) {<tp-spinner [size]="20"/>} @else {Deploy}
        </tp-button>
      }
    } @else {
      <tp-button [enabled]="regionGroups.length != 0" class="continue-button" buttonId="continue" buttonStyle="primary-outline green stroke" (click)="advance()">Continue</tp-button>
    }

    @if (willCreditsApply$(form.value.provider?.id, form.value.region?.id, form.value.machineType?.vendorId) | async) {
      <p class="form-additional-info">
        Your TypeDB Cloud credits will be automatically used. You won't be charged until they run out.
      </p>
    }
    @if (showAskAdminForPaymentMethodPrompt$ | async) {
      <p class="form-additional-info">
        Please ask a team administrator to add a payment method, then
        <a href="javascript:void(0);" (click)="fetchPayableProviders(true)">refresh</a> to continue.
      </p>
    }
    @if ((currentStep == ClusterCreationSteps.CONFIGURATION) && !(mustAddPaymentMethod$ | async)) {
      <p class="form-additional-info">
        By creating a cluster, you are accepting the
        <a class="tos-privacy-link" href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
        and the
        <a class="tos-privacy-link" href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
      </p>
    }
  </div>

</tp-form>

@if (currentStep == ClusterCreationSteps.CLOUD) {
  <button class="back-button" (click)="cancel()">
    <i class="fa-light fa-xmark"></i>
  </button>
} @else {
  <button class="back-button" (click)="return()">
    <i class="back-button fa-light fa-arrow-left"></i>
  </button>
}
