/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { ApiListResponse } from "../../concept/api-response";
import { Parameters } from "../../concept/common";
import { Cluster, ClusterColumn, clusterPropertiesList } from "../../concept/cluster";
import { invitationProperties, Team } from "../../concept/team";
import { Space } from "../../concept/space";
import {
    SpaceSquadAssignment,
    spaceSquadAssignmentProperties,
    spaceSquadAssignmentPropertiesList,
    SquadColumn,
} from "../../concept/squad";
import {
    SpaceUserAssignment,
    spaceUserAssignmentProperties,
    spaceUserAssignmentPropertiesList,
    UserColumn,
} from "../../concept/user";
import { ApplicationState } from "../application-state.service";
import { AuthorizationService } from "../authorization.service";
import { TeamApi } from "../team/team-api.service";
import { BaseAPITokensTable, BaseMembersTable, BaseSquadsTable, TeamClustersTable } from "../team/team-controller.service";
import { Observable, Subject } from "rxjs";
import { ResourceTable } from "../resource-table.service";
import { SpaceApi } from "./space-api.service";
import { stringFilterSpec } from "../../framework/table";
import { accessLevelValues } from "../../concept/iam";
import {
    SpaceAPIToken, APITokenColumn, spaceAPITokenProperties, spaceAPITokenPropertiesList
} from "../../concept/api-token";
import { Property } from "../../concept/base";

@Injectable({
    providedIn: "root",
})
export class SpaceController {
    constructor(private api: SpaceApi, private teamApi: TeamApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    clustersTable(space: Space, unsub$: Subject<void>): ResourceTable<Cluster, ClusterColumn> {
        return new SpaceClustersTable(this.app.requireCurrentTeam(), this.teamApi, space, this.api, this.authorization, unsub$);
    }

    membersTable(space: Space, unsub$: Subject<void>): ResourceTable<SpaceUserAssignment, UserColumn> {
        return new SpaceUsersTable(this.app.requireCurrentTeam(), space, this.api, this.authorization, unsub$);
    }

    squadsTable(space: Space, unsub$: Subject<void>): ResourceTable<SpaceSquadAssignment, SquadColumn> {
        return new SpaceSquadsTable(this.app.requireCurrentTeam(), space, this.api, this.authorization, unsub$);
    }

    apiTokensTable(space: Space, unsub$: Subject<void>): ResourceTable<SpaceAPIToken, APITokenColumn> {
        return new SpaceAPITokensTable(this.app.requireCurrentTeam(), space, this.api, this.authorization, unsub$);
    }
}

class SpaceClustersTable extends TeamClustersTable {
    override readonly properties = clusterPropertiesList.filter(x => x.id !== "space");
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "createdAt", "typeDBVersion"].includes(x.id))];

    constructor(team: Team, teamApi: TeamApi, private space: Space, private spaceApi: SpaceApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, teamApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Cluster>> {
        return this.spaceApi.listClusters(this.space.uuid, params, this.unsub$);
    }

    override get displayedColumns(): ClusterColumn[] {
        return [...this.displayedProperties.map(x => x.id)] as ClusterColumn[];
    }
}

class SpaceUsersTable extends BaseMembersTable<SpaceUserAssignment, UserColumn> {
    override properties = spaceUserAssignmentPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(spaceUserAssignmentProperties.id),
        stringFilterSpec(spaceUserAssignmentProperties.email),
        stringFilterSpec(spaceUserAssignmentProperties.firstName),
        stringFilterSpec(spaceUserAssignmentProperties.lastName),
        stringFilterSpec(spaceUserAssignmentProperties.accessLevel, accessLevelValues)
    ];

    constructor(team: Team, private space: Space, private spaceApi: SpaceApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<SpaceUserAssignment>> {
        return this.spaceApi.listUsers(this.space.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (UserColumn | "actions")[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id), "actions"] as (UserColumn | "actions")[];
    }
}

class SpaceSquadsTable extends BaseSquadsTable<SpaceSquadAssignment, SquadColumn> {
    override properties = spaceSquadAssignmentPropertiesList;
    override displayedProperties = [...this.properties];
    override filterSpecs = [
        stringFilterSpec(spaceSquadAssignmentProperties.id),
        stringFilterSpec(spaceSquadAssignmentProperties.name),
        stringFilterSpec(spaceSquadAssignmentProperties.accessLevel, accessLevelValues),
    ];

    constructor(team: Team, private space: Space, private spaceApi: SpaceApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<SpaceSquadAssignment>> {
        return this.spaceApi.listSquads(this.space.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (SquadColumn | "actions")[] {
        return [...this.displayedProperties.map(x => x.id), "actions"] as (SquadColumn | "actions")[];
    }
}

class SpaceAPITokensTable extends BaseAPITokensTable<SpaceAPIToken, APITokenColumn> {
    override readonly properties = spaceAPITokenPropertiesList.filter(x => x.id !== invitationProperties.userEmail.id);
    override displayedProperties: Property[] = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(spaceAPITokenProperties.name),
        stringFilterSpec(spaceAPITokenProperties.id),
        stringFilterSpec(spaceAPITokenProperties.accessLevel, accessLevelValues),
    ];

    constructor(protected override team: Team, private space: Space, private spaceApi: SpaceApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<SpaceAPIToken>> {
        return this.spaceApi.listAPITokens(this.space.uuid, params, this.unsub$);
    }

    override get displayedColumns(): APITokenColumn[] {
        return this.displayedProperties.map(x => x.id as APITokenColumn);
    }
}
