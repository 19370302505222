/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    TeamCtlProtoInviteMemberReq,
    TeamCtlProtoCreateTeamReq,
    TeamCtlProtoGetAccessLevelsReq,
    TeamCtlProtoGetFreeClusterCountReq,
    TeamCtlProtoGetTeamReq,
    TeamCtlProtoGetPayableClusterOptionsReq,
    TeamCtlProtoListClustersReq,
    TeamCtlProtoListMembersReq,
    TeamCtlProtoListSpacesReq,
    TeamCtlProtoListSquadsReq,
    TeamCtlProtoRemoveMemberReq,
    TeamCtlProtoCheckTeamIDReq,
    TeamCtlProtoReq,
    TeamCtlProtoUpdateTeamReq,
    TeamCtlProtoListInvitationsReq,
    TeamCtlProtoRevokeInvitationReq,
    TeamCtlProtoResendInvitationReq,
    TeamCtlProtoInviteMemberSpaceAccess,
    TeamCtlProtoInviteMemberSquadAccess,
    TeamCtlProtoGetMemberReq,
    TeamCtlProtoSetMemberAccessLevelReq,
    TeamCtlProtoRegisterAPITokenReq,
    TeamCtlProtoListAPITokensReq,
    TeamCtlProtoRevokeAPITokenReq,
} from "../../../application/protocol/team-controller";
import { Ok, PartialWithUuid } from "../../concept/base";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { Cluster, partialClusterOf } from "../../concept/cluster";
import { ProviderOptions, providerOptionsOf } from "../../concept/cluster-options";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import {
    Invitation,
    Team,
    teamProtoOf,
    partialInvitationOf,
    partialTeamOf
} from "../../concept/team";
import { partialSpaceOf, Space } from "../../concept/space";
import { partialSquadOf, Squad } from "../../concept/squad";
import { TeamMember, partialTeamMemberOf } from "../../concept/user";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { stringToBytes } from "../../util";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { ApiService } from "../backend/api.service";
import { TeamAPIToken, partialTeamAPITokenOf } from "../../concept/api-token";

@Injectable({
    providedIn: "root",
})
export class TeamApi {
    constructor(private api: ApiService) {}

    getAccessLevels(teamUuids: string[]): Observable<AccessLevel[]> {
        return this.api.teamReqRes(new TeamCtlProtoReq({ getAccessLevels: new TeamCtlProtoGetAccessLevelsReq({ teamUuids: teamUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createTeam(team: Partial<Team>): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            createTeam: new TeamCtlProtoCreateTeamReq({ team: teamProtoOf(team) })
        }));
    }

    checkTeamId(teamId: string): Observable<{ exists: boolean }> {
        return this.api.teamReqRes(new TeamCtlProtoReq({
            checkTeamId: new TeamCtlProtoCheckTeamIDReq({ id: teamId })
        })).pipe(map((res) => ({ exists: res.checkTeamId.exists })));
    }

    getTeam(teamId: string, unsub$: UnsubListener): Observable<ApiResponse<Team>> {
        return this.api.teamReqSub(new TeamCtlProtoReq({ getTeam: new TeamCtlProtoGetTeamReq({ teamId }) }), unsub$)
            .pipe(map((res) => responseOf(res.type, partialTeamOf(res.data.getTeam.team))));
    }

    updateTeam(team: PartialWithUuid<Team>): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({ updateTeam: new TeamCtlProtoUpdateTeamReq({ team: teamProtoOf(team) }) }));
    }

    // TODO
    // deleteTeam(teamUuid: string): Observable<Ok> {
    //     return this.api.teamReq(new TeamCtlProtoReq({ deleteTeam: new TeamCtlProtoDeleteTeamReq({ teamUuid: stringToBytes(teamUuid) }) }))
    // }

    getMember(userId: string, teamUuid: string, unsub$: UnsubListener): Observable<ApiResponse<TeamMember>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { getMember: new TeamCtlProtoGetMemberReq({ userId: userId, teamUuid: stringToBytes(teamUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialTeamMemberOf(res.data.getMember.member)))
        );
    }

    listClusters(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Cluster>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listClusters: new TeamCtlProtoListClustersReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listClusters.clusters.map(partialClusterOf))));
    }

    listSpaces(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Space>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listSpaces: new TeamCtlProtoListSpacesReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSpaces.spaces.map(partialSpaceOf))));
    }

    listSquads(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Squad>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listSquads: new TeamCtlProtoListSquadsReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listSquads.squads.map(partialSquadOf))));
    }

    listMembers(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<TeamMember>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listMembers: new TeamCtlProtoListMembersReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listMembers.members.map(partialTeamMemberOf))));
    }

    listInvitations(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Invitation>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listInvitations: new TeamCtlProtoListInvitationsReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listInvitations.invitations.map(partialInvitationOf))));
    }

    inviteMember(props: {
        teamUuid: string, userEmail: string, accessLevel: AccessLevel,
        spaces: { uuid: string, accessLevel: AccessLevel }[],
    }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            inviteMember: new TeamCtlProtoInviteMemberReq({
                teamUuid: stringToBytes(props.teamUuid),
                userEmail: props.userEmail,
                accessLevel: accessLevelProtoOf(props.accessLevel),
                squadAccess: [],
                spaceAccess: props.spaces.map(x => new TeamCtlProtoInviteMemberSpaceAccess({
                    spaceUuid: stringToBytes(x.uuid),
                    accessLevel: accessLevelProtoOf(x.accessLevel),
                })),
            })
        }));
    }

    resendInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            resendInvitation: new TeamCtlProtoResendInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    revokeInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            revokeInvitation: new TeamCtlProtoRevokeInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    removeMember(props: { teamUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            removeMember: new TeamCtlProtoRemoveMemberReq(
                { teamUuid: stringToBytes(props.teamUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setMemberAccessLevel(props: { teamUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            setMemberAccessLevel: new TeamCtlProtoSetMemberAccessLevelReq({
                teamUuid: stringToBytes(props.teamUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    getFreeClusterCount(teamUuid: string): Observable<number> {
        return this.api.teamReqRes(new TeamCtlProtoReq({
            getFreeClusterCount: new TeamCtlProtoGetFreeClusterCountReq({ teamUuid: stringToBytes(teamUuid) })
        })).pipe(map((res) => res.getFreeClusterCount.count));
    }

    getPayableClusterOptions(teamUuid: string): Observable<ProviderOptions[]> {
        return this.api.teamReqRes(new TeamCtlProtoReq({
            getPayableClusterOptions: new TeamCtlProtoGetPayableClusterOptionsReq({ teamUuid: stringToBytes(teamUuid) })
        })).pipe(map(res => res.getPayableClusterOptions.providers.map(providerOptionsOf)));
    }

    registerAPIToken(props: {
        name: string, id: string,
        teamUuid: string,
        spaceUuid: string,
        accessLevel: AccessLevel,
    }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            registerApiToken: new TeamCtlProtoRegisterAPITokenReq({
                name: props.name, id: props.id,
                teamUuid: stringToBytes(props.teamUuid),
                spaceUuid: stringToBytes(props.spaceUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    listAPITokens(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<TeamAPIToken>> {
        return this.api.teamReqSub(new TeamCtlProtoReq({
            listApiTokens: new TeamCtlProtoListAPITokensReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listApiTokens.apiTokens.map(partialTeamAPITokenOf))));
    }

    revokeAPIToken(uuid: string): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({ revokeApiToken: new TeamCtlProtoRevokeAPITokenReq({ apiTokenUuid: stringToBytes(uuid) }) }));
    }
}
