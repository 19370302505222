/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AccountStatusProto } from "../../protocol/common";
import {
    AccessLevelProto,
    TeamMemberProto,
    SpaceUserAssignmentProto,
    SquadMemberProto,
    UserProto
} from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevel, accessLevelOf } from "./iam";

export interface User extends BaseResource {
    email: string;
    firstName: string;
    lastName: string;
    createdAt: Date;
    isVerified?: boolean;
}

export interface TeamMember extends User {
    accessLevel: AccessLevel;
}

export interface SquadMember extends User {
    accessLevel: AccessLevel;
}

export interface SpaceUserAssignment extends User {
    accessLevel: AccessLevel;
}

export function partialUserOf(data: UserProto): PartialWithUuid<User> {
    const user: PartialWithUuid<User> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        email: data.hasEmail ? data.email : undefined,
        firstName: data.hasFirstName ? data.firstName : undefined,
        lastName: data.hasLastName ? data.lastName : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        isVerified: data.hasIsVerified ? data.isVerified : undefined,
    };
    return stripUndefinedValues(user) as PartialWithUuid<User>;
}

function partialUserWithAccessLevelOf(data: { user: UserProto, accessLevel: AccessLevelProto, hasAccessLevel: boolean }) {
    const userWithAccessLevel = {
        ...partialUserOf(data.user),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(userWithAccessLevel)
}

export function partialTeamMemberOf(data: TeamMemberProto): PartialWithUuid<TeamMember> {
    return partialUserWithAccessLevelOf(data) as PartialWithUuid<TeamMember>;
}

export function partialSquadMemberOf(data: SquadMemberProto): PartialWithUuid<SquadMember> {
    return partialUserWithAccessLevelOf(data) as PartialWithUuid<SquadMember>;
}

export function partialSpaceUserAssignmentOf(data: SpaceUserAssignmentProto): PartialWithUuid<SpaceUserAssignment> {
    return partialUserWithAccessLevelOf(data) as PartialWithUuid<SpaceUserAssignment>;
}

export function userProtoOf(user: Partial<User>): UserProto {
    return new UserProto({
        uuid: user.uuid ? stringToBytes(user.uuid) : undefined,
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        createdAt: user.createdAt?.getTime(),
        isVerified: user.isVerified,
    });
}

export type AccountStatus = "invalid" | "unverified" | "verified" | "profile_created" | "survey_completed" | "setup_completed" | "team_joined_not_surveyed";

const accountStatusFromProtoMap: Record<AccountStatusProto, AccountStatus> = {
    [AccountStatusProto.INVALID]: "invalid",
    [AccountStatusProto.UNVERIFIED]: "unverified",
    [AccountStatusProto.VERIFIED]: "verified",
    [AccountStatusProto.PROFILE_CREATED]: "profile_created",
    [AccountStatusProto.SURVEY_COMPLETED]: "survey_completed",
    [AccountStatusProto.SETUP_COMPLETED]: "setup_completed",
    [AccountStatusProto.TEAM_JOINED_NOT_SURVEYED]: "team_joined_not_surveyed",
};

export function userFullName(user: Partial<User>) {
    return [user.firstName, user.lastName].filter(x => x != null).join(" ");
}

const accountStatusToProtoMap = Object.fromEntries(
    Object.entries(accountStatusFromProtoMap).map(([proto, status]) => [status, proto])
) as unknown as Record<AccountStatus, AccountStatusProto>;

export function accountStatusOf(status: AccountStatusProto): AccountStatus {
    return accountStatusFromProtoMap[status];
}

function accountStatusProtoOf(status: AccountStatus): AccountStatusProto {
    return accountStatusToProtoMap[status];
}

export type UserAuth = { status: AccountStatus | "logged_out", user?: Partial<User> };

export function userAuthOf(statusProto: AccountStatusProto, userProto?: UserProto): UserAuth {
    if (!userProto) return { status: accountStatusOf(statusProto) };

    const user: Partial<User> = {
        uuid: userProto.hasUuid ? bytesToString(userProto.uuid) : undefined,
        id: userProto.hasId ? userProto.id : undefined,
        email: userProto.hasEmail ? userProto.email : undefined,
        firstName: userProto.hasFirstName ? userProto.firstName : undefined,
        lastName: userProto.hasLastName ? userProto.lastName : undefined,
        createdAt: userProto.hasCreatedAt ? new Date(userProto.createdAt) : undefined,
    };
    return { user: stripUndefinedValues(user), status: accountStatusOf(statusProto) };
}

type BaseUserColumn = "id" | "email" | "firstName" | "lastName" | "createdAt";
export type UserColumn = BaseUserColumn | "accessLevel";

export const userProperties: Record<BaseUserColumn, Property> = {
    id: { id: "id", name: "Username", attributeType: "id" },
    email: { id: "email", name: "Email", attributeType: "email" },
    firstName: { id: "firstName", name: "First Name", attributeType: "first-name" },
    lastName: { id: "lastName", name: "Last Name", attributeType: "last-name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const teamMemberProperties: Record<UserColumn, Property> = {
    ...userProperties,
    accessLevel: { id: "accessLevel", name: "Team Access Level", ownerType: "membership", attributeType: "access-level" },
}

export const spaceUserAssignmentProperties: Record<UserColumn, Property> = {
    ...userProperties,
    accessLevel: { id: "accessLevel", name: "Space Access Level", ownerType: "assignment", attributeType: "access-level" },
}

export const squadMemberProperties: Record<UserColumn, Property> = {
    ...userProperties,
    accessLevel: { id: "accessLevel", name: "Squad Access Level", ownerType: "membership", attributeType: "access-level" },
}

const userPropertiesList = [
    userProperties.id, userProperties.email, userProperties.firstName, userProperties.lastName,
    userProperties.createdAt,
];

export const teamMemberPropertiesList = [...userPropertiesList, teamMemberProperties.accessLevel];
export const spaceUserAssignmentPropertiesList = [...userPropertiesList, spaceUserAssignmentProperties.accessLevel];
export const squadMemberPropertiesList = [...userPropertiesList, squadMemberProperties.accessLevel];
