/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subject, forkJoin, map } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, FormOption, FormSelectComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { AccessLevel, accessLevelToString, accessLevelValues } from "../../../concept/iam";
import { Space } from "../../../concept/space";
import { Squad } from "../../../concept/squad";
import { emailPattern, emailPatternErrorText } from "typedb-web-common/lib";
import { DialogResult } from "../../../service/dialog.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { ApplicationState } from "../../../service/application-state.service";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SnackbarService } from "../../../service/snackbar.service";
import { MatTooltipModule } from "@angular/material/tooltip";

type Resource = Space | Squad;
type ResourceFormGroup = FormGroup<{ resource: FormControl<Resource | null>, accessLevel: FormControl<AccessLevel | null>}>;

const resourcesUniqueValidator: (resourceType: string) => ValidatorFn = (resourceType) => (data) => {
    const entries = (data as FormArray<ResourceFormGroup>).controls;
    const resources = entries.map(x => x.value.resource);
    if (resources.some(x => x == null)) return null; // fall back to individual field validation
    let resourceUuids = {} as Record<string, true>;
    for (const resource of (resources as Resource[])) {
        if (resource.uuid in resourceUuids) {
            return { errorText: `The ${resourceType} '${resource.id}' has been added twice` }
        }
        resourceUuids[resource.uuid] = true;
    }
    return null;
};

@Component({
    selector: "tp-invitation-dialog",
    templateUrl: "./invitation-dialog.component.html",
    styleUrls: ["./invitation-dialog.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormSelectComponent, FormActionsComponent, MatTooltipModule,
        FormsModule, ReactiveFormsModule, FormComponent, MatButtonModule, MatFormFieldModule,
    ],
})
export class InvitationDialogComponent {
    readonly form = this.formBuilder.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        accessLevel: ["read" as AccessLevel, [requiredValidator]],
        spaces: this.formBuilder.array([] as ResourceFormGroup[], [resourcesUniqueValidator("space")]),
    }, {
        validators: [(form) => {
            return form.value.accessLevel === "admin" || form.value.spaces.length ? null : { errorText: "Must select at least one space (or grant admin access)" };
        }],
    });
    readonly squads$ = this.teamCtl.listWritableSquadsSnapshot();
    readonly squadOptions$: Observable<FormOption<Squad>[]> = this.squads$.pipe(map(squads => squads.map(squad => ({
        value: squad, viewValue: squad.id,
    }))));
    readonly teamHasSquads$: Observable<boolean> = this.squads$.pipe(map(x => !!x.length));
    readonly addSquadTooltip$: Observable<string> = this.teamHasSquads$.pipe(map(x => x ? "" : "This team has no squads"));
    readonly spaces$ = this.teamCtl.listWritableSpacesSnapshot();
    readonly spaceOptions$: Observable<FormOption<Space>[]> = this.spaces$.pipe(map(spaces => spaces.map(space => ({
        value: space, viewValue: space.id,
    }))));
    readonly accessLevelOptions: FormOption<AccessLevel>[] = accessLevelValues.map(x => ({ value: x, viewValue: accessLevelToString(x) }));
    readonly isReady$ = forkJoin([this.squads$, this.spaces$]).pipe(map(() => true));
    readonly isSubmitting$ = new Subject<boolean>();

    constructor(
        private app: ApplicationState, private dialogRef: MatDialogRef<InvitationDialogComponent, DialogResult>,
        private router: Router, private formBuilder: FormBuilder, private teamCtl: TeamController, private teamApi: TeamApi,
        private snackbar: SnackbarService,
    ) {
        forkJoin([this.squadOptions$, this.spaceOptions$]).subscribe(([squads, _spaces]) => {
            if (squads.length) this.addSquadEntry();
            else this.addSpaceEntry();
        });
    }

    addSpaceEntry() {
        this.form.controls.spaces.push(this.newResourceEntry());
    }

    removeSpaceEntryAt(idx: number) {
        this.form.controls.spaces.removeAt(idx);
    }

    addSquadEntry() {
    }

    removeSquadEntryAt(idx: number) {
    }

    private newResourceEntry() {
        return this.formBuilder.group({
            resource: [null as Resource | null, [requiredValidator]],
            accessLevel: ["read" as AccessLevel, [requiredValidator]],
        });
    }

    submit() {
        const team = this.app.requireCurrentTeam();
        this.teamApi.inviteMember({
            teamUuid: team.uuid,
            userEmail: this.form.value.email!,
            accessLevel: this.form.value.accessLevel!,
            spaces: this.form.value.spaces!.map(x => ({
                uuid: x.resource!.uuid,
                accessLevel: x.accessLevel!,
            })),
        }).subscribe({
            next: () => {
                this.snackbar.success(`An invitation email has been sent to '${this.form.value.email}'`);
                this.close("ok");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
