/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, ViewChild } from "@angular/core";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { ParagraphWithHighlights } from "typedb-web-schema/lib";
import { HeadingWithHighlightsComponent, ParagraphWithHighlightsComponent } from "../../framework/text/text-with-highlights.component";
import { ApplicationState } from "../../service/application-state.service";
import { SanityService } from "../../service/sanity.service";
import { SetupTeamComponent } from "./team/setup-team.component";
import { SetupProfileComponent } from "./profile/setup-profile.component";
import { SetupProjectComponent } from "./project/setup-project.component";
import { SpinnerComponent } from "typedb-platform-framework";
import { Router } from "@angular/router";
import { first, map } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { AccountStatus } from "../../concept/user";

enum SetupStep {
    CREATE_PROFILE = 0,
    PROJECT_INFO = 1,
    JOIN_TEAM = 2,
}

@Component({
    selector: "tp-setup",
    templateUrl: "./setup.component.html",
    styleUrls: ["./setup.component.scss"],
    standalone: true,
    imports: [
        MatStepperModule, SetupProfileComponent, SetupTeamComponent, SetupProjectComponent, SpinnerComponent,
        HeadingWithHighlightsComponent, ParagraphWithHighlightsComponent, AsyncPipe,
    ],
})
export class SetupComponent {
    currentStep: SetupStep | null = null;
    @ViewChild(MatStepper) stepper?: MatStepper;
    onboardingTitle$ = this.sanity.onboarding.pipe(map(x => ParagraphWithHighlights.fromSanity(x.title)));
    private userCreatedAccountStatuses: (AccountStatus | "logged_out")[] = ["profile_created", "survey_completed"];
    userCreated$ = this.app.userAuth$.pipe(map((userAuth) => this.userCreatedAccountStatuses.includes(userAuth.status)));

    constructor(private router: Router, private app: ApplicationState, private sanity: SanityService) {
        this.app.userAuth$.pipe(first()).subscribe(userAuth => {
            switch (userAuth.status) {
                case "verified":
                    this.currentStep = SetupStep.CREATE_PROFILE;
                    break;
                case "profile_created":
                    this.currentStep = SetupStep.PROJECT_INFO;
                    break;
                case "survey_completed":
                    this.currentStep = SetupStep.JOIN_TEAM;
                    break;
                default:
                    this.router.navigate(["/"]);
                    return;
            }
        });
    }

    advance() {
        this.currentStep!++;
    }
}
