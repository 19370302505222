<tp-modal dialogTitle="Edit Team" [isBusy]="isSubmitting$ | async">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-input type="text" [form]="form" field="name" label="Team Name" />
    <tp-form-input type="text" [form]="form" field="id" label="Team URL">
      <div inputTextPrefix>cloud.typedb.com/team/</div>
    </tp-form-input>
    <tp-form-actions
      [cancellable]="true" [submitDisabled]="!form.dirty" submitText="Save" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="editTeamModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
