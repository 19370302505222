/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AccessLevelProto, UserSpaceProto, SquadSpaceProto, SpaceProto } from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevel, accessLevelOf } from "./iam";

export interface Space extends BaseResource {
    name: string;
    createdAt: Date;
}

export interface UserSpace extends Space {
    accessLevel: AccessLevel;
}

export interface SquadSpace extends Space {
    accessLevel: AccessLevel;
}

export function partialSpaceOf(data: SpaceProto): PartialWithUuid<Space> {
    const space: PartialWithUuid<Space> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name: undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(space) as PartialWithUuid<Space>;
}

function partialSpaceWithAccessLevelOf(data: { space: SpaceProto, accessLevel: AccessLevelProto, hasAccessLevel: boolean }) {
    const spaceWithAccessLevel = {
        ...partialSpaceOf(data.space),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(spaceWithAccessLevel)
}

export function partialUserSpaceOf(data: UserSpaceProto): PartialWithUuid<UserSpace> {
    return partialSpaceWithAccessLevelOf(data) as PartialWithUuid<UserSpace>
}

export function partialSquadSpaceOf(data: SquadSpaceProto): PartialWithUuid<SquadSpace> {
    return partialSpaceWithAccessLevelOf(data) as PartialWithUuid<SquadSpace>
}

export function spaceProtoOf(space: Partial<Space>): SpaceProto {
    return new SpaceProto({
        uuid: space.uuid ? stringToBytes(space.uuid) : undefined,
        id: space.id,
        name: space.name,
        createdAt: space.createdAt?.getTime(),
    });
}

type BaseSpaceColumn = "id" | "name" | "createdAt";
export type SpaceColumn = BaseSpaceColumn | "accessLevel";

export const spaceProperties: Record<BaseSpaceColumn, Property> = {
    id: { id: "id", name: "Space ID", attributeType: "id" },
    name: { id: "name", name: "Space Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

const assignedSpaceProperties: Record<SpaceColumn, Property> = {
    ...spaceProperties,
    accessLevel: { id: "accessLevel", name: "Access Level", ownerType: "assignment", attributeType: "access-level" },
}
export const squadSpaceProperties = assignedSpaceProperties
export const userSpaceProperties = assignedSpaceProperties

export const spacePropertiesList = [spaceProperties.id, spaceProperties.name, spaceProperties.createdAt];
const assignedSpacePropertiesList = [...spacePropertiesList, assignedSpaceProperties.accessLevel];
export const squadSpacePropertiesList = assignedSpacePropertiesList;
export const userSpacePropertiesList = assignedSpacePropertiesList;
