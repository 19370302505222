/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { PartialWithId } from "../../../concept/base";
import { Space } from "../../../concept/space";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { spaceDetailsPath } from "../../../routing/resource-paths";
import { SpaceApi } from "../../../service/space/space-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";

@Component({
    selector: "tp-space-creation-dialog",
    templateUrl: "./space-creation-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent],
})
export class SpaceCreationDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private app: ApplicationState, private dialogRef: MatDialogRef<SpaceCreationDialogComponent>,
        private router: Router, private formBuilder: FormBuilder, private spaceService: SpaceApi,
        private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const team = this.app.requireCurrentTeam();
        const space: PartialWithId<Space> = { id: this.form.value.id!, name: this.form.value.name! };
        this.spaceService.createSpace({ space, teamUuid: team.uuid }).subscribe({
            next: () => {
                this.router.navigate([spaceDetailsPath(space, team)]);
                this.close();
                this.snackbar.success("Space created successfully");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
