/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { ResourceTable, ResourceTableService } from "../../../../service/resource-table.service";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { TableSelectionCellComponent, TableSelectionHeaderCellComponent } from "../../../../framework/table";
import { AccessLevelPipe } from "../../../../framework/access-level.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { APIToken, APITokenColumn } from "../../../../concept/api-token";

@Component({
    selector: "tp-api-tokens-table",
    templateUrl: "./api-tokens-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatMenuModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, SpinnerComponent, AccessLevelPipe,
        MatButtonModule,
    ],
})
export class ApiTokensTableComponent<ENTITY extends APIToken> {
    @Input({ required: true}) table!: ResourceTable<ENTITY, APITokenColumn>;
    @Input() rowPopupMenuItems?: ((row: ENTITY) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService) {}
}
