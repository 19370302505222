/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe, NgClass } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject, map, of } from "rxjs";
import {
    BreadcrumbComponent, ButtonComponent, ConfirmationModalComponent, ConfirmationModalData, ContactSupportDialogData,
    ContactSupportModalComponent, DatetimePipe, DeleteResourceSectionComponent, IntegrationCardComponent,
    MenuItem, ModalComponent,
    PropertiesTableComponent, PropertiesTableRowComponent, SpinnerComponent
} from "typedb-platform-framework";
import { mergeChanges } from "../../../concept/api-response";
import { AWSAccount, AzureAccount, CreditCard, GCPAccount, Invoice } from "../../../concept/subscription";
import { BillingAddressPipe } from "../billing-address.pipe";
import { CreditCardExpiryDatePipe } from "../credit-card-expiry-date.pipe";
import { CreditCardTypePipe } from "../credit-card-type.pipe";
import { CurrencyPipe } from "../currency.pipe";
import { PaymentDetailsModalComponent } from "../payment-details-modal/payment-details-modal.component";
import { DialogResult } from "../../../service/dialog.service";
import { TeamUpdateDialogComponent } from "../update/team-update-dialog.component";

import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { SubscriptionController } from "../../../service/subscription/subscription-controller.service";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { InvoicesTableComponent } from "../invoices-table/invoices-table.component";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PaymentMethodStatusPipe } from "../payment-method-status.pipe";
import {
    MarketplaceCardsComponent
} from "../marketplace-cards/marketplace-cards.component";
import { CreditsTableComponent } from "../credits-table/credits-table.component";
import { DiscountsTableComponent } from "../discounts-table/discounts-table.component";
import { ApiTokensTableComponent } from "../api-token/table/api-tokens-table.component";
import { TeamController } from "../../../service/team/team-controller.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { APITokenCreationDialogComponent } from "../api-token/create/api-token-creation-dialog.component";
import { APIToken, TeamAPIToken } from "../../../concept/api-token";

@Component({
    selector: "tp-team-settings-page",
    templateUrl: "./team-settings-page.component.html",
    styleUrls: ["./team-settings-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, PropertiesTableComponent, DeleteResourceSectionComponent,
        ModalComponent, ConfirmationModalComponent, AsyncPipe, ButtonComponent, SpinnerComponent,
        InvoicesTableComponent, TablePaginatorComponent, PaymentDetailsModalComponent, PropertiesTableRowComponent,
        CreditCardTypePipe, CreditCardExpiryDatePipe, BillingAddressPipe, DatetimePipe, CurrencyPipe,
        MatTooltipModule, PaymentMethodStatusPipe, NgClass, IntegrationCardComponent, SpinnerComponent,
        MarketplaceCardsComponent, CreditsTableComponent, DiscountsTableComponent, ApiTokensTableComponent, TableToolbarComponent,
    ],
})
export class TeamSettingsPageComponent implements OnInit, OnDestroy {
    readonly team = this.app.requireCurrentTeam();
    readonly breadcrumb$ = of({ items: [this.team.id], url: `/` });

    readonly upcomingInvoice$ = new BehaviorSubject<Invoice | "none" | null>(null);
    readonly creditCard$ = new BehaviorSubject<CreditCard | null>(null);
    readonly activeGcpAccount$ = new BehaviorSubject<GCPAccount | null>(null);
    readonly azureAccount$ = new BehaviorSubject<AzureAccount | null>(null);
    readonly activeAwsAccount$ = new BehaviorSubject<AWSAccount | null>(null);
    private readonly upcomingInvoiceUnsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.app.hasTeamAdminAccess$.pipe(map(x => x ? "ready" : "loading"));

    generating = false;
    apiTokenGenerationButton: TableToolbarAction = {
        text: "Generate API Token",
        enabled: !this.generating,
        onClick: () => { this.openAPITokenCreationModal(); }
    };

    private readonly unsubInvoices$ = new Subject<void>();
    readonly invoices = this.subscriptionCtl.invoicesTable(this.unsubInvoices$);
    private readonly unsubCredits$ = new Subject<void>();
    readonly credits = this.subscriptionCtl.creditsTable(this.unsubCredits$);
    private readonly unsubDiscounts$ = new Subject<void>();
    readonly discounts = this.subscriptionCtl.discountsTable(this.unsubDiscounts$);
    private readonly unsubAPITokens$ = new Subject<void>();
    readonly apiTokens = this.teamCtl.apiTokensTable(this.unsubAPITokens$);

    cardSectionReady = false;

    constructor(
        private app: ApplicationState, private snackbar: SnackbarService, private dialog: MatDialog,
        private subscriptionApi: SubscriptionApi, private subscriptionCtl: SubscriptionController,
        private teamCtl: TeamController, private teamApi: TeamApi,
    ) {}

    ngOnInit() {
        this.initUpcomingInvoiceLoader();
        this.fetchCreditCards();
        this.fetchMarketplaceAccounts();
    }

    ngOnDestroy() {
        this.upcomingInvoiceUnsub$.next();
        this.unsubInvoices$.next();
        this.unsubCredits$.next();
    }

    readonly apiTokensTableRowPopupMenuItems: (apiToken: TeamAPIToken) => MenuItem[] = (apiToken) => {
        return [
            {
                label: "Revoke API Token",
                action: () => this.openAPITokenRevokeModal(apiToken),
                dangerous: true
            },
        ];
    }

    openAPITokenRevokeModal(apiToken: APIToken) {
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: "Revoke API Token",
                body: `Are you sure you would like to revoke access for '${apiToken.name}'? Make sure to remove it from any live integrations first.`,
                confirmText: `Revoke API Token`,
                confirmButtonStyle: "primary-outline red stroke",
            },
        }).componentInstance;
        modal.confirmed.subscribe(() => {
            this.teamApi.revokeAPIToken(apiToken.uuid).subscribe(() => {
                this.snackbar.success("API Token successfully revoked");
                modal.close();
            })
        })
    }

    openAPITokenCreationModal() {
        this.dialog.open(APITokenCreationDialogComponent, { width: "580px" });
    }

    openEditTeamDialog() {
        this.dialog.open(TeamUpdateDialogComponent);
    }

    showContactSupportDialog() {
        this.dialog.open<ContactSupportModalComponent, ContactSupportDialogData>(ContactSupportModalComponent, { data: { title: "Delete team" } });
    }

    initUpcomingInvoiceLoader() {
        const team = this.app.requireCurrentTeam();
        this.subscriptionApi.getUpcomingInvoice(team.uuid, this.upcomingInvoiceUnsub$).subscribe((res) => {
            if (!res) {
                this.upcomingInvoice$.next("none");
                return;
            }
            const current = this.upcomingInvoice$.value === "none" ? null : this.upcomingInvoice$.value;
            const mergeResult = mergeChanges(current, res);
            if (mergeResult.result === "shouldDelete") {
                this.upcomingInvoiceUnsub$.next();
                // TODO: ???
            } else if (mergeResult.result === "shouldResync") {
                console.warn(`Received update is not valid in the current state; forcing a full resync`);
                this.upcomingInvoiceUnsub$.next();
                this.initUpcomingInvoiceLoader();
                return;
            }
            if (mergeResult.data) {
                this.upcomingInvoice$.next(mergeResult.data);
            }
        });
    }

    openPaymentModal() {
        this.dialog.open<PaymentDetailsModalComponent, any, DialogResult>(PaymentDetailsModalComponent).afterClosed().subscribe(result => {
            if (result === "ok") {
                this.fetchCreditCards();
                this.snackbar.success("Payment method added successfully");
            }
            else if (result == null || result === "cancelled") return;
            else this.snackbar.errorPersistent(`Error setting up payment method: ${result.error}`);
        });
    }

    private fetchCreditCards() {
        const team = this.app.requireCurrentTeam();
        this.subscriptionApi.listCreditCards(team.uuid).subscribe((cards) => {
            this.creditCard$.next(cards[0]);
            this.cardSectionReady = true
        });
    }

    private fetchMarketplaceAccounts() {
        const team = this.app.requireCurrentTeam();
        this.subscriptionApi.getMarketplaceAccounts(team.uuid).subscribe((accounts) => {
            if (accounts.gcp?.status == "active") {
                this.activeGcpAccount$.next(accounts.gcp);
            }
            this.azureAccount$.next(accounts.azure);
            if (accounts.aws?.status == "active") {
                this.activeAwsAccount$.next(accounts.aws);
            }
        });
    }

    // TODO
    // deleteTeam() {
    //     const currentTeam = this.app.requireCurrentTeam();
    //     this.teamService.deleteTeam(currentTeam.uuid).subscribe(() => {
    //         this.snackbar.success(`Team '${currentTeam.id}' is now deleted.`);
    //         this.router.navigate(["/"]);
    //     });
    // }
}
