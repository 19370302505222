<div style="display: flex; gap: 4px;">
  <svg class="tp-logo" viewBox="0 0 636 687" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M318 131.276C449.444 131.276 556 237.77 556 369.138C556 500.506 449.444 607 318 607C186.556 607 80 500.506 80 369.138C80 237.77 186.556 131.276 318 131.276ZM410.82 253.852L222.166 253.852L221.868 253.853C157.86 254.182 106.237 306.308 106.566 370.28C106.897 434.692 159.212 486.746 223.662 486.795L410.907 486.936C475.285 486.936 527.473 434.778 527.473 370.438C527.473 306.049 475.245 253.852 410.82 253.852Z" fill="currentColor"></path>
    <path d="M244.073 420.735C272.438 420.735 295.433 398.522 295.433 371.122C295.433 343.721 272.438 321.509 244.073 321.509C215.708 321.509 192.714 343.721 192.714 371.122C192.714 398.522 215.708 420.735 244.073 420.735Z" fill="currentColor"></path>
    <path class="tp-right-eye" d="M400.292 420.735C428.657 420.735 451.652 398.522 451.652 371.122C451.652 343.721 428.657 321.509 400.292 321.509C371.927 321.509 348.932 343.721 348.932 371.122C348.932 398.522 371.927 420.735 400.292 420.735Z" fill="currentColor"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M134.797 80C160.075 80 180.566 100.48 180.566 125.743C180.566 144.461 169.317 160.553 153.207 167.634L153.207 271.148H116.591L116.591 167.723C100.372 160.688 89.028 144.539 89.028 125.743C89.028 100.48 109.52 80 134.797 80Z" fill="currentColor"></path>
  </svg>
  @if (!collapsed) {
    <div class="flex-spacer"></div>
    <button (click)="toggleCollapsed()">
      <i class="fa-light fa-chevron-left"></i>
    </button>
  }
</div>

@if (!collapsed) {
  <mat-form-field class="tp-team-selector">
    <mat-select panelClass="tp-sidebar-team-selection-panel" [value]="team" (selectionChange)="onTeamChange($event)">
      @for (team of app.currentUserTeams$ | async; track team) {
        <mat-option [value]="team"><span>{{ team.id }}</span></mat-option>
      }
      <hr/>
      <button type="button" class="selector-button mat-mdc-option mdc-list-item" (click)="onCreateTeamClick()">
        <i class="fa-light fa-plus"></i><span>Create team</span>
      </button>
    </mat-select>
  </mat-form-field>
} @else {
  <button (click)="toggleCollapsed()">
    <i class="fa-light fa-chevron-right"></i>
  </button>
}

<div class="links">
  <tp-sidebar-link [matTooltip]="collapsed ? 'Get Started' : ''" matTooltipPosition="right" [link]="getStartedPath(team)" domain="get-started" idSuffix="getStarted"><i class="fa-light fa-rocket"></i>@if (!collapsed) { <span>Get Started</span> }</tp-sidebar-link>
  <tp-sidebar-link [matTooltip]="collapsed ? 'Clusters' : ''" matTooltipPosition="right" [link]="clustersPath(team)" domain="clusters" idSuffix="clusters"><i class="fa-light fa-objects-column"></i>@if (!collapsed) { <span>Clusters</span> }</tp-sidebar-link>
  <tp-sidebar-link [matTooltip]="collapsed ? 'Spaces' : ''" matTooltipPosition="right" [link]="spacesPath(team)" domain="spaces" idSuffix="spaces"><i class="fa-light fa-folders"></i>@if (!collapsed) { <span>Spaces</span> }</tp-sidebar-link>
  <tp-sidebar-link [matTooltip]="collapsed ? 'Members' : ''" matTooltipPosition="right" [link]="membersPath(team)" domain="members" idSuffix="members"><i class="fa-light fa-user-group"></i>@if (!collapsed) { <span>Members</span> }</tp-sidebar-link>
  @if (app.hasTeamAdminAccess$ | async) {
    <tp-sidebar-link [matTooltip]="collapsed ? 'Settings' : ''" matTooltipPosition="right" [link]="teamSettingsPath(team)" domain="settings" idSuffix="settings"><i class="fa-light fa-gear"></i>@if (!collapsed) { <span>Settings</span> }</tp-sidebar-link>
  }
  <div class="flex-spacer"></div>
</div>

<button mat-button type="button" class="tp-sidebar-user-avatar" [matMenuTriggerFor]="userInfoMenu">
  <tp-user-avatar [userEmail]="userEmail$ | async"/>
  @if (!collapsed) { <span>{{ (userId$ | async) ?? "" }}</span> }
</button>

<mat-menu #userInfoMenu="matMenu" xPosition="after">
  <div class="tp-user-info-area">
    <tp-user-avatar [userEmail]="userEmail$ | async"/>
    <div>
      <p><strong>{{ (userId$ | async) ?? "" }}</strong></p>
      <p>{{ (userFullName$ | async) ?? "" }}</p>
    </div>
  </div>
  <button mat-menu-item id="sidebarAccountSettings">
    <a routerLink="/settings" class="tp-menu-button-internal">
      <i class="fa-light fa-user"></i>
      <span>Account</span>
    </a>
  </button>
  <button mat-menu-item (click)="signOut()" id="sidebarSignOut">
    <div class="tp-menu-button-internal">
      <i class="fa-light fa-sign-out"></i><span>Sign out</span>
    </div>
  </button>
</mat-menu>
