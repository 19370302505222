@if (loaded) {
  <div class="integrations-row">
    @if (gcpMarketplaceAddress$ | async; as address) {
      <tp-integration-card
        title="Google Cloud Marketplace" [imageIcon]="{src: '/assets/logo/gcp.svg', alt: 'GCP'}"
        [chip]="gcpAccountPresent ? 'active' : undefined"
        [buttonLinkText]="!gcpAccountPresent ? 'Connect' : 'Disconnect'" [enabled]="!gcpAccountPresent || canDisconnect"
        [link]="gcpAccountPresent ? 'https://console.cloud.google.com/marketplace/orders' : address"
        (cardClick)="cardClick.emit('gcp')"
      >
        <p>
          Pay for clusters hosted on GCP
          with an existing Google Cloud Billing Account.
        </p>
      </tp-integration-card>
    }
    @if (awsMarketplaceAddress$ | async; as address) {
      <tp-integration-card
        title="AWS Marketplace"
        [imageIcon]="{src: '/assets/logo/aws.svg', alt: 'AWS'}"
        [chip]="awsAccountPresent ? 'active' : undefined"
        [buttonLinkText]="!awsAccountPresent ? 'Connect' : 'Disconnect'"
        [enabled]="!awsAccountPresent || canDisconnect"
        [link]="awsAccountPresent ? 'https://console.aws.amazon.com/marketplace/home#/subscriptions' : address"
        (cardClick)="cardClick.emit('aws')"
      >
        <p>
          Pay for clusters hosted on AWS
          with an existing AWS Billing Account.
        </p>
      </tp-integration-card>
    }
  </div>
} @else {
  <tp-spinner/>
}
