@if (invitations$ | async; as invitations) {
  @if (invitations.length) {
    <div class="setup-container">
      @for (invitation of invitations; track invitation) {
        <section>
          <tp-form
            [formGroup]="invitationReviewForm" [isSubmitting$]="invitationReviewFormIsSubmitting$"
            (submit)="acceptInvitation(invitation)"
          >
            <div class="tp-big-envelope"><i class="fa-thin fa-envelope-open-text"></i></div>
            <p style="text-align: center">You've been invited to the <u>{{ invitation.team.name }}</u> team.</p>
            <div class="tp-form-actions">
              <tp-button
                type="button" [enabled]="!(invitationReviewFormIsSubmitting$ | async)" buttonId="rejectInvitation"
                buttonStyle="primary-outline white stroke" (click)="declineInvitation(invitation)"
              >
                Setup your own environment
              </tp-button>
              <tp-button
                type="submit" [enabled]="!(invitationReviewFormIsSubmitting$ | async)"
                buttonStyle="primary-outline green stroke" buttonId="acceptInvitation"
              >
                Join {{ invitation.team.name }}
              </tp-button>
            </div>
          </tp-form>
        </section>
      }
    </div>
  } @else {
    @if (tagline$ | async; as tagline) {
      <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
    } @else {
      <div style="height: 22px;"></div>
    }
    <div class="setup-container">
      <div class="tp-bento-container row tp-team-selection-row">
        <button (click)="selectTeamMode('personal')" class="tp-team-mode-card tp-bento-box hoverable" [class.selected]="teamMode === 'personal'">
          <i class="fa-user-vneck fa-thin fa-fw"></i>
          <p class="text-p2">Personal</p>
        </button>
        <button (click)="selectTeamMode('team')" class="tp-team-mode-card tp-bento-box hoverable" [class.selected]="teamMode === 'team'">
          <i class="fa-buildings fa-thin fa-fw"></i>
          <p class="text-p2">Team</p>
        </button>
      </div>
      <tp-form [formGroup]="createTeamForm" [isSubmitting$]="createTeamFormIsSubmitting$" (doSubmit)="submitCreateTeamForm()">
        @if (teamMode === 'team') {
          <tp-form-input @fadeInOut [form]="createTeamForm" type="text" label="Team Name" field="name" autocomplete="organization"/>
          <tp-form-input @fadeInOut [form]="createTeamForm" type="text" label="Team URL" field="id">
            <div inputTextPrefix>cloud.typedb.com/team/</div>
            </tp-form-input>

            <div class="tp-finish">
              <tp-form-actions
                [isSubmitting]="createTeamFormIsSubmitting$ | async" buttonIdPrefix="createTeam"
                [submitDisabled]="!createTeamForm.dirty" submitText="Continue"
                submitButtonStyle="primary-outline green stroke"
              />
            </div>
          } @else {
            <div class="tp-finish">
              <tp-button style="margin-top: 20px;" [enabled]="!!teamMode && !(createTeamFormIsSubmitting$ | async)" buttonId="skipTeamCreation" buttonStyle="primary-outline green stroke" (click)="createDefaultTeam()">Continue</tp-button>
            </div>
          }
        </tp-form>
    </div>
  }
} @else {
  <tp-spinner/>
}
