/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    first,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from "rxjs";
import {
    BreadcrumbComponent,
    ButtonComponent,
    ConfirmationModalComponent,
    DatetimePipe,
    DeleteResourceSectionComponent,
    MenuItem,
    ModalComponent,
    PropertiesTableComponent,
    PropertiesTableRowComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { AccessLevel, hasAdminAccess, hasWriteAccess } from "../../../concept/iam";
import { Space, SpaceColumn } from "../../../concept/space";
import { SpaceUserAssignment, User, UserColumn } from "../../../concept/user";
import { SPACE_ID } from "../../../framework/url-params";
import { spacesPath } from "../../../routing/resource-paths";
import { DialogResult } from "../../../service/dialog.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { SpaceApi } from "../../../service/space/space-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SpaceController } from "../../../service/space/space-controller.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { ClustersTableComponent } from "../../cluster/table/clusters-table.component";
import { PageScaffoldComponent, ResourceAvailability } from "../../scaffold/page/page-scaffold.component";
import {
    SpaceAddUserDialogData,
    SpaceAssignUserDialogComponent
} from "../assign-user/space-assign-user-dialog.component";
import { Cluster, ClusterColumn } from "../../../concept/cluster";
import { SpaceUpdateDialogComponent, SpaceUpdateDialogData } from "../update/space-update-dialog.component";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { UsersTableComponent } from "../../member/table/users-table.component";
import { SpaceSetUserAccessLevelDialogComponent } from "../set-user-access-level/space-set-user-access-level-dialog.component";
import { APITokenColumn, SpaceAPIToken } from "../../../concept/api-token";
import { ApiTokensTableComponent } from "../../team/api-token/table/api-tokens-table.component";

@Component({
    selector: "tp-space-details-page",
    templateUrl: "./space-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, ConfirmationModalComponent, AsyncPipe, TableToolbarComponent,
        ClustersTableComponent, TablePaginatorComponent,
        PropertiesTableRowComponent, DatetimePipe, UsersTableComponent, ApiTokensTableComponent,
    ],
})
export class SpaceDetailsPageComponent implements OnInit, OnDestroy {
    readonly space$ = new BehaviorSubject<Space | null>(null);
    readonly spaceAccess$ = this.space$.pipe(switchMap(space => {
        if (space) return this.spaceApi.getAccessLevels([space.uuid]).pipe(map(x => x[0]));
        else return of("none" as AccessLevel);
    }));
    readonly hasWriteAccess$ = this.spaceAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly hasAdminAccess$ = this.spaceAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly breadcrumb$ = this.space$.pipe(
        map((space) => {return !space ? null : { items: [space.id], url: spacesPath(this.app.requireCurrentTeam()) };}),
    );

    cannotDeleteReason: string | null = null;

    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.space$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubClusters$ = new Subject<void>();
    clusters?: ResourceTable<Cluster, ClusterColumn>;
    readonly deployClusterButton: TableToolbarAction = {
        text: "Deploy cluster",
        enabled: false,
        onClick: () => { this.navigateToDeployCluster(); },
    };

    private readonly unsubMembers$ = new Subject<void>();
    members?: ResourceTable<SpaceUserAssignment, UserColumn>;
    readonly assignUserButton: TableToolbarAction = {
        text: "Add user",
        enabled: false,
        onClick: () => { this.openAssignUserDialog(); },
    };

    private readonly unsubAPITokens$ = new Subject<void>();
    apiTokens?: ResourceTable<SpaceAPIToken, APITokenColumn>;

    private readonly unsubTeamSpaces$ = new Subject<void>();
    private readonly teamSpaces: ResourceTable<Space, SpaceColumn> = this.teamCtl.spacesTable(this.unsubTeamSpaces$);

    membersTableRowPopupMenuItems: (user: SpaceUserAssignment) => MenuItem[] = () => [];

    constructor(
        private app: ApplicationState, private router: Router, private route: ActivatedRoute,
        private teamCtl: TeamController, private teamService: TeamApi, private spaceApi: SpaceApi,
        private dialog: MatDialog, private snackbar: SnackbarService, private spaceCtl: SpaceController,
        private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubClusters$.next();
        this.unsubMembers$.next();
        this.unsubAPITokens$.next();
        this.unsubTeamSpaces$.next();
    }

    initDataLoader() {
        const team = this.app.requireCurrentTeam();

        this.space$.pipe(first(x => !!x), map(x => x!)).subscribe(space => {
            this.clusters = this.spaceCtl.clustersTable(space, this.unsubClusters$);
            this.members = this.spaceCtl.membersTable(space, this.unsubMembers$);
            this.apiTokens = this.spaceCtl.apiTokensTable(space, this.unsubAPITokens$);
            this.spaceAccess$.subscribe((accessLevel) => {
                this.membersTableRowPopupMenuItems = (user: SpaceUserAssignment): MenuItem[] => {
                    return hasAdminAccess(accessLevel) ? [
                        {
                            label: "Change access level",
                            action: () => {
                                this.dialog.open(SpaceSetUserAccessLevelDialogComponent, {data: {space, user}});
                            }
                        },
                        {
                            label: "Remove from space",
                            action: () => {
                                this.spaceApi.unassignUser({
                                    spaceUuid: this.space$.value!.uuid,
                                    userUuid: user.uuid
                                }).subscribe(() => this.members!.refresh());
                            },
                            dangerous: true
                        },
                    ] : [];
                };
            });
            combineLatest([this.teamSpaces.items$, this.clusters.items$]).subscribe(([spaces, clusters]) => {
                if (!clusters || !spaces) {
                    this.cannotDeleteReason = "";
                } else if (clusters.length > 0) {
                    this.cannotDeleteReason = "This space cannot be deleted because it has at least one cluster";
                } else if (spaces.length === 1) {
                    this.cannotDeleteReason = "This space cannot be deleted because it is the only space in the team";
                } else {
                    this.cannotDeleteReason = null;
                }
            });
        });

        this.route.params.pipe(
            filter((params) => params["space-id"]),
            map((params) => params["space-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((spaceId) => {
                return this.spaceApi.getSpace({ spaceId, teamUuid: team.uuid }, of(undefined));
            })
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.space$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.space$.error(err);
            }
        });

        this.hasWriteAccess$.subscribe(canWrite => {
            this.deployClusterButton.enabled = canWrite;
        });
        this.hasAdminAccess$.subscribe(canAdmin => {
            this.assignUserButton.enabled = canAdmin;
        });
    }

    openEditModal() {
        this.dialog.open<SpaceUpdateDialogComponent, SpaceUpdateDialogData, DialogResult>(SpaceUpdateDialogComponent, {
            data: { space: this.space$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    get spaceCanBeDeleted(): boolean {
        return this.cannotDeleteReason == null;
    }

    get deleteSpaceDisabledText(): string {
        return this.cannotDeleteReason || "";
    }

    openDeleteModal() {
        const space = this.space$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete space",
                body: `Are you sure you would like to delete '${space.name}'? This action cannot be undone.`,
                confirmText: "Delete space",
                strongConfirmationString: space.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.spaceApi.deleteSpace(space.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Space '${space.id}' is now deleted.`);
                this.router.navigate(["../../spaces"], { relativeTo: this.route });
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    private navigateToDeployCluster() {
        const currentSpace = this.space$.value;
        if (!currentSpace) {
            return;
        }
        this.router.navigate(["../../clusters/deploy"], { relativeTo: this.route, queryParams: { [SPACE_ID]: currentSpace.id }, });
    }

    private openAssignUserDialog() {
        return this.teamService.listMembers(this.app.requireCurrentTeam().uuid, this.createDefaultParams(), of(undefined)).pipe(
            switchMap((res) => {
                const teamMembers = res.initial!;
                return this.dialog.open<SpaceAssignUserDialogComponent, SpaceAddUserDialogData, DialogResult>(
                    SpaceAssignUserDialogComponent, { data: { teamMembers, space: this.space$.value! } }
                ).beforeClosed();
            }),
        ).subscribe((result) => {
            if (result === "ok") this.members!.refresh();
        });
    }

    private createDefaultParams() {
        return { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } };
    }
}
