<p>{{ question.spec.body }}</p>
@if (question.spec.presentation === "chips") {
  <mat-chip-listbox [multiple]="question.spec.isMultiSelect">
    @for (option of question.spec.options; track option) {
      <mat-chip-option (selectionChange)="onOptionSelectedChange(option, $event.selected)">{{ option.text }}</mat-chip-option>
    }
    @if (question.spec.hasOpenEndedOption) {
      <mat-form-field class="form-field-dense" [ngClass]="{ 'tp-selected': otherOptionSelected }">
        <input matInput [(ngModel)]="otherValueRaw" (blur)="onOtherFieldBlur()" placeholder="Other">
        @if (otherOptionSelected) {<mat-icon matPrefix>check</mat-icon>}
      </mat-form-field>
    }
  </mat-chip-listbox>
} @else if (question.spec.presentation === "dropdown") {
  <mat-form-field style="width: 100%">
    <mat-select [formControl]="question.formControl" [multiple]="question.spec.isMultiSelect">
      @for (option of question.spec.options; track option) {
        <mat-option [value]="option.text" (onSelectionChange)="onOptionSelectedChange(option, $event.source.selected)">{{ option.text }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
}
