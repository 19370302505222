/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from '@angular/core';
import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { MarketplaceIntegrationComponent } from "../marketplace-integration/marketplace-integration.component";
import { Subject, first } from 'rxjs';
import { Router } from '@angular/router';
import { SnackbarService } from "../../../service/snackbar.service";
import { Team } from "../../../concept/team";

@Component({
    selector: 'tp-aws-marketplace-integration',
    standalone: true,
    imports: [MarketplaceIntegrationComponent],
    templateUrl: './aws-marketplace-integration.component.html',
})
export class AwsMarketplaceIntegrationComponent {
    constructor(private subscriptionApi: SubscriptionApi, private router: Router, private snackbar: SnackbarService) { }

    isSubmitting$ = new Subject<boolean>();

    setAWSAccount(team: Team, token: string) {
        this.subscriptionApi.setAWSAccount(team.uuid, token).pipe(first()).subscribe({
            next: () => {
                this.snackbar.success(`Your AWS Account has been connected to the team ${team.id}`);
                this.router.navigate([`/team/${team.id}/settings`])
            },
            error: () => {
                this.isSubmitting$.next(false);
                this.snackbar.errorPersistent(`Could not connect AWS Account to the team ${team.id}`)
            }
        })
    }
}
