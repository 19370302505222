/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { PaymentMethod, StripeAddressElementOptions, StripeElementsOptions, StripePaymentElementOptions } from "@stripe/stripe-js";
import { StripeAddressComponent, StripeFactoryService, StripeInstance, StripePaymentElementComponent } from "ngx-stripe";
import { ButtonComponent, FormActionsComponent, ModalComponent } from "typedb-platform-framework";

import { MatDialogRef } from "@angular/material/dialog";
import { from } from "rxjs";
import { DialogResult } from "../../../service/dialog.service";
import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { ApplicationState } from "../../../service/application-state.service";

interface BillingDetails {
    name: string;
    address: {
        line1: string;
        line2: string;
        city: string;
        country: string;
        postal_code: string;
        state: string;
    };
}

const elementsOptsBase: StripeElementsOptions = {
    locale: "en",
    appearance: {
        theme: "flat",
        variables: {
            fontFamily: "Titillium Web, sans-serif",
            fontSizeBase: "16px",
            borderRadius: "4px",
            colorTextPlaceholder: "#63607C",
            colorText: "#FFFFFF",
            colorDanger: "#E96464",
            spacingUnit: "4px",
            colorBackground: "#232135",
        },
    },
};

@Component({
    selector: "tp-payment-details-modal",
    templateUrl: "./payment-details-modal.component.html",
    styleUrls: ["./payment-details-modal.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, StripeAddressComponent, ButtonComponent, FormActionsComponent, StripePaymentElementComponent,
    ],
})
export class PaymentDetailsModalComponent implements OnInit {
    @ViewChild(StripeAddressComponent)
    private addressElement?: StripeAddressComponent;
    @ViewChild(StripePaymentElementComponent)
    private paymentElement?: StripePaymentElementComponent;

    private details?: BillingDetails;
    protected stripe?: StripeInstance;
    isSubmitting: boolean = false;
    step: "address" | "payment" = "address";

    protected readonly elementsOpts = { ...elementsOptsBase };
    protected readonly addressOpts: StripeAddressElementOptions = {
        mode: "billing",
    };

    constructor(
        private dialogRef: MatDialogRef<PaymentDetailsModalComponent, DialogResult>,
        private app: ApplicationState, private stripeFactory: StripeFactoryService, private subscriptionService: SubscriptionApi,
    ) {}

    ngOnInit(): void {
        this.startAddCreditCard();
    }

    get dialogTitle(): string {
        return this.step === "address" ? "Billing Address" : "Payment Information";
    }

    get paymentOpts(): StripePaymentElementOptions {
        return {
            readOnly: this.isSubmitting,
            fields: {
                billingDetails: {
                    address: "never",
                },
            },
            terms: {
                card: "never",
            },
        };
    }

    submitAddress() {
        from(this.addressElement!.element.getValue()).subscribe(x => {
            const details = x.value;
            const name = details.name;
            const address = details.address;
            this.details = {
                name,
                address: {
                    line1: address.line1,
                    line2: address.line2 ?? "",
                    city: address.city,
                    country: address.country,
                    postal_code: address.postal_code,
                    state: address.state,
                },
            };
            this.addressOpts.defaultValues = this.details;
            this.step = "payment";
        });
    }

    private startAddCreditCard() {
        const team = this.app.requireCurrentTeam();
        this.subscriptionService.startAddCreditCard(team.uuid).subscribe((res) => {
            this.stripe = this.stripeFactory.create(res.apiKey)
            this.elementsOpts.clientSecret = res.token;
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }

    submitPayment() {
        this.isSubmitting = true;
        this.stripe!.confirmSetup({
            elements: this.paymentElement!.elements,
            confirmParams: { payment_method_data: { billing_details: this.details } },
            redirect: "if_required",
        }).subscribe((result) => {
            this.isSubmitting = false;
            if (result.error) {
                this.close({ error: result.error.message || "" });
            } else if (result.setupIntent.status === "succeeded") {
                const paymentMethod = result.setupIntent.payment_method as string;
                this.subscriptionService.setDefaultCreditCard(this.app.requireCurrentTeam().uuid, paymentMethod).subscribe({
                    error: () => {
                        this.close({ error: "Error while updating billing address" });
                    }
                });
                this.close("ok");
            }
        });
    }
}
