@if (tagline$ | async; as tagline) {
  <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
} @else {
  <div style="height: 22px;"></div>
}

@if (survey) {
  @if (survey.sections.length > 1) {
    <mat-accordion>
      @for (section of survey.sections; track section; let sectionIndex = $index) {
        <mat-expansion-panel class="tp-survey-section-container" [disabled]="$first && $last" [expanded]="$first">
          <mat-expansion-panel-header>
            <mat-panel-title>
              @if (section.isCompleted) {
                <i class="fa-light fa-check ok"></i>
              } @else {
                <i class="fa-light fa-hyphen"></i>
              }
              {{ section.spec.name || "" }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngTemplateOutlet="questions; context: { section }"></ng-container>

          @if (!$last) {
            <div class="tp-advance">
              <p><a (click)="expandSection(sectionIndex + 1)">
                Next: {{ survey.sections[sectionIndex + 1].spec.name }} →
              </a></p>
            </div>
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  } @else {
    <div class="tp-survey-section-container">
      <ng-container *ngTemplateOutlet="questions; context: { section: survey.sections[0] }"></ng-container>
    </div>
  }

  <div class="tp-finish">
    <tp-form-actions
      [submitDisabled]="!survey.isCompleted" submitText="Continue" [isSubmitting]="isSubmitting" buttonIdPrefix="completeSurvey"
      [matTooltip]="finishButtonTooltip" submitButtonStyle="primary-outline green stroke" (submitClick)="completeSurvey()"
    />
  </div>
} @else {
  <tp-spinner/>
}

<ng-template #questions let-section="section">
  @for (question of section.questions; track question) {
    @if (!section.hiddenQuestions.includes(question)) {
      <tp-multiple-choice-question @fadeInOut [question]="question" (answerChanged)="onQuestionAnswerChanged(section, question)"/>
    }
  }
</ng-template>
