<tp-modal dialogTitle="Invite user to team" [isBusy]="(isSubmitting$ | async) || !(isReady$ | async)">
  @if (isReady$ | async) {
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
      <section class="form">
        <div class="ui-form-fields-row">
          <tp-form-input type="email" [form]="form" field="email" label="User Email"/>
          <tp-form-select label="Team Access Level" [form]="form" field="accessLevel" [options]="accessLevelOptions"/>
        </div>
      </section>

      <section class="form">
        <div class="ui-resources-header-row">
          <h3>Spaces</h3>
          <button type="button" mat-mini-fab color="" aria-label="add" (click)="addSpaceEntry()"><i class="fa-light fa-plus"></i></button>
        </div>
        @for (spaceForm of form.controls.spaces.controls; track spaceForm) {
          <div class="ui-form-fields-row">
            <tp-form-select label="Space" [form]="spaceForm" field="resource" [options]="(spaceOptions$ | async) || []"/>
            <tp-form-select label="Access Level" [form]="spaceForm" field="accessLevel" [options]="accessLevelOptions"/>
            <button type="button" mat-icon-button aria-label="remove" class="ui-remove-resource" (click)="removeSpaceEntryAt($index)"><i class="fa-light fa-trash-can-xmark"></i></button>
          </div>
        }
        @if (form.controls.spaces.getError("errorText")) {
          <mat-error>{{ form.controls.spaces.getError("errorText") }}</mat-error>
        }
      </section>

      @if (form.getError("errorText")) {
        <mat-error>{{ form.getError("errorText") }}</mat-error>
      }

      <tp-form-actions
        [cancellable]="true" submitText="Invite user" [isSubmitting]="isSubmitting$ | async"
        buttonIdPrefix="invitationModal" (cancel)="close()"
      />
    </tp-form>
  }
</tp-modal>
