/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { combineLatest, map, Observable, of, startWith, Subject, switchMap } from "rxjs";
import {
    ButtonComponent,
    ConfirmationModalComponent,
    MenuItem,
    ModalComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { Space, spaceProperties } from "../../../concept/space";
import { LOADING } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { SpaceApi } from "../../../service/space/space-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { SpaceCreationDialogComponent } from "../create/space-creation-dialog.component";
import { PageScaffoldComponent, resourceAvailabilityStreamOf } from "../../scaffold/page/page-scaffold.component";
import { SpacesTableComponent } from "../table/spaces-table.component";
import { AsyncPipe } from "@angular/common";
import { ApplicationState } from "../../../service/application-state.service";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessLevel, hasAdminAccess, hasReadAccess } from "../../../concept/iam";

@Component({
    selector: "tp-spaces-page",
    templateUrl: "./spaces-page.component.html",
    styleUrls: ["./spaces-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, SpacesTableComponent, ModalComponent, ConfirmationModalComponent, AsyncPipe,
        TableToolbarComponent, TablePaginatorComponent, ButtonComponent, MatTooltipModule,
    ],
})
export class SpacesPageComponent implements OnInit, OnDestroy {
    readonly team = this.app.requireCurrentTeam();
    private readonly unsub$ = new Subject<void>();
    readonly spaces = this.teamCtl.spacesTable(this.unsub$);
    readonly secondSpaceInTeam = this.teamCtl.spacesTable(this.unsub$, {
        pagination: { offset: 1, limit: 1 },
        sorting: { attributeType: spaceProperties.id.attributeType }
    });
    readonly availability$ = resourceAvailabilityStreamOf(this.spaces);
    readonly clusterCountBySpaceUuid$: Observable<Record<string, number>> = this.spaces.items$.pipe(
        switchMap((spaces) => combineLatest(spaces.map((space) => this.spaces.accessLevelByUuid$(space.uuid)
                .pipe(map((accessLevel) => {
                    return { spaceUuid: space.uuid, accessLevel }
                }))
        ))),
        switchMap((spaces) => combineLatest(
            spaces.filter((x) => x.accessLevel && hasReadAccess(x.accessLevel)).map((x) =>
                this.spaceApi.listClusters(x.spaceUuid, { pagination: { offset: 0, limit: 1 }, sorting: { attributeType: "id" } }, of(undefined))
                    .pipe(map((depList) => [x.spaceUuid, depList.initial!.length]))
            )
        )),
        map(Object.fromEntries)
    )

    readonly cannotCreateReason$ = this.app.hasTeamWriteAccess$.pipe(map(canWrite => {
        if (!canWrite) return "Only users with write permissions to the team can create spaces";
        return null;
    }), startWith(LOADING));
    readonly createEnabled$ = this.cannotCreateReason$.pipe(map(x => !x));

    spacesTableRowPopupMenuItems: (space: Space, accessLevel: AccessLevel) => MenuItem[] = () => [];

    constructor(
        private spaceApi: SpaceApi, private teamCtl: TeamController, private dialog: MatDialog,
        private app: ApplicationState, private snackbar: SnackbarService,
    ) {}

    ngOnInit() {
        this.clusterCountBySpaceUuid$.subscribe((clusterCountBySpaceUuid) => {
            this.spacesTableRowPopupMenuItems = (space, accessLevel): MenuItem[] => {
                const spaceHasClusters = !!clusterCountBySpaceUuid[space.uuid]
                const cannotDeleteReason = !hasAdminAccess(accessLevel) ? "You don't have admin access to this space" :
                    spaceHasClusters ? "This space cannot be deleted because it has at least one cluster" :
                        !this.teamHasMultipleSpaces ? "A team must have at least one space" : undefined
                return [{
                    label: "Delete",
                    action: () => this.openDeleteModal(space),
                    disabled: !!cannotDeleteReason,
                    disabledReason: cannotDeleteReason,
                    dangerous: true
                }];
            }
        });
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    get teamHasMultipleSpaces(): boolean {
        return !!this.secondSpaceInTeam.items?.length
    }

    openCreateModal() {
        this.dialog.open(SpaceCreationDialogComponent);
    }

    openDeleteModal(space: Space) {
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete space",
                body: confirmationMessage("delete", [space], "space") + ` This action cannot be undone.`,
                confirmText: "Delete space",
                strongConfirmationString: strongConfirmationStringOf([space]),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest([space].map(x => this.spaceApi.deleteSpace(x.uuid))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Space deleted successfully.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
