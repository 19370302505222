<tp-page-scaffold [pageAvailability]="availability$ | async">
  <ng-container actionBar>
    <tp-button
      [matTooltip]="(cannotInviteReason$ | async) || ''" buttonStyle="secondary" buttonId="openInviteMembersModal"
      [enabled]="(inviteEnabled$ | async) || false" (click)="onInviteClick()"
    >
      <i class="fa-light fa-envelope"></i> Invite
    </tp-button>
  </ng-container>
  <article>
    <section>
      <tp-table-toolbar [table]="members" filterGroupId="members" tableTitle="Members" />
      <tp-users-table [table]="members" [rowPopupMenuItems]="usersTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="members"/>
    </section>

    @if (invitations) {
      <section>
        <tp-table-toolbar [table]="invitations" filterGroupId="invitations" tableTitle="Invitations"/>
        <tp-invitations-table [table]="invitations" [rowPopupMenuItems]="invitationsTableRowPopupMenuItems"/>
        <tp-table-paginator [table]="invitations"/>
      </section>
    }
  </article>
</tp-page-scaffold>
