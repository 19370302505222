/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/squad-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as dependency_2 from "./../../protocol/common";
import * as pb_1 from "google-protobuf";
export class SquadCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProto {
        const message = new SquadCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProto {
        return SquadCtlProto.deserialize(bytes);
    }
}
export class SquadCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SquadCtlProtoGetAccessLevelsReq;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: SquadCtlProtoCheckSquadIDReq;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: SquadCtlProtoCreateSquadReq;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: SquadCtlProtoGetSquadReq;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: SquadCtlProtoUpdateSquadReq;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: SquadCtlProtoDeleteSquadReq;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: SquadCtlProtoListMembersReq;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: SquadCtlProtoListSpacesReq;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: SquadCtlProtoAddMemberReq;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: SquadCtlProtoRemoveMemberReq;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        createSquad?: never;
        getSquad?: never;
        updateSquad?: never;
        deleteSquad?: never;
        listMembers?: never;
        listSpaces?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: SquadCtlProtoSetMemberAccessLevelReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkSquadId" in data && data.checkSquadId != undefined) {
                this.checkSquadId = data.checkSquadId;
            }
            if ("createSquad" in data && data.createSquad != undefined) {
                this.createSquad = data.createSquad;
            }
            if ("getSquad" in data && data.getSquad != undefined) {
                this.getSquad = data.getSquad;
            }
            if ("updateSquad" in data && data.updateSquad != undefined) {
                this.updateSquad = data.updateSquad;
            }
            if ("deleteSquad" in data && data.deleteSquad != undefined) {
                this.deleteSquad = data.deleteSquad;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listSpaces" in data && data.listSpaces != undefined) {
                this.listSpaces = data.listSpaces;
            }
            if ("addMember" in data && data.addMember != undefined) {
                this.addMember = data.addMember;
            }
            if ("removeMember" in data && data.removeMember != undefined) {
                this.removeMember = data.removeMember;
            }
            if ("setMemberAccessLevel" in data && data.setMemberAccessLevel != undefined) {
                this.setMemberAccessLevel = data.setMemberAccessLevel;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoGetAccessLevelsReq, 1) as SquadCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: SquadCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkSquadId() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoCheckSquadIDReq, 2) as SquadCtlProtoCheckSquadIDReq;
    }
    set checkSquadId(value: SquadCtlProtoCheckSquadIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckSquadId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createSquad() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoCreateSquadReq, 3) as SquadCtlProtoCreateSquadReq;
    }
    set createSquad(value: SquadCtlProtoCreateSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateSquad() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getSquad() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoGetSquadReq, 4) as SquadCtlProtoGetSquadReq;
    }
    set getSquad(value: SquadCtlProtoGetSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetSquad() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateSquad() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoUpdateSquadReq, 5) as SquadCtlProtoUpdateSquadReq;
    }
    set updateSquad(value: SquadCtlProtoUpdateSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateSquad() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get deleteSquad() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoDeleteSquadReq, 6) as SquadCtlProtoDeleteSquadReq;
    }
    set deleteSquad(value: SquadCtlProtoDeleteSquadReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasDeleteSquad() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoListMembersReq, 7) as SquadCtlProtoListMembersReq;
    }
    set listMembers(value: SquadCtlProtoListMembersReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSpaces() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoListSpacesReq, 8) as SquadCtlProtoListSpacesReq;
    }
    set listSpaces(value: SquadCtlProtoListSpacesReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSpaces() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get addMember() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoAddMemberReq, 9) as SquadCtlProtoAddMemberReq;
    }
    set addMember(value: SquadCtlProtoAddMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasAddMember() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get removeMember() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoRemoveMemberReq, 10) as SquadCtlProtoRemoveMemberReq;
    }
    set removeMember(value: SquadCtlProtoRemoveMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasRemoveMember() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get setMemberAccessLevel() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoSetMemberAccessLevelReq, 11) as SquadCtlProtoSetMemberAccessLevelReq;
    }
    set setMemberAccessLevel(value: SquadCtlProtoSetMemberAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasSetMemberAccessLevel() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkSquadId" | "createSquad" | "getSquad" | "updateSquad" | "deleteSquad" | "listMembers" | "listSpaces" | "addMember" | "removeMember" | "setMemberAccessLevel";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkSquadId",
            3: "createSquad",
            4: "getSquad",
            5: "updateSquad",
            6: "deleteSquad",
            7: "listMembers",
            8: "listSpaces",
            9: "addMember",
            10: "removeMember",
            11: "setMemberAccessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SquadCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkSquadId?: ReturnType<typeof SquadCtlProtoCheckSquadIDReq.prototype.toObject>;
        createSquad?: ReturnType<typeof SquadCtlProtoCreateSquadReq.prototype.toObject>;
        getSquad?: ReturnType<typeof SquadCtlProtoGetSquadReq.prototype.toObject>;
        updateSquad?: ReturnType<typeof SquadCtlProtoUpdateSquadReq.prototype.toObject>;
        deleteSquad?: ReturnType<typeof SquadCtlProtoDeleteSquadReq.prototype.toObject>;
        listMembers?: ReturnType<typeof SquadCtlProtoListMembersReq.prototype.toObject>;
        listSpaces?: ReturnType<typeof SquadCtlProtoListSpacesReq.prototype.toObject>;
        addMember?: ReturnType<typeof SquadCtlProtoAddMemberReq.prototype.toObject>;
        removeMember?: ReturnType<typeof SquadCtlProtoRemoveMemberReq.prototype.toObject>;
        setMemberAccessLevel?: ReturnType<typeof SquadCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
    }): SquadCtlProtoReq {
        const message = new SquadCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SquadCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkSquadId != null) {
            message.checkSquadId = SquadCtlProtoCheckSquadIDReq.fromObject(data.checkSquadId);
        }
        if (data.createSquad != null) {
            message.createSquad = SquadCtlProtoCreateSquadReq.fromObject(data.createSquad);
        }
        if (data.getSquad != null) {
            message.getSquad = SquadCtlProtoGetSquadReq.fromObject(data.getSquad);
        }
        if (data.updateSquad != null) {
            message.updateSquad = SquadCtlProtoUpdateSquadReq.fromObject(data.updateSquad);
        }
        if (data.deleteSquad != null) {
            message.deleteSquad = SquadCtlProtoDeleteSquadReq.fromObject(data.deleteSquad);
        }
        if (data.listMembers != null) {
            message.listMembers = SquadCtlProtoListMembersReq.fromObject(data.listMembers);
        }
        if (data.listSpaces != null) {
            message.listSpaces = SquadCtlProtoListSpacesReq.fromObject(data.listSpaces);
        }
        if (data.addMember != null) {
            message.addMember = SquadCtlProtoAddMemberReq.fromObject(data.addMember);
        }
        if (data.removeMember != null) {
            message.removeMember = SquadCtlProtoRemoveMemberReq.fromObject(data.removeMember);
        }
        if (data.setMemberAccessLevel != null) {
            message.setMemberAccessLevel = SquadCtlProtoSetMemberAccessLevelReq.fromObject(data.setMemberAccessLevel);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SquadCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkSquadId?: ReturnType<typeof SquadCtlProtoCheckSquadIDReq.prototype.toObject>;
            createSquad?: ReturnType<typeof SquadCtlProtoCreateSquadReq.prototype.toObject>;
            getSquad?: ReturnType<typeof SquadCtlProtoGetSquadReq.prototype.toObject>;
            updateSquad?: ReturnType<typeof SquadCtlProtoUpdateSquadReq.prototype.toObject>;
            deleteSquad?: ReturnType<typeof SquadCtlProtoDeleteSquadReq.prototype.toObject>;
            listMembers?: ReturnType<typeof SquadCtlProtoListMembersReq.prototype.toObject>;
            listSpaces?: ReturnType<typeof SquadCtlProtoListSpacesReq.prototype.toObject>;
            addMember?: ReturnType<typeof SquadCtlProtoAddMemberReq.prototype.toObject>;
            removeMember?: ReturnType<typeof SquadCtlProtoRemoveMemberReq.prototype.toObject>;
            setMemberAccessLevel?: ReturnType<typeof SquadCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkSquadId != null) {
            data.checkSquadId = this.checkSquadId.toObject();
        }
        if (this.createSquad != null) {
            data.createSquad = this.createSquad.toObject();
        }
        if (this.getSquad != null) {
            data.getSquad = this.getSquad.toObject();
        }
        if (this.updateSquad != null) {
            data.updateSquad = this.updateSquad.toObject();
        }
        if (this.deleteSquad != null) {
            data.deleteSquad = this.deleteSquad.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listSpaces != null) {
            data.listSpaces = this.listSpaces.toObject();
        }
        if (this.addMember != null) {
            data.addMember = this.addMember.toObject();
        }
        if (this.removeMember != null) {
            data.removeMember = this.removeMember.toObject();
        }
        if (this.setMemberAccessLevel != null) {
            data.setMemberAccessLevel = this.setMemberAccessLevel.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckSquadId)
            writer.writeMessage(2, this.checkSquadId, () => this.checkSquadId.serialize(writer));
        if (this.hasCreateSquad)
            writer.writeMessage(3, this.createSquad, () => this.createSquad.serialize(writer));
        if (this.hasGetSquad)
            writer.writeMessage(4, this.getSquad, () => this.getSquad.serialize(writer));
        if (this.hasUpdateSquad)
            writer.writeMessage(5, this.updateSquad, () => this.updateSquad.serialize(writer));
        if (this.hasDeleteSquad)
            writer.writeMessage(6, this.deleteSquad, () => this.deleteSquad.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(7, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListSpaces)
            writer.writeMessage(8, this.listSpaces, () => this.listSpaces.serialize(writer));
        if (this.hasAddMember)
            writer.writeMessage(9, this.addMember, () => this.addMember.serialize(writer));
        if (this.hasRemoveMember)
            writer.writeMessage(10, this.removeMember, () => this.removeMember.serialize(writer));
        if (this.hasSetMemberAccessLevel)
            writer.writeMessage(11, this.setMemberAccessLevel, () => this.setMemberAccessLevel.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SquadCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkSquadId, () => message.checkSquadId = SquadCtlProtoCheckSquadIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createSquad, () => message.createSquad = SquadCtlProtoCreateSquadReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getSquad, () => message.getSquad = SquadCtlProtoGetSquadReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateSquad, () => message.updateSquad = SquadCtlProtoUpdateSquadReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.deleteSquad, () => message.deleteSquad = SquadCtlProtoDeleteSquadReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listMembers, () => message.listMembers = SquadCtlProtoListMembersReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSpaces, () => message.listSpaces = SquadCtlProtoListSpacesReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.addMember, () => message.addMember = SquadCtlProtoAddMemberReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.removeMember, () => message.removeMember = SquadCtlProtoRemoveMemberReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.setMemberAccessLevel, () => message.setMemberAccessLevel = SquadCtlProtoSetMemberAccessLevelReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoReq {
        return SquadCtlProtoReq.deserialize(bytes);
    }
}
export class SquadCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SquadCtlProtoGetAccessLevelsRes;
        checkSquadId?: never;
        getSquad?: never;
        listMembers?: never;
        listSpaces?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: SquadCtlProtoCheckSquadIDRes;
        getSquad?: never;
        listMembers?: never;
        listSpaces?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        getSquad?: SquadCtlProtoGetSquadRes;
        listMembers?: never;
        listSpaces?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        getSquad?: never;
        listMembers?: SquadCtlProtoListMembersRes;
        listSpaces?: never;
    } | {
        getAccessLevels?: never;
        checkSquadId?: never;
        getSquad?: never;
        listMembers?: never;
        listSpaces?: SquadCtlProtoListSpacesRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkSquadId" in data && data.checkSquadId != undefined) {
                this.checkSquadId = data.checkSquadId;
            }
            if ("getSquad" in data && data.getSquad != undefined) {
                this.getSquad = data.getSquad;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listSpaces" in data && data.listSpaces != undefined) {
                this.listSpaces = data.listSpaces;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoGetAccessLevelsRes, 1) as SquadCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: SquadCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkSquadId() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoCheckSquadIDRes, 2) as SquadCtlProtoCheckSquadIDRes;
    }
    set checkSquadId(value: SquadCtlProtoCheckSquadIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckSquadId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getSquad() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoGetSquadRes, 4) as SquadCtlProtoGetSquadRes;
    }
    set getSquad(value: SquadCtlProtoGetSquadRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetSquad() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoListMembersRes, 7) as SquadCtlProtoListMembersRes;
    }
    set listMembers(value: SquadCtlProtoListMembersRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listSpaces() {
        return pb_1.Message.getWrapperField(this, SquadCtlProtoListSpacesRes, 8) as SquadCtlProtoListSpacesRes;
    }
    set listSpaces(value: SquadCtlProtoListSpacesRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListSpaces() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkSquadId" | "getSquad" | "listMembers" | "listSpaces";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkSquadId",
            4: "getSquad",
            7: "listMembers",
            8: "listSpaces"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SquadCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkSquadId?: ReturnType<typeof SquadCtlProtoCheckSquadIDRes.prototype.toObject>;
        getSquad?: ReturnType<typeof SquadCtlProtoGetSquadRes.prototype.toObject>;
        listMembers?: ReturnType<typeof SquadCtlProtoListMembersRes.prototype.toObject>;
        listSpaces?: ReturnType<typeof SquadCtlProtoListSpacesRes.prototype.toObject>;
    }): SquadCtlProtoRes {
        const message = new SquadCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SquadCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkSquadId != null) {
            message.checkSquadId = SquadCtlProtoCheckSquadIDRes.fromObject(data.checkSquadId);
        }
        if (data.getSquad != null) {
            message.getSquad = SquadCtlProtoGetSquadRes.fromObject(data.getSquad);
        }
        if (data.listMembers != null) {
            message.listMembers = SquadCtlProtoListMembersRes.fromObject(data.listMembers);
        }
        if (data.listSpaces != null) {
            message.listSpaces = SquadCtlProtoListSpacesRes.fromObject(data.listSpaces);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SquadCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkSquadId?: ReturnType<typeof SquadCtlProtoCheckSquadIDRes.prototype.toObject>;
            getSquad?: ReturnType<typeof SquadCtlProtoGetSquadRes.prototype.toObject>;
            listMembers?: ReturnType<typeof SquadCtlProtoListMembersRes.prototype.toObject>;
            listSpaces?: ReturnType<typeof SquadCtlProtoListSpacesRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkSquadId != null) {
            data.checkSquadId = this.checkSquadId.toObject();
        }
        if (this.getSquad != null) {
            data.getSquad = this.getSquad.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listSpaces != null) {
            data.listSpaces = this.listSpaces.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckSquadId)
            writer.writeMessage(2, this.checkSquadId, () => this.checkSquadId.serialize(writer));
        if (this.hasGetSquad)
            writer.writeMessage(4, this.getSquad, () => this.getSquad.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(7, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListSpaces)
            writer.writeMessage(8, this.listSpaces, () => this.listSpaces.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SquadCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkSquadId, () => message.checkSquadId = SquadCtlProtoCheckSquadIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getSquad, () => message.getSquad = SquadCtlProtoGetSquadRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listMembers, () => message.listMembers = SquadCtlProtoListMembersRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listSpaces, () => message.listSpaces = SquadCtlProtoListSpacesRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoRes {
        return SquadCtlProtoRes.deserialize(bytes);
    }
}
export class SquadCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoGetAccessLevels {
        const message = new SquadCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetAccessLevels {
        return SquadCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class SquadCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuids" in data && data.squadUuids != undefined) {
                this.squadUuids = data.squadUuids;
            }
        }
    }
    get squadUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set squadUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        squadUuids?: Uint8Array[];
    }): SquadCtlProtoGetAccessLevelsReq {
        const message = new SquadCtlProtoGetAccessLevelsReq({});
        if (data.squadUuids != null) {
            message.squadUuids = data.squadUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuids?: Uint8Array[];
        } = {};
        if (this.squadUuids != null) {
            data.squadUuids = this.squadUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuids.length)
            writer.writeRepeatedBytes(1, this.squadUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetAccessLevelsReq {
        return SquadCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class SquadCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): SquadCtlProtoGetAccessLevelsRes {
        const message = new SquadCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetAccessLevelsRes {
        return SquadCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class SquadCtlProtoCheckSquadID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoCheckSquadID {
        const message = new SquadCtlProtoCheckSquadID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoCheckSquadID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoCheckSquadID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoCheckSquadID {
        return SquadCtlProtoCheckSquadID.deserialize(bytes);
    }
}
export class SquadCtlProtoCheckSquadIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        id?: string;
    }): SquadCtlProtoCheckSquadIDReq {
        const message = new SquadCtlProtoCheckSquadIDReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            id?: string;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoCheckSquadIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoCheckSquadIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoCheckSquadIDReq {
        return SquadCtlProtoCheckSquadIDReq.deserialize(bytes);
    }
}
export class SquadCtlProtoCheckSquadIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): SquadCtlProtoCheckSquadIDRes {
        const message = new SquadCtlProtoCheckSquadIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoCheckSquadIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoCheckSquadIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoCheckSquadIDRes {
        return SquadCtlProtoCheckSquadIDRes.deserialize(bytes);
    }
}
export class SquadCtlProtoCreateSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoCreateSquad {
        const message = new SquadCtlProtoCreateSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoCreateSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoCreateSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoCreateSquad {
        return SquadCtlProtoCreateSquad.deserialize(bytes);
    }
}
export class SquadCtlProtoCreateSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        squad?: dependency_1.SquadProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, dependency_1.SquadProto, 2) as dependency_1.SquadProto;
    }
    set squad(value: dependency_1.SquadProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
    }): SquadCtlProtoCreateSquadReq {
        const message = new SquadCtlProtoCreateSquadReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.squad != null) {
            message.squad = dependency_1.SquadProto.fromObject(data.squad);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasSquad)
            writer.writeMessage(2, this.squad, () => this.squad.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoCreateSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoCreateSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.squad, () => message.squad = dependency_1.SquadProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoCreateSquadReq {
        return SquadCtlProtoCreateSquadReq.deserialize(bytes);
    }
}
export class SquadCtlProtoGetSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoGetSquad {
        const message = new SquadCtlProtoGetSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetSquad {
        return SquadCtlProtoGetSquad.deserialize(bytes);
    }
}
export class SquadCtlProtoGetSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        squadId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("squadId" in data && data.squadId != undefined) {
                this.squadId = data.squadId;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get squadId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set squadId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        squadId?: string;
    }): SquadCtlProtoGetSquadReq {
        const message = new SquadCtlProtoGetSquadReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.squadId != null) {
            message.squadId = data.squadId;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            squadId?: string;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.squadId != null) {
            data.squadId = this.squadId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.squadId.length)
            writer.writeString(2, this.squadId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.squadId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetSquadReq {
        return SquadCtlProtoGetSquadReq.deserialize(bytes);
    }
}
export class SquadCtlProtoGetSquadRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squad?: dependency_1.SquadProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
        }
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, dependency_1.SquadProto, 1) as dependency_1.SquadProto;
    }
    set squad(value: dependency_1.SquadProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
    }): SquadCtlProtoGetSquadRes {
        const message = new SquadCtlProtoGetSquadRes({});
        if (data.squad != null) {
            message.squad = dependency_1.SquadProto.fromObject(data.squad);
        }
        return message;
    }
    toObject() {
        const data: {
            squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
        } = {};
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSquad)
            writer.writeMessage(1, this.squad, () => this.squad.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoGetSquadRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoGetSquadRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squad, () => message.squad = dependency_1.SquadProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoGetSquadRes {
        return SquadCtlProtoGetSquadRes.deserialize(bytes);
    }
}
export class SquadCtlProtoUpdateSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoUpdateSquad {
        const message = new SquadCtlProtoUpdateSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoUpdateSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoUpdateSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoUpdateSquad {
        return SquadCtlProtoUpdateSquad.deserialize(bytes);
    }
}
export class SquadCtlProtoUpdateSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squad?: dependency_1.SquadProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
        }
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, dependency_1.SquadProto, 1) as dependency_1.SquadProto;
    }
    set squad(value: dependency_1.SquadProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
    }): SquadCtlProtoUpdateSquadReq {
        const message = new SquadCtlProtoUpdateSquadReq({});
        if (data.squad != null) {
            message.squad = dependency_1.SquadProto.fromObject(data.squad);
        }
        return message;
    }
    toObject() {
        const data: {
            squad?: ReturnType<typeof dependency_1.SquadProto.prototype.toObject>;
        } = {};
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSquad)
            writer.writeMessage(1, this.squad, () => this.squad.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoUpdateSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoUpdateSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squad, () => message.squad = dependency_1.SquadProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoUpdateSquadReq {
        return SquadCtlProtoUpdateSquadReq.deserialize(bytes);
    }
}
export class SquadCtlProtoDeleteSquad extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoDeleteSquad {
        const message = new SquadCtlProtoDeleteSquad({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoDeleteSquad {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoDeleteSquad();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoDeleteSquad {
        return SquadCtlProtoDeleteSquad.deserialize(bytes);
    }
}
export class SquadCtlProtoDeleteSquadReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
    }): SquadCtlProtoDeleteSquadReq {
        const message = new SquadCtlProtoDeleteSquadReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoDeleteSquadReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoDeleteSquadReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoDeleteSquadReq {
        return SquadCtlProtoDeleteSquadReq.deserialize(bytes);
    }
}
export class SquadCtlProtoListMembers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoListMembers {
        const message = new SquadCtlProtoListMembers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListMembers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListMembers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListMembers {
        return SquadCtlProtoListMembers.deserialize(bytes);
    }
}
export class SquadCtlProtoListMembersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SquadCtlProtoListMembersReq {
        const message = new SquadCtlProtoListMembersReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListMembersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListMembersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListMembersReq {
        return SquadCtlProtoListMembersReq.deserialize(bytes);
    }
}
export class SquadCtlProtoListMembersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        members?: dependency_1.SquadMemberProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("members" in data && data.members != undefined) {
                this.members = data.members;
            }
        }
    }
    get members() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.SquadMemberProto, 1) as dependency_1.SquadMemberProto[];
    }
    set members(value: dependency_1.SquadMemberProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        members?: ReturnType<typeof dependency_1.SquadMemberProto.prototype.toObject>[];
    }): SquadCtlProtoListMembersRes {
        const message = new SquadCtlProtoListMembersRes({});
        if (data.members != null) {
            message.members = data.members.map(item => dependency_1.SquadMemberProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            members?: ReturnType<typeof dependency_1.SquadMemberProto.prototype.toObject>[];
        } = {};
        if (this.members != null) {
            data.members = this.members.map((item: dependency_1.SquadMemberProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.members.length)
            writer.writeRepeatedMessage(1, this.members, (item: dependency_1.SquadMemberProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListMembersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListMembersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.members, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.SquadMemberProto.deserialize(reader), dependency_1.SquadMemberProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListMembersRes {
        return SquadCtlProtoListMembersRes.deserialize(bytes);
    }
}
export class SquadCtlProtoListSpaces extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoListSpaces {
        const message = new SquadCtlProtoListSpaces({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListSpaces {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListSpaces();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListSpaces {
        return SquadCtlProtoListSpaces.deserialize(bytes);
    }
}
export class SquadCtlProtoListSpacesReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SquadCtlProtoListSpacesReq {
        const message = new SquadCtlProtoListSpacesReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListSpacesReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListSpacesReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListSpacesReq {
        return SquadCtlProtoListSpacesReq.deserialize(bytes);
    }
}
export class SquadCtlProtoListSpacesRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaces?: dependency_1.SquadSpaceProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaces" in data && data.spaces != undefined) {
                this.spaces = data.spaces;
            }
        }
    }
    get spaces() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.SquadSpaceProto, 1) as dependency_1.SquadSpaceProto[];
    }
    set spaces(value: dependency_1.SquadSpaceProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        spaces?: ReturnType<typeof dependency_1.SquadSpaceProto.prototype.toObject>[];
    }): SquadCtlProtoListSpacesRes {
        const message = new SquadCtlProtoListSpacesRes({});
        if (data.spaces != null) {
            message.spaces = data.spaces.map(item => dependency_1.SquadSpaceProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            spaces?: ReturnType<typeof dependency_1.SquadSpaceProto.prototype.toObject>[];
        } = {};
        if (this.spaces != null) {
            data.spaces = this.spaces.map((item: dependency_1.SquadSpaceProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaces.length)
            writer.writeRepeatedMessage(1, this.spaces, (item: dependency_1.SquadSpaceProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoListSpacesRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoListSpacesRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.spaces, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.SquadSpaceProto.deserialize(reader), dependency_1.SquadSpaceProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoListSpacesRes {
        return SquadCtlProtoListSpacesRes.deserialize(bytes);
    }
}
export class SquadCtlProtoAddMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoAddMember {
        const message = new SquadCtlProtoAddMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoAddMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoAddMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoAddMember {
        return SquadCtlProtoAddMember.deserialize(bytes);
    }
}
export class SquadCtlProtoAddMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SquadCtlProtoAddMemberReq {
        const message = new SquadCtlProtoAddMemberReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoAddMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoAddMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoAddMemberReq {
        return SquadCtlProtoAddMemberReq.deserialize(bytes);
    }
}
export class SquadCtlProtoRemoveMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoRemoveMember {
        const message = new SquadCtlProtoRemoveMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoRemoveMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoRemoveMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoRemoveMember {
        return SquadCtlProtoRemoveMember.deserialize(bytes);
    }
}
export class SquadCtlProtoRemoveMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): SquadCtlProtoRemoveMemberReq {
        const message = new SquadCtlProtoRemoveMemberReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoRemoveMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoRemoveMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoRemoveMemberReq {
        return SquadCtlProtoRemoveMemberReq.deserialize(bytes);
    }
}
export class SquadCtlProtoSetMemberAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SquadCtlProtoSetMemberAccessLevel {
        const message = new SquadCtlProtoSetMemberAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoSetMemberAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoSetMemberAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoSetMemberAccessLevel {
        return SquadCtlProtoSetMemberAccessLevel.deserialize(bytes);
    }
}
export class SquadCtlProtoSetMemberAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squadUuid" in data && data.squadUuid != undefined) {
                this.squadUuid = data.squadUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squadUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set squadUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        squadUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): SquadCtlProtoSetMemberAccessLevelReq {
        const message = new SquadCtlProtoSetMemberAccessLevelReq({});
        if (data.squadUuid != null) {
            message.squadUuid = data.squadUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squadUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.squadUuid != null) {
            data.squadUuid = this.squadUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.squadUuid.length)
            writer.writeBytes(1, this.squadUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadCtlProtoSetMemberAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadCtlProtoSetMemberAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.squadUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadCtlProtoSetMemberAccessLevelReq {
        return SquadCtlProtoSetMemberAccessLevelReq.deserialize(bytes);
    }
}
