/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { InvitationProto, UserTeamProto, TeamProto } from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseConcept, BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevel, accessLevelOf } from "./iam";
import { partialSquadOf, Squad } from "./squad";
import { partialSpaceOf, Space } from "./space";

export interface Team extends BaseResource {
    name: string;
    createdAt: Date;
    isPersonal: boolean;
}

export interface UserTeam extends Team {
    accessLevel?: AccessLevel;
}

export function partialTeamOf(data: TeamProto): PartialWithUuid<Team> {
    const team: PartialWithUuid<Team> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        isPersonal: data.hasIsPersonal ? data.isPersonal : undefined,
    };
    return stripUndefinedValues(team) as PartialWithUuid<Team>;
}

export function partialUserTeamOf(data: UserTeamProto): PartialWithUuid<UserTeam> {
    const userTeam: PartialWithUuid<UserTeam> = {
        ...partialTeamOf(data.team),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(userTeam) as PartialWithUuid<UserTeam>;
}

export function teamOf(team: TeamProto): Team {
    return partialTeamOf(team) as Team;
}

export function teamProtoOf(team: Partial<Team>): TeamProto {
    return new TeamProto({
        uuid: team.uuid ? stringToBytes(team.uuid) : undefined,
        id: team.id,
        name: team.name,
        createdAt: team.createdAt?.getTime(),
        isPersonal: team.isPersonal,
    });
}

export interface Invitation extends BaseConcept {
    userEmail: string;
    createdAt: Date;
    team: PartialWithUuid<Team>;
    accessLevel: AccessLevel;
    squadAccess: { squad: PartialWithUuid<Squad>, accessLevel: AccessLevel }[];
    spaceAccess: { space: PartialWithUuid<Space>, accessLevel: AccessLevel }[];
}

export function partialInvitationOf(data: InvitationProto): PartialWithUuid<Invitation> {
    const invitation: PartialWithUuid<Invitation> = {
        uuid: bytesToString(data.uuid),
        userEmail: data.hasUserEmail ? data.userEmail : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        team: data.hasTeam ? partialTeamOf(data.team) : undefined,
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
        squadAccess: data.squadAccess.map((squadAccess) => {
            return { squad: partialSquadOf(squadAccess.squad), accessLevel: accessLevelOf(squadAccess.accessLevel) };
        }),
        spaceAccess: data.spaceAccess.map((spaceAccess) => {
            return { space: partialSpaceOf(spaceAccess.space), accessLevel: accessLevelOf(spaceAccess.accessLevel) };
        }),
    };
    return stripUndefinedValues(invitation) as PartialWithUuid<Invitation>;
}

export type TeamColumn = "id" | "name" | "createdAt" | "accessLevel";

export const teamProperties: Record<TeamColumn, Property> = {
    id: { id: "id", name: "Team ID", attributeType: "id" },
    name: { id: "name", name: "Team Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
    accessLevel: { id: "accessLevel", name: "Access Level", attributeType: "access-level", ownerType: "membership" },
};

export const teamPropertiesList = [teamProperties.id, teamProperties.name, teamProperties.createdAt];
export const teamPropertiesListWithAccessLevel = [...teamPropertiesList, teamProperties.accessLevel];

export type InvitationColumn = "teamId" | "teamName" | "userEmail" | "createdAt" | "teamAccessLevel";

export const invitationProperties: Record<InvitationColumn, Property> = {
    teamId: { id: "teamId", name: "Team ID", attributeType: "id", ownerType: "team" },
    teamName: { id: "teamName", name: "Team Name", attributeType: "name", ownerType: "team" },
    userEmail: { id: "userEmail", name: "User Email", attributeType: "email" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
    teamAccessLevel: { id: "teamAccessLevel", name: "Team Access Level", ownerType: "team-invitation", attributeType: "access-level" },
};

export const invitationPropertiesList = [
    invitationProperties.teamId, invitationProperties.teamName,
    invitationProperties.userEmail, invitationProperties.createdAt,
    invitationProperties.teamAccessLevel
];
