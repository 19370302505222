/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Property } from "../../concept/base";
import { Parameters } from "../../concept/common";
import {
    Invitation,
    InvitationColumn,
    invitationProperties,
    invitationPropertiesList,
    Team,
    TeamColumn,
    teamProperties,
    teamPropertiesListWithAccessLevel
} from "../../concept/team";
import {
    SpaceColumn, spaceProperties,
    UserSpace, userSpaceProperties,
    userSpacePropertiesList
} from "../../concept/space";
import { UserSquad, memberSquadProperties, memberSquadPropertiesList, SquadColumn } from "../../concept/squad";
import { AccessLevel, accessLevelValues } from "../../concept/iam";
import { User } from "../../concept/user";
import { stringFilterSpec } from "../../framework/table";
import { AuthorizationService } from "../authorization.service";
import { BaseSpacesTable, BaseSquadsTable } from "../team/team-controller.service";
import { ResourceTable } from "../resource-table.service";
import { first, map, Observable, of, Subject } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { UserApi } from "./user-api.service";
import { ApplicationState } from "../application-state.service";

@Injectable({
    providedIn: "root",
})
export class UserController {
    constructor(private api: UserApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    teamsTable(unsub$: Subject<void>): ResourceTable<Team, TeamColumn> {
        return new UserTeamsTable(this.api, this.authorization, unsub$);
    }

    spacesTable(user: User, unsub$: Subject<void>): ResourceTable<UserSpace, SpaceColumn> {
        return new UserSpacesTable(this.app.requireCurrentTeam(), user, this.api, this.authorization, unsub$);
    }

    squadsTable(user: User, unsub$: Subject<void>): ResourceTable<UserSquad, SquadColumn> {
        return new UserSquadsTable(this.app.requireCurrentTeam(), user, this.api, this.authorization, unsub$);
    }

    invitationsTable(unsub$: Subject<void>): ResourceTable<Invitation, InvitationColumn> {
        return new UserInvitationsTable(this.api, this.authorization, unsub$);
    }

    getDefaultTeam(): Observable<Team> {
        const lastUsedTeamId = this.app.lastUsedTeamId();
        return this.api.listTeams({ pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }, of(undefined)).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            map(teams => {
                return teams.find(x => x.id === lastUsedTeamId) || teams[0];
            }),
        );
    }
}

class UserTeamsTable extends ResourceTable<Team, TeamColumn> {
    override readonly properties = teamPropertiesListWithAccessLevel;
    override readonly primaryProperty = teamProperties.id;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(teamProperties.id),
        stringFilterSpec(teamProperties.name),
        stringFilterSpec(teamProperties.accessLevel, accessLevelValues),
    ];

    constructor(private api: UserApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Team>> {
        return this.api.listTeams(params, this.unsub$);
    }

    override getAccessLevels(resources: Team[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels(resources.map(x => x.uuid));
    }

    override get displayedColumns(): TeamColumn[] {
        return [...this.displayedProperties.map(x => x.id)] as TeamColumn[];
    }

    override itemRoute(team: Team): string {
        return `/team/${team.id}`;
    }

    override noAccessMessage(team: Team): string {
        return `You don't have access to the team '${team.id}'`;
    }
}

class UserSpacesTable extends BaseSpacesTable<UserSpace> {
    override properties = userSpacePropertiesList
    override displayedProperties = [...this.properties]
    override filterSpecs = [
        stringFilterSpec(spaceProperties.id),
        stringFilterSpec(spaceProperties.name),
        stringFilterSpec(userSpaceProperties.accessLevel, accessLevelValues),
    ];

    constructor(team: Team, private user: User, private userApi: UserApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override get displayedColumns(): SpaceColumn[] {
        return [...this.displayedProperties.map(x => x.id as SpaceColumn)];
    }

    override getData(params: Parameters): Observable<ApiListResponse<UserSpace>> {
        return this.userApi.listSpaces({ userUuid: this.user.uuid, teamUuid: this.team.uuid }, params, this.unsub$);
    }
}

class UserSquadsTable extends BaseSquadsTable<UserSquad, SquadColumn> {
    override properties = memberSquadPropertiesList
    override displayedProperties = [...this.properties]
    override filterSpecs = [
        stringFilterSpec(memberSquadProperties.id),
        stringFilterSpec(memberSquadProperties.name),
        stringFilterSpec(memberSquadProperties.accessLevel, accessLevelValues),
    ];

    constructor(team: Team, private user: User, private userApi: UserApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(team, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<UserSquad>> {
        return this.userApi.listSquads({ userUuid: this.user.uuid, teamUuid: this.team.uuid }, params, this.unsub$);
    }

    override get displayedColumns(): SquadColumn[] {
        return [...this.displayedProperties.map(x => x.id as SquadColumn)];
    }
}

class UserInvitationsTable extends ResourceTable<Invitation, InvitationColumn> {
    override readonly properties = invitationPropertiesList.filter(x => x.id !== invitationProperties.userEmail.id);
    override readonly primaryProperty = invitationProperties.teamId;
    override displayedProperties: Property[] = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(invitationProperties.teamId),
        stringFilterSpec(invitationProperties.teamName),
        stringFilterSpec(invitationProperties.teamAccessLevel, accessLevelValues),
    ];

    constructor(private userApi: UserApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "created-at", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Invitation>> {
        return this.userApi.listInvitations(params, this.unsub$);
    }

    override getAccessLevels(resources: Invitation[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels(resources.map(x => x.team.uuid));
    }

    override get displayedColumns(): ("actions" | InvitationColumn)[] {
        return [...this.displayedProperties.map(x => x.id as InvitationColumn), "actions"];
    }

    override itemRoute(): string {
        return ``;
    }

    override noAccessMessage(): string {
        return ``;
    }
}
