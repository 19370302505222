/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { Subject } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent, ButtonStyle, FormActionsComponent, ModalComponent, PropertiesTableComponent,
    PropertiesTableRowComponent, SpinnerComponent } from "typedb-platform-framework";
import { Cluster, ClusterPrice } from "../../../concept/cluster";
import { ClusterApi } from "../../../service/cluster/cluster-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { renderCentsAsUSD } from "../../../util";

export type SuspendResumeModalData = { cluster: Cluster, action: "resume" | "suspend" };

@Component({
    selector: "tp-suspend-resume-modal",
    templateUrl: "./suspend-resume-modal.component.html",
    styleUrls: ["./suspend-resume-modal.component.scss"],
    standalone: true,
    imports: [FormsModule, ButtonComponent, ModalComponent, FormActionsComponent, AsyncPipe, SpinnerComponent, PropertiesTableComponent, PropertiesTableRowComponent],
})
export class SuspendResumeModalComponent {
    @Output() confirmed = new EventEmitter<void>();
    isSubmitting$ = new Subject<boolean>();
    price$ = this.clusterApi.getClusterPriceCentsPerHour({ cluster: this.data.cluster, teamUuid: this.app.requireCurrentTeam().uuid });
    submitText = this.data.action == "resume" ? "Resume cluster" : "Suspend cluster";
    title = this.data.action == "resume" ? "Resume cluster" : "Suspend cluster";
    buttonIdPrefix = `${this.data.action}Modal`
    submitButtonStyle: ButtonStyle = this.data.action == "resume" ? "primary-outline green stroke" : "primary-outline red stroke";

    constructor(
        private dialogRef: MatDialogRef<SuspendResumeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SuspendResumeModalData,
        private clusterApi: ClusterApi, private app: ApplicationState
    ) {}

    confirm(): void {
        this.isSubmitting$.next(true);
        this.confirmed.emit();
    }

    close(): void {
        this.dialogRef.close();
    }

    runningPriceString(price: ClusterPrice) {
        return `${renderCentsAsUSD(price.running.centsPerHour)}/hour`;
    }

    suspendedPriceString(price: ClusterPrice) {
        return `${renderCentsAsUSD(price.suspended.centsPerHour, 3)}/hour`;
    }
}
