/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ResourceTable } from "../../../service/resource-table.service";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { Observable, combineLatest, map } from "rxjs";
import { ButtonComponent, SpinnerComponent } from "typedb-platform-framework";
import { RightSidebarComponent } from "../sidebar/right/right-sidebar.component";
import { ApplicationState } from "../../../service/application-state.service";
import { NgClass } from "@angular/common";
import { trigger, transition, animate, style, state } from '@angular/animations';

export type ResourceAvailability = "ready" | "loading" | "failed";

export function resourceAvailabilityStreamOf(...resourceTables: ResourceTable<any, any>[]): Observable<ResourceAvailability> {
    return combineLatest(resourceTables.map(x => x.ready$)).pipe(
        map(loadingStates => loadingStates.some(y => !y) ? "loading" : "ready")
    );
}

@Component({
    selector: "tp-page-scaffold",
    templateUrl: "./page-scaffold.component.html",
    styleUrls: ["./page-scaffold.component.scss"],
    standalone: true,
    imports: [
        SidebarComponent, RightSidebarComponent, SpinnerComponent, NgClass, MatSidenavModule, ButtonComponent,
    ],
    animations: [
        trigger("sidebarLeftMargin", [
            state("open", style({ "margin-left": "289px" })),
            state("collapsed", style({ "margin-left": "101px" })),
            transition("open <=> collapsed", animate("250ms ease")),
        ])
    ],
})
export class PageScaffoldComponent {
    @Input() pageAvailability: ResourceAvailability | null = "ready";

    constructor(private app: ApplicationState) {}

    leftSidebarCollapsed = this.app.getSidebarCollapsed();
}
