/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { PartialWithId } from "../concept/base";
import { Cluster, Server } from "../concept/cluster";
import { Team } from "../concept/team";
import { Space } from "../concept/space";
import { Squad } from "../concept/squad";
import { User } from "../concept/user";

export function teamPath(team: Team): string {
    return `/team/${team.id}`;
}

export function getStartedPath(team: Team): string {
    return `${teamPath(team)}/get-started`
}

export function spacesPath(team: Team): string {
    return `${teamPath(team)}/spaces`;
}

export function spaceDetailsPath(space: PartialWithId<Space>, team: Team): string {
    return `${spacesPath(team)}/${space.id}`;
}

export function clustersPath(team: Team): string {
    return `${teamPath(team)}/clusters`;
}

export function clustersDeployPath(team: Team): string {
    return `${clustersPath(team)}/deploy`
}

export function clusterDetailsPath(cluster: PartialWithId<Cluster> & { space: Space }, team: Team): string {
    return `${spaceDetailsPath(cluster.space, team)}/clusters/${cluster.id}`;
}

export function serverDetailsPath(server: Server, cluster: PartialWithId<Cluster> & { space: Space }, team: Team): string {
    return `${clusterDetailsPath(cluster, team)}/servers/${server.id}`;
}

export function squadsPath(team: Team): string {
    return `${teamPath(team)}/squads`;
}

export function squadDetailsPath(squad: PartialWithId<Squad>, team: Team): string {
    return `${squadsPath(team)}/${squad.id}`;
}

export function membersPath(team: Team): string {
    return `${teamPath(team)}/members`;
}

export function memberDetailsPath(member: PartialWithId<User>, team: Team): string {
    return `${membersPath(team)}/${member.id}`;
}

export function teamSettingsPath(team: Team): string {
    return `${teamPath(team)}/settings`;
}
