/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: protocol/concept.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum AccessLevelProto {
    NONE = 0,
    READ = 1,
    WRITE = 2,
    ADMIN = 3
}
export enum PaymentMethodStatusProto {
    DEACTIVATED = 0,
    SUSPENDED = 1,
    ACTIVE = 2
}
export class UserProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4], [5], [6], [7]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        email?: string;
    }) | ({
        firstName?: string;
    }) | ({
        lastName?: string;
    }) | ({
        createdAt?: number;
    }) | ({
        isVerified?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
            if ("firstName" in data && data.firstName != undefined) {
                this.firstName = data.firstName;
            }
            if ("lastName" in data && data.lastName != undefined) {
                this.lastName = data.lastName;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("isVerified" in data && data.isVerified != undefined) {
                this.isVerified = data.isVerified;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasEmail() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get firstName() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set firstName(value: string) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasFirstName() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get lastName() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set lastName(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[4], value);
    }
    get hasLastName() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[5], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get isVerified() {
        return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
    }
    set isVerified(value: boolean) {
        pb_1.Message.setOneofField(this, 7, this.#one_of_decls[6], value);
    }
    get hasIsVerified() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _email() {
        const cases: {
            [index: number]: "none" | "email";
        } = {
            0: "none",
            3: "email"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _first_name() {
        const cases: {
            [index: number]: "none" | "firstName";
        } = {
            0: "none",
            4: "firstName"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    get _last_name() {
        const cases: {
            [index: number]: "none" | "lastName";
        } = {
            0: "none",
            5: "lastName"
        };
        return cases[pb_1.Message.computeOneofCase(this, [5])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            6: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [6])];
    }
    get _is_verified() {
        const cases: {
            [index: number]: "none" | "isVerified";
        } = {
            0: "none",
            7: "isVerified"
        };
        return cases[pb_1.Message.computeOneofCase(this, [7])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        createdAt?: number;
        isVerified?: boolean;
    }): UserProto {
        const message = new UserProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.email != null) {
            message.email = data.email;
        }
        if (data.firstName != null) {
            message.firstName = data.firstName;
        }
        if (data.lastName != null) {
            message.lastName = data.lastName;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.isVerified != null) {
            message.isVerified = data.isVerified;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            email?: string;
            firstName?: string;
            lastName?: string;
            createdAt?: number;
            isVerified?: boolean;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.email != null) {
            data.email = this.email;
        }
        if (this.firstName != null) {
            data.firstName = this.firstName;
        }
        if (this.lastName != null) {
            data.lastName = this.lastName;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        if (this.isVerified != null) {
            data.isVerified = this.isVerified;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasEmail)
            writer.writeString(3, this.email);
        if (this.hasFirstName)
            writer.writeString(4, this.firstName);
        if (this.hasLastName)
            writer.writeString(5, this.lastName);
        if (this.hasCreatedAt)
            writer.writeSint64(6, this.createdAt);
        if (this.hasIsVerified)
            writer.writeBool(7, this.isVerified);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.email = reader.readString();
                    break;
                case 4:
                    message.firstName = reader.readString();
                    break;
                case 5:
                    message.lastName = reader.readString();
                    break;
                case 6:
                    message.createdAt = reader.readSint64();
                    break;
                case 7:
                    message.isVerified = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserProto {
        return UserProto.deserialize(bytes);
    }
}
export class TeamProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4], [5]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        name?: string;
    }) | ({
        createdAt?: number;
    }) | ({
        isPersonal?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("isPersonal" in data && data.isPersonal != undefined) {
                this.isPersonal = data.isPersonal;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasName() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get isPersonal() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set isPersonal(value: boolean) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[4], value);
    }
    get hasIsPersonal() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _name() {
        const cases: {
            [index: number]: "none" | "name";
        } = {
            0: "none",
            3: "name"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            4: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    get _is_personal() {
        const cases: {
            [index: number]: "none" | "isPersonal";
        } = {
            0: "none",
            5: "isPersonal"
        };
        return cases[pb_1.Message.computeOneofCase(this, [5])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        name?: string;
        createdAt?: number;
        isPersonal?: boolean;
    }): TeamProto {
        const message = new TeamProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.isPersonal != null) {
            message.isPersonal = data.isPersonal;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            name?: string;
            createdAt?: number;
            isPersonal?: boolean;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        if (this.isPersonal != null) {
            data.isPersonal = this.isPersonal;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasName)
            writer.writeString(3, this.name);
        if (this.hasCreatedAt)
            writer.writeSint64(4, this.createdAt);
        if (this.hasIsPersonal)
            writer.writeBool(5, this.isPersonal);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.createdAt = reader.readSint64();
                    break;
                case 5:
                    message.isPersonal = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamProto {
        return TeamProto.deserialize(bytes);
    }
}
export class TeamMemberProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        user?: UserProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get user() {
        return pb_1.Message.getWrapperField(this, UserProto, 1) as UserProto;
    }
    set user(value: UserProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        user?: ReturnType<typeof UserProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): TeamMemberProto {
        const message = new TeamMemberProto({});
        if (data.user != null) {
            message.user = UserProto.fromObject(data.user);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            user?: ReturnType<typeof UserProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUser)
            writer.writeMessage(1, this.user, () => this.user.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamMemberProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamMemberProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.user, () => message.user = UserProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamMemberProto {
        return TeamMemberProto.deserialize(bytes);
    }
}
export class UserTeamProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        team?: TeamProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, TeamProto, 1) as TeamProto;
    }
    set team(value: TeamProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        team?: ReturnType<typeof TeamProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): UserTeamProto {
        const message = new UserTeamProto({});
        if (data.team != null) {
            message.team = TeamProto.fromObject(data.team);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof TeamProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(1, this.team, () => this.team.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserTeamProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserTeamProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.team, () => message.team = TeamProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserTeamProto {
        return UserTeamProto.deserialize(bytes);
    }
}
export class InvitationProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4], [5], [6]];
    constructor(data?: any[] | ({
        squadAccess?: InvitationProtoSquadAccess[];
        spaceAccess?: InvitationProtoSpaceAccess[];
    } & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        userEmail?: string;
    }) | ({
        createdAt?: number;
    }) | ({
        team?: TeamProto;
    }) | ({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7, 8], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("userEmail" in data && data.userEmail != undefined) {
                this.userEmail = data.userEmail;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
            if ("squadAccess" in data && data.squadAccess != undefined) {
                this.squadAccess = data.squadAccess;
            }
            if ("spaceAccess" in data && data.spaceAccess != undefined) {
                this.spaceAccess = data.spaceAccess;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get userEmail() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set userEmail(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasUserEmail() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get team() {
        return pb_1.Message.getWrapperField(this, TeamProto, 5) as TeamProto;
    }
    set team(value: TeamProto) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[4], value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 6, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[5], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get squadAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, InvitationProtoSquadAccess, 7) as InvitationProtoSquadAccess[];
    }
    set squadAccess(value: InvitationProtoSquadAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    get spaceAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, InvitationProtoSpaceAccess, 8) as InvitationProtoSpaceAccess[];
    }
    set spaceAccess(value: InvitationProtoSpaceAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 8, value);
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _user_email() {
        const cases: {
            [index: number]: "none" | "userEmail";
        } = {
            0: "none",
            3: "userEmail"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            4: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    get _team() {
        const cases: {
            [index: number]: "none" | "team";
        } = {
            0: "none",
            5: "team"
        };
        return cases[pb_1.Message.computeOneofCase(this, [5])];
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            6: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [6])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        userEmail?: string;
        createdAt?: number;
        team?: ReturnType<typeof TeamProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
        squadAccess?: ReturnType<typeof InvitationProtoSquadAccess.prototype.toObject>[];
        spaceAccess?: ReturnType<typeof InvitationProtoSpaceAccess.prototype.toObject>[];
    }): InvitationProto {
        const message = new InvitationProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.userEmail != null) {
            message.userEmail = data.userEmail;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.team != null) {
            message.team = TeamProto.fromObject(data.team);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        if (data.squadAccess != null) {
            message.squadAccess = data.squadAccess.map(item => InvitationProtoSquadAccess.fromObject(item));
        }
        if (data.spaceAccess != null) {
            message.spaceAccess = data.spaceAccess.map(item => InvitationProtoSpaceAccess.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            userEmail?: string;
            createdAt?: number;
            team?: ReturnType<typeof TeamProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
            squadAccess?: ReturnType<typeof InvitationProtoSquadAccess.prototype.toObject>[];
            spaceAccess?: ReturnType<typeof InvitationProtoSpaceAccess.prototype.toObject>[];
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.userEmail != null) {
            data.userEmail = this.userEmail;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        if (this.squadAccess != null) {
            data.squadAccess = this.squadAccess.map((item: InvitationProtoSquadAccess) => item.toObject());
        }
        if (this.spaceAccess != null) {
            data.spaceAccess = this.spaceAccess.map((item: InvitationProtoSpaceAccess) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasUserEmail)
            writer.writeString(3, this.userEmail);
        if (this.hasCreatedAt)
            writer.writeSint64(4, this.createdAt);
        if (this.hasTeam)
            writer.writeMessage(5, this.team, () => this.team.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(6, this.accessLevel);
        if (this.squadAccess.length)
            writer.writeRepeatedMessage(7, this.squadAccess, (item: InvitationProtoSquadAccess) => item.serialize(writer));
        if (this.spaceAccess.length)
            writer.writeRepeatedMessage(8, this.spaceAccess, (item: InvitationProtoSpaceAccess) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvitationProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvitationProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.userEmail = reader.readString();
                    break;
                case 4:
                    message.createdAt = reader.readSint64();
                    break;
                case 5:
                    reader.readMessage(message.team, () => message.team = TeamProto.deserialize(reader));
                    break;
                case 6:
                    message.accessLevel = reader.readEnum();
                    break;
                case 7:
                    reader.readMessage(message.squadAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 7, InvitationProtoSquadAccess.deserialize(reader), InvitationProtoSquadAccess));
                    break;
                case 8:
                    reader.readMessage(message.spaceAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 8, InvitationProtoSpaceAccess.deserialize(reader), InvitationProtoSpaceAccess));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvitationProto {
        return InvitationProto.deserialize(bytes);
    }
}
export class InvitationProtoSquadAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        squad?: SquadProto;
        accessLevel?: AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, SquadProto, 1) as SquadProto;
    }
    set squad(value: SquadProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        squad?: ReturnType<typeof SquadProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): InvitationProtoSquadAccess {
        const message = new InvitationProtoSquadAccess({});
        if (data.squad != null) {
            message.squad = SquadProto.fromObject(data.squad);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squad?: ReturnType<typeof SquadProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSquad)
            writer.writeMessage(1, this.squad, () => this.squad.serialize(writer));
        if (this.accessLevel != AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvitationProtoSquadAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvitationProtoSquadAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squad, () => message.squad = SquadProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvitationProtoSquadAccess {
        return InvitationProtoSquadAccess.deserialize(bytes);
    }
}
export class InvitationProtoSpaceAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        space?: SpaceProto;
        accessLevel?: AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get space() {
        return pb_1.Message.getWrapperField(this, SpaceProto, 1) as SpaceProto;
    }
    set space(value: SpaceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        space?: ReturnType<typeof SpaceProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): InvitationProtoSpaceAccess {
        const message = new InvitationProtoSpaceAccess({});
        if (data.space != null) {
            message.space = SpaceProto.fromObject(data.space);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            space?: ReturnType<typeof SpaceProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSpace)
            writer.writeMessage(1, this.space, () => this.space.serialize(writer));
        if (this.accessLevel != AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvitationProtoSpaceAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvitationProtoSpaceAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.space, () => message.space = SpaceProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvitationProtoSpaceAccess {
        return InvitationProtoSpaceAccess.deserialize(bytes);
    }
}
export class SpaceProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        name?: string;
    }) | ({
        createdAt?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasName() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _name() {
        const cases: {
            [index: number]: "none" | "name";
        } = {
            0: "none",
            3: "name"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            4: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        name?: string;
        createdAt?: number;
    }): SpaceProto {
        const message = new SpaceProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            name?: string;
            createdAt?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasName)
            writer.writeString(3, this.name);
        if (this.hasCreatedAt)
            writer.writeSint64(4, this.createdAt);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.createdAt = reader.readSint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceProto {
        return SpaceProto.deserialize(bytes);
    }
}
export class SpaceUserAssignmentProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        user?: UserProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get user() {
        return pb_1.Message.getWrapperField(this, UserProto, 1) as UserProto;
    }
    set user(value: UserProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        user?: ReturnType<typeof UserProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): SpaceUserAssignmentProto {
        const message = new SpaceUserAssignmentProto({});
        if (data.user != null) {
            message.user = UserProto.fromObject(data.user);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            user?: ReturnType<typeof UserProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUser)
            writer.writeMessage(1, this.user, () => this.user.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceUserAssignmentProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceUserAssignmentProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.user, () => message.user = UserProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceUserAssignmentProto {
        return SpaceUserAssignmentProto.deserialize(bytes);
    }
}
export class UserSpaceProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        space?: SpaceProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get space() {
        return pb_1.Message.getWrapperField(this, SpaceProto, 1) as SpaceProto;
    }
    set space(value: SpaceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        space?: ReturnType<typeof SpaceProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): UserSpaceProto {
        const message = new UserSpaceProto({});
        if (data.space != null) {
            message.space = SpaceProto.fromObject(data.space);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            space?: ReturnType<typeof SpaceProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSpace)
            writer.writeMessage(1, this.space, () => this.space.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserSpaceProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserSpaceProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.space, () => message.space = SpaceProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserSpaceProto {
        return UserSpaceProto.deserialize(bytes);
    }
}
export class SquadProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        name?: string;
    }) | ({
        createdAt?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasName() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _name() {
        const cases: {
            [index: number]: "none" | "name";
        } = {
            0: "none",
            3: "name"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            4: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        name?: string;
        createdAt?: number;
    }): SquadProto {
        const message = new SquadProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            name?: string;
            createdAt?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasName)
            writer.writeString(3, this.name);
        if (this.hasCreatedAt)
            writer.writeSint64(4, this.createdAt);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.createdAt = reader.readSint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadProto {
        return SquadProto.deserialize(bytes);
    }
}
export class SpaceSquadAssignmentProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        squad?: SquadProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, SquadProto, 1) as SquadProto;
    }
    set squad(value: SquadProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        squad?: ReturnType<typeof SquadProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): SpaceSquadAssignmentProto {
        const message = new SpaceSquadAssignmentProto({});
        if (data.squad != null) {
            message.squad = SquadProto.fromObject(data.squad);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squad?: ReturnType<typeof SquadProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSquad)
            writer.writeMessage(1, this.squad, () => this.squad.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceSquadAssignmentProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceSquadAssignmentProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squad, () => message.squad = SquadProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceSquadAssignmentProto {
        return SpaceSquadAssignmentProto.deserialize(bytes);
    }
}
export class SquadSpaceProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        space?: SpaceProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get space() {
        return pb_1.Message.getWrapperField(this, SpaceProto, 1) as SpaceProto;
    }
    set space(value: SpaceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        space?: ReturnType<typeof SpaceProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): SquadSpaceProto {
        const message = new SquadSpaceProto({});
        if (data.space != null) {
            message.space = SpaceProto.fromObject(data.space);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            space?: ReturnType<typeof SpaceProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSpace)
            writer.writeMessage(1, this.space, () => this.space.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadSpaceProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadSpaceProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.space, () => message.space = SpaceProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadSpaceProto {
        return SquadSpaceProto.deserialize(bytes);
    }
}
export class SquadMemberProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        user?: UserProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get user() {
        return pb_1.Message.getWrapperField(this, UserProto, 1) as UserProto;
    }
    set user(value: UserProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        user?: ReturnType<typeof UserProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): SquadMemberProto {
        const message = new SquadMemberProto({});
        if (data.user != null) {
            message.user = UserProto.fromObject(data.user);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            user?: ReturnType<typeof UserProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUser)
            writer.writeMessage(1, this.user, () => this.user.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SquadMemberProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SquadMemberProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.user, () => message.user = UserProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SquadMemberProto {
        return SquadMemberProto.deserialize(bytes);
    }
}
export class UserSquadProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        squad?: SquadProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("squad" in data && data.squad != undefined) {
                this.squad = data.squad;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get squad() {
        return pb_1.Message.getWrapperField(this, SquadProto, 1) as SquadProto;
    }
    set squad(value: SquadProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSquad() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        squad?: ReturnType<typeof SquadProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): UserSquadProto {
        const message = new UserSquadProto({});
        if (data.squad != null) {
            message.squad = SquadProto.fromObject(data.squad);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            squad?: ReturnType<typeof SquadProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.squad != null) {
            data.squad = this.squad.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSquad)
            writer.writeMessage(1, this.squad, () => this.squad.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserSquadProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserSquadProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.squad, () => message.squad = SquadProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserSquadProto {
        return UserSquadProto.deserialize(bytes);
    }
}
export class ClusterProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4], [5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        id?: string;
    }) | ({
        serverCount?: number;
    }) | ({
        storageGb?: number;
    }) | ({
        typedbCloudVersion?: TypeDBVersionProto;
    }) | ({
        createdAt?: number;
    }) | ({
        destroyedAt?: number;
    }) | ({
        isFree?: boolean;
    }) | ({
        status?: ClusterProtoStatus;
    }) | ({
        isAdminUserInitialized?: boolean;
    }) | ({
        space?: SpaceProto;
    }) | ({
        provider?: ProviderProto;
    }) | ({
        region?: RegionProto;
    }) | ({
        machineType?: MachineTypeProto;
    }) | ({
        storageType?: StorageTypeProto;
    }) | ({
        sampleDataset?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("serverCount" in data && data.serverCount != undefined) {
                this.serverCount = data.serverCount;
            }
            if ("storageGb" in data && data.storageGb != undefined) {
                this.storageGb = data.storageGb;
            }
            if ("typedbCloudVersion" in data && data.typedbCloudVersion != undefined) {
                this.typedbCloudVersion = data.typedbCloudVersion;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("destroyedAt" in data && data.destroyedAt != undefined) {
                this.destroyedAt = data.destroyedAt;
            }
            if ("isFree" in data && data.isFree != undefined) {
                this.isFree = data.isFree;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
            if ("isAdminUserInitialized" in data && data.isAdminUserInitialized != undefined) {
                this.isAdminUserInitialized = data.isAdminUserInitialized;
            }
            if ("space" in data && data.space != undefined) {
                this.space = data.space;
            }
            if ("provider" in data && data.provider != undefined) {
                this.provider = data.provider;
            }
            if ("region" in data && data.region != undefined) {
                this.region = data.region;
            }
            if ("machineType" in data && data.machineType != undefined) {
                this.machineType = data.machineType;
            }
            if ("storageType" in data && data.storageType != undefined) {
                this.storageType = data.storageType;
            }
            if ("sampleDataset" in data && data.sampleDataset != undefined) {
                this.sampleDataset = data.sampleDataset;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get serverCount() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set serverCount(value: number) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasServerCount() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get storageGb() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set storageGb(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasStorageGb() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get typedbCloudVersion() {
        return pb_1.Message.getWrapperField(this, TypeDBVersionProto, 5) as TypeDBVersionProto;
    }
    set typedbCloudVersion(value: TypeDBVersionProto) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[4], value);
    }
    get hasTypedbCloudVersion() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[5], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get destroyedAt() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set destroyedAt(value: number) {
        pb_1.Message.setOneofField(this, 7, this.#one_of_decls[6], value);
    }
    get hasDestroyedAt() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get isFree() {
        return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
    }
    set isFree(value: boolean) {
        pb_1.Message.setOneofField(this, 8, this.#one_of_decls[7], value);
    }
    get hasIsFree() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 9, ClusterProtoStatus.STARTING) as ClusterProtoStatus;
    }
    set status(value: ClusterProtoStatus) {
        pb_1.Message.setOneofField(this, 9, this.#one_of_decls[8], value);
    }
    get hasStatus() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get isAdminUserInitialized() {
        return pb_1.Message.getFieldWithDefault(this, 10, false) as boolean;
    }
    set isAdminUserInitialized(value: boolean) {
        pb_1.Message.setOneofField(this, 10, this.#one_of_decls[9], value);
    }
    get hasIsAdminUserInitialized() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get space() {
        return pb_1.Message.getWrapperField(this, SpaceProto, 11) as SpaceProto;
    }
    set space(value: SpaceProto) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[10], value);
    }
    get hasSpace() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get provider() {
        return pb_1.Message.getWrapperField(this, ProviderProto, 12) as ProviderProto;
    }
    set provider(value: ProviderProto) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[11], value);
    }
    get hasProvider() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get region() {
        return pb_1.Message.getWrapperField(this, RegionProto, 13) as RegionProto;
    }
    set region(value: RegionProto) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[12], value);
    }
    get hasRegion() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get machineType() {
        return pb_1.Message.getWrapperField(this, MachineTypeProto, 14) as MachineTypeProto;
    }
    set machineType(value: MachineTypeProto) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[13], value);
    }
    get hasMachineType() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get storageType() {
        return pb_1.Message.getWrapperField(this, StorageTypeProto, 15) as StorageTypeProto;
    }
    set storageType(value: StorageTypeProto) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[14], value);
    }
    get hasStorageType() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get sampleDataset() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set sampleDataset(value: string) {
        pb_1.Message.setOneofField(this, 16, this.#one_of_decls[15], value);
    }
    get hasSampleDataset() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            2: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _server_count() {
        const cases: {
            [index: number]: "none" | "serverCount";
        } = {
            0: "none",
            3: "serverCount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _storage_gb() {
        const cases: {
            [index: number]: "none" | "storageGb";
        } = {
            0: "none",
            4: "storageGb"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    get _typedb_cloud_version() {
        const cases: {
            [index: number]: "none" | "typedbCloudVersion";
        } = {
            0: "none",
            5: "typedbCloudVersion"
        };
        return cases[pb_1.Message.computeOneofCase(this, [5])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            6: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [6])];
    }
    get _destroyed_at() {
        const cases: {
            [index: number]: "none" | "destroyedAt";
        } = {
            0: "none",
            7: "destroyedAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [7])];
    }
    get _is_free() {
        const cases: {
            [index: number]: "none" | "isFree";
        } = {
            0: "none",
            8: "isFree"
        };
        return cases[pb_1.Message.computeOneofCase(this, [8])];
    }
    get _status() {
        const cases: {
            [index: number]: "none" | "status";
        } = {
            0: "none",
            9: "status"
        };
        return cases[pb_1.Message.computeOneofCase(this, [9])];
    }
    get _is_admin_user_initialized() {
        const cases: {
            [index: number]: "none" | "isAdminUserInitialized";
        } = {
            0: "none",
            10: "isAdminUserInitialized"
        };
        return cases[pb_1.Message.computeOneofCase(this, [10])];
    }
    get _space() {
        const cases: {
            [index: number]: "none" | "space";
        } = {
            0: "none",
            11: "space"
        };
        return cases[pb_1.Message.computeOneofCase(this, [11])];
    }
    get _provider() {
        const cases: {
            [index: number]: "none" | "provider";
        } = {
            0: "none",
            12: "provider"
        };
        return cases[pb_1.Message.computeOneofCase(this, [12])];
    }
    get _region() {
        const cases: {
            [index: number]: "none" | "region";
        } = {
            0: "none",
            13: "region"
        };
        return cases[pb_1.Message.computeOneofCase(this, [13])];
    }
    get _machine_type() {
        const cases: {
            [index: number]: "none" | "machineType";
        } = {
            0: "none",
            14: "machineType"
        };
        return cases[pb_1.Message.computeOneofCase(this, [14])];
    }
    get _storage_type() {
        const cases: {
            [index: number]: "none" | "storageType";
        } = {
            0: "none",
            15: "storageType"
        };
        return cases[pb_1.Message.computeOneofCase(this, [15])];
    }
    get _sample_dataset() {
        const cases: {
            [index: number]: "none" | "sampleDataset";
        } = {
            0: "none",
            16: "sampleDataset"
        };
        return cases[pb_1.Message.computeOneofCase(this, [16])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: string;
        serverCount?: number;
        storageGb?: number;
        typedbCloudVersion?: ReturnType<typeof TypeDBVersionProto.prototype.toObject>;
        createdAt?: number;
        destroyedAt?: number;
        isFree?: boolean;
        status?: ClusterProtoStatus;
        isAdminUserInitialized?: boolean;
        space?: ReturnType<typeof SpaceProto.prototype.toObject>;
        provider?: ReturnType<typeof ProviderProto.prototype.toObject>;
        region?: ReturnType<typeof RegionProto.prototype.toObject>;
        machineType?: ReturnType<typeof MachineTypeProto.prototype.toObject>;
        storageType?: ReturnType<typeof StorageTypeProto.prototype.toObject>;
        sampleDataset?: string;
    }): ClusterProto {
        const message = new ClusterProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.serverCount != null) {
            message.serverCount = data.serverCount;
        }
        if (data.storageGb != null) {
            message.storageGb = data.storageGb;
        }
        if (data.typedbCloudVersion != null) {
            message.typedbCloudVersion = TypeDBVersionProto.fromObject(data.typedbCloudVersion);
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.destroyedAt != null) {
            message.destroyedAt = data.destroyedAt;
        }
        if (data.isFree != null) {
            message.isFree = data.isFree;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        if (data.isAdminUserInitialized != null) {
            message.isAdminUserInitialized = data.isAdminUserInitialized;
        }
        if (data.space != null) {
            message.space = SpaceProto.fromObject(data.space);
        }
        if (data.provider != null) {
            message.provider = ProviderProto.fromObject(data.provider);
        }
        if (data.region != null) {
            message.region = RegionProto.fromObject(data.region);
        }
        if (data.machineType != null) {
            message.machineType = MachineTypeProto.fromObject(data.machineType);
        }
        if (data.storageType != null) {
            message.storageType = StorageTypeProto.fromObject(data.storageType);
        }
        if (data.sampleDataset != null) {
            message.sampleDataset = data.sampleDataset;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: string;
            serverCount?: number;
            storageGb?: number;
            typedbCloudVersion?: ReturnType<typeof TypeDBVersionProto.prototype.toObject>;
            createdAt?: number;
            destroyedAt?: number;
            isFree?: boolean;
            status?: ClusterProtoStatus;
            isAdminUserInitialized?: boolean;
            space?: ReturnType<typeof SpaceProto.prototype.toObject>;
            provider?: ReturnType<typeof ProviderProto.prototype.toObject>;
            region?: ReturnType<typeof RegionProto.prototype.toObject>;
            machineType?: ReturnType<typeof MachineTypeProto.prototype.toObject>;
            storageType?: ReturnType<typeof StorageTypeProto.prototype.toObject>;
            sampleDataset?: string;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.serverCount != null) {
            data.serverCount = this.serverCount;
        }
        if (this.storageGb != null) {
            data.storageGb = this.storageGb;
        }
        if (this.typedbCloudVersion != null) {
            data.typedbCloudVersion = this.typedbCloudVersion.toObject();
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        if (this.destroyedAt != null) {
            data.destroyedAt = this.destroyedAt;
        }
        if (this.isFree != null) {
            data.isFree = this.isFree;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        if (this.isAdminUserInitialized != null) {
            data.isAdminUserInitialized = this.isAdminUserInitialized;
        }
        if (this.space != null) {
            data.space = this.space.toObject();
        }
        if (this.provider != null) {
            data.provider = this.provider.toObject();
        }
        if (this.region != null) {
            data.region = this.region.toObject();
        }
        if (this.machineType != null) {
            data.machineType = this.machineType.toObject();
        }
        if (this.storageType != null) {
            data.storageType = this.storageType.toObject();
        }
        if (this.sampleDataset != null) {
            data.sampleDataset = this.sampleDataset;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasServerCount)
            writer.writeInt32(3, this.serverCount);
        if (this.hasStorageGb)
            writer.writeInt64(4, this.storageGb);
        if (this.hasTypedbCloudVersion)
            writer.writeMessage(5, this.typedbCloudVersion, () => this.typedbCloudVersion.serialize(writer));
        if (this.hasCreatedAt)
            writer.writeSint64(6, this.createdAt);
        if (this.hasDestroyedAt)
            writer.writeSint64(7, this.destroyedAt);
        if (this.hasIsFree)
            writer.writeBool(8, this.isFree);
        if (this.hasStatus)
            writer.writeEnum(9, this.status);
        if (this.hasIsAdminUserInitialized)
            writer.writeBool(10, this.isAdminUserInitialized);
        if (this.hasSpace)
            writer.writeMessage(11, this.space, () => this.space.serialize(writer));
        if (this.hasProvider)
            writer.writeMessage(12, this.provider, () => this.provider.serialize(writer));
        if (this.hasRegion)
            writer.writeMessage(13, this.region, () => this.region.serialize(writer));
        if (this.hasMachineType)
            writer.writeMessage(14, this.machineType, () => this.machineType.serialize(writer));
        if (this.hasStorageType)
            writer.writeMessage(15, this.storageType, () => this.storageType.serialize(writer));
        if (this.hasSampleDataset)
            writer.writeString(16, this.sampleDataset);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.serverCount = reader.readInt32();
                    break;
                case 4:
                    message.storageGb = reader.readInt64();
                    break;
                case 5:
                    reader.readMessage(message.typedbCloudVersion, () => message.typedbCloudVersion = TypeDBVersionProto.deserialize(reader));
                    break;
                case 6:
                    message.createdAt = reader.readSint64();
                    break;
                case 7:
                    message.destroyedAt = reader.readSint64();
                    break;
                case 8:
                    message.isFree = reader.readBool();
                    break;
                case 9:
                    message.status = reader.readEnum();
                    break;
                case 10:
                    message.isAdminUserInitialized = reader.readBool();
                    break;
                case 11:
                    reader.readMessage(message.space, () => message.space = SpaceProto.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.provider, () => message.provider = ProviderProto.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.region, () => message.region = RegionProto.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.machineType, () => message.machineType = MachineTypeProto.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.storageType, () => message.storageType = StorageTypeProto.deserialize(reader));
                    break;
                case 16:
                    message.sampleDataset = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterProto {
        return ClusterProto.deserialize(bytes);
    }
}
export enum ClusterProtoStatus {
    STARTING = 0,
    RUNNING = 1,
    SUSPENDING = 2,
    SUSPENDED = 3,
    RESUMING = 4,
    DESTROYING = 5,
    DESTROYED = 6,
    ERROR = 7
}
export class ClusterProtoServer extends pb_1.Message {
    #one_of_decls: number[][] = [[3], [4]];
    constructor(data?: any[] | ({
        uuid?: Uint8Array;
        id?: number;
    } & (({
        address?: string;
    }) | ({
        status?: ClusterProtoServerStatus;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set id(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 4, ClusterProtoServerStatus.PENDING) as ClusterProtoServerStatus;
    }
    set status(value: ClusterProtoServerStatus) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
    }
    get hasStatus() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get _address() {
        const cases: {
            [index: number]: "none" | "address";
        } = {
            0: "none",
            3: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _status() {
        const cases: {
            [index: number]: "none" | "status";
        } = {
            0: "none",
            4: "status"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        id?: number;
        address?: string;
        status?: ClusterProtoServerStatus;
    }): ClusterProtoServer {
        const message = new ClusterProtoServer({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            id?: number;
            address?: string;
            status?: ClusterProtoServerStatus;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.address != null) {
            data.address = this.address;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.id != 0)
            writer.writeInt32(2, this.id);
        if (this.hasAddress)
            writer.writeString(3, this.address);
        if (this.hasStatus)
            writer.writeEnum(4, this.status);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterProtoServer {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterProtoServer();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readInt32();
                    break;
                case 3:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.status = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterProtoServer {
        return ClusterProtoServer.deserialize(bytes);
    }
}
export enum ClusterProtoServerStatus {
    PENDING = 0,
    RUNNING = 1,
    UNKNOWN = 2,
    ERROR = 3
}
export class ClusterProtoServerLogBatch extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        lines?: string[];
        lineNumberStart?: number;
        isEndOfFile?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("lines" in data && data.lines != undefined) {
                this.lines = data.lines;
            }
            if ("lineNumberStart" in data && data.lineNumberStart != undefined) {
                this.lineNumberStart = data.lineNumberStart;
            }
            if ("isEndOfFile" in data && data.isEndOfFile != undefined) {
                this.isEndOfFile = data.isEndOfFile;
            }
        }
    }
    get lines() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
    }
    set lines(value: string[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get lineNumberStart() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set lineNumberStart(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get isEndOfFile() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set isEndOfFile(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        lines?: string[];
        lineNumberStart?: number;
        isEndOfFile?: boolean;
    }): ClusterProtoServerLogBatch {
        const message = new ClusterProtoServerLogBatch({});
        if (data.lines != null) {
            message.lines = data.lines;
        }
        if (data.lineNumberStart != null) {
            message.lineNumberStart = data.lineNumberStart;
        }
        if (data.isEndOfFile != null) {
            message.isEndOfFile = data.isEndOfFile;
        }
        return message;
    }
    toObject() {
        const data: {
            lines?: string[];
            lineNumberStart?: number;
            isEndOfFile?: boolean;
        } = {};
        if (this.lines != null) {
            data.lines = this.lines;
        }
        if (this.lineNumberStart != null) {
            data.lineNumberStart = this.lineNumberStart;
        }
        if (this.isEndOfFile != null) {
            data.isEndOfFile = this.isEndOfFile;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.lines.length)
            writer.writeRepeatedString(1, this.lines);
        if (this.lineNumberStart != 0)
            writer.writeInt32(2, this.lineNumberStart);
        if (this.isEndOfFile != false)
            writer.writeBool(3, this.isEndOfFile);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterProtoServerLogBatch {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterProtoServerLogBatch();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                    break;
                case 2:
                    message.lineNumberStart = reader.readInt32();
                    break;
                case 3:
                    message.isEndOfFile = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterProtoServerLogBatch {
        return ClusterProtoServerLogBatch.deserialize(bytes);
    }
}
export class ProviderProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        name?: string;
        regions?: RegionProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("regions" in data && data.regions != undefined) {
                this.regions = data.regions;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get regions() {
        return pb_1.Message.getRepeatedWrapperField(this, RegionProto, 3) as RegionProto[];
    }
    set regions(value: RegionProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    static fromObject(data: {
        id?: string;
        name?: string;
        regions?: ReturnType<typeof RegionProto.prototype.toObject>[];
    }): ProviderProto {
        const message = new ProviderProto({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.regions != null) {
            message.regions = data.regions.map(item => RegionProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            name?: string;
            regions?: ReturnType<typeof RegionProto.prototype.toObject>[];
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.regions != null) {
            data.regions = this.regions.map((item: RegionProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.regions.length)
            writer.writeRepeatedMessage(3, this.regions, (item: RegionProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProviderProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProviderProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.regions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, RegionProto.deserialize(reader), RegionProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProviderProto {
        return ProviderProto.deserialize(bytes);
    }
}
export class RegionProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        continentName?: string;
        continentOrdinal?: number;
        countryCode?: string;
        locationName?: string;
        vendorId?: string;
        machineTypes?: MachineTypeProto[];
        storageTypes?: StorageTypeProto[];
        clusterPresets?: ClusterPresetProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7, 8, 9], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("continentName" in data && data.continentName != undefined) {
                this.continentName = data.continentName;
            }
            if ("continentOrdinal" in data && data.continentOrdinal != undefined) {
                this.continentOrdinal = data.continentOrdinal;
            }
            if ("countryCode" in data && data.countryCode != undefined) {
                this.countryCode = data.countryCode;
            }
            if ("locationName" in data && data.locationName != undefined) {
                this.locationName = data.locationName;
            }
            if ("vendorId" in data && data.vendorId != undefined) {
                this.vendorId = data.vendorId;
            }
            if ("machineTypes" in data && data.machineTypes != undefined) {
                this.machineTypes = data.machineTypes;
            }
            if ("storageTypes" in data && data.storageTypes != undefined) {
                this.storageTypes = data.storageTypes;
            }
            if ("clusterPresets" in data && data.clusterPresets != undefined) {
                this.clusterPresets = data.clusterPresets;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get continentName() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set continentName(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get continentOrdinal() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set continentOrdinal(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get countryCode() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set countryCode(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get locationName() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set locationName(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get vendorId() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set vendorId(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get machineTypes() {
        return pb_1.Message.getRepeatedWrapperField(this, MachineTypeProto, 7) as MachineTypeProto[];
    }
    set machineTypes(value: MachineTypeProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    get storageTypes() {
        return pb_1.Message.getRepeatedWrapperField(this, StorageTypeProto, 8) as StorageTypeProto[];
    }
    set storageTypes(value: StorageTypeProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 8, value);
    }
    get clusterPresets() {
        return pb_1.Message.getRepeatedWrapperField(this, ClusterPresetProto, 9) as ClusterPresetProto[];
    }
    set clusterPresets(value: ClusterPresetProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 9, value);
    }
    static fromObject(data: {
        id?: string;
        continentName?: string;
        continentOrdinal?: number;
        countryCode?: string;
        locationName?: string;
        vendorId?: string;
        machineTypes?: ReturnType<typeof MachineTypeProto.prototype.toObject>[];
        storageTypes?: ReturnType<typeof StorageTypeProto.prototype.toObject>[];
        clusterPresets?: ReturnType<typeof ClusterPresetProto.prototype.toObject>[];
    }): RegionProto {
        const message = new RegionProto({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.continentName != null) {
            message.continentName = data.continentName;
        }
        if (data.continentOrdinal != null) {
            message.continentOrdinal = data.continentOrdinal;
        }
        if (data.countryCode != null) {
            message.countryCode = data.countryCode;
        }
        if (data.locationName != null) {
            message.locationName = data.locationName;
        }
        if (data.vendorId != null) {
            message.vendorId = data.vendorId;
        }
        if (data.machineTypes != null) {
            message.machineTypes = data.machineTypes.map(item => MachineTypeProto.fromObject(item));
        }
        if (data.storageTypes != null) {
            message.storageTypes = data.storageTypes.map(item => StorageTypeProto.fromObject(item));
        }
        if (data.clusterPresets != null) {
            message.clusterPresets = data.clusterPresets.map(item => ClusterPresetProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            continentName?: string;
            continentOrdinal?: number;
            countryCode?: string;
            locationName?: string;
            vendorId?: string;
            machineTypes?: ReturnType<typeof MachineTypeProto.prototype.toObject>[];
            storageTypes?: ReturnType<typeof StorageTypeProto.prototype.toObject>[];
            clusterPresets?: ReturnType<typeof ClusterPresetProto.prototype.toObject>[];
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.continentName != null) {
            data.continentName = this.continentName;
        }
        if (this.continentOrdinal != null) {
            data.continentOrdinal = this.continentOrdinal;
        }
        if (this.countryCode != null) {
            data.countryCode = this.countryCode;
        }
        if (this.locationName != null) {
            data.locationName = this.locationName;
        }
        if (this.vendorId != null) {
            data.vendorId = this.vendorId;
        }
        if (this.machineTypes != null) {
            data.machineTypes = this.machineTypes.map((item: MachineTypeProto) => item.toObject());
        }
        if (this.storageTypes != null) {
            data.storageTypes = this.storageTypes.map((item: StorageTypeProto) => item.toObject());
        }
        if (this.clusterPresets != null) {
            data.clusterPresets = this.clusterPresets.map((item: ClusterPresetProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.continentName.length)
            writer.writeString(2, this.continentName);
        if (this.continentOrdinal != 0)
            writer.writeInt32(3, this.continentOrdinal);
        if (this.countryCode.length)
            writer.writeString(4, this.countryCode);
        if (this.locationName.length)
            writer.writeString(5, this.locationName);
        if (this.vendorId.length)
            writer.writeString(6, this.vendorId);
        if (this.machineTypes.length)
            writer.writeRepeatedMessage(7, this.machineTypes, (item: MachineTypeProto) => item.serialize(writer));
        if (this.storageTypes.length)
            writer.writeRepeatedMessage(8, this.storageTypes, (item: StorageTypeProto) => item.serialize(writer));
        if (this.clusterPresets.length)
            writer.writeRepeatedMessage(9, this.clusterPresets, (item: ClusterPresetProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RegionProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RegionProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.continentName = reader.readString();
                    break;
                case 3:
                    message.continentOrdinal = reader.readInt32();
                    break;
                case 4:
                    message.countryCode = reader.readString();
                    break;
                case 5:
                    message.locationName = reader.readString();
                    break;
                case 6:
                    message.vendorId = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.machineTypes, () => pb_1.Message.addToRepeatedWrapperField(message, 7, MachineTypeProto.deserialize(reader), MachineTypeProto));
                    break;
                case 8:
                    reader.readMessage(message.storageTypes, () => pb_1.Message.addToRepeatedWrapperField(message, 8, StorageTypeProto.deserialize(reader), StorageTypeProto));
                    break;
                case 9:
                    reader.readMessage(message.clusterPresets, () => pb_1.Message.addToRepeatedWrapperField(message, 9, ClusterPresetProto.deserialize(reader), ClusterPresetProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): RegionProto {
        return RegionProto.deserialize(bytes);
    }
}
export class MachineTypeProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        vendorId?: string;
        cpu?: number;
        ramGb?: number;
        isFree?: boolean;
        payableWith?: MachineTypeProtoPaymentOptions[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("vendorId" in data && data.vendorId != undefined) {
                this.vendorId = data.vendorId;
            }
            if ("cpu" in data && data.cpu != undefined) {
                this.cpu = data.cpu;
            }
            if ("ramGb" in data && data.ramGb != undefined) {
                this.ramGb = data.ramGb;
            }
            if ("isFree" in data && data.isFree != undefined) {
                this.isFree = data.isFree;
            }
            if ("payableWith" in data && data.payableWith != undefined) {
                this.payableWith = data.payableWith;
            }
        }
    }
    get vendorId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set vendorId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get cpu() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set cpu(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get ramGb() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set ramGb(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get isFree() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set isFree(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get payableWith() {
        return pb_1.Message.getFieldWithDefault(this, 5, []) as MachineTypeProtoPaymentOptions[];
    }
    set payableWith(value: MachineTypeProtoPaymentOptions[]) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        vendorId?: string;
        cpu?: number;
        ramGb?: number;
        isFree?: boolean;
        payableWith?: MachineTypeProtoPaymentOptions[];
    }): MachineTypeProto {
        const message = new MachineTypeProto({});
        if (data.vendorId != null) {
            message.vendorId = data.vendorId;
        }
        if (data.cpu != null) {
            message.cpu = data.cpu;
        }
        if (data.ramGb != null) {
            message.ramGb = data.ramGb;
        }
        if (data.isFree != null) {
            message.isFree = data.isFree;
        }
        if (data.payableWith != null) {
            message.payableWith = data.payableWith;
        }
        return message;
    }
    toObject() {
        const data: {
            vendorId?: string;
            cpu?: number;
            ramGb?: number;
            isFree?: boolean;
            payableWith?: MachineTypeProtoPaymentOptions[];
        } = {};
        if (this.vendorId != null) {
            data.vendorId = this.vendorId;
        }
        if (this.cpu != null) {
            data.cpu = this.cpu;
        }
        if (this.ramGb != null) {
            data.ramGb = this.ramGb;
        }
        if (this.isFree != null) {
            data.isFree = this.isFree;
        }
        if (this.payableWith != null) {
            data.payableWith = this.payableWith;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.vendorId.length)
            writer.writeString(1, this.vendorId);
        if (this.cpu != 0)
            writer.writeInt32(2, this.cpu);
        if (this.ramGb != 0)
            writer.writeInt32(3, this.ramGb);
        if (this.isFree != false)
            writer.writeBool(4, this.isFree);
        if (this.payableWith.length)
            writer.writePackedEnum(5, this.payableWith);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): MachineTypeProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new MachineTypeProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.vendorId = reader.readString();
                    break;
                case 2:
                    message.cpu = reader.readInt32();
                    break;
                case 3:
                    message.ramGb = reader.readInt32();
                    break;
                case 4:
                    message.isFree = reader.readBool();
                    break;
                case 5:
                    message.payableWith = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): MachineTypeProto {
        return MachineTypeProto.deserialize(bytes);
    }
}
export enum MachineTypeProtoPaymentOptions {
    CARD = 0,
    MARKETPLACE = 1,
    CREDITS = 2
}
export class StorageTypeProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        vendorId?: string;
        minGb?: number;
        maxGb?: number;
        iops?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("vendorId" in data && data.vendorId != undefined) {
                this.vendorId = data.vendorId;
            }
            if ("minGb" in data && data.minGb != undefined) {
                this.minGb = data.minGb;
            }
            if ("maxGb" in data && data.maxGb != undefined) {
                this.maxGb = data.maxGb;
            }
            if ("iops" in data && data.iops != undefined) {
                this.iops = data.iops;
            }
        }
    }
    get vendorId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set vendorId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get minGb() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set minGb(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get maxGb() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set maxGb(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get iops() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set iops(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        vendorId?: string;
        minGb?: number;
        maxGb?: number;
        iops?: string;
    }): StorageTypeProto {
        const message = new StorageTypeProto({});
        if (data.vendorId != null) {
            message.vendorId = data.vendorId;
        }
        if (data.minGb != null) {
            message.minGb = data.minGb;
        }
        if (data.maxGb != null) {
            message.maxGb = data.maxGb;
        }
        if (data.iops != null) {
            message.iops = data.iops;
        }
        return message;
    }
    toObject() {
        const data: {
            vendorId?: string;
            minGb?: number;
            maxGb?: number;
            iops?: string;
        } = {};
        if (this.vendorId != null) {
            data.vendorId = this.vendorId;
        }
        if (this.minGb != null) {
            data.minGb = this.minGb;
        }
        if (this.maxGb != null) {
            data.maxGb = this.maxGb;
        }
        if (this.iops != null) {
            data.iops = this.iops;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.vendorId.length)
            writer.writeString(1, this.vendorId);
        if (this.minGb != 0)
            writer.writeInt32(2, this.minGb);
        if (this.maxGb != 0)
            writer.writeInt32(3, this.maxGb);
        if (this.iops.length)
            writer.writeString(4, this.iops);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StorageTypeProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StorageTypeProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.vendorId = reader.readString();
                    break;
                case 2:
                    message.minGb = reader.readInt32();
                    break;
                case 3:
                    message.maxGb = reader.readInt32();
                    break;
                case 4:
                    message.iops = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StorageTypeProto {
        return StorageTypeProto.deserialize(bytes);
    }
}
export class ClusterPresetProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        name?: string;
        singleNodeSpec?: ClusterPresetProtoClusterPresetSpecProto;
        multiNodeSpec?: ClusterPresetProtoClusterPresetSpecProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("singleNodeSpec" in data && data.singleNodeSpec != undefined) {
                this.singleNodeSpec = data.singleNodeSpec;
            }
            if ("multiNodeSpec" in data && data.multiNodeSpec != undefined) {
                this.multiNodeSpec = data.multiNodeSpec;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get singleNodeSpec() {
        return pb_1.Message.getWrapperField(this, ClusterPresetProtoClusterPresetSpecProto, 3) as ClusterPresetProtoClusterPresetSpecProto;
    }
    set singleNodeSpec(value: ClusterPresetProtoClusterPresetSpecProto) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasSingleNodeSpec() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get multiNodeSpec() {
        return pb_1.Message.getWrapperField(this, ClusterPresetProtoClusterPresetSpecProto, 4) as ClusterPresetProtoClusterPresetSpecProto;
    }
    set multiNodeSpec(value: ClusterPresetProtoClusterPresetSpecProto) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get hasMultiNodeSpec() {
        return pb_1.Message.getField(this, 4) != null;
    }
    static fromObject(data: {
        id?: string;
        name?: string;
        singleNodeSpec?: ReturnType<typeof ClusterPresetProtoClusterPresetSpecProto.prototype.toObject>;
        multiNodeSpec?: ReturnType<typeof ClusterPresetProtoClusterPresetSpecProto.prototype.toObject>;
    }): ClusterPresetProto {
        const message = new ClusterPresetProto({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.singleNodeSpec != null) {
            message.singleNodeSpec = ClusterPresetProtoClusterPresetSpecProto.fromObject(data.singleNodeSpec);
        }
        if (data.multiNodeSpec != null) {
            message.multiNodeSpec = ClusterPresetProtoClusterPresetSpecProto.fromObject(data.multiNodeSpec);
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            name?: string;
            singleNodeSpec?: ReturnType<typeof ClusterPresetProtoClusterPresetSpecProto.prototype.toObject>;
            multiNodeSpec?: ReturnType<typeof ClusterPresetProtoClusterPresetSpecProto.prototype.toObject>;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.singleNodeSpec != null) {
            data.singleNodeSpec = this.singleNodeSpec.toObject();
        }
        if (this.multiNodeSpec != null) {
            data.multiNodeSpec = this.multiNodeSpec.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.hasSingleNodeSpec)
            writer.writeMessage(3, this.singleNodeSpec, () => this.singleNodeSpec.serialize(writer));
        if (this.hasMultiNodeSpec)
            writer.writeMessage(4, this.multiNodeSpec, () => this.multiNodeSpec.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterPresetProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterPresetProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.singleNodeSpec, () => message.singleNodeSpec = ClusterPresetProtoClusterPresetSpecProto.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.multiNodeSpec, () => message.multiNodeSpec = ClusterPresetProtoClusterPresetSpecProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterPresetProto {
        return ClusterPresetProto.deserialize(bytes);
    }
}
export class ClusterPresetProtoClusterPresetSpecProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        machineType?: MachineTypeProto;
        storageType?: StorageTypeProto;
        storageGb?: number;
        serverCount?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("machineType" in data && data.machineType != undefined) {
                this.machineType = data.machineType;
            }
            if ("storageType" in data && data.storageType != undefined) {
                this.storageType = data.storageType;
            }
            if ("storageGb" in data && data.storageGb != undefined) {
                this.storageGb = data.storageGb;
            }
            if ("serverCount" in data && data.serverCount != undefined) {
                this.serverCount = data.serverCount;
            }
        }
    }
    get machineType() {
        return pb_1.Message.getWrapperField(this, MachineTypeProto, 1) as MachineTypeProto;
    }
    set machineType(value: MachineTypeProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasMachineType() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get storageType() {
        return pb_1.Message.getWrapperField(this, StorageTypeProto, 2) as StorageTypeProto;
    }
    set storageType(value: StorageTypeProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasStorageType() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get storageGb() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set storageGb(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get serverCount() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set serverCount(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        machineType?: ReturnType<typeof MachineTypeProto.prototype.toObject>;
        storageType?: ReturnType<typeof StorageTypeProto.prototype.toObject>;
        storageGb?: number;
        serverCount?: number;
    }): ClusterPresetProtoClusterPresetSpecProto {
        const message = new ClusterPresetProtoClusterPresetSpecProto({});
        if (data.machineType != null) {
            message.machineType = MachineTypeProto.fromObject(data.machineType);
        }
        if (data.storageType != null) {
            message.storageType = StorageTypeProto.fromObject(data.storageType);
        }
        if (data.storageGb != null) {
            message.storageGb = data.storageGb;
        }
        if (data.serverCount != null) {
            message.serverCount = data.serverCount;
        }
        return message;
    }
    toObject() {
        const data: {
            machineType?: ReturnType<typeof MachineTypeProto.prototype.toObject>;
            storageType?: ReturnType<typeof StorageTypeProto.prototype.toObject>;
            storageGb?: number;
            serverCount?: number;
        } = {};
        if (this.machineType != null) {
            data.machineType = this.machineType.toObject();
        }
        if (this.storageType != null) {
            data.storageType = this.storageType.toObject();
        }
        if (this.storageGb != null) {
            data.storageGb = this.storageGb;
        }
        if (this.serverCount != null) {
            data.serverCount = this.serverCount;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasMachineType)
            writer.writeMessage(1, this.machineType, () => this.machineType.serialize(writer));
        if (this.hasStorageType)
            writer.writeMessage(2, this.storageType, () => this.storageType.serialize(writer));
        if (this.storageGb != 0)
            writer.writeInt32(3, this.storageGb);
        if (this.serverCount != 0)
            writer.writeInt32(4, this.serverCount);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterPresetProtoClusterPresetSpecProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterPresetProtoClusterPresetSpecProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.machineType, () => message.machineType = MachineTypeProto.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.storageType, () => message.storageType = StorageTypeProto.deserialize(reader));
                    break;
                case 3:
                    message.storageGb = reader.readInt32();
                    break;
                case 4:
                    message.serverCount = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterPresetProtoClusterPresetSpecProto {
        return ClusterPresetProtoClusterPresetSpecProto.deserialize(bytes);
    }
}
export class TypeDBVersionProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        version?: string;
        sampleDatasets?: string[];
        singleNodeOnly?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("version" in data && data.version != undefined) {
                this.version = data.version;
            }
            if ("sampleDatasets" in data && data.sampleDatasets != undefined) {
                this.sampleDatasets = data.sampleDatasets;
            }
            if ("singleNodeOnly" in data && data.singleNodeOnly != undefined) {
                this.singleNodeOnly = data.singleNodeOnly;
            }
        }
    }
    get version() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set version(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get sampleDatasets() {
        return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
    }
    set sampleDatasets(value: string[]) {
        pb_1.Message.setField(this, 2, value);
    }
    get singleNodeOnly() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set singleNodeOnly(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        version?: string;
        sampleDatasets?: string[];
        singleNodeOnly?: boolean;
    }): TypeDBVersionProto {
        const message = new TypeDBVersionProto({});
        if (data.version != null) {
            message.version = data.version;
        }
        if (data.sampleDatasets != null) {
            message.sampleDatasets = data.sampleDatasets;
        }
        if (data.singleNodeOnly != null) {
            message.singleNodeOnly = data.singleNodeOnly;
        }
        return message;
    }
    toObject() {
        const data: {
            version?: string;
            sampleDatasets?: string[];
            singleNodeOnly?: boolean;
        } = {};
        if (this.version != null) {
            data.version = this.version;
        }
        if (this.sampleDatasets != null) {
            data.sampleDatasets = this.sampleDatasets;
        }
        if (this.singleNodeOnly != null) {
            data.singleNodeOnly = this.singleNodeOnly;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.version.length)
            writer.writeString(1, this.version);
        if (this.sampleDatasets.length)
            writer.writeRepeatedString(2, this.sampleDatasets);
        if (this.singleNodeOnly != false)
            writer.writeBool(3, this.singleNodeOnly);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TypeDBVersionProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TypeDBVersionProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.version = reader.readString();
                    break;
                case 2:
                    pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                    break;
                case 3:
                    message.singleNodeOnly = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TypeDBVersionProto {
        return TypeDBVersionProto.deserialize(bytes);
    }
}
export class CreditCardProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        brand?: string;
        name?: string;
        lastFourDigits?: string;
        expiryMonth?: number;
        expiryYear?: number;
        default?: boolean;
        address?: AddressProto;
        type?: CreditCardProtoType;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("brand" in data && data.brand != undefined) {
                this.brand = data.brand;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("lastFourDigits" in data && data.lastFourDigits != undefined) {
                this.lastFourDigits = data.lastFourDigits;
            }
            if ("expiryMonth" in data && data.expiryMonth != undefined) {
                this.expiryMonth = data.expiryMonth;
            }
            if ("expiryYear" in data && data.expiryYear != undefined) {
                this.expiryYear = data.expiryYear;
            }
            if ("default" in data && data.default != undefined) {
                this.default = data.default;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get brand() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set brand(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get lastFourDigits() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set lastFourDigits(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get expiryMonth() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set expiryMonth(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get expiryYear() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set expiryYear(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get default() {
        return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
    }
    set default(value: boolean) {
        pb_1.Message.setField(this, 7, value);
    }
    get address() {
        return pb_1.Message.getWrapperField(this, AddressProto, 8) as AddressProto;
    }
    set address(value: AddressProto) {
        pb_1.Message.setWrapperField(this, 8, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 9, CreditCardProtoType.UNKNOWN) as CreditCardProtoType;
    }
    set type(value: CreditCardProtoType) {
        pb_1.Message.setField(this, 9, value);
    }
    static fromObject(data: {
        id?: string;
        brand?: string;
        name?: string;
        lastFourDigits?: string;
        expiryMonth?: number;
        expiryYear?: number;
        default?: boolean;
        address?: ReturnType<typeof AddressProto.prototype.toObject>;
        type?: CreditCardProtoType;
    }): CreditCardProto {
        const message = new CreditCardProto({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.brand != null) {
            message.brand = data.brand;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.lastFourDigits != null) {
            message.lastFourDigits = data.lastFourDigits;
        }
        if (data.expiryMonth != null) {
            message.expiryMonth = data.expiryMonth;
        }
        if (data.expiryYear != null) {
            message.expiryYear = data.expiryYear;
        }
        if (data.default != null) {
            message.default = data.default;
        }
        if (data.address != null) {
            message.address = AddressProto.fromObject(data.address);
        }
        if (data.type != null) {
            message.type = data.type;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            brand?: string;
            name?: string;
            lastFourDigits?: string;
            expiryMonth?: number;
            expiryYear?: number;
            default?: boolean;
            address?: ReturnType<typeof AddressProto.prototype.toObject>;
            type?: CreditCardProtoType;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.brand != null) {
            data.brand = this.brand;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.lastFourDigits != null) {
            data.lastFourDigits = this.lastFourDigits;
        }
        if (this.expiryMonth != null) {
            data.expiryMonth = this.expiryMonth;
        }
        if (this.expiryYear != null) {
            data.expiryYear = this.expiryYear;
        }
        if (this.default != null) {
            data.default = this.default;
        }
        if (this.address != null) {
            data.address = this.address.toObject();
        }
        if (this.type != null) {
            data.type = this.type;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.brand.length)
            writer.writeString(2, this.brand);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.lastFourDigits.length)
            writer.writeString(4, this.lastFourDigits);
        if (this.expiryMonth != 0)
            writer.writeInt32(5, this.expiryMonth);
        if (this.expiryYear != 0)
            writer.writeInt32(6, this.expiryYear);
        if (this.default != false)
            writer.writeBool(7, this.default);
        if (this.hasAddress)
            writer.writeMessage(8, this.address, () => this.address.serialize(writer));
        if (this.type != CreditCardProtoType.UNKNOWN)
            writer.writeEnum(9, this.type);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreditCardProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreditCardProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.brand = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.lastFourDigits = reader.readString();
                    break;
                case 5:
                    message.expiryMonth = reader.readInt32();
                    break;
                case 6:
                    message.expiryYear = reader.readInt32();
                    break;
                case 7:
                    message.default = reader.readBool();
                    break;
                case 8:
                    reader.readMessage(message.address, () => message.address = AddressProto.deserialize(reader));
                    break;
                case 9:
                    message.type = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CreditCardProto {
        return CreditCardProto.deserialize(bytes);
    }
}
export enum CreditCardProtoType {
    UNKNOWN = 0,
    CREDIT = 1,
    DEBIT = 2,
    PREPAID = 3
}
export class AddressProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        city?: string;
        country?: string;
        line1?: string;
        line2?: string;
        postalCode?: string;
        state?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("city" in data && data.city != undefined) {
                this.city = data.city;
            }
            if ("country" in data && data.country != undefined) {
                this.country = data.country;
            }
            if ("line1" in data && data.line1 != undefined) {
                this.line1 = data.line1;
            }
            if ("line2" in data && data.line2 != undefined) {
                this.line2 = data.line2;
            }
            if ("postalCode" in data && data.postalCode != undefined) {
                this.postalCode = data.postalCode;
            }
            if ("state" in data && data.state != undefined) {
                this.state = data.state;
            }
        }
    }
    get city() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set city(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get country() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set country(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get line1() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set line1(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get line2() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set line2(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get postalCode() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set postalCode(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get state() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set state(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data: {
        city?: string;
        country?: string;
        line1?: string;
        line2?: string;
        postalCode?: string;
        state?: string;
    }): AddressProto {
        const message = new AddressProto({});
        if (data.city != null) {
            message.city = data.city;
        }
        if (data.country != null) {
            message.country = data.country;
        }
        if (data.line1 != null) {
            message.line1 = data.line1;
        }
        if (data.line2 != null) {
            message.line2 = data.line2;
        }
        if (data.postalCode != null) {
            message.postalCode = data.postalCode;
        }
        if (data.state != null) {
            message.state = data.state;
        }
        return message;
    }
    toObject() {
        const data: {
            city?: string;
            country?: string;
            line1?: string;
            line2?: string;
            postalCode?: string;
            state?: string;
        } = {};
        if (this.city != null) {
            data.city = this.city;
        }
        if (this.country != null) {
            data.country = this.country;
        }
        if (this.line1 != null) {
            data.line1 = this.line1;
        }
        if (this.line2 != null) {
            data.line2 = this.line2;
        }
        if (this.postalCode != null) {
            data.postalCode = this.postalCode;
        }
        if (this.state != null) {
            data.state = this.state;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.city.length)
            writer.writeString(1, this.city);
        if (this.country.length)
            writer.writeString(2, this.country);
        if (this.line1.length)
            writer.writeString(3, this.line1);
        if (this.line2.length)
            writer.writeString(4, this.line2);
        if (this.postalCode.length)
            writer.writeString(5, this.postalCode);
        if (this.state.length)
            writer.writeString(6, this.state);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.city = reader.readString();
                    break;
                case 2:
                    message.country = reader.readString();
                    break;
                case 3:
                    message.line1 = reader.readString();
                    break;
                case 4:
                    message.line2 = reader.readString();
                    break;
                case 5:
                    message.postalCode = reader.readString();
                    break;
                case 6:
                    message.state = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AddressProto {
        return AddressProto.deserialize(bytes);
    }
}
export class GCPAccountProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({
        id?: string;
        status?: PaymentMethodStatusProto;
    } & (({
        orderNumber?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orderNumber" in data && data.orderNumber != undefined) {
                this.orderNumber = data.orderNumber;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
        }
    }
    get orderNumber() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set orderNumber(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasOrderNumber() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 3, PaymentMethodStatusProto.DEACTIVATED) as PaymentMethodStatusProto;
    }
    set status(value: PaymentMethodStatusProto) {
        pb_1.Message.setField(this, 3, value);
    }
    get _order_number() {
        const cases: {
            [index: number]: "none" | "orderNumber";
        } = {
            0: "none",
            1: "orderNumber"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    static fromObject(data: {
        orderNumber?: string;
        id?: string;
        status?: PaymentMethodStatusProto;
    }): GCPAccountProto {
        const message = new GCPAccountProto({});
        if (data.orderNumber != null) {
            message.orderNumber = data.orderNumber;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        return message;
    }
    toObject() {
        const data: {
            orderNumber?: string;
            id?: string;
            status?: PaymentMethodStatusProto;
        } = {};
        if (this.orderNumber != null) {
            data.orderNumber = this.orderNumber;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOrderNumber)
            writer.writeString(1, this.orderNumber);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (this.status != PaymentMethodStatusProto.DEACTIVATED)
            writer.writeEnum(3, this.status);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GCPAccountProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GCPAccountProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orderNumber = reader.readString();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.status = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): GCPAccountProto {
        return GCPAccountProto.deserialize(bytes);
    }
}
export class AzureAccountProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        status?: PaymentMethodStatusProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 2, PaymentMethodStatusProto.DEACTIVATED) as PaymentMethodStatusProto;
    }
    set status(value: PaymentMethodStatusProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        name?: string;
        status?: PaymentMethodStatusProto;
    }): AzureAccountProto {
        const message = new AzureAccountProto({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        return message;
    }
    toObject() {
        const data: {
            name?: string;
            status?: PaymentMethodStatusProto;
        } = {};
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.status != PaymentMethodStatusProto.DEACTIVATED)
            writer.writeEnum(2, this.status);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AzureAccountProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AzureAccountProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.status = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AzureAccountProto {
        return AzureAccountProto.deserialize(bytes);
    }
}
export class AWSAccountProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        status?: PaymentMethodStatusProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 2, PaymentMethodStatusProto.DEACTIVATED) as PaymentMethodStatusProto;
    }
    set status(value: PaymentMethodStatusProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        id?: string;
        status?: PaymentMethodStatusProto;
    }): AWSAccountProto {
        const message = new AWSAccountProto({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            status?: PaymentMethodStatusProto;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.status != PaymentMethodStatusProto.DEACTIVATED)
            writer.writeEnum(2, this.status);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AWSAccountProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AWSAccountProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.status = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AWSAccountProto {
        return AWSAccountProto.deserialize(bytes);
    }
}
export class CreditsProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: Uint8Array;
        name?: string;
        currentAmount?: number;
        originalAmount?: number;
        startTime?: number;
        endTime?: number;
        validFor?: ProviderProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("currentAmount" in data && data.currentAmount != undefined) {
                this.currentAmount = data.currentAmount;
            }
            if ("originalAmount" in data && data.originalAmount != undefined) {
                this.originalAmount = data.originalAmount;
            }
            if ("startTime" in data && data.startTime != undefined) {
                this.startTime = data.startTime;
            }
            if ("endTime" in data && data.endTime != undefined) {
                this.endTime = data.endTime;
            }
            if ("validFor" in data && data.validFor != undefined) {
                this.validFor = data.validFor;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get currentAmount() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set currentAmount(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get originalAmount() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set originalAmount(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get startTime() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set startTime(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get endTime() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set endTime(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get validFor() {
        return pb_1.Message.getRepeatedWrapperField(this, ProviderProto, 7) as ProviderProto[];
    }
    set validFor(value: ProviderProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        name?: string;
        currentAmount?: number;
        originalAmount?: number;
        startTime?: number;
        endTime?: number;
        validFor?: ReturnType<typeof ProviderProto.prototype.toObject>[];
    }): CreditsProto {
        const message = new CreditsProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.currentAmount != null) {
            message.currentAmount = data.currentAmount;
        }
        if (data.originalAmount != null) {
            message.originalAmount = data.originalAmount;
        }
        if (data.startTime != null) {
            message.startTime = data.startTime;
        }
        if (data.endTime != null) {
            message.endTime = data.endTime;
        }
        if (data.validFor != null) {
            message.validFor = data.validFor.map(item => ProviderProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            name?: string;
            currentAmount?: number;
            originalAmount?: number;
            startTime?: number;
            endTime?: number;
            validFor?: ReturnType<typeof ProviderProto.prototype.toObject>[];
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.currentAmount != null) {
            data.currentAmount = this.currentAmount;
        }
        if (this.originalAmount != null) {
            data.originalAmount = this.originalAmount;
        }
        if (this.startTime != null) {
            data.startTime = this.startTime;
        }
        if (this.endTime != null) {
            data.endTime = this.endTime;
        }
        if (this.validFor != null) {
            data.validFor = this.validFor.map((item: ProviderProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.currentAmount != 0)
            writer.writeInt32(3, this.currentAmount);
        if (this.originalAmount != 0)
            writer.writeInt32(4, this.originalAmount);
        if (this.startTime != 0)
            writer.writeSint64(5, this.startTime);
        if (this.endTime != 0)
            writer.writeSint64(6, this.endTime);
        if (this.validFor.length)
            writer.writeRepeatedMessage(7, this.validFor, (item: ProviderProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreditsProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreditsProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.currentAmount = reader.readInt32();
                    break;
                case 4:
                    message.originalAmount = reader.readInt32();
                    break;
                case 5:
                    message.startTime = reader.readSint64();
                    break;
                case 6:
                    message.endTime = reader.readSint64();
                    break;
                case 7:
                    reader.readMessage(message.validFor, () => pb_1.Message.addToRepeatedWrapperField(message, 7, ProviderProto.deserialize(reader), ProviderProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CreditsProto {
        return CreditsProto.deserialize(bytes);
    }
}
export class DiscountProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: Uint8Array;
        name?: string;
        percentage?: number;
        startTime?: number;
        endTime?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("percentage" in data && data.percentage != undefined) {
                this.percentage = data.percentage;
            }
            if ("startTime" in data && data.startTime != undefined) {
                this.startTime = data.startTime;
            }
            if ("endTime" in data && data.endTime != undefined) {
                this.endTime = data.endTime;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get percentage() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set percentage(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get startTime() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set startTime(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get endTime() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set endTime(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        name?: string;
        percentage?: number;
        startTime?: number;
        endTime?: number;
    }): DiscountProto {
        const message = new DiscountProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.percentage != null) {
            message.percentage = data.percentage;
        }
        if (data.startTime != null) {
            message.startTime = data.startTime;
        }
        if (data.endTime != null) {
            message.endTime = data.endTime;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            name?: string;
            percentage?: number;
            startTime?: number;
            endTime?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.percentage != null) {
            data.percentage = this.percentage;
        }
        if (this.startTime != null) {
            data.startTime = this.startTime;
        }
        if (this.endTime != null) {
            data.endTime = this.endTime;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.percentage != 0)
            writer.writeInt32(3, this.percentage);
        if (this.startTime != 0)
            writer.writeSint64(4, this.startTime);
        if (this.endTime != 0)
            writer.writeSint64(5, this.endTime);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DiscountProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DiscountProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.percentage = reader.readInt32();
                    break;
                case 4:
                    message.startTime = reader.readSint64();
                    break;
                case 5:
                    message.endTime = reader.readSint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DiscountProto {
        return DiscountProto.deserialize(bytes);
    }
}
export class InvoiceProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uuid?: Uint8Array;
        number?: number;
        startTime?: number;
        endTime?: number;
        discountedAmount?: number;
        total?: number;
        products?: InvoiceProtoProduct[];
        payments?: InvoiceProtoPayment[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7, 8], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("number" in data && data.number != undefined) {
                this.number = data.number;
            }
            if ("startTime" in data && data.startTime != undefined) {
                this.startTime = data.startTime;
            }
            if ("endTime" in data && data.endTime != undefined) {
                this.endTime = data.endTime;
            }
            if ("discountedAmount" in data && data.discountedAmount != undefined) {
                this.discountedAmount = data.discountedAmount;
            }
            if ("total" in data && data.total != undefined) {
                this.total = data.total;
            }
            if ("products" in data && data.products != undefined) {
                this.products = data.products;
            }
            if ("payments" in data && data.payments != undefined) {
                this.payments = data.payments;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get number() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set number(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get startTime() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set startTime(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get endTime() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set endTime(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get discountedAmount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set discountedAmount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get total() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set total(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get products() {
        return pb_1.Message.getRepeatedWrapperField(this, InvoiceProtoProduct, 7) as InvoiceProtoProduct[];
    }
    set products(value: InvoiceProtoProduct[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    get payments() {
        return pb_1.Message.getRepeatedWrapperField(this, InvoiceProtoPayment, 8) as InvoiceProtoPayment[];
    }
    set payments(value: InvoiceProtoPayment[]) {
        pb_1.Message.setRepeatedWrapperField(this, 8, value);
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        number?: number;
        startTime?: number;
        endTime?: number;
        discountedAmount?: number;
        total?: number;
        products?: ReturnType<typeof InvoiceProtoProduct.prototype.toObject>[];
        payments?: ReturnType<typeof InvoiceProtoPayment.prototype.toObject>[];
    }): InvoiceProto {
        const message = new InvoiceProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.number != null) {
            message.number = data.number;
        }
        if (data.startTime != null) {
            message.startTime = data.startTime;
        }
        if (data.endTime != null) {
            message.endTime = data.endTime;
        }
        if (data.discountedAmount != null) {
            message.discountedAmount = data.discountedAmount;
        }
        if (data.total != null) {
            message.total = data.total;
        }
        if (data.products != null) {
            message.products = data.products.map(item => InvoiceProtoProduct.fromObject(item));
        }
        if (data.payments != null) {
            message.payments = data.payments.map(item => InvoiceProtoPayment.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            number?: number;
            startTime?: number;
            endTime?: number;
            discountedAmount?: number;
            total?: number;
            products?: ReturnType<typeof InvoiceProtoProduct.prototype.toObject>[];
            payments?: ReturnType<typeof InvoiceProtoPayment.prototype.toObject>[];
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.number != null) {
            data.number = this.number;
        }
        if (this.startTime != null) {
            data.startTime = this.startTime;
        }
        if (this.endTime != null) {
            data.endTime = this.endTime;
        }
        if (this.discountedAmount != null) {
            data.discountedAmount = this.discountedAmount;
        }
        if (this.total != null) {
            data.total = this.total;
        }
        if (this.products != null) {
            data.products = this.products.map((item: InvoiceProtoProduct) => item.toObject());
        }
        if (this.payments != null) {
            data.payments = this.payments.map((item: InvoiceProtoPayment) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.number != 0)
            writer.writeInt32(2, this.number);
        if (this.startTime != 0)
            writer.writeSint64(3, this.startTime);
        if (this.endTime != 0)
            writer.writeSint64(4, this.endTime);
        if (this.discountedAmount != 0)
            writer.writeInt32(5, this.discountedAmount);
        if (this.total != 0)
            writer.writeInt32(6, this.total);
        if (this.products.length)
            writer.writeRepeatedMessage(7, this.products, (item: InvoiceProtoProduct) => item.serialize(writer));
        if (this.payments.length)
            writer.writeRepeatedMessage(8, this.payments, (item: InvoiceProtoPayment) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvoiceProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvoiceProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.number = reader.readInt32();
                    break;
                case 3:
                    message.startTime = reader.readSint64();
                    break;
                case 4:
                    message.endTime = reader.readSint64();
                    break;
                case 5:
                    message.discountedAmount = reader.readInt32();
                    break;
                case 6:
                    message.total = reader.readInt32();
                    break;
                case 7:
                    reader.readMessage(message.products, () => pb_1.Message.addToRepeatedWrapperField(message, 7, InvoiceProtoProduct.deserialize(reader), InvoiceProtoProduct));
                    break;
                case 8:
                    reader.readMessage(message.payments, () => pb_1.Message.addToRepeatedWrapperField(message, 8, InvoiceProtoPayment.deserialize(reader), InvoiceProtoPayment));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvoiceProto {
        return InvoiceProto.deserialize(bytes);
    }
}
export enum InvoiceProtoStatus {
    UNPAID = 0,
    PAID = 1
}
export class InvoiceProtoProduct extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        description?: string;
        startTime?: number;
        endTime?: number;
        discountedAmount?: number;
        totalCost?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("description" in data && data.description != undefined) {
                this.description = data.description;
            }
            if ("startTime" in data && data.startTime != undefined) {
                this.startTime = data.startTime;
            }
            if ("endTime" in data && data.endTime != undefined) {
                this.endTime = data.endTime;
            }
            if ("discountedAmount" in data && data.discountedAmount != undefined) {
                this.discountedAmount = data.discountedAmount;
            }
            if ("totalCost" in data && data.totalCost != undefined) {
                this.totalCost = data.totalCost;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get description() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set description(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get startTime() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set startTime(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get endTime() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set endTime(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get discountedAmount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set discountedAmount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get totalCost() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set totalCost(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data: {
        name?: string;
        description?: string;
        startTime?: number;
        endTime?: number;
        discountedAmount?: number;
        totalCost?: number;
    }): InvoiceProtoProduct {
        const message = new InvoiceProtoProduct({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.description != null) {
            message.description = data.description;
        }
        if (data.startTime != null) {
            message.startTime = data.startTime;
        }
        if (data.endTime != null) {
            message.endTime = data.endTime;
        }
        if (data.discountedAmount != null) {
            message.discountedAmount = data.discountedAmount;
        }
        if (data.totalCost != null) {
            message.totalCost = data.totalCost;
        }
        return message;
    }
    toObject() {
        const data: {
            name?: string;
            description?: string;
            startTime?: number;
            endTime?: number;
            discountedAmount?: number;
            totalCost?: number;
        } = {};
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.description != null) {
            data.description = this.description;
        }
        if (this.startTime != null) {
            data.startTime = this.startTime;
        }
        if (this.endTime != null) {
            data.endTime = this.endTime;
        }
        if (this.discountedAmount != null) {
            data.discountedAmount = this.discountedAmount;
        }
        if (this.totalCost != null) {
            data.totalCost = this.totalCost;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.description.length)
            writer.writeString(2, this.description);
        if (this.startTime != 0)
            writer.writeSint64(3, this.startTime);
        if (this.endTime != 0)
            writer.writeSint64(4, this.endTime);
        if (this.discountedAmount != 0)
            writer.writeInt32(5, this.discountedAmount);
        if (this.totalCost != 0)
            writer.writeInt32(6, this.totalCost);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvoiceProtoProduct {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvoiceProtoProduct();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.description = reader.readString();
                    break;
                case 3:
                    message.startTime = reader.readSint64();
                    break;
                case 4:
                    message.endTime = reader.readSint64();
                    break;
                case 5:
                    message.discountedAmount = reader.readInt32();
                    break;
                case 6:
                    message.totalCost = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvoiceProtoProduct {
        return InvoiceProtoProduct.deserialize(bytes);
    }
}
export class InvoiceProtoPayment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        amount?: number;
        taxAmount?: number;
        taxPercentage?: number;
        description?: string;
        status?: InvoiceProtoStatus;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("amount" in data && data.amount != undefined) {
                this.amount = data.amount;
            }
            if ("taxAmount" in data && data.taxAmount != undefined) {
                this.taxAmount = data.taxAmount;
            }
            if ("taxPercentage" in data && data.taxPercentage != undefined) {
                this.taxPercentage = data.taxPercentage;
            }
            if ("description" in data && data.description != undefined) {
                this.description = data.description;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
        }
    }
    get amount() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set amount(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get taxAmount() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set taxAmount(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get taxPercentage() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set taxPercentage(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get description() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set description(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 5, InvoiceProtoStatus.UNPAID) as InvoiceProtoStatus;
    }
    set status(value: InvoiceProtoStatus) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        amount?: number;
        taxAmount?: number;
        taxPercentage?: number;
        description?: string;
        status?: InvoiceProtoStatus;
    }): InvoiceProtoPayment {
        const message = new InvoiceProtoPayment({});
        if (data.amount != null) {
            message.amount = data.amount;
        }
        if (data.taxAmount != null) {
            message.taxAmount = data.taxAmount;
        }
        if (data.taxPercentage != null) {
            message.taxPercentage = data.taxPercentage;
        }
        if (data.description != null) {
            message.description = data.description;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        return message;
    }
    toObject() {
        const data: {
            amount?: number;
            taxAmount?: number;
            taxPercentage?: number;
            description?: string;
            status?: InvoiceProtoStatus;
        } = {};
        if (this.amount != null) {
            data.amount = this.amount;
        }
        if (this.taxAmount != null) {
            data.taxAmount = this.taxAmount;
        }
        if (this.taxPercentage != null) {
            data.taxPercentage = this.taxPercentage;
        }
        if (this.description != null) {
            data.description = this.description;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.amount != 0)
            writer.writeInt32(1, this.amount);
        if (this.taxAmount != 0)
            writer.writeInt32(2, this.taxAmount);
        if (this.taxPercentage != 0)
            writer.writeInt32(3, this.taxPercentage);
        if (this.description.length)
            writer.writeString(4, this.description);
        if (this.status != InvoiceProtoStatus.UNPAID)
            writer.writeEnum(5, this.status);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvoiceProtoPayment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvoiceProtoPayment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.amount = reader.readInt32();
                    break;
                case 2:
                    message.taxAmount = reader.readInt32();
                    break;
                case 3:
                    message.taxPercentage = reader.readInt32();
                    break;
                case 4:
                    message.description = reader.readString();
                    break;
                case 5:
                    message.status = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InvoiceProtoPayment {
        return InvoiceProtoPayment.deserialize(bytes);
    }
}
export class ClusterPriceProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        running?: ClusterPriceProtoPrice;
        suspended?: ClusterPriceProtoPrice;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("running" in data && data.running != undefined) {
                this.running = data.running;
            }
            if ("suspended" in data && data.suspended != undefined) {
                this.suspended = data.suspended;
            }
        }
    }
    get running() {
        return pb_1.Message.getWrapperField(this, ClusterPriceProtoPrice, 1) as ClusterPriceProtoPrice;
    }
    set running(value: ClusterPriceProtoPrice) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasRunning() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get suspended() {
        return pb_1.Message.getWrapperField(this, ClusterPriceProtoPrice, 2) as ClusterPriceProtoPrice;
    }
    set suspended(value: ClusterPriceProtoPrice) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSuspended() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        running?: ReturnType<typeof ClusterPriceProtoPrice.prototype.toObject>;
        suspended?: ReturnType<typeof ClusterPriceProtoPrice.prototype.toObject>;
    }): ClusterPriceProto {
        const message = new ClusterPriceProto({});
        if (data.running != null) {
            message.running = ClusterPriceProtoPrice.fromObject(data.running);
        }
        if (data.suspended != null) {
            message.suspended = ClusterPriceProtoPrice.fromObject(data.suspended);
        }
        return message;
    }
    toObject() {
        const data: {
            running?: ReturnType<typeof ClusterPriceProtoPrice.prototype.toObject>;
            suspended?: ReturnType<typeof ClusterPriceProtoPrice.prototype.toObject>;
        } = {};
        if (this.running != null) {
            data.running = this.running.toObject();
        }
        if (this.suspended != null) {
            data.suspended = this.suspended.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasRunning)
            writer.writeMessage(1, this.running, () => this.running.serialize(writer));
        if (this.hasSuspended)
            writer.writeMessage(2, this.suspended, () => this.suspended.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterPriceProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterPriceProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.running, () => message.running = ClusterPriceProtoPrice.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.suspended, () => message.suspended = ClusterPriceProtoPrice.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterPriceProto {
        return ClusterPriceProto.deserialize(bytes);
    }
}
export class ClusterPriceProtoPrice extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        centsPerHour?: number;
    } & (({
        baseCentsPerHour?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("centsPerHour" in data && data.centsPerHour != undefined) {
                this.centsPerHour = data.centsPerHour;
            }
            if ("baseCentsPerHour" in data && data.baseCentsPerHour != undefined) {
                this.baseCentsPerHour = data.baseCentsPerHour;
            }
        }
    }
    get centsPerHour() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set centsPerHour(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get baseCentsPerHour() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set baseCentsPerHour(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasBaseCentsPerHour() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _base_cents_per_hour() {
        const cases: {
            [index: number]: "none" | "baseCentsPerHour";
        } = {
            0: "none",
            2: "baseCentsPerHour"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        centsPerHour?: number;
        baseCentsPerHour?: number;
    }): ClusterPriceProtoPrice {
        const message = new ClusterPriceProtoPrice({});
        if (data.centsPerHour != null) {
            message.centsPerHour = data.centsPerHour;
        }
        if (data.baseCentsPerHour != null) {
            message.baseCentsPerHour = data.baseCentsPerHour;
        }
        return message;
    }
    toObject() {
        const data: {
            centsPerHour?: number;
            baseCentsPerHour?: number;
        } = {};
        if (this.centsPerHour != null) {
            data.centsPerHour = this.centsPerHour;
        }
        if (this.baseCentsPerHour != null) {
            data.baseCentsPerHour = this.baseCentsPerHour;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.centsPerHour != 0)
            writer.writeInt64(1, this.centsPerHour);
        if (this.hasBaseCentsPerHour)
            writer.writeInt64(2, this.baseCentsPerHour);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterPriceProtoPrice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterPriceProtoPrice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.centsPerHour = reader.readInt64();
                    break;
                case 2:
                    message.baseCentsPerHour = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterPriceProtoPrice {
        return ClusterPriceProtoPrice.deserialize(bytes);
    }
}
export class APITokenProto extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3], [4]];
    constructor(data?: any[] | ({} & (({
        uuid?: Uint8Array;
    }) | ({
        name?: string;
    }) | ({
        id?: string;
    }) | ({
        createdAt?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasUuid() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
    }
    get hasName() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
    }
    get hasCreatedAt() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get _uuid() {
        const cases: {
            [index: number]: "none" | "uuid";
        } = {
            0: "none",
            1: "uuid"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _name() {
        const cases: {
            [index: number]: "none" | "name";
        } = {
            0: "none",
            2: "name"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _id() {
        const cases: {
            [index: number]: "none" | "id";
        } = {
            0: "none",
            3: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    get _created_at() {
        const cases: {
            [index: number]: "none" | "createdAt";
        } = {
            0: "none",
            4: "createdAt"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        name?: string;
        id?: string;
        createdAt?: number;
    }): APITokenProto {
        const message = new APITokenProto({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            name?: string;
            id?: string;
            createdAt?: number;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.createdAt != null) {
            data.createdAt = this.createdAt;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUuid)
            writer.writeBytes(1, this.uuid);
        if (this.hasName)
            writer.writeString(2, this.name);
        if (this.hasId)
            writer.writeString(3, this.id);
        if (this.hasCreatedAt)
            writer.writeSint64(4, this.createdAt);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): APITokenProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new APITokenProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.id = reader.readString();
                    break;
                case 4:
                    message.createdAt = reader.readSint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): APITokenProto {
        return APITokenProto.deserialize(bytes);
    }
}
export class TeamAPITokenProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        apiToken?: APITokenProto;
    } & (({
        spaceAccess?: TeamAPITokenProtoSpaceAccess;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("apiToken" in data && data.apiToken != undefined) {
                this.apiToken = data.apiToken;
            }
            if ("spaceAccess" in data && data.spaceAccess != undefined) {
                this.spaceAccess = data.spaceAccess;
            }
        }
    }
    get apiToken() {
        return pb_1.Message.getWrapperField(this, APITokenProto, 1) as APITokenProto;
    }
    set apiToken(value: APITokenProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasApiToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get spaceAccess() {
        return pb_1.Message.getWrapperField(this, TeamAPITokenProtoSpaceAccess, 2) as TeamAPITokenProtoSpaceAccess;
    }
    set spaceAccess(value: TeamAPITokenProtoSpaceAccess) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasSpaceAccess() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _space_access() {
        const cases: {
            [index: number]: "none" | "spaceAccess";
        } = {
            0: "none",
            2: "spaceAccess"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        apiToken?: ReturnType<typeof APITokenProto.prototype.toObject>;
        spaceAccess?: ReturnType<typeof TeamAPITokenProtoSpaceAccess.prototype.toObject>;
    }): TeamAPITokenProto {
        const message = new TeamAPITokenProto({});
        if (data.apiToken != null) {
            message.apiToken = APITokenProto.fromObject(data.apiToken);
        }
        if (data.spaceAccess != null) {
            message.spaceAccess = TeamAPITokenProtoSpaceAccess.fromObject(data.spaceAccess);
        }
        return message;
    }
    toObject() {
        const data: {
            apiToken?: ReturnType<typeof APITokenProto.prototype.toObject>;
            spaceAccess?: ReturnType<typeof TeamAPITokenProtoSpaceAccess.prototype.toObject>;
        } = {};
        if (this.apiToken != null) {
            data.apiToken = this.apiToken.toObject();
        }
        if (this.spaceAccess != null) {
            data.spaceAccess = this.spaceAccess.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasApiToken)
            writer.writeMessage(1, this.apiToken, () => this.apiToken.serialize(writer));
        if (this.hasSpaceAccess)
            writer.writeMessage(2, this.spaceAccess, () => this.spaceAccess.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamAPITokenProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamAPITokenProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.apiToken, () => message.apiToken = APITokenProto.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.spaceAccess, () => message.spaceAccess = TeamAPITokenProtoSpaceAccess.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamAPITokenProto {
        return TeamAPITokenProto.deserialize(bytes);
    }
}
export class TeamAPITokenProtoSpaceAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        spaceId?: string;
        accessLevel?: AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("spaceId" in data && data.spaceId != undefined) {
                this.spaceId = data.spaceId;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get spaceId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set spaceId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        spaceId?: string;
        accessLevel?: AccessLevelProto;
    }): TeamAPITokenProtoSpaceAccess {
        const message = new TeamAPITokenProtoSpaceAccess({});
        if (data.spaceId != null) {
            message.spaceId = data.spaceId;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            spaceId?: string;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.spaceId != null) {
            data.spaceId = this.spaceId;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.spaceId.length)
            writer.writeString(1, this.spaceId);
        if (this.accessLevel != AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamAPITokenProtoSpaceAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamAPITokenProtoSpaceAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.spaceId = reader.readString();
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamAPITokenProtoSpaceAccess {
        return TeamAPITokenProtoSpaceAccess.deserialize(bytes);
    }
}
export class SpaceAPITokenProto extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        apiToken?: APITokenProto;
    } & (({
        accessLevel?: AccessLevelProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("apiToken" in data && data.apiToken != undefined) {
                this.apiToken = data.apiToken;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get apiToken() {
        return pb_1.Message.getWrapperField(this, APITokenProto, 1) as APITokenProto;
    }
    set apiToken(value: APITokenProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasApiToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, AccessLevelProto.NONE) as AccessLevelProto;
    }
    set accessLevel(value: AccessLevelProto) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAccessLevel() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _access_level() {
        const cases: {
            [index: number]: "none" | "accessLevel";
        } = {
            0: "none",
            2: "accessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        apiToken?: ReturnType<typeof APITokenProto.prototype.toObject>;
        accessLevel?: AccessLevelProto;
    }): SpaceAPITokenProto {
        const message = new SpaceAPITokenProto({});
        if (data.apiToken != null) {
            message.apiToken = APITokenProto.fromObject(data.apiToken);
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            apiToken?: ReturnType<typeof APITokenProto.prototype.toObject>;
            accessLevel?: AccessLevelProto;
        } = {};
        if (this.apiToken != null) {
            data.apiToken = this.apiToken.toObject();
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasApiToken)
            writer.writeMessage(1, this.apiToken, () => this.apiToken.serialize(writer));
        if (this.hasAccessLevel)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SpaceAPITokenProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SpaceAPITokenProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.apiToken, () => message.apiToken = APITokenProto.deserialize(reader));
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SpaceAPITokenProto {
        return SpaceAPITokenProto.deserialize(bytes);
    }
}
