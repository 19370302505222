/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Routes } from "@angular/router";
import { ServerDetailsPageComponent } from "../module/cluster/server/server-details-page.component";
import { TeamSubsiteScaffoldComponent } from "../module/scaffold/team-subsite/team-subsite-scaffold.component";
import { SetupProjectComponent } from "../module/setup/project/setup-project.component";
import { SetupComponent } from "../module/setup/setup.component";
import { genericGuard, setupCompletedGuard, loggedOutGuard, setupGuard, teamJoinedNotSurveyedGuard } from "./auth-guard";

import { _404PageComponent } from "../module/404/404-page.component";
import { Internal404PageComponent } from "../module/404/internal/internal-404-page.component";
import { ClusterDeploymentPageComponent } from "../module/cluster/deploy/cluster-deployment-page.component";
import { ClusterDetailsPageComponent } from "../module/cluster/details/cluster-details-page.component";
import { ClustersPageComponent } from "../module/cluster/page/clusters-page.component";
import { PasswordResetPageComponent } from "../module/login/password-reset/password-reset-page.component";
import { LoginPageComponent } from "../module/login/login-page/login-page.component";
import { TeamSettingsPageComponent } from "../module/team/settings/team-settings-page.component";
import { UserSettingsPageComponent } from "../module/user/page/user-settings-page.component";
import { SpaceDetailsPageComponent } from "../module/space/details/space-details-page.component";
import { SpacesPageComponent } from "../module/space/page/spaces-page.component";
import { MemberDetailsPageComponent } from "../module/member/details/member-details-page.component";
import { MembersPageComponent } from "../module/member/page/members-page.component";
import { SpinnerComponent } from "typedb-platform-framework";
import { GcpMarketplaceIntegrationComponent } from "../module/team/gcp-marketplace-integration/gcp-marketplace-integration.component";
import {
    AzureMarketplaceIntegrationComponent
} from "../module/team/azure-marketplace-integration/azure-marketplace-integration.component";
import {
    AwsMarketplaceIntegrationComponent
} from "../module/team/aws-marketplace-integration/aws-marketplace-integration.component";
import { GetStartedPageComponent } from "../module/team/get-started/get-started-page.component";

const domains = {
    clusters: "clusters",
    spaces: "spaces",
    squads: "squads",
    members: "members",
    settings: "settings",
    getStarted: "get-started",
} as const;

export type RouteDomain = typeof domains[keyof typeof domains];

export const routeDomains = domains;

export const routes: Routes = [
    { path: "", component: SpinnerComponent, pathMatch: "full", canActivate: [genericGuard] },
    { path: "sign-in", component: LoginPageComponent, canActivate: [loggedOutGuard], title: "Sign In" },
    { path: "sign-up", component: LoginPageComponent, canActivate: [loggedOutGuard], title: "Sign Up" },
    { path: "forgot-password", component: LoginPageComponent, title: "Forgot Password" },
    { path: "verify-email", component: LoginPageComponent, title: "Verify Email" },
    {
        path: "setup",
        children: [
            { path: "", component: SetupComponent, pathMatch: "full", canActivate: [setupGuard], title: "Setup" },
            { path: "project", component: SetupProjectComponent, canActivate: [teamJoinedNotSurveyedGuard], data: { variant: "standalone" }, title: "Project Info" },
        ]
    },
    { path: "reset-password", component: PasswordResetPageComponent, canActivate: [loggedOutGuard], title: "Reset Password" },
    { path: "org", redirectTo: "team" },
    {
        path: "team/:team-id",
        canActivate: [setupCompletedGuard],
        component: TeamSubsiteScaffoldComponent,
        children: [
            { path: "", redirectTo: "get-started", pathMatch: "full" },
            { path: "settings", component: TeamSettingsPageComponent, data: { domain: domains.settings, }, title: "Team Settings" },
            { path: "get-started", component: GetStartedPageComponent, data: { domain: domains.getStarted, }, title: "Get Started" },
            { path: "clusters", component: ClustersPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "clusters/deploy", component: ClusterDeploymentPageComponent, data: { domain: domains.clusters, }, title: "Deploy Cluster" },
            { path: "spaces/:space-id/clusters/:cluster-id", component: ClusterDetailsPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "spaces/:space-id/clusters/:cluster-id/servers/:server-id", component: ServerDetailsPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "spaces", component: SpacesPageComponent, data: { domain: domains.spaces, }, title: "Spaces" },
            { path: "spaces/:space-id", component: SpaceDetailsPageComponent, data: { domain: domains.spaces, }, title: "Spaces" },
            { path: "members", component: MembersPageComponent, data: { domain: domains.members, }, title: "Members" },
            { path: "members/:user-id", component: MemberDetailsPageComponent, data: { domain: domains.members }, title: "Members" },
            { path: "projects", redirectTo: "spaces" },

            { path: "**", component: Internal404PageComponent, title: "404" },
        ],
    },
    {
        path: "marketplace",
        children: [
            {
                path: "gcp",
                canActivate: [setupCompletedGuard],
                component: GcpMarketplaceIntegrationComponent,
            },
            {
                path: "azure",
                canActivate: [setupCompletedGuard],
                component: AzureMarketplaceIntegrationComponent,
            },
            {
                path: "aws",
                canActivate: [setupCompletedGuard],
                component: AwsMarketplaceIntegrationComponent,
            }
        ]
    },
    {
        path: "settings",
        canActivate: [setupCompletedGuard],
        component: TeamSubsiteScaffoldComponent,
        children: [{ path: "", component: UserSettingsPageComponent, title: "User Settings" }],
    },
    { path: "**", component: _404PageComponent, title: "404" },
];
